import React from "react"
import { settlementTxColumns } from "../Table/settlementTxColumns"
import useSettlement from "../hooks/useSettlement"
import { queryKeys } from "constants/queryKeys"
import { Pagination } from "components/Pagination/PaginationComponent"
import Table from "components/table"
import PaginationInfo from "components/Pagination/paginationInfo"

type Props = {
  settlementId: number | undefined
  forPrint?: boolean
}
const PayoutTransactions: React.FC<Props> = ({ settlementId, forPrint }) => {
  const { settlementTx, txPagination } = useSettlement(
    queryKeys.settlement.fetchSettlementTransactions,
    settlementId
  )

  return (
    <div>
      <Table
        columns={settlementTxColumns}
        data={settlementTx.settlementTxList?.content}
        isLoading={
          settlementTx.isLoadingPayout || settlementTx.refetchingPayout
        }
        loadingSkeletonCount={10}
      />
      {!forPrint && (
        <>
          {settlementTx.settlementTxList?.content?.length > 0 ? (
            <div className="flex h-[4.3125rem] items-center justify-between bg-white-default px-5">
              <PaginationInfo pagination={txPagination} />

              <div>
                <Pagination pagination={txPagination} />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default PayoutTransactions
