import { useWallets } from "pages/wallets/hooks/useWallets"
import React from "react"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { setSelectedWallet } from "redux/wallet/walletSlice"
import { WalletType } from "types/wallet"
import NgnFlag from "assets/images/flags/NGN.png"

const WalletTabs = () => {
  const dispatch = useAppDispatch()
  const selectedWalet = useAppSelector((state) => state.wallet.selectedWallet)
  const {
    wallet: { walletData },
  } = useWallets()

  const handleTabClick = (wallet: WalletType) => {
    dispatch(setSelectedWallet(wallet))
  }

  return (
    <div className="flex gap-2">
      {walletData?.map((wallet: WalletType, index: number) => (
        <div
          key={index}
          className={`border border-gray-400 py-2 px-4 cursor-pointer rounded flex gap-2 items-center 
              bg-primary-default text-white-default shadow-lg
            }`}
          onClick={() => handleTabClick(wallet)}
        >
          {"NGN"}
          {/* {wallet.currencyCode} */}
          <img
            src={NgnFlag}
            className="w-4 h-4 rounded-full"
            // alt={wallet.currencyName}
          />
        </div>
      ))}
    </div>
  )
}

export default WalletTabs
