import { queryKeys } from "constants/queryKeys"
// import useBreadcrumb from "hooks/useBreadcrumb"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import {
  exportSettlementrecords,
  fetchPayoutTransactions,
  fetchSettlementdetails,
  fetchSettlements,
} from "../../../axios/services/payment-services"
import { useAppSelector } from "redux/hooks"
import { encodeSearchParams } from "utils/encodeSearchParams"
import { toast } from "react-toastify"

type FilterT = {
  status?: { label: string; value: string | undefined }
  transactionAmount: number | undefined
  payoutAmount: number | undefined
  startDate?: string
  endDate?: string
  settlementReference?: string | undefined
}

const useSettlement = (
  queryKey: string,
  settlementId?: number | undefined | null
) => {
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [pageSize] = useState(100)
  const [currentPage, setCurrentPage] = useState(0)
  const [payoutPageSize] = useState(100)
  const [payoutCurrentPage, setPayoutCurrentPage] = useState(0)
  const [searchParams, setSearchParams] = useState<FilterT>({
    status: { label: "All", value: undefined },
    startDate: "",
    transactionAmount: undefined,
    endDate: "",
    payoutAmount: undefined,
    settlementReference: "",
  })

  const profileDetails = useAppSelector((state) => state.profile.profile)
  const merchantId: number = profileDetails?.businessId ?? 0

  const filterMethods = useForm()
  const searchMethods = useForm()

  const {
    data: settlementResponse,
    isLoading,
    isFetching: refetchingSettlement,
  } = useQuery(
    [queryKeys.settlement.fetchSettlements, currentPage, searchParams],
    () =>
      fetchSettlements({
        merchantId,
        pageSize,
        currentPage,
        filter: encodeSearchParams({
          ...searchParams,
          status: searchParams.status?.value,
        }),
      }),
    { enabled: queryKey === queryKeys.settlement.fetchSettlements }
  )

  const { data: detailsResponse, isLoading: loadingDetails } = useQuery(
    [queryKeys.settlement.fetchSettlementDetails],
    () => fetchSettlementdetails({ merchantId, settlementId }),
    {
      enabled:
        !!settlementId &&
        queryKey === queryKeys.settlement.fetchSettlementDetails,
    }
  )

  const {
    data: settlementTxResponse,
    isLoading: isLoadingPayout,
    isFetching: refetchingPayout,
  } = useQuery(
    [queryKeys.settlement.fetchSettlementTransactions, payoutCurrentPage],
    () =>
      fetchPayoutTransactions({
        merchantId,
        settlementId,
        payoutPageSize,
        payoutCurrentPage,
      }),
    { enabled: queryKey === queryKeys.settlement.fetchSettlementTransactions }
  )

  const { mutate: exportSettlement, isLoading: isExporting } = useMutation(
    exportSettlementrecords,
    {
      onSuccess: (response, { type }) => {
        // Create a blob object from the response data
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        })

        // Create a download link for the blob and click it
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = `Settlement ${new Date()}.${
          type === "excel" ? "xlsx" : type
        }`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      onError: () => {
        //@ts-ignore
        toast.error("Unable to export file, try again")
      },
    }
  )

  const handleExport = (downloadType: string) => {
    exportSettlement({
      merchantId,
      type: downloadType,
      filter: encodeSearchParams({
        ...searchParams,
        status: searchParams.status?.value,
      }),
    })
  }

  //settlement
  const settlementList = settlementResponse?.data?.data.content
  const totalElements = settlementResponse?.data?.data?.totalElements
  const totalPages = settlementResponse?.data?.data?.totalPages

  //payout
  const settlementTxList = settlementTxResponse?.data?.data
  const totalTxElements = settlementTxResponse?.data?.data?.totalElements
  const totalTxPages = settlementTxResponse?.data?.data?.totalPages

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  const updateSearchParams = (payload: React.SetStateAction<FilterT>) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  const onFilter = (data: any) => {
    updateSearchParams({
      ...data,
    })
  }

  const onSearch = (data: any) => {
    setSearchParams({
      ...searchParams,
      ...data,
    })
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const handlePayoutPageChange = (page: { selected: number }) => {
    setPayoutCurrentPage(page.selected)
  }

  //pagination for settlement
  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  //pagination for payout
  const txPagination = {
    pageSize: payoutPageSize,
    currentPage: payoutCurrentPage,
    totalElements: totalTxElements,
    totalPages: totalTxPages,
    handlePageChange: handlePayoutPageChange,
  }

  const search = {
    searchMethods,
    handleSearch,
    onSearch,
    updateSearchParams,
    searchParams,
  }

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: filterMethods.reset,
  }

  const settlement = { settlementList, isLoading, refetchingSettlement }
  const settlementTx = { settlementTxList, isLoadingPayout, refetchingPayout }

  const exportManager = {
    showExportModal,
    setShowExportModal,
    handleExport,
    isExporting,
  }

  return {
    settlement,
    settlementTx,
    search,
    filter,
    pagination,
    txPagination,
    exportManager,
    detailsData: detailsResponse?.data?.data,
    loadingDetails,
  }
}

export default useSettlement
