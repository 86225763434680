import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { default as profileReducer } from "./profile/profileSlice"
import { default as uiReducer } from "./ui/uiSlice"
import { default as walletReducer } from "./wallet/walletSlice"
import { default as paymethodReducer } from "./paymethods/paymethodsSlice"
import { persistReducer, persistStore } from "redux-persist"
import sessionStorage from "redux-persist/lib/storage/session"

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  // whitelist: ["profileReducer"],
}

const rootReducer = combineReducers({
  ui: uiReducer,
  profile: profileReducer,
  wallet: walletReducer,
  paymethod: paymethodReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
