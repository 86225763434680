import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { paymethodData } from "constants/payment"
import React, { FC } from "react"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { setSelectedPaymethod } from "redux/paymethods/paymethodsSlice"
import { PaymethodType } from "types/payment"

type PaymentMethodProps = {
  payMethod: PaymethodType & { icon: JSX.Element }
  isSelected?: boolean
  onClick?: (paymethod: PaymethodType) => void
}

const PaymentMethods = () => {
  const dispatch = useAppDispatch()

  const { selectedPaymethod } = useAppSelector((state) => state.paymethod)

  const PaymentMethod: FC<PaymentMethodProps> = ({
    payMethod,
    isSelected,
    onClick,
  }) => {
    const handleClick = () => {
      onClick && onClick(payMethod)
    }
    return (
      <div
        className={`flex relative items-center rounded cursor-pointer border-l-4 py-4 ${
          isSelected
            ? "border-l-primary-default text-primary-default font-medium"
            : "text-gray-800"
        }`}
        onClick={handleClick}
      >
        {/* {isSelected ? (
          <div className="w-8 h-8 rounded-full border flex items-center justify-center border-primary-default absolute -top-2 -right-2 bg-white-default">
            <FontAwesomeIcon
              icon={faCheck}
              className={isSelected ? "text-primary-default" : ""}
            />
          </div>
        ) : null} */}

        <div
          className={`ml-8 font-normal ${
            isSelected ? "text-primary-default/80" : ""
          }`}
        >
          {payMethod.icon}
        </div>

        <div className="text-xl capitalize absolute left-20">
          {payMethod.name}
        </div>
      </div>
    )
  }

  const handlePaymethodClick = (payMethod: PaymethodType) => {
    dispatch(setSelectedPaymethod(payMethod))
  }

  return (
    <div className="flex flex-col min-w-[14rem] pb-10 bg-gray-200">
      <div className="mt-10 mb-6 text-xl pl-10">PAY WITH</div>
      {paymethodData.map((payMethod, index) => (
        <PaymentMethod
          key={index}
          payMethod={payMethod}
          isSelected={selectedPaymethod?.name === payMethod.name}
          onClick={handlePaymethodClick}
        />
      ))}
    </div>
  )
}

export default PaymentMethods
