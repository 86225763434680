import React from "react"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"
import { useParams } from "react-router-dom"
import { capitalizeFirstLetter } from "utils/capitalize-first-letter"

const InvalidInvoice = () => {
  const params = useParams()
  const errorType = params.errorType
  function BackToHome() {
    window.location.href = "https://tgipay.com"
    return
  }
  return (
    <div className=" pt-16 flex flex-col items-center mb-28">
      <div>
        <Logo className="h-10" />
      </div>

      <section className="mt-16 text-center">
        <h1 className="text-3xl font-semibold">
          Link {capitalizeFirstLetter(errorType)}
        </h1>

        <p className="mt-3 font-light">
          {errorType === "overdue"
            ? "This invoice is overdue"
            : errorType === "paid"
            ? "The invoice has been paid for. "
            : null}
        </p>
        <p className="font-light">
          Kindly contact the merchant for another invoice link
        </p>
        <div className="flex flex-col justify-center items-center mt-4">
          <button
            onClick={() => BackToHome()}
            className="bg-primary-default text-white-default px-5 py-2 rounded w-auto"
          >
            Return to Home
          </button>
        </div>
      </section>
    </div>
  )
}

export default InvalidInvoice
