import React, { Fragment, useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import { ReactComponent as Elipsis } from "../../../assets/icons/vertical-elipsis.svg"
import Status from "components/status/Status"
import { Popover, Transition } from "@headlessui/react"
import { formatAmount, formatWithCustomCurrency } from "utils/format-amount"
import { encodeSearchParams } from "utils/encodeSearchParams"

const columnHelper = createColumnHelper<Settlement>()

export const settlementColumns = [
  columnHelper.accessor("reference", {
    cell: (info) => info.getValue().toUpperCase(),
    header: () => <span>Reference</span>,
  }),
  columnHelper.accessor((row) => row.totalPayoutAmount, {
    id: "totalPayoutAmount",
    cell: (info) => (
      <span>{formatWithCustomCurrency(info.getValue(), "")}</span>
    ),
    header: () => <span>Settlement Amount (NGN)</span>,
  }),
  columnHelper.accessor("accountNumber", {
    header: () => "Account",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("settlementDate", {
    header: () => "Date",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const navigate = useNavigate()
      const [showModal, setShowModal] = useState(false)
      function closeModal() {
        setShowModal(false)
      }
      return (
        <>
          <Popover className="relative">
            {() => (
              <>
                <Popover.Button className="px-3">
                  <div className="text-center">
                    <Elipsis />
                  </div>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -top-1/4 left-3/4 z-50 mt-3 w-max -translate-x-full transform px-4 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black-default ring-opacity-5">
                      <div className="relative grid gap-8 bg-white-default p-5">
                        <div className="-m-3 flex items-center rounded p-1 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                          <div
                            className="ml-4"
                            onClick={() =>
                              navigate(
                                `details?settlementId=${originalData.id}`
                              )
                            }
                          >
                            <p className="text-sm">View Details</p>
                          </div>
                        </div>

                        <div className="-m-3 flex items-center rounded p-1 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                          <div
                            className="ml-4"
                            onClick={() =>
                              navigate(
                                `/settlement/dispute/?settlementId=${
                                  originalData.id
                                }&${encodeSearchParams(originalData)}`
                              )
                            }
                          >
                            <p className="text-sm">Raise Dispute</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </>
      )
    },
  }),
]
