import { faBank } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "components/button"
import Input from "components/input/input"
import StyledSelect from "components/select"
import React, { FC } from "react"
import { FormProvider, useForm, FieldValues } from "react-hook-form"
import * as yup from "yup"
import { PaymentComponentProps } from "types/payment"
import { banksForDirectDebit } from "constants/payment"
import { useMutation } from "react-query"
import { AxiosError } from "axios"
import { format } from "date-fns"
import {
  directDebitNotification,
  getDirectDebitStatus,
  initiateDirectDebit,
} from "../../../../axios/services/payment-services"
import { generatePaymentData } from "utils/generatePaymentData"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { error } from "console"
import { getUniqueId, randomId } from "utils/getUniqueId"

export const bankPayValidationSchema = yup.object().shape({
  bank: yup.object().shape({
    value: yup.string().required("Bank is required"),
  }),
  accountNumber: yup
    .string()
    .nullable()
    .matches(/^[0-9]+$/, "Account number be only digits")
    .matches(/^\d{10}$/, "Account Number must be exactly 10 digits")
    .required("Account Number is required"),
})

const PayViaBank: FC<PaymentComponentProps> = ({
  paymentData,
  customerData,
  successCallback,
}) => {
  const navigate = useNavigate()

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(bankPayValidationSchema),
  })

  const values = methods.watch()
  const { isValid } = methods.formState

  const { mutate: simulateNotification } = useMutation(
    directDebitNotification,
    {
      onSuccess: (response) => {
        console.log("notification response", response)
      },
      onError: (error: AxiosError) => {
        //@ts-expect-error
        toast.error(error.response?.data.message)
      },
    }
  )

  const { mutate: initiatePayWithBank, isLoading: initiatingDirectDebit } =
    useMutation(initiateDirectDebit, {
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
      onSuccess: (response) => {
        if (response?.data.status === true) {
          //notify first
          simulateNotification({
            data: {
              narration: "NIP-Direct Debit",
              message: "Transaction was successful",
              status: "Success",
              transactionReference: response.data.data,
              transactionStan: getUniqueId().toString(),
              platformTransactionReference: randomId().toString(),
              orinalTxnTransactionDate: format(new Date(), "dd-MM-yyyy"),
            },
            title: "Transaction Notice",
            message: "TGIPAY Direct Debit Notice",
            requestType: 3,
          })

          setTimeout(() => {
            verifyPaymentStatus(response.data.data)
          }, 0)
        }
      },
    })

  const { mutate: verifyPaymentStatus, isLoading: verifyingDirectDebit } =
    useMutation(getDirectDebitStatus, {
      onError: (error: AxiosError) => {
        //@ts-expect-error
        toast.error(error.response?.data.message)
      },
      onSuccess: (response) => {
        if (successCallback) {
          successCallback({
            successMessage: response?.data?.message,
            status: response?.data?.status,
          })
        } else {
          if (response?.data.status === true) {
            navigate("/make-payment/payment-success")
          }
        }
      },
      retry: 3,
    })

  const onSubmit = (values: FieldValues) => {
    const generatedPaymentData = generatePaymentData(
      paymentData.paymentType,
      paymentData,
      customerData
    )

    initiatePayWithBank({
      ...generatedPaymentData,
      sourceAccountNumber: values.accountNumber,
    })
  }

  return (
    <div>
      <div className="flex  flex-col justify-center">
        <FontAwesomeIcon
          size="4x"
          className="text-primary-default"
          icon={faBank}
        />

        <p className="mt-10 text-center text-xl">
          Choose your bank to start the payment
        </p>

        <div className="mt-6 w-[30rem] self-center">
          <FormProvider {...methods}>
            <StyledSelect
              control={methods.control}
              label=""
              isDisabled={false}
              isLoading={false}
              isClearable={false}
              isSearchable={true}
              name="bank"
              options={banksForDirectDebit}
              placeholder="Select your bank"
            />
            {values.bank ? (
              <div className="mb-4">
                <Input
                  label=""
                  placeholder="Account Number"
                  name="accountNumber"
                />
              </div>
            ) : null}
          </FormProvider>

          {values.bank ? (
            <div className="mt-10">
              <Button
                onClick={methods.handleSubmit(onSubmit)}
                text="Proceed"
                className="px-20 py-4"
                disabled={
                  !isValid || initiatingDirectDebit || verifyingDirectDebit
                }
                loading={initiatingDirectDebit || verifyingDirectDebit}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default PayViaBank
