import React, { Fragment } from "react"
import CloseIcon from "../../assets/icons/close.svg"
import { Transition } from "@headlessui/react"
import { twMerge } from "tailwind-merge"

interface DropdownProps {
  trigger: React.ReactNode
  content: React.ReactNode
  showBackdrop?: boolean
  contentContainerClass?: string
  showCloseButton?: boolean
  isOpen: boolean
  toggleOpen: () => void
}
export const Dropdown: React.FC<DropdownProps> = ({
  content,
  trigger,
  showBackdrop = true,
  contentContainerClass,
  showCloseButton = true,
  isOpen,
  toggleOpen,
}) => {
  const Overlay = () => (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        onClick={toggleOpen}
        className={`fixed inset-0 z-30 ${showBackdrop ? " bg-gray-100" : ""} `}
        aria-hidden="true"
      />
    </Transition.Child>
  )

  return (
    <div className="relative w-min">
      {React.cloneElement(trigger as React.ReactElement, {
        onClick: toggleOpen,
      })}

      {isOpen && (
        <Transition appear show={isOpen} as="div">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={twMerge(
                `bg-gray-100 absolute right-0 z-40 mt-2 rounded-md bg-white-default p-[0.8125rem] shadow-xl ${contentContainerClass}`
              )}
            >
              {showCloseButton ? (
                <img
                  className="absolute right-[0.8125rem] top-[0.8125rem] w-4 cursor-pointer"
                  src={CloseIcon}
                  alt="close"
                  onClick={toggleOpen}
                />
              ) : null}

              <div className=" mt-3">{content}</div>
            </div>
          </Transition.Child>
          <Overlay />
        </Transition>
      )}
    </div>
  )
}
