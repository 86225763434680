import React from "react"
import Button from "components/button"
import { ReactComponent as Logo } from "../../../../../assets/images/TGIPrimaryLogo.svg"
import { getCurrentDate } from "utils/getDateAndTime"
import { formatAmount } from "utils/format-amount"
import { BusinessProfileT } from "redux/profile/profileSlice"

interface PropsT {
  values: any
  isSavingInvoice: boolean
  isSendingInvoice: boolean
  saveInvoice: () => void
  sendInvoice: () => void
  businessDetails: BusinessProfileT
}

const BasicInvoicePreview = ({
  values,
  saveInvoice,
  sendInvoice,
  isSavingInvoice,
  isSendingInvoice,
  businessDetails,
}: PropsT) => {
  return (
    <div className="w-[50%] md:w-[35%]">
      <div className="text-center min-h-[2rem] font-medium">Preview</div>
      <div className="rounded-lg border-2 border-gray-600 h-[43rem] px-5 py-5">
        <div className="flex justify-between items-center">
          <Logo className=" w-16" />
          <div className="text-gray-default">{getCurrentDate()}</div>
        </div>
        <div className="flex mt-3">
          <div className="text-gray-default w-3/12">From</div>
          <div className=" w-4/6">{businessDetails?.name}</div>
        </div>
        <div className="flex mt-3">
          <div className="text-gray-default w-3/12">To</div>
          <div className="text-gray-default w-4/6">{values?.email}</div>
        </div>

        <div className="flex justify-between mt-10">
          <div className="font-semibold">Amount</div>
          <div className="">{formatAmount(values?.amount)}</div>
        </div>

        <hr className="border-black-default mt-8" />

        <div className="mt-8">
          <div className="font-medium">Notes</div>
          <div className="text-gray-default">{values?.notes}</div>
        </div>
      </div>
      <div className="mt-10 font-medium flex gap-6 justify-center items-center">
        <Button
          onClick={saveInvoice}
          loading={isSavingInvoice}
          disabled={isSavingInvoice}
          text="Save As Draft"
          variant="outline"
          className="w-auto px-3 py-1 rounded ring-gray-default outline-none text-gray-default"
        />
        <Button
          onClick={sendInvoice}
          loading={isSendingInvoice}
          disabled={isSendingInvoice}
          text="Send Invoice"
          className="w-auto px-3 py-1 rounded border"
        />
      </div>
    </div>
  )
}

export default BasicInvoicePreview
