import React, { useState } from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { securitySchema } from "./validation-schema"
import Input from "../../components/input/input"
import Button from "../../components/button"
import { Switch } from "@headlessui/react"
import SuccessModal from "./modals/success-modal"
import TwoFactorModal from "./modals/two-factor-modal"
import { useMutation, useQuery } from "react-query"
import {
  changePassword,
  fetch2faStatus,
  initiate2faStatusChange,
} from "../../axios/services/auth-services"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { useAppSelector } from "../../redux/hooks"
import FullScreenLoader from "../../components/loader/fullscreen-loader"
import { queryClient } from "../.."
import Spinner from "../../components/spinner/Spinner"

const Security = () => {
  const [twoFactor, setTwoFactor] = useState<boolean>(false)
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const [accessKey, setAccessKey] = useState<string>("")
  const [twoFactorModal, setTwoFactorModal] = useState<boolean>(false)
  const methods = useForm({
    resolver: yupResolver(securitySchema),
  })

  const { handleSubmit, setValue } = methods
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { isFetching } = useQuery(
    "fetch2faStatus",
    () => fetch2faStatus(profileDetails?.id),
    {
      onSuccess: (response) => {
        setTwoFactor(response.data?.data)
      },
    }
  )

  const { mutate: handleChangePassword, isLoading } = useMutation(
    changePassword,
    {
      onSuccess: () => {
        toast.success("Password changed successfully!")
        setValue("oldPassword", "")
        setValue("newPassword", "")
        setValue("repeatPassword", "")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    }
  )

  const { mutate: handleInitiate2faChange, isLoading: isInitiating } =
    useMutation(initiate2faStatusChange, {
      onSuccess: (response) => {
        setTwoFactorModal(true)
        setAccessKey(response.data?.data.accessKey)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    })

  const onSubmitPassword = (data: FieldValues) => {
    handleChangePassword({
      userId: profileDetails?.id,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      repeatNewPassword: data.repeatPassword,
    })
  }

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmitPassword)}>
          <div className="bg-white-default p-10">
            <div className="w-[100%] md:w-[50%]">
              <div className="mb-6">
                <Input
                  label="Old Password"
                  type="password"
                  name="oldPassword"
                />
              </div>

              <div className="mb-6">
                <Input
                  label="New Password"
                  type="password"
                  name="newPassword"
                />
              </div>
              <div className="mb-6">
                <Input
                  label="Repeat Password"
                  type="password"
                  name="repeatPassword"
                />
              </div>
            </div>

            <div className="mt-16">
              <h3 className="font-semibold mb-3">Two Factor Authentication</h3>
              <div className="mb-4">
                Add extra security to authenticate login to your account
              </div>
              {isFetching ? (
                <Spinner classNames="text-primary-default" />
              ) : (
                <div className="flex items-center">
                  <Switch
                    checked={twoFactor}
                    onChange={() =>
                      handleInitiate2faChange({
                        userId: profileDetails?.id,
                        enable: twoFactor ? false : true,
                      })
                    }
                    className={`${
                      twoFactor ? "bg-primary-default" : "bg-gray-700"
                    }
          relative inline-flex h-[18px] w-[38px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Enable Two Factor</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        twoFactor ? "translate-x-6 " : "translate-x-0"
                      }
            pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white-default shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>{" "}
                  <span className="ml-5">
                    {twoFactor ? "Disable" : "Enable"} Two Factor Authentication
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end mt-10 mb-20">
            <Button
              loading={isLoading}
              text="Save Changes"
              className="w-[10rem] px-5 py-2"
            />
          </div>
        </form>
      </FormProvider>

      {isInitiating && (
        <FullScreenLoader loading={isInitiating} message="Fetching OTP" />
      )}

      {twoFactorModal && (
        <TwoFactorModal
          closeModal={() => setTwoFactorModal(false)}
          isOpen={twoFactorModal}
          setSuccessModal={setSuccessModal}
          accessKey={accessKey}
          twoFactor={twoFactor}
        />
      )}

      {successModal && (
        <SuccessModal
          closeModal={() => {
            setSuccessModal(false)
            queryClient.invalidateQueries("fetch2faStatus")
          }}
          isOpen={successModal}
          twoFactor={twoFactor}
        />
      )}
    </div>
  )
}

export default Security
