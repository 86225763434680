import { AxiosError } from "axios"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { queryClient } from "../../.."
import { authorizeSettingsSettlementAccount } from "../../../axios/services/merchant-services"
import Button from "../../../components/button"
import OtpInput from "../../../components/input/otpInput"
import Modal from "../../../components/modal"

interface ModalPropsT {
  isOpen: boolean
  closeModal: () => void
  accessKey: string
  setIsEditing: (isEditing: boolean) => void
}

const SettlementOtpModal = ({
  accessKey,
  isOpen,
  closeModal,
  setIsEditing,
}: ModalPropsT) => {
  const [error, setError] = useState<string>("")
  const [modalOTP, setModalOtp] = useState<string>("")

  const OTPFieldValue = (otp: string[]) => {
    setModalOtp(otp.join(""))
  }

  const { mutate: authorizeSettlementAccount, isLoading } = useMutation(
    authorizeSettingsSettlementAccount,
    {
      onSuccess: () => {
        toast.success("Settlement Account saved successfully!")
        closeModal()
        queryClient.invalidateQueries("fetchBankDetails")
        setIsEditing(false)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (modalOTP?.length !== 6) {
      return setError("Please enter complete otp to continue")
    } else {
      authorizeSettlementAccount({
        otp: modalOTP,
        accessKey: accessKey,
      })
      return
    }
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen} maxWidth="max-w-[45rem]">
      <form className="px-10" onSubmit={handleSubmit}>
        <div className="mb-8 text-center">
          Enter the OTP sent to the business admin email for confirmation
        </div>
        <OtpInput
          fieldCount={6}
          onChange={(otp: string[]) => {
            OTPFieldValue(otp)
            setError("")
          }}
        />
        {error && (
          <div className="mt-2 text-center text-danger-default">{error}</div>
        )}
        <div className="mt-14">
          <Button loading={isLoading} text="Submit" />
        </div>
      </form>
    </Modal>
  )
}

export default SettlementOtpModal
