import jwt_decode from "jwt-decode"

export type TokenDetails = {
  sub: string
  userDetails: {
    id: number
    businessId: number
    firstName: string
    lastName: string
    email: string
    mobileNumber: string
    roleId: number
    roleName: string
    authorizations: string[]
    defaultRole: boolean
  }
  username: string
  iat: number
  exp: number
}

export const DecodeJwt = (token: string | null) => {
  try {
    if (token) {
      const decoded: TokenDetails = jwt_decode(token)
      return decoded
    }
  } catch (error) {
    return
  }
}
