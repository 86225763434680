import React from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import Input from "../../../components/input/input"
import Modal from "../../../components/modal"
import { paymentLinkSchema } from "../validation-schema"
import { yupResolver } from "@hookform/resolvers/yup"
import TextArea from "../../../components/input/textarea"
import Button from "../../../components/button"
import Checkbox from "../../../components/input/checkbox"
import { useMutation } from "react-query"
import { addPaymentLink } from "../../../axios/services/payment-services"
import { toast } from "react-toastify"
import { queryClient } from "../../.."
import { AxiosError } from "axios"
import { useAppSelector } from "../../../redux/hooks"

interface ModalPropsT {
  isOpen: boolean
  closeModal: () => void
  openSuccess: () => void
  setSelectedLink: (selectedLink: any) => void
}

const CreatePaymentLink = ({
  closeModal,
  isOpen,
  openSuccess,
  setSelectedLink,
}: ModalPropsT) => {
  const methods = useForm({
    resolver: yupResolver(paymentLinkSchema),
  })
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { handleSubmit, watch } = methods
  const values = watch()

  const { mutate: createPaymentLink, isLoading } = useMutation(addPaymentLink, {
    onSuccess: (response) => {
      const selectedData = {
        url: response.data?.data,
        name: values.name,
        description: values.description,
        amount: values.amount,
        fixedAmount: values.fixedAmount,
      }
      setSelectedLink(selectedData)
      closeModal()
      openSuccess()
      queryClient.invalidateQueries("fetchPaymentLinks")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const onSubmit = (data: FieldValues) => {
    createPaymentLink({
      businessId: profileDetails?.businessId,
      name: data.name,
      description: data.description,
      amount: parseFloat(data.amount).toFixed(2),
      fixedAmount: data.fixedAmount,
      currency: "NGN",
    })
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <FormProvider {...methods}>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8">
            <div className="font-semibold text-xl">Create Payment Link</div>
            <div>Create new link to receive payment</div>
          </div>

          <div className="mb-3">
            <Input label="Name" name="name" placeholder="Name your link" />
          </div>
          <div className="mb-3">
            <Input
              label="Amount"
              type="text"
              inputMode="numeric"
              name="amount"
            />
          </div>
          <div className=" flex justify-end mb-4 font-light">
            <Checkbox
              label="Fixed Amount"
              name="fixedAmount"
              checkedValue={values.fixedAmount}
            />
          </div>
          <div className="mb-3">
            <TextArea
              label="Description"
              name="description"
              placeholder="Give some description"
            />
          </div>
          <div className="mt-10 flex justify-end">
            <Button
              loading={isLoading}
              disabled={isLoading}
              className="w-max px-5 py-2 rounded-md"
              text="Generate Link"
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default CreatePaymentLink
