import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, useFieldArray, FieldValues } from "react-hook-form"
import ProfessionalInvoiceForm from "./components/professional-invoice-form"
import ProfessionalInvoicePreview from "./components/professional-invoice-preview"
import { ProfessionalInvoiceSchema } from "./validation-schema"
import {
  createInvoiceAndSave,
  createInvoiceAndSend,
} from "../../../../axios/services/payment-services"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { queryClient } from "index"
import { AxiosError } from "axios"
import { useAppSelector } from "redux/hooks"
import { formatDate } from "utils/getDateAndTime"
import { useNavigate } from "react-router-dom"

type itemT = {
  item: string
  quantity: number
  unitPrice: number
}

const CreateProfessionalInvoice = () => {
  const methods = useForm({
    resolver: yupResolver(ProfessionalInvoiceSchema),
  })
  const navigate = useNavigate()

  const profileDetails = useAppSelector((state) => state.profile.profile)
  const businessDetails = useAppSelector(
    (state) => state.profile.businessProfile
  )

  const { handleSubmit, watch, control } = methods
  const values = watch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  })

  const { mutate: saveNewInvoice, isLoading: isSavingInvoice } = useMutation(
    createInvoiceAndSave,
    {
      onSuccess: () => {
        toast.success("Invoice saved!")
        navigate("/invoicing")
        queryClient.invalidateQueries("fetchInvoices")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    }
  )

  const { mutate: sendNewInvoice, isLoading: isSendingInvoice } = useMutation(
    createInvoiceAndSend,
    {
      onSuccess: () => {
        toast.success("Invoice sent!")
        navigate("/invoicing")
        queryClient.invalidateQueries("fetchInvoices")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    }
  )

  const getItems = (items: itemT[]) => {
    const newItemList: itemT[] = []
    items.map((item: itemT, index) => {
      const newItem = {
        item: item.item,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
      }
      newItemList.push(newItem)
    })
    return newItemList
  }

  const calculateTotal = (items: itemT[]) => {
    let total = 0
    for (let i = 0; i < items.length; i++) {
      const subTotal =
        parseFloat(values?.items[i]?.unitPrice) *
        parseFloat(values?.items[i]?.quantity)
      total += subTotal
    }
    return total
  }

  const sendInvoice = (values: FieldValues) => {
    const payload = {
      businessId: profileDetails?.businessId,
      invoiceType: "professional",
      customerEmail: values.email,
      notes: values.notes,
      amount: calculateTotal(values.items),
      currency: "NGN",
      customerName: values.name,
      dueDate: formatDate(values.paymentDue),
      invoiceItems: getItems(values.items),
    }
    sendNewInvoice(payload)
  }

  const saveInvoice = () => {
    const payload = {
      businessId: profileDetails?.businessId,
      invoiceType: "professional",
      customerEmail: values.email,
      notes: values.notes,
      amount: calculateTotal(values.items),
      currency: "NGN",
      customerName: values.name,
      dueDate: formatDate(values.paymentDue),
      invoiceItems: getItems(values.items),
    }
    saveNewInvoice(payload)
  }

  return (
    <div className="flex gap-10 mb-20">
      <ProfessionalInvoiceForm
        methods={methods}
        fields={fields}
        append={append}
        remove={remove}
      />
      <ProfessionalInvoicePreview
        values={values}
        isSavingInvoice={isSavingInvoice}
        isSendingInvoice={isSendingInvoice}
        saveInvoice={handleSubmit(saveInvoice)}
        sendInvoice={handleSubmit(sendInvoice)}
        businessDetails={businessDetails}
      />
    </div>
  )
}

export default CreateProfessionalInvoice
