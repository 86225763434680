import React from "react"
import Button from "../../components/button"
import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg"
import { useNavigate } from "react-router-dom"

type PropsT = {
  totalElements: number
  setCreateUserModal: (open: boolean) => void
}

const TeamsActions = ({ totalElements, setCreateUserModal }: PropsT) => {
  const navigate = useNavigate()
  return (
    <div className="flex justify-end mb-10">
      <Button
        icon={<UsersIcon className="mr-2" />}
        text={`${totalElements ? totalElements : ""} Users`}
        className="w-max px-5 py-3 mr-5 rounded-md flex items-center bg-white-default hover:bg-white-default text-primary-default border-0 cursor-auto"
      />
      <Button
        onClick={() => navigate("/teams/manage-user-roles")}
        text="+ Manage User Roles"
        variant="outline"
        className="w-max px-5 py-3 mr-5 rounded-md items-center"
      />
      <Button
        onClick={() => setCreateUserModal(true)}
        text="+ Create New User"
        className="w-max px-5 py-3 rounded-md items-center"
      />
    </div>
  )
}

export default TeamsActions
