import { queryKeys } from "constants/queryKeys"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import {
  countByDate,
  countFailedByDate,
  countSuccessfulByDate,
  failedCountpointsByDate,
  successfulCountPointsByDate,
  totalValueByDate,
} from "../../../axios/services/transactions"
import { TransactionTimeDataType } from "../types/chart"
import { totalPendingSettlement } from "../../../axios/services/payment-services"
import { flipDateFormat } from "utils/flip-date-format"

type TransactionCountPoints = {
  dateTime: string
  totalAmount: number
  totalCount: number
}[]

const useDashboard = ({
  startDate,
  endDate,
  targetQuerykeys,
  dateGrouping,
  merchantId,
}: {
  startDate: string
  endDate: string
  targetQuerykeys: string[]
  dateGrouping?: string
  merchantId?: number | undefined | null
}) => {
  const filterMethods = useForm()
  const searchMethods = useForm()
  const { handleSubmit: handleFilter } = searchMethods

  const {
    data: successfulValuePointsResponse,
    isLoading: loadingSuccessfulValuePoints,
  } = useQuery(
    [queryKeys.dashboard.successfulCountPointsByDate, startDate, endDate],
    () =>
      successfulCountPointsByDate({
        startDate,
        endDate,
        dateGrouping,
        merchantId,
      }),
    {
      enabled: targetQuerykeys.includes(
        queryKeys.dashboard.successfulCountPointsByDate
      ),
    }
  )

  const {
    data: failedCountpointsByDateResponse,
    isLoading: loadingFailedCountpointsByDate,
  } = useQuery(
    [queryKeys.dashboard.failedCountpointsByDate, startDate, endDate],
    () =>
      failedCountpointsByDate({
        startDate,
        endDate,
        merchantId,
        dateGrouping,
      }),
    {
      enabled: targetQuerykeys.includes(
        queryKeys.dashboard.failedCountpointsByDate
      ),
    }
  )

  const {
    data: totalPendingSettlementResponse,
    isLoading: loadingPendingSettlement,
  } = useQuery(
    [queryKeys.dashboard.totalPendingSettlement, startDate, endDate],
    () =>
      totalPendingSettlement({
        startDate,
        endDate,
      }),
    {
      enabled: targetQuerykeys.includes(
        queryKeys.dashboard.totalPendingSettlement
      ),
    }
  )

  const { data: totalValueByDateResponse, isLoading: loadingTotalValueByDate } =
    useQuery(
      [queryKeys.dashboard.totalValueByDate, startDate, endDate],
      () =>
        totalValueByDate({
          startDate,
          endDate,
          merchantId,
        }),
      {
        enabled: targetQuerykeys.includes(queryKeys.dashboard.totalValueByDate),
      }
    )

  const {
    data: countSuccessfulByDateResponse,
    isLoading: loadingcountSuccessfulByDate,
  } = useQuery(
    [queryKeys.dashboard.countSuccessfulByDate, startDate, endDate],
    () =>
      countSuccessfulByDate({
        startDate,
        endDate,
        merchantId,
      }),
    {
      enabled: targetQuerykeys.includes(
        queryKeys.dashboard.countSuccessfulByDate
      ),
    }
  )

  const {
    data: countFailedByDateResponse,
    isLoading: loadingCountFailedByDate,
  } = useQuery(
    [queryKeys.dashboard.countFailedByDate, startDate, endDate],
    () =>
      countFailedByDate({
        startDate,
        endDate,
        merchantId,
      }),
    {
      enabled: targetQuerykeys.includes(queryKeys.dashboard.countFailedByDate),
    }
  )

  const { data: countByDateResponse, isLoading: loadingCountByDate } = useQuery(
    [queryKeys.dashboard.countByDate, startDate, endDate],
    () =>
      countByDate({
        startDate,
        endDate,
        merchantId,
      }),
    {
      enabled: targetQuerykeys.includes(queryKeys.dashboard.countByDate),
    }
  )

  const composeChartData = (
    failedCountpointsByDate: TransactionCountPoints,
    successfulCountPointsByDate: TransactionCountPoints
  ): TransactionTimeDataType => {
    const transactionTimeData: TransactionTimeDataType = []

    successfulCountPointsByDate?.forEach((_countpoint, index) => {
      transactionTimeData.push({
        name: successfulCountPointsByDate[index]?.dateTime,
        successfulTransactions:
          (successfulCountPointsByDate &&
            successfulCountPointsByDate[index]?.totalCount) ||
          0,
        failedTransactions:
          (failedCountpointsByDate &&
            failedCountpointsByDate[index]?.totalCount) ||
          0,
      })
    })
    return transactionTimeData
  }

  const transactionTimeData: TransactionTimeDataType = composeChartData(
    //Sort the array by date before passing it to composeChartData
    failedCountpointsByDateResponse?.data?.data.sort((a: any, b: any) => {
      const dateA = new Date(flipDateFormat(a.dateTime))
      const dateB = new Date(flipDateFormat(b.dateTime))
      return dateA.getTime() - dateB.getTime()
    }),
    successfulValuePointsResponse?.data?.data.sort((a: any, b: any) => {
      const dateA = new Date(flipDateFormat(a.dateTime))
      const dateB = new Date(flipDateFormat(b.dateTime))
      return dateA.getTime() - dateB.getTime()
    })
  )

  const loading = {
    loadingSuccessfulValuePoints,
    loadingFailedCountpointsByDate,
    loadingTotalValueByDate,
    loadingcountSuccessfulByDate,
    loadingCountFailedByDate,
    loadingCountByDate,
    loadingPendingSettlement,
  }

  const data = {
    successfulValuePoints: successfulValuePointsResponse?.data?.data,
    failedCountpointsByDate: failedCountpointsByDateResponse?.data?.data,
    totalValueByDate: totalValueByDateResponse?.data?.data,
    countSuccessfulByDate: countSuccessfulByDateResponse?.data?.data,
    countFailedByDate: countFailedByDateResponse?.data?.data,
    countByDate: countByDateResponse?.data?.data,
    pendingSettlement: totalPendingSettlementResponse?.data?.data,
  }

  const onFilter = (data: unknown) => {
    // console.log(data)
  }

  const filter = {
    handleFilter,
    filterMethods,
    onFilter,
    resetFilter: filterMethods.reset,
  }

  return { transactionTimeData, loading, data, filter }
}

export default useDashboard
