import {
  WalletTopUpRequest,
  CustomerPaymentLinkRequest,
  PaymentRequestData,
  PaymentChannel,
  InvoiceRequestData,
  PaymentIntegrationRequest,
} from "pages/make-payment/types/payment"

function generatePaymentData(
  paymentType: PaymentChannel,
  paymentData: any,
  customerData?: any
): PaymentRequestData {
  switch (paymentType) {
    case PaymentChannel.PaymentLink: {
      const amount: number = parseFloat(paymentData.amount!)
      const businessId: number = parseInt(paymentData.businessId!)
      const paymentLinkId: number = parseInt(paymentData.id!)

      const customerPaymentLinkRequest: CustomerPaymentLinkRequest = {
        businessId,
        paymentLinkId,
        firstName: customerData?.firstName,
        lastName: customerData?.lastName,
        email: customerData?.email,
        amount,
      }

      return {
        forPaymentLinkRequest: true,
        customerPaymentLinkRequest,
      }
    }
    case PaymentChannel.WalletTopUp: {
      const amount: number = parseFloat(paymentData.amount!)
      const businessId: number = parseInt(paymentData.businessId!)

      const cardTopUpRequest: WalletTopUpRequest = {
        businessId,
        amount,
      }

      return {
        forWalletTopUp: true,
        cardTopUpRequest,
        paymentChannel: paymentData?.paymentType,
      }
    }
    case PaymentChannel.Invoice: {
      const invoiceRequest: InvoiceRequestData = {
        ...paymentData,
      }

      return {
        forInvoiceRequest: true,
        invoiceRequest,
      }
    }
    case PaymentChannel.Integration: {
      const paymentIntegrationRequest: PaymentIntegrationRequest = {
        id: paymentData.id,
        businessId: paymentData.businessId,
        customerFirstName: paymentData.customerFirstName,
        customerLastName: paymentData.customerLastName,
        customerEmail: paymentData.customerEmail,
        amount: paymentData.amount,
        transactionReference: paymentData.transactionReference,
        currency: paymentData.currency,
      }
      return {
        paymentIntegrationRequest,
        forPaymentIntegrationRequest: true,
      }
    }
    default:
      throw new Error("Invalid payment type")
  }
}

export { generatePaymentData }
