import React, { useState } from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { BusinessDetailsSchema } from "./validation-schema"
import Input from "../../components/input/input"
import Button from "../../components/button"
import TextArea from "../../components/input/textarea"
import StyledSelect from "../../components/select"
import { AxiosError } from "axios"
import { useMutation, useQuery } from "react-query"
import {
  editBusinessDetails,
  fetchBusinessCategories,
  fetchBusinessDetailsByBusinessId,
} from "../../axios/services/merchant-services"
import { toast } from "react-toastify"
import { useAppSelector } from "../../redux/hooks"
import FullScreenLoader from "../../components/loader/fullscreen-loader"
import BusinessProfilePreview from "./preview-components/business-profile-preview"
import { queryClient } from "../../index"
import PhoneInputField from "../../components/input/phone-input"
import { handlePhoneNumber } from "../../utils/handlePhoneNumber"
import UploadBusinessLogo from "./preview-components/components/upload-business-logo"

type SelectT = {
  label: string
  value: string
  isDisabled?: boolean
}

const BusinessProfile = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [businessCategories, setBusinessCategories] = useState<Array<SelectT>>(
    []
  )
  const methods = useForm({
    resolver: yupResolver(BusinessDetailsSchema),
  })

  const { handleSubmit, setValue } = methods
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { data: businessDetails, isFetching: isFetchingBusinessDetails } =
    useQuery(
      "fetchBusinessDetails",
      // @ts-ignore
      () => fetchBusinessDetailsByBusinessId(profileDetails?.businessId),
      {
        onSuccess: (response) => {
          setValue("businessName", response.data?.data.name)
          setValue("businessDescription", response.data?.data.description)
          setValue("businessCategory", {
            label: response.data?.data.category,
            value: response.data?.data.categoryId,
          })
          setValue("businessAddress", response.data?.data.address)
          setValue("description", response.data?.data.description)
          setValue("website", response.data?.data.website)
          setValue("supportEmail", response.data?.data.supportEmail)
          setValue("supportPhone", response.data?.data.supportPhoneNumber)
        },
      }
    )

  const { isLoading: isLoadingBusinessCategories } = useQuery(
    "fetchBusinessCategories",
    fetchBusinessCategories,
    {
      onSuccess: (response) => {
        const busCatRes = response.data.data
        const busCat: Array<SelectT> = []
        for (let i = 0; i < busCatRes.length; i++) {
          const item = {
            label: busCatRes[i].name,
            value: busCatRes[i].id,
            isDisabled: false,
          }
          busCat.push(item)
        }
        setBusinessCategories([...busCat])
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const {
    mutate: handleBusinessDetailsUpdate,
    isLoading: isLoadingDetailsUpdate,
  } = useMutation(editBusinessDetails, {
    onSuccess: () => {
      toast.success("Business Details Saved Successfully!")
      queryClient.invalidateQueries("fetchBusinessDetails")
      setIsEditing(false)
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const onSubmit = (data: FieldValues) => {
    handleBusinessDetailsUpdate({
      businessId: profileDetails?.businessId,
      merchantUserId: profileDetails?.id,
      typeId: businessDetails?.data.data.typeId,
      state: businessDetails?.data.data.state,
      countryCode: businessDetails?.data.data.countryCode,
      city: businessDetails?.data.data.city,
      name: data.businessName,
      description: data.businessDescription,
      categoryId: data.businessCategory.value,
      category: data.businessCategory.label,
      address: data.businessAddress,
      supportEmailAddress: data.supportEmail,
      supportPhoneNumber: handlePhoneNumber(data.supportPhone),
      website: data.website,
    })
  }

  return (
    <>
      {isFetchingBusinessDetails ? (
        <FullScreenLoader
          loading={isFetchingBusinessDetails}
          message="Fetching Business Profile"
        />
      ) : (
        <div>
          {isEditing ? (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white-default p-10 block md:flex md:items-center">
                  <div className=" w-[100%] md:w-[70%]">
                    <div className="mb-6">
                      <Input
                        disabled
                        label="Business Name"
                        name="businessName"
                      />
                    </div>

                    <div className="mb-6">
                      <Input label="Business Address" name="businessAddress" />
                    </div>
                    <div className="mb-6 block md:flex md:justify-between">
                      <div className=" w-[100%] md:w-[45%]">
                        <StyledSelect
                          control={methods.control}
                          defaultValue={businessCategories[0]}
                          isDisabled={false}
                          isLoading={isLoadingBusinessCategories}
                          isClearable={false}
                          isSearchable={false}
                          name="businessCategory"
                          label="Business Category"
                          options={businessCategories}
                        />
                      </div>
                      <div className=" w-[100%] mt-6 md:w-[45%] md:mt-0">
                        <Input
                          label="Support Email Address"
                          name="supportEmail"
                        />
                      </div>
                    </div>
                    <div className="mb-6 block md:flex md:justify-between">
                      <div className=" w-[100%] md:w-[45%]">
                        <Input label="Website" name="website" />
                      </div>
                      <div className=" w-[100%] mt-6 md:mt-0 md:w-[45%]">
                        <PhoneInputField
                          methods={methods}
                          label="Support Number"
                          name="supportPhone"
                        />
                      </div>
                    </div>
                    <div className="mb-6">
                      <TextArea
                        label="Business Description"
                        name="businessDescription"
                      />
                    </div>
                  </div>

                  <div className=" w-[100%] md:w-[30%]">
                    <UploadBusinessLogo
                      businessId={profileDetails?.businessId}
                    />
                  </div>
                </div>

                <div className="flex justify-end mt-10 mb-20">
                  <Button
                    loading={isLoadingDetailsUpdate}
                    disabled={isLoadingDetailsUpdate}
                    text="Save Changes"
                    className="w-auto px-5 py-2"
                  />
                </div>
              </form>
            </FormProvider>
          ) : (
            <BusinessProfilePreview
              setIsEditing={setIsEditing}
              businessDetails={businessDetails?.data?.data}
            />
          )}
        </div>
      )}
    </>
  )
}

export default BusinessProfile
