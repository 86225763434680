export const currentDateTime = () => {
  const date = new Date()
  const options = { year: "numeric", month: "short", day: "2-digit" }

  const hours = ("0" + date.getHours()).slice(-2) // Ensures two-digit format for hours
  const minutes = ("0" + date.getMinutes()).slice(-2) // Ensures two-digit format for minutes
  const time = `${hours}:${minutes}`
  const day = date.toLocaleDateString("en-NG", options)

  return `Today: ${day} ${time}`
}

export const getCurrentDate = () => {
  const date = new Date()
  const options = { year: "numeric", month: "short", day: "2-digit" }
  const day = date.toLocaleDateString("en-NG", options)

  return `${day}`
}

export const formatDate = (date) => {
  const parts = date.split("-")
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`

  return formattedDate
}

export const formatDateFromBackend = (date) => {
  const parts = date.split("-")
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`

  return formattedDate
}
