import React from "react"
import { ReactComponent as Logo } from "../../../assets/images/TGIPrimaryLogo.svg"
import { formatAmount } from "utils/format-amount"
import Button from "components/button"
import { useNavigate } from "react-router-dom"
import { format } from "date-fns"

interface PropsT {
  invoiceData: InvoiceDetailsT
}

const BasicInvoice = ({ invoiceData }: PropsT) => {
  const navigate = useNavigate()
  const params = {
    invoiceRequest: encodeURIComponent(JSON.stringify(invoiceData)),
  }
  const searchParams = new URLSearchParams(params)
  const splitDate = invoiceData.sentDate.split("-")
  const sentDate: Date = new Date(
    parseInt(splitDate[0], 10),
    parseInt(splitDate[1], 10) - 1,
    parseInt(splitDate[2], 10)
  )
  return (
    <div className="">
      <div className=" px-5 py-5">
        <div className="">
          <Logo className=" w-16" />
        </div>
        <div className="flex justify-between items-center text-end">
          <div></div>
          <div>
            <div className="text-gray-default">
              Date sent: {format(sentDate, "dd MMMM yyyy")}
            </div>
            <div className="">From: {invoiceData.businessName}</div>
          </div>
        </div>
        <div className=" mt-3 font-medium text-sm">
          <div>{invoiceData.customerName}</div>
        </div>
        <div className=" font-medium text-sm">
          <div>{invoiceData.customerEmail}</div>
        </div>

        <div className="flex justify-between mt-10">
          <div className="font-semibold">Amount</div>
          <div className="">{formatAmount(invoiceData.amount)}</div>
        </div>

        <hr className="border-black-default mt-8" />

        <div className="mt-8">
          <div className="font-medium">Notes</div>
          <div className="text-gray-default">{invoiceData.notes}</div>
        </div>
      </div>
      <div className="mt-10 font-medium flex gap-6 justify-center items-center">
        <Button
          onClick={() =>
            navigate(
              `/make-invoice-card-payment/pay-now/?${searchParams.toString()}`
            )
          }
          text="Proceed To Payment"
          className="w-auto px-10 py-2 rounded-lg border"
        />
      </div>
    </div>
  )
}

export default BasicInvoice
