import React from "react"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"
import WhiteLogo from "../../assets/images/TGIWhiteLogo.png"
import { convertAmountToNumber } from "utils/convert-amount-to-number"
import CardPayment from "components/payment/payment-methods/card"

const MakeIntegrationCardPayment = () => {
  const params = new URLSearchParams(location.search)
  const id = params.get("id")
  const parsedId = parseInt(id!)
  const businessId = params.get("businessId")
  const parsedBusinessId = parseInt(businessId!)
  const customerFirstName = params.get("customerFirstName")
  const customerLastName = params.get("customerLastName")
  const customerEmail = params.get("customerEmail")
  const amount = convertAmountToNumber(params.get("amount")!)
  const transactionReference = params.get("transactionReference")
  const currency = params.get("currency")
  const cardProcessor = params.get("cardProcessor")

  const dataToSend = {
    paymentIntegrationRequest: {
      id: parsedId,
      businessId: parsedBusinessId,
      customerFirstName: customerFirstName,
      customerLastName: customerLastName,
      customerEmail: customerEmail,
      amount: amount,
      transactionReference: transactionReference,
      currency: currency,
    },
    forPaymentLinkRequest: false,
    forInvoiceRequest: false,
    forPaymentIntegrationRequest: true,
  }

  return (
    <div className="flex flex-col items-center">
      <div>
        <Logo className="h-10" />
      </div>
      <div className=" w-[95%] md:w-[80%] mt-10 p-8 border border-gray-600 rounded-xl bg-white-default flex items-center justify-between gap-10 ">
        <div className=" w-full md:w-8/12 pr-10">
          <CardPayment dataToSend={dataToSend} cardProcessor={cardProcessor} />
        </div>
        <div className=" hidden w-4/12 bg-primary-default rounded-lg self-stretch md:flex md:flex-col md:justify-center">
          <div className="text-white-default text-center mb-5">
            Secured By:{" "}
          </div>
          <div className="text-white-default flex justify-center">
            <img
              src={WhiteLogo}
              className=" object-contain w-1/3"
              alt="tgipay-logo"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MakeIntegrationCardPayment
