import { StylesConfig } from "react-select"

export const colourStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    width: "100%",
    padding: "0.1rem",
    outline: "none",
    "&:hover": {
      ...styles[":hover"],
      borderColor: "#204D88",
      outline: "none",
    },
    "&:focus-within": {
      ...styles[":focus-within"],
      border: "1px solid #204D88",
      outline: "none",
      boxShadow: "unset",
    },
    "&:focus": {
      ...styles[":focus"],
      border: "1px solid #204D88",
      outline: "none",
      boxShadow: "unset",
    },
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 9991,
    }
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      zIndex: 9991,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#204D88"
        : isFocused
        ? "#eaf1fa"
        : undefined,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "#fff"
        : isFocused
        ? "#292727"
        : undefined,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isFocused
            ? "#fff"
            : isSelected
            ? "#204D88"
            : "#204D88"
          : undefined,
      },
    }
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
}
