import React from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "../../components/button"
import Input from "../../components/input/input"
import { setPasswordValidationSchema } from "./validation-schema"
import { useNavigate, useSearchParams } from "react-router-dom"
import AuthLayout from "../../layout/public/authLayout"
import { AxiosError } from "axios"
import { useMutation } from "react-query"
import { resetPassword } from "../../axios/services/auth-services"
import { toast } from "react-toastify"

const SetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const methods = useForm({
    resolver: yupResolver(setPasswordValidationSchema),
  })

  const { handleSubmit } = methods

  const accessKey = searchParams.get("code")

  const { mutate: setUserPassword, isLoading } = useMutation(resetPassword, {
    onSuccess: () => {
      navigate("/set-password-success")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
      if (
        //@ts-ignore
        error.response?.data.message ===
        "Set password link expired, kindly reattempt password reset"
      ) {
        navigate("/forgot-password")
      }
    },
  })

  const handleSetPassword = (data: FieldValues) => {
    setUserPassword({
      accessKey: accessKey,
      password: data.password,
      repeatPassword: data.confirmPassword,
    })
  }
  return (
    <AuthLayout
      title="Set Password"
      content={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSetPassword)}>
            <div className="mb-4">
              <Input label="Password" name="password" type="password" />
            </div>
            <div className="mb-4">
              <Input
                label="Confirm Password"
                name="confirmPassword"
                type="password"
              />
            </div>

            <div className="w-100 mt-20">
              <Button
                loading={isLoading}
                disabled={isLoading}
                text="Continue"
              />
            </div>
          </form>
        </FormProvider>
      }
    />
  )
}

export default SetPassword
