import React from "react"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"

const IntegrationLinkInactive = () => {
  function BackToHome() {
    window.location.href = "https://tgipay.com"
    return
  }
  return (
    <div className=" pt-16 flex flex-col items-center mb-28">
      <div>
        <Logo className="h-10" />
      </div>

      <section className="mt-16 text-center">
        <h1 className="text-3xl font-semibold">Link Invalid</h1>

        <p className="mt-3 font-light">Unable to make payment</p>
        <p className="font-light">Try again</p>
        <div className="flex flex-col justify-center items-center mt-4">
          <button
            onClick={() => BackToHome()}
            className="bg-primary-default text-white-default px-5 py-2 rounded w-auto"
          >
            Return to Home
          </button>
        </div>
      </section>
    </div>
  )
}

export default IntegrationLinkInactive
