import { toast } from "react-toastify"

async function copyToClipboard(text: string | null | undefined): Promise<void> {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      if (text) {
        await navigator.clipboard.writeText(text)
        toast.success(`Text copied`)
      }
    } catch (err) {
      console.error("Failed to copy text using Clipboard API: ", err)
    }
  } else {
    // Fallback for older browsers
    const textArea = document.createElement("textarea")
    textArea.value = text || ""
    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    try {
      const successful = document.execCommand("copy")
      if (successful) {
        toast.success(`Text copied`)
      }
    } catch (err) {
      console.error("Failed to copy text using execCommand method: ", err)
    }
    document.body.removeChild(textArea)
  }
}

export { copyToClipboard }
