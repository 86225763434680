import crypto from "crypto-browserify"
import { JSEncrypt } from "jsencrypt"
import CryptoJS from "crypto-js"
import process from "process"
import { Buffer } from "buffer"

// define the public key
// @ts-ignore
const publicKey = process.env.REACT_APP_PAYMENT_ENCRYPTION_KEY

export const encryptData = (data) => {
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey)

  // convert the JSON object to a string
  const jsonDataString = JSON.stringify(data)

  // Create iv and secret_key
  const iv = crypto.randomBytes(16)
  const secret_key = crypto.randomBytes(32)

  // Create the iv and secret to WordArray
  const mod_iv = CryptoJS.lib.WordArray.create(iv)
  const mod_secret_key = CryptoJS.lib.WordArray.create(secret_key)

  // Encrypt iv and secret_key with public key to be sent back to the backend
  // @ts-ignore
  const encryptedIv = encrypt.encrypt(Buffer.from(iv).toString("base64"))
  const encryptedSecret = encrypt.encrypt(
    // @ts-ignore
    Buffer.from(secret_key).toString("base64")
  )

  const ciphertext = CryptoJS.AES.encrypt(jsonDataString, mod_secret_key, {
    iv: mod_iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  })

  // If you need to get the secret and iv in string
  // secret_key = CryptoJS.enc.Base64.stringify(mod_secret_key),
  // iv = CryptoJS.enc.Base64.stringify(mod_iv),

  // Print the ciphertext
  const encryptedData = {
    ciphertext: ciphertext.toString(),
    encryptedIv: encryptedIv,
    encryptedSecret: encryptedSecret,
  }
  return encryptedData
}

export const decryptData = (cipherText, secret, iv) => {
  const new_iv = CryptoJS.enc.Base64.parse(iv)
  const new_secret_key = CryptoJS.enc.Base64.parse(secret)
  const new_cipherText = CryptoJS.enc.Base64.parse(cipherText)

  const decryptedData = CryptoJS.AES.decrypt(
    { ciphertext: new_cipherText },
    new_secret_key,
    {
      iv: new_iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  )

  // Convert the decrypted data from a WordArray object to a string
  const decryptedDataString = CryptoJS.enc.Utf8.stringify(decryptedData)

  // Parse the decrypted data string as a JSON object
  const decryptedJsonData = JSON.parse(decryptedDataString)
  return decryptedJsonData
}
