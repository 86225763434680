import React from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { TransactionTimeT } from "../types/chart"

export const CustomLineChart: React.FC<{
  transactionData: TransactionTimeT[]
}> = ({ transactionData }) => {
  return (
    <div className=" h-[30rem] min-h-[30rem] w-full">
      {transactionData.length === 0 && (
        <div className="flex justify-center items-center h-full">
          <div className="text-2xl font-semibold text-gray-400">
            No data to display
          </div>
        </div>
      )}
      <ResponsiveContainer>
        <LineChart
          width={500}
          height={300}
          data={transactionData}
          margin={{ top: 50, right: 50, left: 50, bottom: 50 }}
        >
          <XAxis
            dataKey="name"
            tickMargin={10}
            tick={{ fontSize: 12 }}
            label={{
              value: "Time",
              offset: -30,
              position: "insideBottom",
            }}
          />
          <YAxis
            tickMargin={10}
            label={{
              value: "Number of Transactions",
              angle: -90,
              position: "insideBottomLeft",
            }}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="successfulTransactions"
            name="Successful"
            stroke="#204D88"
            activeDot={{ r: 8 }}
            strokeWidth={3}
          />
          <Line
            type="monotone"
            dataKey="failedTransactions"
            name="Failed"
            stroke="#C73232"
            strokeWidth={3}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
