import React, { useEffect } from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import Input from "../../../components/input/input"
import Modal from "../../../components/modal"
import { paymentLinkSchema } from "../validation-schema"
import { yupResolver } from "@hookform/resolvers/yup"
import TextArea from "../../../components/input/textarea"
import Button from "../../../components/button"
import Checkbox from "../../../components/input/checkbox"
import { useMutation } from "react-query"
import { editPaymentLink } from "../../../axios/services/payment-services"
import { queryClient } from "../../.."
import { AxiosError } from "axios"
import { toast } from "react-toastify"

interface ModalPropsT {
  isOpen: boolean
  closeModal: () => void
  selectedLink: LinkDetailsT
}

const EditPaymentLink = ({ closeModal, isOpen, selectedLink }: ModalPropsT) => {
  const methods = useForm({
    resolver: yupResolver(paymentLinkSchema),
  })

  const { handleSubmit, watch, setValue } = methods
  const values = watch()

  useEffect(() => {
    setValue("name", selectedLink.name)
    setValue("amount", selectedLink.amount)
    setValue("fixedAmount", selectedLink.fixedAmount)
    setValue("description", selectedLink.description)
  }, [])

  const { mutate: handleEdit, isLoading } = useMutation(editPaymentLink, {
    onSuccess: () => {
      toast.success("Payment Link Edited Successfully!")
      closeModal()
      queryClient.invalidateQueries("fetchPaymentLinks")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const onSubmit = (data: FieldValues) => {
    handleEdit({
      ...selectedLink,
      name: data.name,
      description: data.description,
      amount: parseFloat(data.amount).toFixed(2),
      fixedAmount: data.fixedAmount,
      currency: "NGN",
    })
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <FormProvider {...methods}>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8">
            <div className="font-semibold text-xl">Edit Payment Link</div>
            <div>Edit your payment link details</div>
          </div>

          <div className="mb-3">
            <Input label="Name" name="name" placeholder="Name your link" />
          </div>
          <div className="mb-3">
            <Input
              label="Amount"
              name="amount"
              type="text"
              inputMode="numeric"
            />
          </div>
          <div className=" flex justify-end mb-4 font-light">
            <Checkbox
              label="Fixed Amount"
              name="fixedAmount"
              checkedValue={values.fixedAmount}
            />
          </div>
          <div className="mb-3">
            <TextArea
              label="Description"
              name="description"
              placeholder="Give some description"
            />
          </div>
          <div className="mt-10 flex justify-end">
            <Button
              loading={isLoading}
              disabled={isLoading}
              className="w-max px-5 py-2 rounded-md"
              text="Save Changes"
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default EditPaymentLink
