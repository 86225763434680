import React from "react"
import { CustomLineChart } from "./components/custom-line-chart"
import { TransactionTimeDataType } from "./types/chart"
type Props = {
  transactionTimeData: TransactionTimeDataType
}
const TransactionsChart: React.FC<Props> = ({ transactionTimeData }) => {
  return (
    <div className="bg-white-default p-5 rounded min-h-[30rem]">
      <CustomLineChart transactionData={transactionTimeData} />
    </div>
  )
}

export default TransactionsChart
