import React from "react"
import Button from "../../../components/button"
import placeholderImage from "../../../assets/images/no-image.png"

type BusinessDetailsT = {
  setIsEditing: (isEditing: boolean) => void
  businessDetails: {
    id: number
    name: string
    type: string
    typeId: number
    city: string
    description: string
    category: string
    address: string
    state: string
    country: string
    countryCode: string
    approvalDate: null
    createdOn: number
    lastModifiedOn: number
    live: boolean
    approved: boolean
    supportPhoneNumber: string
    supportEmail: string
    website: string
    businessLogoUrl: string
    tin: string
  }
}

const BusinessProfilePreview = ({
  businessDetails,
  setIsEditing,
}: BusinessDetailsT) => {
  return (
    <div>
      <div className="bg-white-default block md:flex md:items-center">
        <div className="w-[100%] md:w-[60%] p-10">
          <table className="table-auto mt-2">
            <tbody>
              <tr>
                <td className="font-bold pb-4">Business Details</td>
              </tr>
              <tr className="">
                <td className="font-medium py-3 pr-10">Business Name</td>
                <td className="py-3">{businessDetails?.name}</td>
              </tr>
              <tr className="">
                <td className="font-medium py-3 pr-10">Business Address</td>
                <td className="py-3">{businessDetails?.address}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">Business Category</td>
                <td className="py-3">{businessDetails?.category}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">
                  Support E-mail Address
                </td>
                <td className="py-3">{businessDetails?.supportEmail}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">Website</td>
                <td className="py-3">{businessDetails?.website}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">Support Phone Number</td>
                <td className="py-3">{businessDetails?.supportPhoneNumber}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">Business Description</td>
                <td className="py-3">{businessDetails?.description}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">
                  Tax Identification Nnumber(TIN)
                </td>
                <td className="py-3">{businessDetails.tin}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="p-10 w-[100%] md:w-[40%] font-semibold">
          <h3 className=" mb-3">Business Logo</h3>
          <img
            src={
              businessDetails?.businessLogoUrl
                ? businessDetails?.businessLogoUrl
                : placeholderImage
            }
            alt="Business Logo"
            className=" h-52 w-52 custom-border p-2"
          />
        </div>
      </div>
      <div className=" flex justify-end mt-10 mb-36">
        <Button
          onClick={() => setIsEditing(true)}
          text="Edit Business Profile"
          className="rounded w-auto px-14 py-2"
        />
      </div>
    </div>
  )
}

export default BusinessProfilePreview
