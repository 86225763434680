import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { WalletType } from "types/wallet"

interface WalletState {
  selectedWallet: WalletType | null
  showTopupModal: boolean
  showWithdrawalModal: boolean
}

const initialState: WalletState = {
  selectedWallet: null,
  showTopupModal: false,
  showWithdrawalModal: false,
}

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setSelectedWallet: (state, action: PayloadAction<WalletType | null>) => {
      state.selectedWallet = action.payload
    },
    openTopupModal: (state) => {
      state.showTopupModal = true
      state.showWithdrawalModal = false
    },
    closeTopupModal: (state) => {
      state.showTopupModal = false
    },
    openWithdrawalModal: (state) => {
      state.showWithdrawalModal = true
      state.showTopupModal = false
    },
    closeWithdrawalModal: (state) => {
      state.showWithdrawalModal = false
    },
  },
})

export const {
  setSelectedWallet,
  openTopupModal,
  closeTopupModal,
  openWithdrawalModal,
  closeWithdrawalModal,
} = walletSlice.actions

export default walletSlice.reducer
