import React, { ReactNode } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Header from "../../components/header"
import Sidebar from "../../components/sidebar"
import MobileSidebar from "../../components/sidebar/mobile-sidebar"
import { useAppSelector } from "../../redux/hooks"
import { currentDateTime } from "../../utils/getDateAndTime"
import IdleTimer from "../../utils/IdleTimer"
import { ReactComponent as ExclamationCircle } from "../../assets/icons/exclamation-circle.svg"

type layoutPropsT = {
  title: string
  subTitle?: ReactNode
  children: React.ReactNode
}

const AppLayout = ({ title, subTitle, children }: layoutPropsT) => {
  const isSidebarOpen = useAppSelector((state) => state.ui.isSidebarOpen)
  const location = useLocation()
  const navigate = useNavigate()

  const businessDetails = useAppSelector(
    (state) => state.profile.businessProfile
  )

  const handleIdle = () => {
    sessionStorage.removeItem("access-token")
    sessionStorage.removeItem("refresh-token")
    navigate(`/session-expired?redirectUrl=${location.pathname}`)
  }

  return (
    <div className="flex min-h-screen relative">
      {isSidebarOpen && (
        <div className=" w-48 z-40 bg-white-default fixed top-0 left-0 md:hidden transition ease-in-out delay-150 duration-1000">
          <MobileSidebar />
        </div>
      )}
      <div className=" w-[10%] md:w-[12%] bg-white-default fixed top-0 left-0 hidden md:block">
        <Sidebar />
      </div>
      <div className=" w-[100%]  md:w-[88%]">
        <div className="fixed top-0 right-0 left-0 md:left-[12%] z-30">
          <Header />
        </div>
        <div className="absolute right-0 left-0 md:left-[12%]">
          <div className="px-5 lg:px-10">
            <div className=" text-end h-10 py-4  mt-16">
              <span className=" text-gray-default">{currentDateTime()}</span>
            </div>
            <div className=" text-3xl font-semibold py-2">{title}</div>
            <div className=" py-2 text-lg">{subTitle}</div>

            {businessDetails.approved ? null : (
              <>
                {businessDetails.kycSubmitted ? (
                  <div className=" bg-caution-default/10 py-4 px-5 flex items-center">
                    <ExclamationCircle />{" "}
                    <span className="ml-3">
                      Your verification application is under review.
                    </span>
                  </div>
                ) : (
                  <div className=" bg-primary-default/10 py-4 px-5 flex items-center">
                    <ExclamationCircle />{" "}
                    <span className="ml-3">
                      Your account is in test mode.{" "}
                      <Link
                        className="text-primary-default font-semibold"
                        to="./kyc"
                      >
                        Verify
                      </Link>{" "}
                      your account to perform Live transactions
                    </span>
                  </div>
                )}
              </>
            )}

            <div className=" mt-4">{children}</div>
          </div>
        </div>
      </div>
      <IdleTimer timeout={1800000} onIdle={handleIdle} />
    </div>
  )
}

export default AppLayout
