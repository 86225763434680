import React, { useState } from "react"
import { useForm, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"
import { paymentValidationSchema } from "./validation-schema"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { fetchPaymentLinkByAccessCode } from "../../axios/services/payment-services"
import FullScreenLoader from "../../components/loader/fullscreen-loader"
import PaymentHandler from "./payment-handler"
import { PaymentChannel } from "./types/payment"
import CustomerDetails from "components/payment/payment-methods/card/customer-details"

const MakePayment = () => {
  const [linkData, setLinkData] = useState<LinkDetailsT>()
  const navigate = useNavigate()
  const params = useParams()
  const methods = useForm({
    resolver: yupResolver(paymentValidationSchema),
  })
  const [customerDetails, setCustomerDetails] = useState<FieldValues>()
  const { setValue } = methods

  const { isFetching } = useQuery(
    "fetchPaymentLinkByAccessCode",
    () =>
      // @ts-ignore
      fetchPaymentLinkByAccessCode(params.id),
    {
      onSuccess: (response) => {
        const linkResponse = response.data?.data

        if (linkResponse.active && !linkResponse.deleted) {
          setLinkData(linkResponse)
          setValue("amount", linkResponse.amount)
        } else navigate("/make-payment/invalid-link")
      },
      onError: () => {
        navigate("/make-payment/invalid-link")
      },
    }
  )

  const handleCustomerDetailsForm = (values: FieldValues) => {
    setCustomerDetails(values)
  }

  if (customerDetails) {
    return (
      <div className=" pt-10 flex flex-col items-center mb-28">
        <div>
          <Logo className="h-10" />
        </div>
        <div className=" w-[95%] md:max-w-[60rem] lg:w-[62%] p-5 ">
          <PaymentHandler
            customerData={customerDetails}
            paymentData={{
              ...linkData,
              paymentType: PaymentChannel.PaymentLink,
            }}
            fullWidth
          />
        </div>
      </div>
    )
  }

  return (
    <div className=" pt-16 flex flex-col items-center mb-28">
      {isFetching ? (
        <FullScreenLoader
          loading={isFetching}
          message="Loading Payment Information"
        />
      ) : (
        <>
          <div>
            <Logo className="h-10" />
          </div>
          <div className=" w-[95%] md:w-[60%] lg:w-[35%] mt-10 p-5 card">
            <CustomerDetails
              linkData={linkData}
              formMethods={methods}
              handleFormSubmission={handleCustomerDetailsForm}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default MakePayment
