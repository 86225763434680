export const STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  KYC: {
    SUBMITTED: "submitted",
    APPROVED: "approved",
    DECLINED: "declined",
    REVIEW: "review",
    PENDING: "pending",
  },
  TRANSACTION: {
    PENDING: "pending",
    SUCCESSFUL: "successful",
    FAILED: "failed",
  },
  AUDIT: {
    SUCCESSFUL: "success",
    FAILED: "failed",
  },
  DISPUTES: {
    RESOLVED: "resolved",
    UNRESOLVED: "unresolved",
  },
}
