import React from "react"
import Button from "../../../components/button"

type BankInfoT = {
  setIsEditing: (isEditing: boolean) => void
  bankDetails: {
    bvn: string
    accountName: string
    accountNumber: string
    bankName: string
  }
}

const SettlementAccountPreview = ({ setIsEditing, bankDetails }: BankInfoT) => {
  return (
    <div>
      <div className=" w-[100%] md:w-[50%] lg:w-[40%] bg-white-default p-10">
        <table className="table-auto">
          <tr>
            <td className="font-bold pb-3">Bank Information</td>
          </tr>
          <tr className="">
            <td className="font-medium py-3 pr-10">BVN Of Business Director</td>
            <td className="py-3">{bankDetails?.bvn}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Bank</td>
            <td className="py-3">{bankDetails?.bankName}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Account Number</td>
            <td className="py-3">{bankDetails?.accountNumber}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Account Name</td>
            <td className="py-3">{bankDetails?.accountName}</td>
          </tr>
        </table>
      </div>

      <div className="flex justify-end mt-10 mb-36">
        <Button
          onClick={() => setIsEditing(true)}
          text="Edit Settlement Account"
          className=" w-auto px-5 py-2"
        />
      </div>
    </div>
  )
}

export default SettlementAccountPreview
