import { logout } from "../axios/services/auth-services"
import { useMutation } from "react-query"

export function useLogout() {
  const { mutate: logoutUser, isLoading: isLoggingUserOut } = useMutation(
    logout,
    {
      onSuccess: () => {
        sessionStorage.clear()
      },
      onError: () => {
        sessionStorage.clear()
        return
      },
    }
  )
  return { logoutUser, isLoggingUserOut }
}
