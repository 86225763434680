import React from "react"
import { Link, useLocation } from "react-router-dom"
import { FormProvider } from "react-hook-form"
import Button from "../../components/button"
import Input from "../../components/input/input"
import AuthLayout from "../../layout/public/authLayout"
import { useLogin } from "hooks/useLogin"

const ExpirationScreen = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const redirectUrl = queryParams.get("redirectUrl")
  const { methods, handleSubmit, onSubmit, isLoading } = useLogin(redirectUrl)
  return (
    <AuthLayout
      title="Your session has expired"
      subTitle="Your session has expired. Please log in again."
      content={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <Input label="Email Address" name="email" type="email" />
            </div>
            <div className="mb-4">
              <Input label="Password" name="password" type="password" />
            </div>
            <div className=" flex justify-end align-middle mt-10 mb-8">
              <div>
                <Link
                  to="/forgot-password"
                  className="text-primary-default text-sm"
                >
                  Forgot Password?
                </Link>
              </div>
            </div>

            <div className="w-100">
              <Button loading={isLoading} disabled={isLoading} text="Login" />
            </div>
          </form>
        </FormProvider>
      }
    />
  )
}

export default ExpirationScreen
