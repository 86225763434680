import React, { useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, useFieldArray } from "react-hook-form"
import EditProfessionalInvoiceForm from "./components/professional-invoice-form"
import EditProfessionalInvoicePreview from "./components/professional-invoice-preview"
import { ProfessionalInvoiceSchema } from "./validation-schema"
import { formatDate, formatDateFromBackend } from "utils/getDateAndTime"
import { useMutation } from "react-query"
import { editInvoice } from "../../../../axios/services/payment-services"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { queryClient } from "index"
import { AxiosError } from "axios"

type PropsT = {
  invoiceData: InvoiceDetailsT
}

type itemT = {
  item: string
  quantity: number
  unitPrice: number
}

const EditProfessionalInvoice = ({ invoiceData }: PropsT) => {
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(ProfessionalInvoiceSchema),
  })

  const { handleSubmit, watch, control, setValue } = methods
  const values = watch()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  })

  const calculateTotal = (items: itemT[]) => {
    let total = 0
    for (let i = 0; i < items.length; i++) {
      const subTotal =
        parseFloat(values?.items[i]?.unitPrice) *
        parseFloat(values?.items[i]?.quantity)
      total += subTotal
    }
    return total
  }

  const getItems = (items: itemT[]) => {
    const newItemList: itemT[] = []
    items.map((item: itemT) => {
      const newItem = {
        item: item.item,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
      }
      newItemList.push(newItem)
    })
    return newItemList
  }

  const { mutate: handleEditInvoice, isLoading: isEditingInvoice } =
    useMutation(editInvoice, {
      onSuccess: () => {
        toast.success("Invoice Updated!")
        navigate("/invoicing")
        queryClient.invalidateQueries("fetchInvoices")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    })

  const saveEditedInvoice = () => {
    handleEditInvoice({
      ...invoiceData,
      currency: "NGN",
      customerEmail: values.email,
      notes: values.notes,
      amount: calculateTotal(values.items),
      customerName: values.name,
      dueDate: formatDate(values.paymentDue),
      invoiceItems: getItems(values.items),
    })
  }

  useEffect(() => {
    setValue("name", invoiceData.customerName)
    setValue("email", invoiceData.customerEmail)
    setValue("items", invoiceData.invoiceItems)
    setValue("notes", invoiceData.notes)
    setValue("paymentDue", formatDateFromBackend(invoiceData.dueDate))
  }, [])

  return (
    <div className="flex gap-10 mb-20">
      <EditProfessionalInvoiceForm
        methods={methods}
        fields={fields}
        append={append}
        remove={remove}
      />
      <EditProfessionalInvoicePreview
        values={values}
        isEditingInvoice={isEditingInvoice}
        invoiceData={invoiceData}
        handleUpdate={handleSubmit(saveEditedInvoice)}
      />
    </div>
  )
}

export default EditProfessionalInvoice
