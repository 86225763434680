import React, { useState } from "react"
import { useQuery } from "react-query"
import { Pagination } from "../../components/Pagination/PaginationComponent"
import PaginationInfo from "../../components/Pagination/paginationInfo"
import Table from "../../components/table"
import AppLayout from "../../layout/private/app-layout"
import { invoiceColumns } from "./columns/invoices-table"
import InvoicingActions from "./components/invoicing-actions"
import NoInvoicesFound from "./components/no-invoices-found"
import CreateInvoiceModal from "./modals/create-invoice-modal"
import { fetchAllInvoices } from "../../axios/services/payment-services"
import { useAppSelector } from "redux/hooks"
import PermissionGate from "components/permission-gate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/permission-gate/ListAccessDenied"
import { encodeSearchParams } from "utils/encodeSearchParams"

type FilterT = {
  paymentStatus?: { label: string; value: string | undefined }
  requestStatus?: { label: string; value: string | undefined }
  amount: number | undefined
  startDate?: string
  endDate?: string
  search?: string | undefined
  invoiceNumber?: string
}

const Invoicing = () => {
  const [invoices, setInvoices] = useState<Array<InvoiceDetailsT>>([])
  const [createInvoiceModal, setCreateInvoiceModal] = useState<boolean>(false)
  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [searchParams, setSearchParams] = useState<FilterT>({
    paymentStatus: { label: "All", value: undefined },
    requestStatus: { label: "All", value: undefined },
    startDate: "",
    amount: undefined,
    endDate: "",
    invoiceNumber: "",
  })
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { data: invoicesDataRes, isFetching } = useQuery(
    ["fetchInvoices", currentPage, pageSize, searchParams],
    // @ts-ignore
    () =>
      fetchAllInvoices({
        businessId: profileDetails?.businessId ?? 0,
        currentPage: currentPage,
        pageSize: pageSize,
        filter: encodeSearchParams({
          paymentStatus: searchParams.paymentStatus?.value,
          requestStatus: searchParams.requestStatus?.value,
          amount: searchParams.amount,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          invoiceNumber: searchParams.invoiceNumber,
        }),
      })
  )

  const invoicesData: InvoiceDetailsT[] = invoicesDataRes?.data?.data?.content
  const totalElements = invoicesDataRes?.data?.data?.totalElements
  const totalPages = invoicesDataRes?.data?.data?.totalPages

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  return (
    <AppLayout
      title="Invoicing"
      subTitle="Create and send an invoice to request for payments."
    >
      <PermissionGate
        allowedPermissions={[
          PERMISSIONS.CREATE_INVOICE,
          PERMISSIONS.MANAGE_INVOICE,
        ]}
        fallback={<ListAccessDenied />}
      >
        {invoicesData?.length === 0 ? (
          <NoInvoicesFound setCreateInvoiceModal={setCreateInvoiceModal} />
        ) : (
          <div>
            <InvoicingActions
              createInvoice={() => setCreateInvoiceModal(true)}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <div className="mt-4 border border-[#D3D3D3] mb-20">
              <Table
                columns={invoiceColumns}
                data={invoicesData}
                isLoading={isFetching}
                loadingSkeletonCount={10}
              />
              <div className="mt-4">
                <div className="flex h-[69px] items-center justify-between bg-white-default px-5">
                  <PaginationInfo pagination={pagination} />

                  <div>
                    <Pagination pagination={pagination} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {createInvoiceModal && (
          <CreateInvoiceModal
            closeModal={() => setCreateInvoiceModal(false)}
            isOpen={createInvoiceModal}
          />
        )}
      </PermissionGate>
    </AppLayout>
  )
}

export default Invoicing
