import React, { useEffect, useState } from "react"
import Input from "../../components/input/input"
import Button from "../../components/button"
import StyledSelect from "../../components/select"
import { Link } from "react-router-dom"
import SignupLayout from "../../layout/public/signupLayout"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { signUpSchema } from "./validation-schema"
import { useNavigate } from "react-router-dom"
import { ErrorMessage } from "@hookform/error-message"
import { useMutation, useQuery } from "react-query"
import { AxiosError } from "axios"
import { signUp } from "../../axios/services/auth-services"
import { toast } from "react-toastify"
import { fetchBusinessTypes } from "../../axios/services/merchant-services"
import { ReactComponent as ExclamationIcon } from "assets/icons/exclamation-circle.svg"
import Tooltip from "components/tooltip"
import { BUSINESS_TYPES } from "constants/kyc"

type SelectT = {
  label: string
  value: string
  isDisabled: boolean
}

const SignUp = () => {
  const navigate = useNavigate()
  const [countries] = useState<Array<SelectT>>([
    { label: "Nigeria", value: "NG", isDisabled: true },
  ])
  const [passwordStrength, setPasswordStrength] = useState<string>("")
  const methods = useForm({
    resolver: yupResolver(signUpSchema),
  })

  const { handleSubmit, formState } = methods
  const values = methods.watch()

  useQuery("fetchBusinessTypes", fetchBusinessTypes, {
    onSuccess: (response) => {
      const busTypeRes = response.data.data
      const busTypes: Array<SelectT> = []

      for (let i = 0; i < busTypeRes.length; i++) {
        const item = {
          label: busTypeRes[i].name,
          value: busTypeRes[i].id,
          isDisabled: false,
        }
        busTypes.push(item)
      }

      const registeredBusinessType = busTypeRes.find(
        (busType: { name: string }) =>
          busType.name === BUSINESS_TYPES.registered
      )

      if (registeredBusinessType) {
        methods.setValue("businessType", {
          label: registeredBusinessType.name,
          value: registeredBusinessType.id,
          isDisabled: false,
        })
        methods.trigger("businessType")
      }
    },
  })

  const { mutate: signUpUser, isLoading } = useMutation(signUp, {
    onSuccess: () => {
      toast.success("Signup successfully!")
      navigate("/signup-success")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const onSubmit = (data: FieldValues) => {
    signUpUser({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      businessName: data.businessName,
      businessTypeId: Number(data.businessType.value),
      countryCode: "NG",
      country: "Nigeria",
      password: data.password,
      repeatPassword: data.repeatPassword,
    })
  }

  function checkPasswordStrength(input_string: string) {
    const n = input_string?.length
    // Checking lower alphabet in string
    let hasLower = false
    let hasUpper = false
    let hasDigit = false
    let specialChar = false
    const normalChars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890 "

    for (let i = 0; i < n; i++) {
      if (input_string[i] >= "a" && input_string[i] <= "z") {
        hasLower = true
      }
      if (input_string[i] >= "A" && input_string[i] <= "Z") {
        hasUpper = true
      }
      if (input_string[i] >= "0" && input_string[i] <= "9") {
        hasDigit = true
      }
      if (!normalChars.includes(input_string[i])) {
        specialChar = true
      }
    }

    // Strength of password
    let strength = "Weak"
    if (hasLower && hasUpper && hasDigit && specialChar && n >= 8) {
      strength = "Strong"
    } else if ((hasLower || hasUpper) && specialChar && n >= 6) {
      strength = "Medium"
    }

    setPasswordStrength(strength)
  }

  useEffect(() => {
    checkPasswordStrength(values.password)
  }, [values.password])

  return (
    <SignupLayout
      content={
        <div className="w-[100%] md:w-[70%]">
          <h2 className=" text-3xl font-semibold mb-14 md:mb-6 text-center md:text-left">
            Sign Up
          </h2>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex justify-between mb-2">
                <div className=" w-[47%]">
                  <Input label="First Name" name="firstName" />
                </div>
                <div className=" w-[47%]">
                  <Input label="Last Name" name="lastName" />
                </div>
              </div>

              <div className="mb-2">
                <Input label="Business Name" name="businessName" />
              </div>

              <div className="mb-2">
                <Input label="Email Address" name="email" type="email" />
              </div>

              <div className="flex justify-between mb-2">
                <div className=" w-full">
                  <StyledSelect
                    control={methods.control}
                    defaultValue={countries[0]}
                    isDisabled={true}
                    isClearable={false}
                    isSearchable={false}
                    name="country"
                    label="Country"
                    options={countries}
                  />
                </div>
              </div>

              <div className="mb-6">
                <Input
                  label="Password"
                  name="password"
                  type="password"
                  labelIcon={
                    <Tooltip
                      content={
                        <span className="text-xs">
                          Password must be 8 characters long and must <br />{" "}
                          contain at least one symbol, one uppercase <br />{" "}
                          letter and one lower case letter.
                        </span>
                      }
                      direction="right"
                    >
                      <ExclamationIcon className="h-5 w-5 text-gray-default" />
                    </Tooltip>
                  }
                />
                <p className="mt-3 text-sm">
                  Password strength:{" "}
                  <span
                    className={`font-semibold ${
                      passwordStrength === "Weak"
                        ? "text-danger-default"
                        : passwordStrength === "Medium"
                        ? " text-caution-default"
                        : "text-success-default"
                    }`}
                  >
                    {passwordStrength}
                  </span>
                </p>
              </div>

              <div className="mb-2">
                <Input
                  label="Repeat Password"
                  name="repeatPassword"
                  type="password"
                />
              </div>

              <div className="mb-6">
                <div className="text-sm flex items-center mb-2">
                  <div className="flex items-center cursor-pointer mr-4">
                    <input
                      type="checkbox"
                      {...methods.register("tandc")}
                      className="absolute h-8 w-8 opacity-0"
                    />
                    <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-md bg-gray-200 border border-gray-700 cursor-pointer">
                      <svg
                        className={`fill-current text-danger-default ${
                          values.tandc ? null : "hidden"
                        } h-3 w-3`}
                        version="1.1"
                        viewBox="0 0 17 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <g
                            transform="translate(-9 -11)"
                            fill="#204D88"
                            fillRule="nonzero"
                          >
                            <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <span>
                    By clicking the Sign Up button, I accept the{" "}
                    <Link
                      className="text-primary-default font-semibold"
                      to="https://tgipay.com/terms-of-use"
                      target="_blank"
                    >
                      Terms of Service
                    </Link>{" "}
                    and{" "}
                    <Link
                      className="text-primary-default font-semibold"
                      to="https://tgipay.com/privacy-policy"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </span>
                </div>

                <ErrorMessage
                  errors={formState.errors}
                  name={"tandc"}
                  render={({ message }) => (
                    <div className="mt-1 text-[11px] text-danger-default">
                      {message}
                    </div>
                  )}
                />
              </div>

              <div className="text-sm mb-3">
                <span>Already have an account?</span>
                <span className="ml-2 text-primary-default font-semibold">
                  <Link to="/login">Log in</Link>
                </span>
              </div>

              <div>
                <Button
                  loading={isLoading}
                  disabled={isLoading}
                  className=" text-xl"
                  text="Sign Up"
                />
              </div>
            </form>
          </FormProvider>
        </div>
      }
    />
  )
}

export default SignUp
