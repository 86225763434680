import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PaymethodType } from "types/payment"
import { PAYMETHOD_IDS, PAYMETHOD_NAMES } from "constants/payment"

interface PaymethodState {
  selectedPaymethod: PaymethodType | null
  integrationData: IntegrationDataT | null
}

const initialState: PaymethodState = {
  selectedPaymethod: {
    id: PAYMETHOD_IDS.CREDIT_CARD,
    name: PAYMETHOD_NAMES.CREDIT_CARD,
  },
  integrationData: null,
}

export const paymethodSlice = createSlice({
  name: "paymethod",
  initialState,
  reducers: {
    setSelectedPaymethod: (
      state,
      action: PayloadAction<PaymethodType | null>
    ) => {
      state.selectedPaymethod = action.payload
    },
    setIntegrationData: (
      state,
      action: PayloadAction<IntegrationDataT | null>
    ) => {
      state.integrationData = action.payload
    },
  },
})

export const { setSelectedPaymethod, setIntegrationData } =
  paymethodSlice.actions

export default paymethodSlice.reducer
