import React from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import Status from "components/status/Status"
import { encodeSearchParams } from "utils/encodeSearchParams"

const columnHelper = createColumnHelper<DisputesDetailsT>()

export const DisputesColumns = [
  columnHelper.accessor("disputeReference", {
    cell: (info) => info.getValue(),
    header: () => <span>Rererence</span>,
  }),
  columnHelper.accessor("reason", {
    id: "reason",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Category</span>,
  }),
  columnHelper.accessor("createdDate", {
    header: () => "Date",
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor("resolvedOn", {
    header: () => "Resolved On",
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor("transactionId", {
    header: () => "Dispute Type",
    cell: (info) => (
      <span>{info.getValue() === null ? "Settlement" : "Payment"}</span>
    ),
  }),
  columnHelper.accessor("resolutionStatus", {
    header: "Resolution",
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const navigate = useNavigate()
      return (
        <div
          onClick={() =>
            navigate(`/disputes/details/?${encodeSearchParams(originalData)}`)
          }
          className="text-primary-default font-bold cursor-pointer"
        >
          View
        </div>
      )
    },
  }),
]
