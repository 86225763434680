import React from "react"

interface PropsT {
  permission: PermissionT
  handlePermissionsChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    permission: PermissionT
  ) => void
  selectedPermissions: PermissionT[]
}

const PermissionsList = ({
  permission,
  handlePermissionsChange,
  selectedPermissions,
}: PropsT) => {
  return (
    <div className="text-sm flex items-center mb-2">
      <div className="flex items-center cursor-pointer mr-4 py-3">
        <input
          type="checkbox"
          onChange={(e) => handlePermissionsChange(e, permission)}
          checked={selectedPermissions.some(
            (selectedPermission) => selectedPermission.id === permission.id
          )}
          className="absolute h-8 w-8 opacity-0"
        />
        <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-md bg-gray-200 border border-gray-700 cursor-pointer">
          <svg
            className={`fill-current text-danger-default ${
              selectedPermissions.some(
                (selectedPermission) => selectedPermission.id === permission.id
              )
                ? null
                : "hidden"
            } h-3 w-3`}
            version="1.1"
            viewBox="0 0 17 12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <g
                transform="translate(-9 -11)"
                fill="#204D88"
                fillRule="nonzero"
              >
                <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <span>{permission.name}</span>
    </div>
  )
}

export default PermissionsList
