import { generateQueryString } from "utils/generate-query-string"
import { transactionsApi } from "../api/transactions"
import { PaymentAxiosInstance } from "../instances/payment-instance"

export const fetchTransactions = async (payload: {
  merchantId: number
  currentPage: number
  pageSize: number
  search: string
}) => {
  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.fetchTransactions}?merchantId=${payload.merchantId}&page=${payload.currentPage}&size=${payload.pageSize}&${payload.search}`
  )
  return response
}

export const fetchTransactiondetails = async (payload: {
  transactionId: string | null | undefined
  merchantId: number
}) => {
  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.fetchTransactionDetails}/${payload.merchantId}/${payload.transactionId}`
  )
  return response
}

export const totalValueByDate = async (payload: {
  merchantId: number | undefined | null
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.totalValueByDate}/${payload.merchantId}${queryParams}`
  )
  return response
}

export const successfulCountPointsByDate = async (payload: {
  merchantId: number | undefined | null
  startDate: string
  endDate: string
  dateGrouping: string | undefined
}) => {
  const { startDate, endDate, dateGrouping } = payload

  const queryParams = generateQueryString({ startDate, endDate, dateGrouping })
  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.successfulCountPointsByDate}/${payload.merchantId}${queryParams}`
  )
  return response
}

export const failedCountpointsByDate = async (payload: {
  merchantId: number | undefined | null
  dateGrouping: string | undefined
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate, dateGrouping } = payload
  const queryParams = generateQueryString({ startDate, endDate, dateGrouping })
  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.failedCountpointsByDate}/${payload.merchantId}${queryParams}`
  )
  return response
}

export const countSuccessfulByDate = async (payload: {
  merchantId: number | undefined | null

  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })
  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.countSuccessfulByDate}/${payload.merchantId}${queryParams}`
  )
  return response
}

export const countFailedByDate = async (payload: {
  merchantId: number | undefined | null
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.countFailedByDate}/${payload.merchantId}${queryParams}`
  )
  return response
}

export const countByDate = async (payload: {
  merchantId: number | undefined | null
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.countByDate}/${payload.merchantId}${queryParams}`
  )
  return response
}

export const exportTransactionsrecords = async (payload: {
  merchantId: number
  type: string
  filter: string
}) => {
  const response = await PaymentAxiosInstance.get(
    `${transactionsApi.exportTransactions}?merchantId=${payload.merchantId}&type=${payload.type}&${payload.filter}`,
    {
      responseType: "blob",
    }
  )
  return response
}
