import { generateNewAccessToken } from "../axios/services/auth-services"
import React, { useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useAppSelector } from "redux/hooks"

type ProtectedRouteT = {
  children: JSX.Element
}

const ProtectedRoute = ({ children }: ProtectedRouteT) => {
  const [accessToken, setAccessToken] = useState("")
  const location = useLocation()
  const businessDetails = useAppSelector(
    (state) => state.profile.businessProfile
  )
  const user = sessionStorage.getItem("access-token")
  //Refresh token every 15 minutes
  useEffect(() => {
    const checkExpirationTimeout = setTimeout(async () => {
      const response = await generateNewAccessToken()
      setAccessToken(response.data.data.accessToken)
    }, 15 * 60 * 1000) // Convert expiresIn to milliseconds
    return () => clearTimeout(checkExpirationTimeout)
  }, [accessToken])

  if (!user) {
    console.log("You have been logged out")
    return <Navigate to="/login" replace />
  }
  if (
    !businessDetails.approved &&
    location.pathname !== "/" &&
    location.pathname !== "/kyc" &&
    location.pathname !== "/settings" &&
    !/^\/kyc\/[^/]+$/.test(location.pathname)
  ) {
    return <Navigate to="/" replace />
  }
  return children
}
export default ProtectedRoute
