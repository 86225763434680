import BackArrow from "components/button/back-arrow"
import { DataRow } from "components/data-row/data-row"
import Status from "components/status/Status"
import { queryKeys } from "constants/queryKeys"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import AppLayout from "layout/private/app-layout"
import useTransactions from "pages/transactions/hooks/useTransactions"
import DisputeSettlementDetails from "./components/dispute-settlement-details"
import DisputeTransactionsDetails from "./components/dispute-transaction-details"
import useSettlement from "pages/Settlement/hooks/useSettlement"
import FullScreenLoader from "components/loader/fullscreen-loader"

const DisputeTransaction: React.FC = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  let transactionId = searchParams.get("transactionId")
  let settlementId = searchParams.get("settlementId")

  transactionId = transactionId !== "null" ? transactionId : null
  settlementId = settlementId !== "null" ? settlementId : null

  const { transactionDetails } = useTransactions(
    queryKeys.transactions.fetchTransactionDetails,
    transactionId
  )

  const { loadingDetails, detailsData } = useSettlement(
    queryKeys.settlement.fetchSettlementDetails,
    Number(settlementId)
  )

  return (
    <AppLayout title="" subTitle="">
      {transactionId && (
        <DisputeTransactionsDetails transactionDetails={transactionDetails} />
      )}
      {settlementId && (
        <>
          {loadingDetails ? (
            <FullScreenLoader
              loading={loadingDetails}
              message="Fetching settlement details"
            />
          ) : (
            <DisputeSettlementDetails
              settlementId={Number(settlementId)}
              loadingDetails={loadingDetails}
              detailsData={detailsData}
            />
          )}
        </>
      )}

      <div className="mt-10">
        <BackArrow
          onClick={() => navigate(-1)}
          label="Return to Dispute Details"
        />
      </div>
    </AppLayout>
  )
}
export default DisputeTransaction
