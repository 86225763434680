import { generateQueryString } from "utils/generate-query-string"
import { paymentApi } from "../api/payment"
import { PaymentAxiosInstance } from "../instances/payment-instance"
import axios from "axios"
import { walletApi } from "../api/wallet"
import { PaymentChannel } from "pages/make-payment/types/payment"
import {
  DirectDebitNotificationData,
  VerifyByTransferPayload,
} from "types/payment"
import { internalPayChannels } from "constants/payment"

export const fetchPaymentPreferences = async (id: number) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.getPaymentPreferences}/${id}`
  )
  return response
}

export const SavePaymentPreferences = async (paymentPreferences: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.updatepaymentPreference,
    paymentPreferences
  )
  return response
}

export const fetchPaymentLinks = async (payload: {
  businessId: number
  currentPage: number
  pageSize: number
  filter: string
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.getAllPaymentLinks}/${payload.businessId}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.filter}`
  )
  return response
}

export const fetchPaymentLinkByAccessCode = async (accessCode: string) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.getPaymentLinkByAccessCode}/${accessCode}`
  )
  return response
}

export const addPaymentLink = async (linkDetails: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.createPaymentLink,
    linkDetails
  )
  return response
}

export const editPaymentLink = async (linkDetails: unknown) => {
  const response = await PaymentAxiosInstance.put(
    paymentApi.editPaymentLink,
    linkDetails
  )
  return response
}

export const deletePaymentLink = async (businessId: any, id: number) => {
  const response = await PaymentAxiosInstance.delete(
    `${paymentApi.deletePaymentLink}/${businessId}/${id}`
  )
  return response
}

export const activateDeactivateLink = async (
  businessId: any,
  id: number,
  activate: boolean
) => {
  const response = await PaymentAxiosInstance.put(
    `${paymentApi.activateDeactivatePaymentLink}/${businessId}/${id}/${activate}`
  )
  return response
}

export const initiatePaymentRequest = async (paymentData: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.initiatePayment,
    paymentData
  )
  return response
}

export const authorizePaymentRequest = async (paymentData: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.authorizePayment,
    paymentData
  )
  return response
}

export const verifyPaymentRequest = async (paymentData: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.verifyPayment,
    paymentData
  )
  return response
}

export const validatePaymentRequest = async (paymentData: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.validatePayment,
    paymentData
  )
  return response
}

export const resendOtpPaymentRequest = async (paymentData: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.resendOtp,
    paymentData
  )
  return response
}

export const editInvoice = async (invoiceData: unknown) => {
  const response = await PaymentAxiosInstance.put(
    paymentApi.editInvoice,
    invoiceData
  )
  return response
}

export const createInvoiceAndSave = async (invoiceData: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.createInvoiceAndSave,
    invoiceData
  )
  return response
}

export const createInvoiceAndSend = async (invoiceData: unknown) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.createInvoiceAndSend,
    invoiceData
  )
  return response
}

export const fetchInvoiceDetails = async (
  businessId: number,
  invoiceId: number
) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.getInvoiceDetails}/${businessId}/${invoiceId}`
  )
  return response
}

export const validateInvoiceByAccessCode = async (accessCode: string) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.validateInvoiceAccessCode}/${accessCode}`
  )
  return response
}

export const sendInvoice = async (businessId: number, invoiceId: number) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.sendInvoiceToCustomer}/${businessId}/${invoiceId}`
  )
  return response
}

export const sendInvoiceReminder = async (
  businessId: number,
  invoiceId: number
) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.sendInvoiceReminder}/${businessId}/${invoiceId}`
  )
  return response
}

export const fetchAllInvoices = async (payload: {
  businessId: number
  currentPage: number
  pageSize: number
  filter: string
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.viewAllInvoices}/${payload.businessId}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.filter}`
  )
  return response
}

export const voidInvoice = async (businessId: number, invoiceId: number) => {
  const response = await PaymentAxiosInstance.put(
    `${paymentApi.voidInvoice}/${businessId}/${invoiceId}`
  )
  return response
}

export const deleteInvoice = async (businessId: number, invoiceId: number) => {
  const response = await PaymentAxiosInstance.delete(
    `${paymentApi.deleteInvoice}/${businessId}/${invoiceId}`
  )
  return response
}

export const fetchSettlements = async (payload: {
  merchantId: number
  currentPage: number
  pageSize: number
  filter: string
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.fetchSettlements}?merchantId=${payload.merchantId}&page=${payload.currentPage}&size=${payload.pageSize}&${payload.filter}`
  )
  return response
}

export const fetchSettlementdetails = async (payload: {
  merchantId: number
  settlementId: number | undefined | null
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.fetchSettlementdetails}/${payload.merchantId}/${payload.settlementId}`
  )
  return response
}

export const fetchPayoutTransactions = async (payload: {
  merchantId: number
  settlementId: number | undefined | null
  payoutPageSize: number
  payoutCurrentPage: number
  filter?: string
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.fetchPayoutTransactions}?merchantId=${payload.merchantId}&settlementId=${payload.settlementId}&page=${payload.payoutCurrentPage}&size=${payload.payoutPageSize}&${payload.filter}`
  )
  return response
}

export const fetchDisputeRecords = async (payload: {
  merchantId: number
  currentPage: number
  pageSize: number
  filter?: string
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.viewDisputeRecords}?merchantId=${payload.merchantId}&page=${payload.currentPage}&size=${payload.pageSize}&${payload.filter}`
  )
  return response
}

export const fetchDisputeDetails = async (payload: {
  disputeId: string | null | undefined
  merchantId: number
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.viewDisputeDetails}/${payload.disputeId}/${payload.merchantId}`
  )
  return response
}

export const fetchDisputeComments = async (payload: {
  disputeId: number | undefined
}) => {
  const response = await axios.get(
    `${paymentApi.viewDisputeComments}/${payload.disputeId}`
  )
  return response
}

export const fetchDisputeTransactions = async (payload: {
  disputeId: string | null | undefined
}) => {
  const response = await axios.get(
    `${paymentApi.viewDisputeTransactions}/${payload.disputeId}`
  )
  return response
}

export const fetchDisputeReasons = async () => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.viewDisputeReasons}`
  )
  return response
}

export const ReopenDispute = async (payload: {
  merchantId: number
  disputeId: number | string
}) => {
  const response = await PaymentAxiosInstance.put(
    `${paymentApi.reopenDispute}/${payload.merchantId}/${payload.disputeId}`
  )
  return response
}

export const raiseTransactionDispute = async (data: FormData) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.raiseTransactionDispute,
    data,
    {
      headers: {
        "Content-Type": "muiltipart/form-data",
        "Access-Control-Allow-Headers": "Accept",
      },
    }
  )
  return response
}

export const raiseSettlementDispute = async (data: FormData) => {
  const response = await PaymentAxiosInstance.post(
    paymentApi.raiseSettlementDispute,
    data,
    {
      headers: {
        "Content-Type": "muiltipart/form-data",
        "Access-Control-Allow-Headers": "Accept",
      },
    }
  )
  return response
}

export const exportSettlementrecords = async (payload: {
  merchantId: number
  type: string
  filter: string
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.exportSettlementData}?merchantId=${payload.merchantId}&type=${payload.type}&${payload.filter}`,
    {
      responseType: "blob",
    }
  )
  return response
}

export const totalPendingSettlement = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })
  const response = await PaymentAxiosInstance.get(
    `${paymentApi.totalPendingSettlement}${queryParams}`
  )
  return response
}

export const fetchUserWallets = async (id: number) => {
  const response = await PaymentAxiosInstance.get(
    `${walletApi.getWalletsByMerchantId}/${id}`
  )
  return response
}

export const payByTransfer = async (data: any) => {
  let apiEndpoint = walletApi.payByTransfer
  let requestData = data

  if (internalPayChannels.includes(data.paymentChannel)) {
    apiEndpoint = walletApi.payByTransferInternal

    requestData = {
      businessId: data?.cardTopUpRequest?.businessId,
      amount: data?.cardTopUpRequest?.amount,
    }
  }

  const response = await PaymentAxiosInstance.post(apiEndpoint, {
    ...requestData,
  })
  return response
}

export const verifyTransfer = async (data: VerifyByTransferPayload) => {
  let apiEndpoint = walletApi.verifyTransfer

  if (
    data.paymentChannel &&
    internalPayChannels.includes(data.paymentChannel)
  ) {
    apiEndpoint = walletApi.verifyTransferInternal
  }
  const response = await PaymentAxiosInstance.post(apiEndpoint, data)
  return response
}

export const initiateDirectDebit = async (data: any) => {
  const response = await PaymentAxiosInstance.post(
    walletApi.initiateDirectDebit,
    data
  )
  return response
}

export const getDirectDebitStatus = async (transactionReference: string) => {
  const response = await PaymentAxiosInstance.get(
    `${walletApi.getDirectDebitStatus}/${transactionReference}`
  )
  return response
}

export const directDebitNotification = async (
  data: DirectDebitNotificationData
) => {
  const response = await PaymentAxiosInstance.post(
    walletApi.directDebitNotification,
    data
  )
  return response
}
