export const authApi = {
  //Authentication
  signup: "api/v1/auth/signup",
  validateOtp: "api/v1/auth/validate-otp",
  regenerateOtp: "api/v1/auth/regenerate-otp",
  login: "api/v1/auth/login",
  logout: "/api/v1/security/logout",
  resetPassword: "api/v1/auth/reset-password",
  validateAccessCode: "api/v1/auth/validate-access-code",
  activateAccount: "api/v1/auth/activate-account",
  initiatePasswordReset: "api/v1/auth/initiate-password-reset",
  getNewAccessToken: "api/v1/security/refresh-token",
  logoutUser: "api/v1/security/logout",
  changePassword: "api/v1/security/change-password",
  //User Details
  viewUserDetails: "api/v1/merchant-user/view-user-profile",
  editUserDetails: "api/v1/merchant-user/edit-user-profile",
  //Two Factor Authentication
  get2faStatus: "api/v1/security/2fa-status",
  initiate2faChange: "api/v1/security/initiate-change-2fa",
  change2fa: "api/v1/security/change-2fa",
  //Roles and Permissions
  getDefaultRoles: "api/v1/roles/default-roles",
  getDefaultRoleById: "api/v1/roles/role",
  getCustomRoles: "api/v1/roles/custom-roles",
  getCustomRoleById: "api/v1/roles/custom-role",
  addCustomRole: "api/v1/roles/custom-role",
  editCustomRole: "api/v1/roles/custom-role",
  getAllPermissions: "api/v1/roles/permissions",
  getPermissionById: "api/v1/roles/permission",
  //Merchant Account
  getAllUserAccounts: "api/v1/merchant-user/user-accounts",
  getUserAccount: "api/v1/merchant-user/view-user-profile",
  getTotalUserCount: "api/v1/merchant-user/total-user-accounts",
  addUserAccount: "api/v1/merchant-user/user-account",
  editUserAccount: "api/v1/merchant-user/user-account",
  activateDeactivateAccount: "api/v1/merchant-user/activate",
}
