import React, { useState } from "react"
import Button from "../../../components/button"
import Modal from "../../../components/modal"
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

interface ModalPropsT {
  isOpen: boolean
  closeModal: () => void
}

type InvoiceType = "Basic" | "Professional" | null

const CreateInvoiceModal = ({ closeModal, isOpen }: ModalPropsT) => {
  const [invoiceType, setInvoiceType] = useState<InvoiceType>(null)
  const [error, setError] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleRoute = () => {
    if (invoiceType === "Basic") {
      navigate("/invoicing/create-invoice/basic")
    } else if (invoiceType === "Professional") {
      navigate("/invoicing/create-invoice/professional")
    } else {
      setError(true)
    }
  }

  const handleInvoiceType = (type: InvoiceType) => {
    setInvoiceType(type)
    setError(false)
  }

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} maxWidth="max-w-xl">
      <h2 className="text-center text-2xl font-medium mb-5">Create Invoice</h2>
      <div className="flex justify-between gap-9">
        <div
          onClick={() => handleInvoiceType("Basic")}
          className={`w-[50%] rounded border text-sm border-gray-100 py-4 px-3 cursor-pointer relative ${
            invoiceType === "Basic"
              ? "bg-primary-default text-white-default "
              : null
          }`}
        >
          <h3 className="font-medium">Basic Invoice</h3>
          <p className="mt-3 font-extralight mb-10">
            Create simple invoices with essential details for easy payment
          </p>

          {invoiceType === "Basic" && (
            <CheckIcon className="text-primary-default bg-white-default rounded-xl h-6 w-6 absolute top-2 right-2 " />
          )}
        </div>
        <div
          onClick={() => handleInvoiceType("Professional")}
          className={`w-[50%] rounded border text-sm border-gray-100 py-4 px-3 cursor-pointer relative ${
            invoiceType === "Professional"
              ? "bg-primary-default text-white-default"
              : null
          }`}
        >
          <h3 className="font-medium">Professional Invoice</h3>
          <p className="mt-3 font-extralight mb-10">
            Create a fully customized invoice with additional information
          </p>
          {invoiceType === "Professional" && (
            <CheckIcon className="text-primary-default bg-white-default rounded-xl h-6 w-6 absolute top-2 right-2 " />
          )}
        </div>
      </div>
      <div className="mt-2 text-sm text-danger-default">
        {error && <span>You must select an invoice type</span>}
      </div>

      <div className="mt-7 flex justify-end">
        <Button
          onClick={handleRoute}
          text="Proceed"
          className="rounded w-auto px-4 py-1"
        />
      </div>
    </Modal>
  )
}

export default CreateInvoiceModal
