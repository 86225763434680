import React from "react"
import { useLocation } from "react-router-dom"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"
import PaymentHandler from "pages/make-payment/payment-handler"
import { PaymentChannel } from "pages/make-payment/types/payment"

const MakeInvoiceCardPayment = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const invoiceRequestJson = params.get("invoiceRequest")
  let invoiceRequest
  if (invoiceRequestJson) {
    invoiceRequest = decodeURIComponent(invoiceRequestJson)
    invoiceRequest = JSON.parse(invoiceRequest)
  }

  const dataToSend = {
    ...invoiceRequest,
    forPaymentLinkRequest: false,
    forInvoiceRequest: true,
    forPaymentIntegrationRequest: false,
  }

  return (
    <div className=" pt-16 flex flex-col items-center mb-28">
      <div>
        <Logo className="h-10" />
      </div>
      <div className=" w-[95%] md:w-[80%] mt-10 p-8 border border-gray-600 rounded-xl bg-white-default flex items-center justify-between gap-10 ">
        <PaymentHandler
          paymentData={{
            ...dataToSend,
            paymentType: PaymentChannel.Invoice,
          }}
          fullWidth
        />
      </div>
    </div>
  )
}

export default MakeInvoiceCardPayment
