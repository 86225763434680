import React, { ReactNode } from "react"
import Spinner from "../spinner/Spinner"
import { twMerge } from "tailwind-merge"

type ButtonVariant =
  | "default"
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "link"
  | "linkPrimary"
  | "outline"
  | "outlinePrimary"

type ButtonType = "submit" | "button"

type ButtonPropsT = {
  icon?: JSX.Element
  text?: ReactNode
  variant?: ButtonVariant
  iconPosition?: string
  className?: string
  disabled?: boolean
  onClick?: () => void
  type?: ButtonType
  loading?: boolean
  withChildren?: boolean
  children?: ReactNode
}

const variantClassMap: { [key in ButtonVariant]: string } = {
  primary:
    "bg-primary-default hover:bg-primary-default/90 text-white-default font-medium",
  outline: "bg-white-default text-primary-default ring-1 ring-primary-default",
  default: "bg-[#0F172A]/50 border text-white-default border-gray-20",
  secondary: "bg-secondary-default hover:bg-secondary-default/90",
  success: "bg-success-default hover:bg-success-default/90 text-white",
  danger: "bg-danger-default hover:bg-danger-default/90 text-white",
  link: "text-gray-10 h-fit w-fit p-0",
  linkPrimary: "text-primary-default h-fit w-fit p-0",
  outlinePrimary:
    "bg-white border text-primary-default border-primary-default bg-none",
}

const disabledClassMap: { [key in ButtonVariant]: string } = {
  primary: "bg-primary-default/50 text-white-default cursor-not-allowed",
  outline:
    "bg-white-default text-gray-400 ring-1 ring-gray-400 cursor-not-allowed",
  default:
    "bg-gray-400 border text-white-default border-gray-200 cursor-not-allowed",
  secondary: "bg-gray-400 cursor-not-allowed",
  success: "bg-gray-400 text-white cursor-not-allowed",
  danger: "bg-gray-400 text-white cursor-not-allowed",
  link: "text-gray-400 cursor-not-allowed",
  linkPrimary: "text-gray-400 cursor-not-allowed",
  outlinePrimary:
    "bg-white border text-gray-400 border-gray-400 cursor-not-allowed",
}

const Button: React.FC<ButtonPropsT> = ({
  text = "Submit",
  variant = "primary",
  disabled = false,
  className,
  onClick,
  type = "submit",
  loading = false,
  icon,
  withChildren,
  children,
}) => {
  const isDisabled = disabled || loading

  const finalClassName = twMerge(
    `w-full py-4 text-center border-0 outline-0 cursor-pointer rounded-lg`,
    isDisabled ? disabledClassMap[variant] : variantClassMap[variant],
    className
  )

  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      type={type}
      className={finalClassName}
    >
      {icon && !loading && icon}
      {loading ? <Spinner /> : withChildren ? children : text}
    </button>
  )
}

export default Button
