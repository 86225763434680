import React, { FC } from "react"
import Input from "components/input/input"
import Button from "components/button"
import { FormProvider, FieldValues } from "react-hook-form"

type Props = {
  linkData?: LinkDetailsT
  formMethods: any
  handleFormSubmission: (values: FieldValues) => void
}

const CustomerDetails: FC<Props> = ({
  linkData,
  formMethods,
  handleFormSubmission,
}) => {
  const onSubmit = (values: FieldValues) => {
    handleFormSubmission(values)
  }

  return (
    <div>
      <div className="flex justify-between items-baseline">
        <div>
          <div>
            <img
              src={linkData?.businessLogoUrl}
              alt=""
              className=" h-12 w-12"
            />
          </div>
          <div className="font-semibold">{linkData?.businessName}</div>
        </div>
        <div>
          <span className=" text-sm text-gray-900">Name of Link:</span>{" "}
          <span>{linkData?.name}</span>
        </div>
      </div>
      <div className="mt-6 mb-6">
        <h2 className="text-lg font-semibold">Customer Details</h2>
      </div>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div>
            <div className="mb-4">
              <Input label="First Name" name="firstName" />
            </div>
            <div className="mb-4">
              <Input label="Last Name" name="lastName" />
            </div>
            <div className="mb-4">
              <Input label="Email Address" name="email" type="email" />
            </div>

            <div className="mb-4">
              <Input disabled label="Amount" name="amount" />
            </div>
          </div>
        </form>
      </FormProvider>

      <div className="mt-10">
        <Button
          onClick={formMethods.handleSubmit(onSubmit)}
          text="Pay Now"
          className="px-20 py-4"
        />
      </div>
    </div>
  )
}

export default CustomerDetails
