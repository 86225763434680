export const PERMISSIONS = {
  VIEW_MERCHANT_KYC_DETAILS: "MO1",
  MANAGE_MERCHANT_KYC_DETAILS: "MO2",
  CREATE_PAYMENT_LINK: "PL1",
  MANAGE_PAYMENT_LINK: "PL2",
  CREATE_INVOICE: "IN1",
  MANAGE_INVOICE: "IN2",
  VIEW_TRANSACTIONS: "TR1",
  MANAGE_TRANSACTION_DISPUTES: "TR2",
  VIEW_PAYOUT_SETTLEMENTS: "STM1",
  MANAGE_SETTLEMENT_DISPUTES: "STM2",
  VIEW_BUSINESS_PROFILE: "ST1",
  EDIT_PERSONAL_PROFILE: "ST2",
  MANAGE_BUSINESS_PROFILE: "ST3",
  VIEW_AUDIT_TRAIL: "AU1",
  VIEW_MERCHANT_ROLES: "RM1",
  CREATE_MERCHANT_ROLES: "RM2",
  MANAGE_MERCHANT_ROLES: "RM3",
  VIEW_TGIPAY_ADMIN_ROLES: "RM4",
  CREATE_TGIPAY_ADMIN_ROLES: "RM5",
  MANAGE_TGIPAY_ADMIN_ROLES: "RM6",
  VIEW_USERS: "UM1",
  CREATE_MERCHANT_USERS: "UM2",
  MANAGE_MERCHANT_USERS: "UM3",
  VIEW_TGIPAY_USERS: "UM4",
  CREATE_TGIPAY_USERS: "UM5",
  MANAGE_TGIPAY_USERS: "UM6",
  VIEW_MERCHANTS: "MM1",
  MANAGE_MERCHANTS: "MM2",
  VIEW_DISPUTES: "DM1",
  MANAGE_DISPUTES: "DM2",
  VIEW_INTEGRATIONS: "INT1",
  MANAGE_INTEGRATIONS: "INT2",
  MANAGE_CONFIGURATIONS: "CONF1",
  VIEW_PAYOUTS: "STM1",
}
