import { AxiosError } from "axios"
import React, { useCallback, useEffect, useRef } from "react"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { activateAccount } from "../../axios/services/auth-services"
import SignupLayout from "../../layout/public/signupLayout"

const ActivateAccount = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dataFetchedRef = useRef(false)

  const code = searchParams.get("code")

  const { mutate: activateUserAccount } = useMutation(activateAccount, {
    onSuccess: () => {
      toast.success("User activated successfully!")
      navigate("/login")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      navigate("/access-code-expired")
    },
  })

  const handleActivation = useCallback(() => {
    activateUserAccount({
      accessKey: code,
    })
  }, [code])

  useEffect(() => {
    if (dataFetchedRef.current) return
    dataFetchedRef.current = true
    handleActivation()
  }, [handleActivation])

  return (
    <SignupLayout
      content={
        <div className="w-[40%] text-center">
          <h2 className="text-xl font-semibold mb-3">Please wait...</h2>
          <div>Activating account</div>
        </div>
      }
    />
  )
}

export default ActivateAccount
