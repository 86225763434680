import React, { useEffect, useState } from "react"
import Pin from "components/input/pin"
import PaymentOtpModal from "./modal/payment-otp-modal"
import { usePayment } from "pages/make-payment/hooks/usePayment"
import Button from "components/button"
import FullScreenLoader from "components/loader/fullscreen-loader"
import Select from "react-select"
import * as CS from "country-state-city"
import verveIcon from "assets/icons/verve.svg"
import visaIcon from "assets/icons/visa.svg"
import mastercardIcon from "assets/icons/mastercard.svg"

type SelectT = {
  label: string
  value: string
  isDisabled?: boolean
}
interface PropsT {
  cardProcessor?: string | null
  dataToSend?: any
  successCallback?: (successData?: any) => void
  failureCallback?: () => void
}

const CardPayment = ({
  cardProcessor,
  dataToSend,
  successCallback,
  failureCallback,
}: PropsT) => {
  const [countries, setCountries] = useState<Array<SelectT>>([])
  const [statesOptions, setStateOptions] = useState<Array<SelectT>>([])

  const {
    showPin,
    openOtpModal,
    cardDetails,
    pin,
    isLoadingInitiatePayment,
    isLoadingAuthorizePayment,
    isLoadingValidatePayment,
    isLoadingVerifyPayment,
    nextStep,
    otp,
    authorizationMode,
    errors,
    cardScheme,
    isResendingOtp,
    resendOTP,
    setOtp,
    handleValidation,
    setOpenOtpModal,
    handleInputChange,
    handleSelectInput,
    handleInputFocus,
    handlePinChange,
    initializePayment,
    authorizePayment,
    validatePayment,
  } = usePayment({
    cardProcessor,
    dataToSend,
    successCallback,
    failureCallback,
  })

  const fetchCountries = async () => {
    const countries = CS.Country.getAllCountries().map((c) => ({
      label: c.name,
      value: c.isoCode,
    }))
    setCountries(countries)
  }

  const handleCountryChange = (selectedOption: {
    label: string
    value: string
  }) => {
    handleSelectInput("country", selectedOption.value)
    const countryStates = CS.State.getStatesOfCountry(selectedOption.value).map(
      (s) => ({
        label: s.name,
        value: s.isoCode,
      })
    )
    setStateOptions(countryStates)
  }

  useEffect(() => {
    fetchCountries()
  }, [])

  const getCardIcon = (cardScheme: string) => {
    switch (cardScheme) {
      case "mastercard":
        return mastercardIcon
      case "visa":
        return visaIcon
      case "verve":
        return verveIcon
      default:
        break
    }
  }

  return (
    <div>
      <div className="">
        <h2 className="text-lg font-semibold">Payment Details</h2>
      </div>
      <div className="mt-10">
        <form>
          <div>
            <label htmlFor="cardNumber" className="font-medium">
              Card Number
            </label>
            <div className="text-sm text-gray-900 mb-3">
              Enter the 16-digit number on your card
            </div>
            <div className="flex justify-between border border-gray-700 px-3 py-4 w-full rounded ">
              <input
                type="tel"
                name="number"
                disabled={nextStep === "authorize"}
                className="border-none w-[90%]"
                placeholder="XXXX XXXX XXXX XXXX"
                pattern="[\d| ]{16,22}"
                value={cardDetails.number}
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              {cardScheme !== "" && (
                <img src={getCardIcon(cardScheme)} width={30} height={20} />
              )}
            </div>
            {errors.number ? (
              <div className="text-sm text-danger-default mt-2">
                {errors.number}
              </div>
            ) : null}
          </div>

          <div className="flex gap-10 justify-between mt-5 ">
            <div className="w-[50%]">
              <label htmlFor="cvc" className="font-medium">
                CVV
              </label>
              <div className="text-sm text-gray-900 mb-3">
                Enter the 3-digit number behind your card
              </div>
              <input
                type="tel"
                name="cvc"
                disabled={nextStep === "authorize"}
                className="border border-gray-700 px-3 py-4 w-full rounded"
                placeholder="CVV"
                pattern="\d{3,4}"
                value={cardDetails.cvc}
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              {errors.cvc ? (
                <div className="text-sm text-danger-default mt-2">
                  {errors.expiry}
                </div>
              ) : null}
            </div>
            <div className="w-[50%]">
              <label htmlFor="expiryDate" className="font-medium">
                Expiry Date
              </label>
              <div className="text-sm text-gray-900 mb-3">
                Enter the expiry date as seen on your card
              </div>
              <input
                type="tel"
                name="expiry"
                disabled={nextStep === "authorize"}
                className="border border-gray-700 px-3 py-4 w-full rounded"
                placeholder="MM/YY"
                pattern="\d\d/\d\d"
                value={cardDetails.expiry}
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
              {errors.expiry ? (
                <div className="text-sm text-danger-default mt-2">
                  {errors.expiry}
                </div>
              ) : null}
            </div>
          </div>

          {authorizationMode === "" ? null : (
            <div>
              <div className="mt-5">
                <label htmlFor="cvc" className="font-medium">
                  Address Line 1
                </label>
                <input
                  name="addressLine1"
                  className="border border-gray-700 px-3 py-4 w-full rounded"
                  placeholder="Address Line 1"
                  required
                  value={cardDetails.addressLine1}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
                {errors.addressLine1 ? (
                  <div className="text-sm text-danger-default mt-2">
                    {errors.addressLine1}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <label htmlFor="cvc" className="font-medium">
                  Address Line 2
                </label>
                <input
                  name="addressLine2"
                  className="border border-gray-700 px-3 py-4 w-full rounded"
                  placeholder="Address Line 2"
                  required
                  value={cardDetails.addressLine2}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                />
                {errors.addressLine2 ? (
                  <div className="text-sm text-danger-default mt-2">
                    {errors.addressLine2}
                  </div>
                ) : null}
              </div>
              <div className="flex gap-10 justify-between mt-5 ">
                <div className="w-[50%]">
                  <label htmlFor="city" className="font-medium">
                    Country
                  </label>
                  <Select
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    name="country"
                    options={countries}
                    onChange={(option) => {
                      if (option) {
                        // Add null check
                        handleCountryChange({
                          label: option.label,
                          value: option.value,
                        })
                      }
                    }}
                  />
                  {errors.country ? (
                    <div className="text-sm text-danger-default mt-2">
                      {errors.country}
                    </div>
                  ) : null}
                </div>
                <div className="w-[50%]">
                  <label htmlFor="city" className="font-medium">
                    State
                  </label>
                  <Select
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    name="state"
                    options={statesOptions}
                    onChange={(option) => {
                      if (option) {
                        handleSelectInput("state", option.value)
                      }
                    }}
                  />
                  {errors.state ? (
                    <div className="text-sm text-danger-default mt-2">
                      {errors.state}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex gap-10 justify-between mt-5 ">
                <div className="w-[50%]">
                  <label htmlFor="city" className="font-medium">
                    City
                  </label>
                  <input
                    name="city"
                    className="border border-gray-700 px-3 py-4 w-full rounded"
                    placeholder="City"
                    required
                    value={cardDetails.city}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                  {errors.city ? (
                    <div className="text-sm text-danger-default mt-2">
                      {errors.city}
                    </div>
                  ) : null}
                </div>
                <div className="w-[50%]">
                  <label htmlFor="postCode" className="font-medium">
                    Postcode
                  </label>
                  <input
                    type="number"
                    name="postCode"
                    className="border border-gray-700 px-3 py-4 w-full rounded"
                    placeholder="Postcode"
                    required
                    value={cardDetails.postCode}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                  {errors.postCode ? (
                    <div className="text-sm text-danger-default mt-2">
                      {errors.postCode}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}

          {showPin && (
            <div className="mt-5">
              <div>
                <h3 className="font-medium">Card Pin</h3>
                <h5 className="text-sm text-gray-900">Enter your card pin</h5>
              </div>
              <div className="flex justify-between gap-10 w-full ">
                <div className="w-[50%]">
                  <input
                    disabled
                    value={pin}
                    type="password"
                    name="pin"
                    className="border border-gray-700 px-3 py-4 w-full rounded"
                    pattern="\d{3,4}"
                    maxLength={4}
                    required
                  />
                  {errors.pin ? (
                    <div className="text-sm text-danger-default mt-2">
                      {errors.pin}
                    </div>
                  ) : null}
                </div>
                <div className="w-[50%]">
                  <Pin onPinChange={handlePinChange} />
                </div>
              </div>
            </div>
          )}

          <div className="mt-10 mx-auto w-[85%] md:w-[50%] lg:w-[40%]">
            <Button
              loading={isLoadingInitiatePayment || isLoadingAuthorizePayment}
              type="button"
              disabled={isLoadingInitiatePayment || isLoadingAuthorizePayment}
              onClick={
                nextStep === "initiate"
                  ? () => handleValidation(initializePayment)
                  : () => handleValidation(authorizePayment)
              }
              text="PAY"
              className={`py-4`}
            />
          </div>
        </form>
      </div>

      {isLoadingVerifyPayment && (
        <FullScreenLoader
          loading={isLoadingVerifyPayment}
          message="Verifying Payment"
        />
      )}

      {openOtpModal && (
        <PaymentOtpModal
          isOpen={openOtpModal}
          closeModal={() => setOpenOtpModal(false)}
          otp={otp}
          setOtp={setOtp}
          validatePayment={validatePayment}
          isLoadingValidatePayment={isLoadingValidatePayment}
          // resendOtp={
          //   cardProcessor === "flutterwave"
          //     ? authorizePayment
          //     : initializePayment
          // }
          resendOtp={resendOTP}
          isResendindOtp={isResendingOtp}
        />
      )}
    </div>
  )
}

export default CardPayment
