import { getPaymentComponent } from "config/paymentComponents"
import React, { FC } from "react"
import { useAppSelector } from "redux/hooks"
import { PaymentComponentProps } from "types/payment"

const PaymentMethodHandler: FC<PaymentComponentProps> = ({
  paymentData,
  customerData,
  successCallback,
  failureCallback,
  paymentStatus,
}) => {
  const { selectedPaymethod } = useAppSelector((state) => state.paymethod)

  const PaymentComponent = getPaymentComponent(selectedPaymethod?.id || "")

  return (
    <div className="w-full">
      {PaymentComponent ? (
        <PaymentComponent
          paymentData={paymentData}
          customerData={customerData}
          successCallback={successCallback}
          failureCallback={failureCallback}
          paymentStatus={paymentStatus}
        />
      ) : (
        <p>Please select a payment method</p>
      )}
    </div>
  )
}

export default PaymentMethodHandler
