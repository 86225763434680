import React from "react"
import Input from "components/input/input"
import TextArea from "components/input/textarea"
import { FormProvider } from "react-hook-form"
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg"
import { ReactComponent as SwitchIcon } from "../../../../../assets/icons/rotate-arrows.svg"
import { useNavigate } from "react-router-dom"

interface PropsT {
  methods: any
}

const BasicInvoiceForm = ({ methods }: PropsT) => {
  const navigate = useNavigate()
  return (
    <div className="w-[50%] md:w-[30%]">
      <div className="rounded-lg border-2 border-gray-600 min-h-[45rem] flex flex-col justify-between">
        <div>
          <div className="text-center bg-primary-400 font-medium py-3">
            Fill Invoice Details
          </div>
          <div className="px-3 pt-3">
            <FormProvider {...methods}>
              <div className="mb-3">
                <Input label="Customer Name" name="name" />
              </div>
              <div className="mb-3">
                <Input label="Customer Email" name="email" />
              </div>
              <div className="mb-3">
                <Input
                  label="Amount"
                  type="text"
                  inputMode="numeric"
                  name="amount"
                />
              </div>
              <div className="mb-3">
                <TextArea
                  label="Notes"
                  name="notes"
                  placeholder="Add notes to your invoice"
                />
              </div>
            </FormProvider>
          </div>
        </div>
        <div
          onClick={() => navigate("/invoicing/create-invoice/professional")}
          className=" text-primary-default opacity-60 font-medium flex items-center justify-center px-3 pb-3 cursor-pointer"
        >
          <SwitchIcon className="mr-3" />
          <span>Switch To Professional Invoice</span>
        </div>
      </div>
      <div
        onClick={() => navigate("/invoicing")}
        className=" text-primary-default mt-10 font-medium flex items-center cursor-pointer"
      >
        <CloseIcon className="mr-3" />
        <span> Discard And Return To Invoicing</span>
      </div>
    </div>
  )
}

export default BasicInvoiceForm
