import React from "react"
import Skeleton from "react-loading-skeleton"
import { twMerge } from "tailwind-merge"

type PropsT = {
  title: string
  value: string | number
  loading?: boolean
  className?: string
}

const DashboardCard = ({ title, value, loading, className }: PropsT) => {
  return (
    <div
      className={twMerge(
        `bg-white-default border rounded py-4 px-3 min-w-[14rem] hover:border hover:shadow-lg cursor-pointer relative ${className}`
      )}
    >
      <div className="font-medium">{loading ? <Skeleton /> : title}</div>
      <div className="text-2xl font-semibold mt-3 mb-3">
        {loading ? <Skeleton /> : value}
      </div>
    </div>
  )
}

export default DashboardCard
