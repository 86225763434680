import React, { useState } from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { BusinessDetailsSchema } from "./validation-schema"
import Input from "../../components/input/input"
import TextArea from "../../components/input/textarea"
import Button from "../../components/button"
import StyledSelect from "../../components/select"
import { States } from "../../utils/data/states"
import BusinessDetailsReview from "./review-pages/business-details-review"
import { useAppSelector } from "../../redux/hooks"
import { useMutation, useQuery } from "react-query"
import {
  editBusinessDetails,
  fetchBusinessCategories,
  fetchBusinessDetailsByBusinessId,
  fetchBusinessTypes,
} from "../../axios/services/merchant-services"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import FullScreenLoader from "../../components/loader/fullscreen-loader"

type SelectT = {
  label: string
  value: string
  isDisabled?: boolean
}

type PropsT = {
  setActiveTab: (activeTab: number) => void
}

const BusinessDetails = ({ setActiveTab }: PropsT) => {
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const [businessTypes, setBusinessTypes] = useState<Array<SelectT>>([])
  const [businessCategories, setBusinessCategories] = useState<Array<SelectT>>(
    []
  )
  const [statesOptions, setStateOptions] = useState<Array<SelectT>>(States)
  const [countries, setCountries] = useState<Array<SelectT>>([
    { label: "Nigeria", value: "NG", isDisabled: true },
  ])
  const profileDetails = useAppSelector((state) => state.profile.profile)
  const methods = useForm({
    resolver: yupResolver(BusinessDetailsSchema),
  })

  const { handleSubmit, setValue } = methods

  const { data: businessDetails, isFetching: isFetchingBusinessDetails } =
    useQuery(
      "fetchBusinessDetails",
      // @ts-ignore
      () => fetchBusinessDetailsByBusinessId(profileDetails?.businessId),
      {
        onSuccess: (response) => {
          setValue("businessName", response.data?.data.name)
          setValue("businessType", {
            label: response.data?.data.type,
            value: response.data?.data.typeId,
          })
          setValue("businessDescription", response.data?.data.description)
          setValue("businessCategory", {
            label: response.data?.data.category,
            value: response.data?.data.categoryId,
          })
          setValue("country", {
            label: response.data?.data.country,
            value: response.data?.data.countryCode,
          })
          setValue("state", {
            label: response.data?.data.state,
            value: response.data?.data.state,
          })
          setValue("businessAddress", response.data?.data.address)
          setValue("description", response.data?.data.description)
          setValue("city", response.data?.data.city)
          setValue("taxId", response.data?.data.tin)
          setIsSaved(response.data?.data.businessDetailsProvided)
        },
      }
    )

  const { isLoading: isLoadingBusinessTypes } = useQuery(
    "fetchBusinessTypes",
    fetchBusinessTypes,
    {
      onSuccess: (response) => {
        const busTypeRes = response.data.data
        const busTypes: Array<SelectT> = []
        for (let i = 0; i < busTypeRes.length; i++) {
          const item = {
            label: busTypeRes[i].name,
            value: busTypeRes[i].id,
            isDisabled: false,
          }
          busTypes.push(item)
        }
        setBusinessTypes([...busTypes])
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const { isLoading: isLoadingBusinessCategories } = useQuery(
    "fetchBusinessCategories",
    fetchBusinessCategories,
    {
      onSuccess: (response) => {
        const busCatRes = response.data.data
        const busCat: Array<SelectT> = []
        for (let i = 0; i < busCatRes.length; i++) {
          const item = {
            label: busCatRes[i].name,
            value: busCatRes[i].id,
            isDisabled: false,
          }
          busCat.push(item)
        }
        setBusinessCategories([...busCat])
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const {
    mutate: handleBusinessDetailsUpdate,
    isLoading: isLoadingDetailsUpdate,
  } = useMutation(editBusinessDetails, {
    onSuccess: () => {
      toast.success("Business Details Saved!")
      setActiveTab(1)
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const onSubmit = (data: FieldValues) => {
    handleBusinessDetailsUpdate({
      businessId: profileDetails?.businessId,
      merchantUserId: profileDetails?.id,
      name: data.businessName,
      typeId: data.businessType.value,
      city: data.city,
      type: data.businessType.label,
      description: data.businessDescription,
      categoryId: data.businessCategory.value,
      category: data.businessCategory.label,
      address: data.businessAddress,
      state: data.state.value,
      country: "Nigeria",
      countryCode: "NG",
      tin: data.taxId,
    })
  }
  return (
    <>
      {isFetchingBusinessDetails ? (
        <FullScreenLoader
          loading={isFetchingBusinessDetails}
          message="Fetching Business Details"
        />
      ) : (
        <div>
          {!isSaved ? (
            <div>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="bg-white-default p-10">
                    <div className="mb-4 w-[100%] md:w-[60%]">
                      <Input
                        disabled
                        label="Business Name"
                        name="businessName"
                      />
                    </div>
                    <div className=" block mb-4 md:flex md:justify-left gap-2">
                      <div className="w-[100%] md:w-[30%]">
                        <Input
                          label="Tax ID"
                          placeholder="Tax Identification number"
                          name="taxId"
                          type="text"
                          inputMode="numeric"
                        />
                      </div>
                      <div className="w-[100%] mt-4 md:mt-0 md:w-[30%]">
                        <StyledSelect
                          control={methods.control}
                          defaultValue={businessCategories[0]}
                          isDisabled={false}
                          isLoading={isLoadingBusinessCategories}
                          isClearable={false}
                          isSearchable={false}
                          name="businessCategory"
                          label="Business Category"
                          options={businessCategories}
                        />
                      </div>
                    </div>
                    <div className="mb-4 w-[100%] md:w-[60%]">
                      <TextArea
                        label="Business Description"
                        name="businessDescription"
                        placeholder="Some brief description of my business."
                      />
                    </div>
                    <div className="mb-4 w-[100%] md:w-[60%]">
                      <Input label="Business Address" name="businessAddress" />
                    </div>
                    <div className=" block md:flex md:justify-left gap-2 mb-4">
                      <div className=" w-[100%] md:w-[30%]">
                        <StyledSelect
                          control={methods.control}
                          defaultValue={countries[0]}
                          isDisabled={true}
                          isLoading={false}
                          isClearable={false}
                          isSearchable={false}
                          name="country"
                          label="Country"
                          options={countries}
                        />
                      </div>
                      <div className="w-[100%] mt-4 md:mt-0 md:w-[30%]">
                        <StyledSelect
                          control={methods.control}
                          // defaultValue={state[0]}
                          isDisabled={false}
                          isLoading={false}
                          isClearable={false}
                          isSearchable={true}
                          name="state"
                          label="State"
                          options={statesOptions}
                        />
                      </div>
                    </div>
                    <div className=" w-[100%] mt-4 md:w-[30%] md:mt-0">
                      <Input label="City" name="city" />
                    </div>
                  </div>
                  <div className="mt-10 mb-32 flex justify-end">
                    <Button
                      loading={isLoadingDetailsUpdate}
                      className="w-[10rem]"
                      text="Save"
                    />
                  </div>
                </form>
              </FormProvider>
            </div>
          ) : (
            <BusinessDetailsReview
              setActiveTab={setActiveTab}
              setIsSaved={setIsSaved}
              businessDetails={businessDetails?.data?.data}
            />
          )}
        </div>
      )}
    </>
  )
}

export default BusinessDetails
