import React, { useState } from "react"
import Modal from "../../../components/modal"
import { ReactComponent as CautionIcon } from "../../../assets/icons/caution.svg"
import Button from "../../../components/button"
import { toast } from "react-toastify"
import { formatAmount } from "../../../utils/format-amount"
import { useMutation } from "react-query"
import { deletePaymentLink } from "../../../axios/services/payment-services"
import { queryClient } from "../../.."
import { AxiosError } from "axios"
import { useAppSelector } from "../../../redux/hooks"

interface ModalPropsT {
  closeModal: () => void
  isOpen: boolean
  selectedLink: LinkDetailsT
}

const DeletePaymentLink = ({
  closeModal,
  isOpen,
  selectedLink,
}: ModalPropsT) => {
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { mutate: handleDelete, isLoading } = useMutation(
    () => deletePaymentLink(profileDetails?.businessId, selectedLink.id),
    {
      onSuccess: () => {
        toast.success("Payment link Deleted!")
        closeModal()
        queryClient.invalidateQueries("fetchPaymentLinks")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )
  const handleSubmit = () => {
    handleDelete()
  }

  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <div>
        <div className="text-center">
          <div className="flex justify-center mb-3 text-danger-default">
            <CautionIcon className="h-14" />
          </div>
          <h2 className="font-semibold text-lg mb-4">Delete Link</h2>
        </div>
        <p className="mb-3">
          <div className=" text-gray-default">Name</div>
          <div>{selectedLink.name}</div>
        </p>
        <p className="mb-3">
          <div className=" text-gray-default">Amount</div>
          <div>{formatAmount(selectedLink.amount)}</div>
        </p>
        <p className="mb-3">
          <div className=" text-gray-default">Description</div>
          <div>{selectedLink.description}</div>
        </p>
        <p className="mb-3">
          <div className=" text-gray-default">Link</div>
          <div className="input">
            <div className={`input_wrapper`}>
              <input
                disabled
                className="input_field truncate"
                value={selectedLink.url}
              />
            </div>
          </div>
        </p>
        <p className="flex justify-end mt-5">
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit}
            text="Delete"
            className="py-2 w-auto px-7 rounded bg-danger-default hover:bg-danger-default/90"
          />
        </p>
      </div>
    </Modal>
  )
}

export default DeletePaymentLink
