import React from "react"
import Button from "../../../components/button"
import { ReactComponent as PaymentIcon } from "assets/icons/payment-link.svg"
import { ReactComponent as ReloadIcon } from "assets/icons/reload.svg"
type PropsT = {
  setPaymentLinkModal: (isOpen: boolean) => void
}

const NoPaymentData = ({ setPaymentLinkModal }: PropsT) => {
  return (
    <div>
      <div className="flex flex-col justify-center items-center mt-40 mb-52">
        <div className="text-secondary-default">
          <PaymentIcon />
        </div>
        <div className="font-semibold text-xl mt-5">No Payment Link</div>
        <div className="mt-1 text-gray-default">Start requesting payments.</div>
        <div className="mt-5">
          <Button
            text="+ Create A Payment Link"
            className="w-max px-5 py-3 mr-5 rounded-md"
            onClick={() => setPaymentLinkModal(true)}
          />
        </div>
        <div className="mt-5">
          <ReloadIcon
            onClick={() => window.location.reload()}
            className="mt-4 cursor-pointer "
          />
        </div>
      </div>
    </div>
  )
}

export default NoPaymentData
