import React from "react"
import SignupLayout from "../../layout/public/signupLayout"

const SignUpSuccess = () => {
  return (
    <SignupLayout
      content={
        <div className="w-[40%] text-center">
          <h2 className="text-xl font-semibold mb-3">Activate Account</h2>
          <div>
            Click on the link sent to your email to activate your account.
          </div>
        </div>
      }
    />
  )
}

export default SignUpSuccess
