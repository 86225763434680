import React, { useEffect, useState } from "react"
import Button from "../../components/button"
import Input from "../../components/input/input"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { bankSchema, bvnSchema } from "./validation-schema"
import StyledSelect from "../../components/select"
import BvnOtpModal from "./modals/bvn-top-modal"
import ReviewSubmitModal from "./modals/review-submit-modal"
import SuccessModal from "./modals/success-modal"
import BankInfoReview from "./review-pages/bank-information-review"
import { useMutation, useQuery } from "react-query"
import {
  fetchBanks,
  fetchBusinessBankDetails,
  initiateBvnVerification,
  saveBankInformation,
  submitKycForApproval,
  verifyBankAccount,
} from "../../axios/services/merchant-services"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import FullScreenLoader from "../../components/loader/fullscreen-loader"
import { queryClient } from "../.."
import { ReactComponent as Verified } from "../../assets/icons/verified.svg"
import { getBusinessData } from "hooks/useLogin"
import NibbsBvnModal from "./modals/nibbs-bvn-modal"
import { Link } from "react-router-dom"
import { bvnIframeSrc } from "../../axios/api/bvn"

type SelectT = {
  label: string
  value: string
  isDisabled: boolean
  id: number
}

type PropsT = {
  setActiveTab: (activeTab: number) => void
}

const BankInformation = ({ setActiveTab }: PropsT) => {
  const [banks, setBanks] = useState<Array<SelectT>>([])
  const [bvnModal, setBvnModal] = useState<boolean>(false)
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const [successModal, setSuccessModal] = useState<boolean>(false)
  const [bvnVerified, setBvnVerified] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)

  const profileDetails = useAppSelector((state) => state.profile.profile)
  const dispatch = useAppDispatch()

  const bankMethods = useForm({
    resolver: yupResolver(bankSchema),
  })

  const { setValue } = bankMethods
  const bankValues = bankMethods.watch()

  const methods = useForm({
    resolver: yupResolver(bvnSchema),
  })

  const { getValues, setValue: setBvnValue } = methods

  const { data: BankDetails, isFetching: isLoadingBankDetails } = useQuery(
    "fetchBankDetails",
    // @ts-ignore
    () => fetchBusinessBankDetails(profileDetails?.id),
    {
      onSuccess: (response) => {
        setBvnValue("bvn", response.data?.data.bvn)
        setBvnVerified(response.data?.data.providedBvn)
        setIsSaved(response.data?.data.providedBankAccount)
        setValue("accountName", response.data?.data.accountName)
        setValue("accountNumber", response.data?.data.accountNumber)
        setValue("bankName", {
          label: response.data?.data.bankName,
          value: response.data?.data.bankCode,
          id: response.data?.data.bankId,
        })
      },
      onError: (error: AxiosError) => {
        // @ts-ignore
        const data = error.response?.data?.data
        if (error.response?.status === 404) {
          setBvnValue("bvn", data.bvn)
          setBvnVerified(data.providedBvn)
          setValue("accountName", data.accountName)
          setValue("accountNumber", data.accountNumber)
          setValue("bankName", {
            label: data.bankName,
            value: data.bankCode,
            id: data.bankId,
          })
        } else {
          // @ts-ignore
          toast.error(error.response?.data.message)
        }
      },
    }
  )

  const { isLoading: isLoadingBanks } = useQuery("fetchBanksList", fetchBanks, {
    onSuccess: (response) => {
      const banksRes = response.data.data
      const banksArray: Array<SelectT> = []
      for (let i = 0; i < banksRes.length; i++) {
        const item = {
          label: banksRes[i].name,
          value: banksRes[i].code,
          id: banksRes[i].id,
          isDisabled: false,
        }
        banksArray.push(item)
      }
      setBanks([...banksArray])
    },
  })

  const {
    mutate: handleVerifyBankDetails,
    isLoading: isLoadingBankVerification,
  } = useMutation(verifyBankAccount, {
    onSuccess: (response) => {
      setValue("accountName", response.data?.data.account_name)
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const { mutate: handleUpdateBankInfo, isLoading: isLoadingBankInfoUpdate } =
    useMutation(saveBankInformation, {
      onSuccess: () => {
        toast.success("Bank Information saved successfully!")
        setConfirmationModal(true)
        queryClient.invalidateQueries("fetchBankDetails")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    })

  const handleBankDetailsSubmit = (data: FieldValues) => {
    handleUpdateBankInfo({
      bankId: data.bankName.id,
      businessId: profileDetails?.businessId,
      accountNumber: data.accountNumber,
      accountName: data.accountName,
      bvn: getValues("bvn"),
    })
  }

  const { mutate: submitKYC, isLoading: isSubmittingKYC } = useMutation(
    submitKycForApproval,
    {
      onSuccess: async () => {
        //Close Review/Sumit modal if open
        setConfirmationModal(false)
        //Open Success Modal
        setSuccessModal(true)
        await getBusinessData(profileDetails?.businessId ?? 0, dispatch)
        queryClient.invalidateQueries("fetchBankDetails")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const handleSubmitKYC = () => {
    submitKYC({
      businessId: profileDetails?.businessId,
    })
  }

  useEffect(() => {
    if (bankValues.accountNumber?.length === 10 && bankValues.bankName) {
      handleVerifyBankDetails({
        account_number: bankValues.accountNumber,
        account_bank: bankValues.bankName.value,
      })
    }
  }, [bankValues.accountNumber, bankValues.bankName])

  const link =
    "https://idsandbox.nibss-plc.com.ng/oxauth/authorize.htm?scope=profile+banking_date+bvn&acr_values=otp&response_type=code&redirect_uri=https://merchant-portal-dev.tgipay.com/validate-bvn&client_id=32eaff2d-c3d1-4646-a1e2-40014ef2a3bb"

  return (
    <>
      {isLoadingBankDetails ? (
        <FullScreenLoader
          loading={isLoadingBankDetails}
          message={"Fetching Bank Details"}
        />
      ) : (
        <div>
          {!isSaved ? (
            <>
              <div className="w-[100%] md:w-[35%]">
                <div className="mb-4 font-medium">BVN</div>

                <div className="mt-4">
                  {bvnVerified ? (
                    <div className="text-primary-default font-semibold flex">
                      <span className="mr-2">BVN Verified</span> <Verified />
                    </div>
                  ) : (
                    <Link to={link}>
                      <Button
                        className=" w-[9rem] px-5 py-2"
                        text="Verify BVN"
                      />
                    </Link>
                  )}
                </div>
              </div>

              <div
                className={` w-[100%] md:w-[35%] mt-12 opacity ${
                  bvnVerified ? "opacity-100" : "opacity-40 pointer-events-none"
                }`}
              >
                <div className="mb-4 font-medium">Bank Details</div>
                <FormProvider {...bankMethods}>
                  <form
                    onSubmit={bankMethods.handleSubmit(handleBankDetailsSubmit)}
                  >
                    <div className="bg-white-default p-10">
                      <div className="mb-4">
                        <StyledSelect
                          control={bankMethods.control}
                          defaultValue={banks[0]}
                          isDisabled={false}
                          isLoading={isLoadingBanks}
                          isClearable={false}
                          isSearchable={true}
                          name="bankName"
                          label="Bank"
                          options={banks}
                        />
                      </div>
                      <div className="mb-4">
                        <Input label="Account Number" name="accountNumber" />
                        {isLoadingBankVerification && (
                          <span className="text-xs text-success-default">
                            Verifying account...
                          </span>
                        )}
                      </div>
                      <div className="mb-4">
                        <Input
                          disabled
                          label="Account Name"
                          name="accountName"
                        />
                      </div>
                    </div>
                    <div className="mt-4 flex justify-end">
                      <Button
                        loading={isLoadingBankInfoUpdate}
                        className=" w-auto px-5 py-2"
                        text="Proceed"
                      />
                    </div>
                  </form>
                </FormProvider>
              </div>
            </>
          ) : (
            <BankInfoReview
              setIsSaved={setIsSaved}
              setBvnVerified={setBvnVerified}
              bankDetails={BankDetails?.data?.data}
              isSubmittingKyc={isSubmittingKYC}
              handleSubmitKYC={handleSubmitKYC}
            />
          )}

          {bvnModal && (
            <BvnOtpModal
              closeModal={() => setBvnModal(false)}
              isOpen={bvnModal}
              setBvnVerified={setBvnVerified}
              merchantId={profileDetails?.id}
              bvn={getValues("bvn")}
            />
          )}

          {confirmationModal && (
            <ReviewSubmitModal
              closeModal={() => setConfirmationModal(false)}
              isOpen={confirmationModal}
              setSuccessModal={setSuccessModal}
              setActiveTab={setActiveTab}
              isSubmittingKyc={isSubmittingKYC}
              handleSubmitKYC={handleSubmitKYC}
            />
          )}

          {successModal && (
            <SuccessModal
              closeModal={() => setSuccessModal(false)}
              isOpen={successModal}
            />
          )}
        </div>
      )}
    </>
  )
}

export default BankInformation
