import React from "react"
import Button from "../../../components/button"

type BankInfoT = {
  setIsSaved: (isSaved: boolean) => void
  setBvnVerified: (bvnVerified: boolean) => void
  bankDetails: {
    bvn: string
    accountName: string
    accountNumber: string
    bankName: string
    kycSubmitted: boolean
  }
  handleSubmitKYC: () => void
  isSubmittingKyc: boolean
}

const BankInfoReview = ({
  setIsSaved,
  setBvnVerified,
  bankDetails,
  isSubmittingKyc,
  handleSubmitKYC,
}: BankInfoT) => {
  const handleEdit = () => {
    setIsSaved(false)
    setBvnVerified(false)
  }
  return (
    <div>
      <div className=" w-[100%] md:w-[50%] lg:w-[40%] bg-white-default p-10">
        <table className="table-auto">
          <tr>
            <td className="font-bold pb-3">BVN</td>
          </tr>
          <tr className="">
            <td className="font-medium py-3 pr-10">BVN Of Business Director</td>
            <td className="py-3">{bankDetails.bvn}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10"></td>
            <td className="py-3"></td>
          </tr>
          <tr>
            <td className="font-bold py-3 pr-10">Bank Details</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Bank</td>
            <td className="py-3">{bankDetails.bankName}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Account Number</td>
            <td className="py-3">{bankDetails.accountNumber}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Account Name</td>
            <td className="py-3">{bankDetails.accountName}</td>
          </tr>
        </table>

        {bankDetails.kycSubmitted ? null : (
          <div className="mt-14">
            <button
              onClick={handleEdit}
              className="text-primary-default font-semibold"
            >
              Edit
            </button>
          </div>
        )}
      </div>

      {bankDetails.kycSubmitted ? null : (
        <div className="w-[75%] md:w-[50%] lg:w-[40%] flex justify-end mt-10 mb-36">
          <Button
            onClick={handleSubmitKYC}
            loading={isSubmittingKyc}
            text="Submit KYC"
            className=" w-auto px-5 py-2"
          />
        </div>
      )}
    </div>
  )
}

export default BankInfoReview
