import { queryKeys } from "constants/queryKeys"
import { updateIntegrationSettings } from "../../../axios/services/merchant-services"
import { queryClient } from "index"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { AxiosError } from "axios"

export const useIntegration = () => {
  const { mutate: handleUpdateSettings, isLoading: isUpdatingSettings } =
    useMutation(updateIntegrationSettings, {
      onSuccess: () => {
        toast.success("Integration settings updated successfully!")
        queryClient.invalidateQueries(
          queryKeys.integration.getMerchantIntegrationSettings
        )
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    })

  return {
    handleUpdateSettings,
    isUpdatingSettings,
  }
}
