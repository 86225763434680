import React from "react"
import { Link } from "react-router-dom"
import Button from "../../components/button"
import AuthLayout from "../../layout/public/authLayout"
import { ReactComponent as Check } from "../../assets/icons/green-check.svg"

const ResetSuccess = () => {
  return (
    <AuthLayout
      title="Reset Password"
      content={
        <>
          <div className="text-center">
            <div className="flex justify-center mt-5 mb-10">
              <Check />
            </div>
            <div className="text-2xl font-semibold mb-3">
              Password Reset Successful
            </div>
            <div>You can now log in with your new password</div>
          </div>
          <div className="w-100 mt-10">
            <Link to="/login">
              <Button text="Go to Login" />
            </Link>
          </div>
        </>
      }
    />
  )
}

export default ResetSuccess
