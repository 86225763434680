import React from "react"
import { Link, useLocation } from "react-router-dom"
import { FormProvider } from "react-hook-form"
import Button from "../../components/button"
import Input from "../../components/input/input"
import AuthLayout from "../../layout/public/authLayout"
import { useLogin } from "hooks/useLogin"

const Login = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const redirectUrl = queryParams.get("redirectUrl")
  const { methods, handleSubmit, onSubmit, isLoading, values } =
    useLogin(redirectUrl)

  return (
    <AuthLayout
      title="Log in to your account"
      content={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <Input label="Email Address" name="email" type="email" />
            </div>
            <div className="mb-4">
              <Input label="Password" name="password" type="password" />
            </div>
            <div className=" flex justify-between align-middle mt-10 mb-8">
              {/* <div className="text-sm flex items-center mb-2">
                <div className="flex items-center cursor-pointer mr-4">
                  <input
                    type="checkbox"
                    {...methods.register("rememberMe")}
                    className="absolute h-8 w-8 opacity-0"
                  />
                  <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-md bg-gray-200 border border-gray-700 cursor-pointer">
                    <svg
                      className={`fill-current text-danger-default ${
                        values.rememberMe ? null : "hidden"
                      } h-3 w-3`}
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <g
                          transform="translate(-9 -11)"
                          fill="#204D88"
                          fillRule="nonzero"
                        >
                          <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <span>Remember Me</span>
              </div> */}
              <div>
                <Link
                  to="/forgot-password"
                  className="text-primary-default text-sm font-semibold"
                >
                  Forgot Password?
                </Link>
              </div>
            </div>

            <div className="w-100">
              <Button loading={isLoading} disabled={isLoading} text="Login" />
            </div>

            <div className="text-xs text-center mt-5">
              <span>Are you a new user? </span>
              <Link className="text-primary-default font-semibold" to="/signup">
                Sign Up
              </Link>
            </div>
          </form>
        </FormProvider>
      }
    />
  )
}

export default Login
