import Button from "components/button"
import BackArrow from "components/button/back-arrow"
import { DataRow } from "components/data-row/data-row"
import Status from "components/status/Status"
import AppLayout from "layout/private/app-layout"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useDisputes from "./hooks/useDisputes"
import { queryKeys } from "constants/queryKeys"
import FullScreenLoader from "components/loader/fullscreen-loader"

const DisputeDetails = () => {
  const [searchParams] = useSearchParams()
  const disputeId = searchParams.get("id")
  const { reopenDisputeHandler } = useDisputes(
    queryKeys.disputes.reopenDispute,
    disputeId
  )
  const navigate = useNavigate()
  const transactionId = searchParams.get("transactionId")
  const settlementId = searchParams.get("settlementId")

  const { disputeDetails } = useDisputes(
    queryKeys.disputes.fetchDisputeDetails,
    disputeId
  )

  const newDisputeDetails = {
    Reference: disputeDetails.data?.disputeReference,
    "Rasied By": disputeDetails.data?.raisedBy,
    "Date Raised": disputeDetails.data?.createdDate,
    Category: disputeDetails.data?.reason,
    Note: disputeDetails.data?.notes,
    "Resolved On": disputeDetails.data?.resolvedOn,
    Status: (
      <Status variant={disputeDetails.data?.resolutionStatus}>
        {disputeDetails.data?.resolutionStatus}
      </Status>
    ),
  }
  return (
    <AppLayout title="" subTitle="">
      <>
        <div className="flex gap-10">
          <div className=" w-[100%] md:w-[50%] bg-white-default rounded p-6 ">
            <div>Dispute Details</div>
            <div className="font-light text-gray-default mb-5">
              View details of raised dispute
            </div>
            <hr />
            <div className="flex min-h-352 max-w-4xl flex-col gap-8 mt-5">
              {Object.entries(newDisputeDetails).map(([label, value]) => (
                <DataRow
                  key={label}
                  isLoading={disputeDetails.loadingDetails}
                  label={label}
                  value={value}
                />
              ))}

              {searchParams.get("resolutionStatus") === "resolved" && (
                <div className="flex gap-4 text-sm">
                  <div className="w-2/5 text-gray-10/60">Action</div>
                  <div
                    className="w-3/5 font-semibold text-gray-10 cursor-pointer text-primary-default"
                    onClick={reopenDisputeHandler.onReopenDispute}
                  >
                    Reopen Dispute
                  </div>
                </div>
              )}
            </div>

            <div className="mt-10 mb-10">
              <Button
                onClick={() =>
                  navigate(
                    `/disputes/details/transactions/?transactionId=${transactionId}&settlementId=${settlementId}`
                  )
                }
                // Check if the dispute is a transation dispute or settlement dispute
                text={`View ${
                  transactionId !== null ? "Transaction" : "Settlement"
                }`}
                variant="outline"
                className="w-auto px-5 py-1 rounded"
              />
            </div>
          </div>
          {/* <div className="w-[50%] p-6">
            <div className="mb-5">Dispute History</div>
            <hr />
            <div className="mt-5">
              {disputeComments.map((comment, index) => (
                <div key={index} className="mb-8 text-sm">
                  <div className="font-medium">{comment.comment}</div>
                  <div className="text-right mt-2">{comment.commentBy}</div>
                  <div className="text-right text-gray-default">
                    {comment.date}
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
        <div className="mt-10">
          <BackArrow onClick={() => navigate(-1)} label="Back" />
        </div>

        {reopenDisputeHandler.isReopeningDispute && (
          <FullScreenLoader
            loading={reopenDisputeHandler.isReopeningDispute}
            message="Re-opening dispute"
          />
        )}
      </>
    </AppLayout>
  )
}

export default DisputeDetails
