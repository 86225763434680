import React, { FC } from "react"

interface SectionLoaderProps {
  loading: boolean
  message?: string
}

const SectionLoader: FC<SectionLoaderProps> = ({
  loading,
  message = "Loading...",
}) => {
  if (!loading) return null

  return (
    <div className="absolute z-50 inset-0 flex items-center justify-center">
      <div className="absolute inset-0 bg-white-default opacity-75"></div>
      <div className="relative z-50 w-64 h-64">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-default mx-auto"></div>
        <div className="mt-4 text-primary-default text-center">{message}</div>
      </div>
    </div>
  )
}

export default SectionLoader
