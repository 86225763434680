import React, { FC } from "react"
import PaymentHandler from "pages/make-payment/payment-handler"
import { PaymentChannel } from "pages/make-payment/types/payment"
import Button from "components/button"
import logo from "assets/images/TGIWhiteLogo.png"
import { useAppSelector } from "redux/hooks"

const MakeIntegrationPayment: FC = () => {
  const { integrationData } = useAppSelector((state) => state.paymethod)

  const handlePaymentSuccess = () => {
    returnToCallback()
  }

  const returnToCallback = () => {
    if (integrationData?.callBackUrl) {
      window.location.href = integrationData.callBackUrl
    }
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full md:w-[60rem] border-2 relative">
        <div className="flex items-center justify-center h-24 bg-primary-default text-white-900 rounded-lg sticky top-0">
          <img src={logo} className="w-[80px]" alt="tgipay-logo" />
        </div>
        <PaymentHandler
          paymentData={{
            ...integrationData,
            paymentType: PaymentChannel.Integration,
          }}
          fullWidth
          successCallback={handlePaymentSuccess}
        />
      </div>
      <Button
        variant="link"
        className="p-5"
        text="Cancel payment"
        onClick={returnToCallback}
      />
    </div>
  )
}

export default MakeIntegrationPayment
