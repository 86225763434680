import FullScreenLoader from "components/loader/fullscreen-loader"
import { verifyPaymentRequest } from "../../axios/services/payment-services"
import React, { useEffect, useRef } from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { encryptData } from "utils/encrypt-data"
import { retrieveInternalTransportDataToStorage } from "./hooks/usePayment"
import { toast } from "react-toastify"
import { encodeSearchParams } from "utils/encodeSearchParams"

type InternalTransportDataT = {
  firstName: string
  lastName: string
  email: string
  currency: string
  amount: number
  transactionId: number
  paymentLinkId: number
  customerPaymentLinkId: number
  tgipayReference: string
  processorReference: string | null
  paymentLinkRequest: boolean
  invoiceRequest: boolean
}

const VerifyPayment = () => {
  const dataFetchedRef = useRef(false)
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(window.location.search)
  const responseJson = urlParams.get("response")
  //   @ts-ignore
  const response = JSON.parse(responseJson)
  const txRef = response.txRef

  const { mutate: handleVerifyPayment, isLoading: isLoadingVerifyPayment } =
    useMutation(verifyPaymentRequest, {
      onSuccess: (response) => {
        const jsonResponseData = JSON.stringify(response.data.data)
        const encodedData = encodeURIComponent(jsonResponseData)
        navigate(`/make-payment/payment-success?response=${encodedData}`)
      },
      onError: () => {
        //@ts-ignore
        toast.error("Unable to complete transaction, try again!")
        navigate("/make-payment/payment-failed")
        return
      },
      retry: 5,
    })

  const verifyPayment = () => {
    const internalTransportData = retrieveInternalTransportDataToStorage()
    const cardDetailsToEncrypt = {
      transactionReference: txRef,
      internalTransportData: internalTransportData,
    }
    const encryptedData = encryptData(cardDetailsToEncrypt)
    const payload = {
      data: encryptedData.ciphertext,
      secretKey: encryptedData.encryptedSecret,
      iv: encryptedData.encryptedIv,
    }
    handleVerifyPayment(payload)
  }

  useEffect(() => {
    if (dataFetchedRef.current) return
    dataFetchedRef.current = true
    verifyPayment()
  }, [txRef !== undefined])
  return (
    <div>
      {isLoadingVerifyPayment ? (
        <FullScreenLoader
          loading={isLoadingVerifyPayment}
          message="Verifying Payment"
        />
      ) : null}
    </div>
  )
}

export default VerifyPayment
