import React, { FC, useEffect, useRef, useState } from "react"

interface Props {
  onChange: (otp: string[]) => void
  fieldCount: number
}

const OtpInput: FC<Props> = ({ fieldCount, onChange }) => {
  const [otp, setOtp] = useState<string[]>(Array(fieldCount).fill(""))
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const newOTP = [...otp]
    newOTP[activeOTPIndex] = value.substring(value.length - 1)
    setOtp(newOTP)

    const nextIndex = value ? activeOTPIndex + 1 : activeOTPIndex - 1
    setActiveOTPIndex(Math.max(0, Math.min(nextIndex, fieldCount - 1)))

    onChange(newOTP)
  }

  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      e.preventDefault()
      const newOTP = [...otp]
      newOTP[index] = ""
      setOtp(newOTP)
      setActiveOTPIndex(Math.max(0, index - 1))
    }
  }

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("text").slice(0, fieldCount)
    const newOTP = [
      ...pasteData.split(""),
      ...Array(fieldCount - pasteData.length).fill(""),
    ]
    setOtp(newOTP)
    setActiveOTPIndex(newOTP.findIndex((val) => val === "") || fieldCount - 1)
    onChange(newOTP)
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [activeOTPIndex])

  return (
    <div className="flex items-center justify-center space-x-2">
      {otp.map((value, index) => (
        <React.Fragment key={index}>
          <input
            ref={index === activeOTPIndex ? inputRef : null}
            type="text"
            inputMode="numeric"
            maxLength={1}
            value={value}
            onChange={handleOnChange}
            onKeyDown={(e) => handleOnKeyDown(e, index)}
            onPaste={handleOnPaste}
            className="bg-transparent spin-button-none h-[45px] w-[45px] rounded border-2 border-[#D9D9D9] text-center text-xl font-semibold text-[#292727] outline-none transition focus:border-[#D9D9D9] focus:text-[#292727] focus:ring-[#D9D9D9]"
          />
          {index < fieldCount - 1 && (
            <span className="bg-gray-400 w-2 py-0.5" />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default OtpInput
