import * as yup from "yup"

export const ProfessionalInvoiceSchema = yup.object().shape({
  name: yup.string().required("Customer Name cannot be empty"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter customer email"),
  notes: yup.string(),
  paymentDue: yup.string().required("Payment Due cannot be empty"),
  items: yup.array().of(
    yup.object().shape({
      item: yup.string().required("Name is required"),
      quantity: yup.number().required("Quantity is required"),
      unitPrice: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(0, "Must be greater than or equal to 0")
        .required("Unit Price is required"),
    })
  ),
})
