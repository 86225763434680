import * as yup from "yup"

export const signUpSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  businessName: yup.string().required("Business Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  businessType: yup.object().shape({
    value: yup.string().required("Business Type is required"),
  }),
  country: yup.object().shape({
    value: yup.string(),
  }),
  password: yup
    .string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Password is required"),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], 'Must match "password" field value'),
  tandc: yup.bool().oneOf([true], "You must accept the terms and conditions"),
})
