import { AxiosError } from "axios"
import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { toast } from "react-toastify"
import { queryClient } from "../.."
import {
  fetchAllKycDocs,
  fetchIdentificationTypes,
  uploadKycDoc,
} from "../../axios/services/merchant-services"
import Button from "../../components/button"
import Dropzone from "../../components/dropzone"
import FullScreenLoader from "../../components/loader/fullscreen-loader"
import { useAppSelector } from "../../redux/hooks"
import EditDocument from "./components/EditDocument"
import DocumentsReview from "./review-pages/documents-review"
import Select from "react-select"
import { colourStyles } from "components/select/select-style"

type PropsT = {
  setActiveTab: (activeTab: number) => void
}

interface OptionType {
  value: string
  label: string
  id?: number
  isDisabled?: boolean
  isFocused?: boolean
  isSelected?: boolean
}

type SelectT = {
  label: string
  value: string
  isDisabled?: boolean
}

type DocumentT = {
  businessId: number
  contentType: string
  documentsId: number
  file: null
  fileName: string
  location: string
}

const UploadDocuments = ({ setActiveTab }: PropsT) => {
  const [isRegistered, setIsRegistered] = useState(false)
  const [isSaved, setIsSaved] = useState(true)
  const [kycSubmitted, setKycSubmitted] = useState(false)
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null)
  const [selectedOptionErrMsg, setSelectedOptionErrMsg] = useState<
    string | undefined
  >()
  const [cacDoc, setCacDoc] = useState<DocumentT | null>(null)
  const [validId, setValidId] = useState<DocumentT | null>(null)
  const [utilBill, setUtilBill] = useState<DocumentT | null>(null)
  const [moa, setMoa] = useState<DocumentT | null>(null)
  const [docs, setDocs] = useState<Array<DocumentT>>([])
  const [idTypes, setIdTypes] = useState<Array<SelectT>>()

  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { isFetching: isFetchingTypes } = useQuery(
    "fecthIdentificationTypes",
    fetchIdentificationTypes,
    {
      onSuccess: (response) => {
        const idTypesRes = response.data.data
        const identificationTypes: Array<SelectT> = []
        for (let i = 0; i < idTypesRes.length; i++) {
          const item = {
            label: idTypesRes[i].name,
            value: idTypesRes[i].id,
            isDisabled: false,
          }
          identificationTypes.push(item)
        }
        setIdTypes([...identificationTypes])
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const { isFetching: isLoadingKycDocuments } = useQuery(
    "fetchKycDocs",
    // @ts-ignore
    () => fetchAllKycDocs(profileDetails?.businessId),
    {
      onSuccess: (response) => {
        if (response.data?.data?.businessTypeId === 1) {
          setIsRegistered(true)
        } else setIsRegistered(false)
        //If the length of documents list is zero, it means no documents has been uploaded, just show the edit page and not the review page
        if (
          // If the business type is Registered, the user requires four document, show preview
          response.data?.data?.documentDataList?.length === 4 &&
          response.data?.data?.businessTypeId === 1
        ) {
          setIsSaved(true)
        } else if (
          // If the business type is Non Registered, the user requires one document, show preview
          response.data?.data?.documentDataList?.length === 1 &&
          response.data?.data?.businessTypeId === 2
        ) {
          setIsSaved(true)
        } else setIsSaved(false)
        setKycSubmitted(response.data?.data?.kycSubmitted)
        setDocs(response.data?.data?.documentDataList)
        const documentList = response.data?.data?.documentDataList
        //I am setting the different documents field to show which one has been uploaded or not
        for (let i = 0; i < documentList.length; i++) {
          if (documentList[i].documentsId === 1) {
            setCacDoc(documentList[i])
          } else if (documentList[i].documentsId === 2) {
            setMoa(documentList[i])
          } else if (documentList[i].documentsId === 3) {
            setUtilBill(documentList[i])
          } else if (documentList[i].documentsId === 4) {
            setValidId(documentList[i])
            setSelectedOption({
              label: documentList[i]?.identificationType,
              value: documentList[i]?.identificationTypeId,
              isDisabled: false,
            })
          }
        }
      },
      onError: (error: AxiosError) => {
        //@ts-ignoref
        toast.error(error.response?.data.message)
      },
    }
  )

  const { mutate, isLoading } = useMutation(uploadKycDoc, {
    onSuccess: () => {
      toast.success("Upload Sucessful")
      queryClient.invalidateQueries("fetchKycDocs")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  //The id represent the documentTypeId
  const handleDrop = (id: number, files: File[]) => {
    if (id === 4 && selectedOption === null) {
      setSelectedOptionErrMsg("Select the Identification Type")
      return
    }
    const businessId = profileDetails?.businessId
    const formdata = new FormData()
    // @ts-ignore
    formdata.append("businessId", businessId?.toString())
    formdata.append("documentTypeId", id.toString())
    formdata.append("document", files[0])
    if (id === 4) {
      formdata.append(
        "identificationTypeId",
        selectedOption?.value ? selectedOption?.value : "0"
      )
    }
    mutate(formdata)
  }

  const handleChange = (selectedOption: OptionType | null) => {
    setSelectedOption(selectedOption)
    setSelectedOptionErrMsg(undefined)
  }

  return (
    <>
      {isLoadingKycDocuments || isLoading ? (
        <FullScreenLoader
          loading={isLoadingKycDocuments || isLoading}
          message={isLoading ? "Uploading Document" : "Fetching Documents"}
        />
      ) : (
        <div>
          {!isSaved ? (
            <>
              <div className="bg-white-default p-10">
                <div className="w-[100%] md:w-[75%] lg:w-[60%]">
                  <div className="mb-7 w-[100%] md:w-[50%]">
                    <div className="mb-3">
                      <label className="font-medium text-black-default">
                        Valid Means of ID
                      </label>
                    </div>
                    <Select
                      value={selectedOption}
                      isLoading={isFetchingTypes}
                      // @ts-ignore
                      onChange={handleChange}
                      options={idTypes}
                      styles={colourStyles}
                    />
                    {selectedOptionErrMsg && (
                      <div className="text-sm text-danger-default mt-2">
                        {selectedOptionErrMsg}
                      </div>
                    )}
                  </div>

                  <div className="mb-7 block md:flex md:justify-between">
                    <div className=" w-[100%] md:w-[45%]">
                      {validId ? (
                        <EditDocument
                          label="Upload Valid Means of ID"
                          fileName={validId?.fileName}
                          removeFile={() => setValidId(null)}
                        />
                      ) : (
                        <>
                          <div className="mb-3">
                            <label className="font-medium text-black-default">
                              Upload Valid Means of ID
                            </label>
                          </div>
                          <Dropzone
                            acceptedFileTypes={{
                              "image/jpeg": [],
                              "image/png": [],
                              "application/pdf": [],
                            }}
                            onDrop={(files) => handleDrop(4, files)}
                          />
                        </>
                      )}
                    </div>
                    {isRegistered ? (
                      <div className=" w-[100%] mt-6 md:mt-0 md:w-[45%]">
                        {utilBill ? (
                          <EditDocument
                            label="Utility Bill"
                            fileName={utilBill?.fileName}
                            removeFile={() => setUtilBill(null)}
                          />
                        ) : (
                          <>
                            <div className="mb-3">
                              <label className="font-medium text-black-default">
                                Utility Bill
                              </label>
                            </div>
                            <Dropzone
                              acceptedFileTypes={{
                                "image/jpeg": [],
                                "image/png": [],
                                "application/pdf": [],
                              }}
                              onDrop={(files) => handleDrop(3, files)}
                            />
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>

                  {isRegistered ? (
                    <div className="mb-7 block md:flex md:justify-between">
                      <div className=" w-[100%] md:w-[45%]">
                        {cacDoc ? (
                          <EditDocument
                            label="CAC Document"
                            fileName={cacDoc.fileName}
                            removeFile={() => setCacDoc(null)}
                          />
                        ) : (
                          <>
                            <div className="mb-3">
                              <label className="font-medium text-black-default">
                                CAC Document
                              </label>
                            </div>
                            <Dropzone
                              acceptedFileTypes={{
                                "image/jpeg": [],
                                "image/png": [],
                                "application/pdf": [],
                              }}
                              onDrop={(files) => handleDrop(1, files)}
                            />
                          </>
                        )}
                      </div>
                      <div className=" w-[100%] mt-6 md:mt-0 md:w-[45%]">
                        {moa ? (
                          <EditDocument
                            label="Memorandum Of Association"
                            fileName={moa?.fileName}
                            removeFile={() => setMoa(null)}
                          />
                        ) : (
                          <>
                            <div className="mb-3">
                              <label className="font-medium text-black-default">
                                Memorandum Of Association
                              </label>
                            </div>
                            <Dropzone
                              acceptedFileTypes={{
                                "image/jpeg": [],
                                "image/png": [],
                                "application/pdf": [],
                              }}
                              onDrop={(files) => handleDrop(2, files)}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="mt-10 mb-32 flex justify-end">
                <Button
                  className="w-[10rem]"
                  text="Proceed"
                  onClick={() => setActiveTab(2)}
                />
              </div>
            </>
          ) : (
            <DocumentsReview
              documentList={docs}
              selectedOption={selectedOption}
              setIsSaved={() => setIsSaved(false)}
              setActiveTab={() => setActiveTab(2)}
              kycSubmitted={kycSubmitted}
            />
          )}
        </div>
      )}
    </>
  )
}

export default UploadDocuments
