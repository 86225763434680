export const formatAmount = (
  amount: number | null | undefined,
  currencyCode?: string
) => {
  const amountToFormat = amount ? amount : 0
  const formatting_options = {
    style: "currency",
    currency: currencyCode || "NGN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const formattedAmount = new Intl.NumberFormat("en-us", formatting_options)
  return formattedAmount.format(amountToFormat)
}

export const formatNumber = (amount: number | null | undefined) => {
  const amountToFormat = amount ? amount : 0
  const formatting_options = {
    style: "decimal",
  }
  const formattedAmount = new Intl.NumberFormat("en-us", formatting_options)
  return formattedAmount.format(amountToFormat)
}

export const formatWithCustomCurrency = (
  amount: number,
  prefix: string
): string => {
  const amountToFormat = amount ? amount : 0
  const formatting_options = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const formattedAmount = new Intl.NumberFormat("en-us", formatting_options)
  const newFormattedAmount = formattedAmount.format(amountToFormat)
  return `${prefix} ${newFormattedAmount}`
}
