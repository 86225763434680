import React from 'react'
import logo from "../../assets/images/TGIWhiteLogo.png"
import styles from './signupLayout.module.scss'

type SignupLayoutPropsT ={
    content: React.ReactNode
}

const SignupLayout = ({content}: SignupLayoutPropsT) => {
  return (
    <div className=" p-0 md:p-5 flex min-h-screen justify-center mt-12 md:mt-0 items-start md:items-center">
      <div
        className={`hidden w-5/12 bg-primary-default md:flex items-center justify-center rounded-lg ${styles.columnOne}  self-stretch`}
      >
        <img src={logo} className=" object-none w-1/2" alt="tgipay-logo" />
      </div>
      <div
        className={`w-[90%] md:w-7/12 flex justify-center items-center  self-stretch`}
      >
       {content}
      </div>
    </div>
  )
}

export default SignupLayout