import { merchantApi } from "../api/merchant"
import {
  MerchantAxiosInstance,
  NoAuthMerchantAxiosInstance,
} from "../instances/merchant-instance"

export const fetchCountries = async () => {
  const response = await NoAuthMerchantAxiosInstance.get(
    merchantApi.getCountries
  )
  return response
}

export const fetchIdentificationTypes = async () => {
  const response = await MerchantAxiosInstance.get(
    merchantApi.getIdentificationTypes
  )
  return response
}

export const fetchBusinessTypes = async () => {
  const response = await NoAuthMerchantAxiosInstance.get(
    merchantApi.getBusinessTypes
  )
  return response
}

export const fetchBusinessCategories = async () => {
  const response = await NoAuthMerchantAxiosInstance.get(
    merchantApi.getBusinessCategories
  )
  return response
}

export const fetchBanks = async () => {
  const response = await MerchantAxiosInstance.get(merchantApi.getAllBanks)
  return response
}

export const verifyBvn = async (bvnDetails: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.verifyBvn,
    bvnDetails
  )
  return response
}

export const nibbsBvnVerification = async (verificationDetails: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.nibbsBvnVerification,
    verificationDetails
  )
  return response
}

export const initiateBvnVerification = async (verificatioDetails: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.initiateBvnVerification,
    verificatioDetails
  )
  return response
}

export const verifyBankAccount = async (bankDetails: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.verifyBankAccount,
    bankDetails
  )
  return response
}

export const fetchAllKycDocs = async (id: number) => {
  const response = await MerchantAxiosInstance.get(
    `${merchantApi.getAllDocuments}/${id}`
  )
  return response
}

export const uploadKycDoc = async (formData: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.uploadKycDocuments,
    formData,
    {
      headers: {
        "Content-Type": "muiltipart/form-data",
        "Access-Control-Allow-Headers": "Accept",
      },
    }
  )
  return response
}

export const fetchBusinessBankDetails = async (id: number) => {
  const response = await MerchantAxiosInstance.get(
    `${merchantApi.viewBusinessBankDetails}/${id}`
  )
  return response
}

export const fetchBusinessDetailsByBusinessId = async (id: number) => {
  const response = await MerchantAxiosInstance.get(
    `${merchantApi.viewBusinessDetails}/${id}`
  )
  return response
}

export const editBusinessDetails = async (businessDetails: unknown) => {
  const response = await MerchantAxiosInstance.put(
    merchantApi.editBusiness,
    businessDetails
  )
  return response
}

export const saveBankInformation = async (bankDetails: unknown) => {
  const response = await MerchantAxiosInstance.put(
    merchantApi.editSettlementAccount,
    bankDetails
  )
  return response
}

export const editSettingsSettlementAccount = async (bankDetails: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.editSettingsSettlementAccount,
    bankDetails
  )
  return response
}

export const authorizeSettingsSettlementAccount = async (data: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.authorizeSettlementAccount,
    data
  )
  return response
}

export const submitKycForApproval = async (kycDetails: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.submitKyc,
    kycDetails
  )
  return response
}

export const uploadBusinessLogo = async (formData: unknown) => {
  const response = await MerchantAxiosInstance.post(
    merchantApi.saveBusinessLogo,
    formData,
    {
      headers: {
        "Content-Type": "muiltipart/form-data",
        "Access-Control-Allow-Headers": "Accept",
      },
    }
  )
  return response
}

export const updateIntegrationSettings = async (payload: {
  id: number
  merchantId: number
  callBackUrl: string
  webhookUrl: string
  privateKey: string
  publicKey: string
  forTest: boolean
}) => {
  const response = await MerchantAxiosInstance.put(
    merchantApi.updateIntegrationSettings,
    payload
  )
  return response
}

export const getMerchantIntegrationSettings = async (merchantId: number) => {
  const response = await MerchantAxiosInstance.get(
    `${merchantApi.getMerchantSettings}/${merchantId}`
  )
  return response
}

export const generatePublicKey = async (
  merchantId: number,
  forTest: boolean
) => {
  const response = await MerchantAxiosInstance.get(
    `${merchantApi.generatePublicKey}/${merchantId}/${forTest}`
  )
  return response
}

export const generatePivateKey = async (
  merchantId: number,
  forTest: boolean
) => {
  const response = await MerchantAxiosInstance.get(
    `${merchantApi.generatePivateKey}/${merchantId}/${forTest}`
  )
  return response
}
