import React from "react"
import Button from "../../../components/button"
import { ReactComponent as InvoiceIcon } from "../../../assets/icons/invoicing.svg"
import { ReactComponent as ReloadIcon } from "assets/icons/reload.svg"

type PropsT = {
  setCreateInvoiceModal: (isOpen: boolean) => void
}

const NoInvoicesFound = ({ setCreateInvoiceModal }: PropsT) => {
  return (
    <div>
      <div className="flex flex-col justify-center items-center mt-40 mb-52">
        <div className="text-secondary-default">
          <InvoiceIcon />
        </div>
        <div className="font-semibold text-xl mt-5">No Invoices</div>
        <div className="mt-1 text-gray-default">Start requesting payments.</div>
        <div className="mt-5">
          <Button
            text="+ Create An Invoice"
            className="w-max px-5 py-3 mr-5 rounded-md"
            onClick={() => setCreateInvoiceModal(true)}
          />
        </div>
        <div className="mt-5">
          <ReloadIcon
            onClick={() => window.location.reload()}
            className="mt-4 cursor-pointer "
          />
        </div>
      </div>
    </div>
  )
}

export default NoInvoicesFound
