import React from "react"
import Button from "../../../components/button"
import SavedDocument from "../components/SavedDocument"
import Select from "react-select"

type DocumentT = {
  businessId: number
  contentType: string
  documentsId: number
  file: null
  fileName: string
  location: string
}

interface OptionType {
  value: string
  label: string
  id?: number
  isDisabled?: boolean
  isFocused?: boolean
  isSelected?: boolean
}

interface PropsT {
  documentList: Array<DocumentT>
  setIsSaved: (isSaved: boolean) => void
  setActiveTab: (activeTab: number) => void
  kycSubmitted: boolean
  selectedOption: OptionType | null
}

const DocumentsReview = ({
  documentList,
  setIsSaved,
  setActiveTab,
  kycSubmitted,
  selectedOption,
}: PropsT) => {
  const getLabelName = (id: number) => {
    if (id === 1) {
      return "CAC Document"
    } else if (id === 2) {
      return "Memorandum of association"
    } else if (id === 3) {
      return "Utility Bill"
    } else if (id === 4) {
      return "Means of Identification"
    }
  }

  const meansOfIdentification = documentList.filter(
    ({ documentsId }) => documentsId === 4
  )
  const utilityBill = documentList.filter(
    ({ documentsId }) => documentsId === 3
  )
  const moa = documentList.filter(({ documentsId }) => documentsId === 2)
  const cacDoc = documentList.filter(({ documentsId }) => documentsId === 1)

  return (
    <div>
      <div className="bg-white-default p-10">
        <div className="w-[100%] md:w-[75%] lg:w-[60%]">
          <div className="mb-7 w-[100%] md:w-[50%]">
            <div className="mb-3">
              <label className="font-medium text-black-default">
                Valid Means of ID
              </label>
            </div>
            <Select isDisabled value={selectedOption} />
          </div>
          <div className=" block md:flex gap-10 md:flex-wrap md:justify-between">
            {meansOfIdentification.map((document, index) => (
              <div className=" w-[100%] mb-6 md:mb-0 md:w-[45%]" key={index}>
                <SavedDocument
                  label={getLabelName(document.documentsId)}
                  fileName={document.fileName}
                  downloadLink={document.location}
                />
              </div>
            ))}
            {utilityBill.map((document, index) => (
              <div className=" w-[100%] mb-6 md:mb-0 md:w-[45%]" key={index}>
                <SavedDocument
                  label={getLabelName(document.documentsId)}
                  fileName={document.fileName}
                  downloadLink={document.location}
                />
              </div>
            ))}

            {cacDoc.map((document, index) => (
              <div className=" w-[100%] mb-6 md:mb-0 md:w-[45%]" key={index}>
                <SavedDocument
                  label={getLabelName(document.documentsId)}
                  fileName={document.fileName}
                  downloadLink={document.location}
                />
              </div>
            ))}

            {moa.map((document, index) => (
              <div className=" w-[100%] mb-6 md:mb-0 md:w-[45%]" key={index}>
                <SavedDocument
                  label={getLabelName(document.documentsId)}
                  fileName={document.fileName}
                  downloadLink={document.location}
                />
              </div>
            ))}
          </div>
        </div>
        {kycSubmitted ? null : (
          <div className="mt-14">
            <button
              onClick={() => setIsSaved(false)}
              className="text-primary-default font-semibold"
            >
              Edit
            </button>
          </div>
        )}
      </div>
      <div className="flex justify-end mt-10 mb-36">
        <Button
          onClick={() => setActiveTab(1)}
          text="Proceed"
          className=" w-auto px-5 py-2"
        />
      </div>
    </div>
  )
}

export default DocumentsReview
