import { createSlice } from "@reduxjs/toolkit"

type ProfilePropT = {
  firstName: string
  lastName: string
  email: string
  id: number | null
  businessId: number | null
  profilePicture: string | null
  authorizations: string[]
}

export type BusinessProfileT = {
  id: number
  name: string
  type: string
  typeId: number
  description: string
  category: string
  address: string
  state: string
  country: string
  countryCode: string
  approvalDate: Array<number>
  createdOn: number
  lastModifiedOn: number
  merchantUserId: number
  businessDetailsProvided: boolean
  bankDetailsProvided: boolean
  bvnProvided: boolean
  cacDocProvided: boolean
  moaDocProvided: boolean
  utilityBillProvided: boolean
  meansOfIdentificationProvided: boolean
  kycCompleted: boolean
  kycSubmitted: boolean
  adminApprovalStatus: null
  comment: null
  businessLogoUrl: string
  website: string
  city: string
  supportPhoneNumber: string
  supportEmail: string
  categoryId: number
  live: boolean
  enabled: boolean
  approved: boolean
}

interface BreadcrumbState {
  profile?: ProfilePropT
  businessProfile: BusinessProfileT
}

const initialState: BreadcrumbState = {
  profile: {
    firstName: "",
    lastName: "",
    email: "",
    id: 0,
    businessId: 0,
    profilePicture: null,
    authorizations: [],
  },
  businessProfile: {
    id: 0,
    name: "",
    type: "",
    typeId: 0,
    description: "",
    category: "",
    address: "",
    state: "",
    country: "",
    countryCode: "",
    approvalDate: [],
    createdOn: 0,
    lastModifiedOn: 0,
    merchantUserId: 0,
    businessDetailsProvided: false,
    bankDetailsProvided: false,
    bvnProvided: false,
    cacDocProvided: false,
    moaDocProvided: false,
    utilityBillProvided: false,
    meansOfIdentificationProvided: false,
    kycCompleted: false,
    kycSubmitted: false,
    adminApprovalStatus: null,
    comment: null,
    businessLogoUrl: "",
    website: "",
    city: "",
    supportPhoneNumber: "",
    supportEmail: "",
    categoryId: 0,
    live: false,
    enabled: false,
    approved: false,
  },
}

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    saveProfileDetails: (state, action) => {
      state.profile = { ...action.payload.userDetails, profilePicture: null }
    },
    saveBusinessProfile: (state, action) => {
      state.businessProfile = { ...action.payload }
    },
  },
})

export const { saveProfileDetails, saveBusinessProfile } = profileSlice.actions

export default profileSlice.reducer
