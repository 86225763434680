import Modal from "components/modal"
import React from "react"
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import FullScreenLoader from "components/loader/fullscreen-loader"
enum DownloadType {
  Excel = "excel",
  PDF = "pdf",
  CSV = "csv",
}

type ExportOptionProps = { label: string; action: () => void }
type ExportModalProps = {
  isOpen: boolean
  closeModal: () => void
  exportDataName: string
  isExporting: boolean
  handleExport: (donloadType: string) => void
}

const ExportModal: React.FC<ExportModalProps> = ({
  isOpen,
  closeModal,
  exportDataName,
  isExporting,
  handleExport,
}) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="p-6">
        <h5 className="text-xl font-semibold">Export {exportDataName}</h5>
        <span className="text-base font-normal">Select preferred Format</span>

        <div className="mt-6 flex flex-col gap-6">
          <div
            className={`flex cursor-pointer`}
            onClick={() => handleExport(DownloadType.Excel)}
          >
            <span className="w-12">Excel</span>
            <DownloadIcon />
          </div>
          {/* <div
            className={`flex cursor-pointer `}
            onClick={() => handleExport(DownloadType.PDF)}
          >
            <span className="w-12">PDF</span>
            <DownloadIcon />
          </div> */}
          <div
            className={`flex cursor-pointer `}
            onClick={() => handleExport(DownloadType.CSV)}
          >
            <span className="w-12">CSV</span>
            <DownloadIcon />
          </div>
        </div>
      </div>
      {isExporting && (
        <FullScreenLoader
          loading={isExporting}
          message="Exporting Settlents record"
        />
      )}
    </Modal>
  )
}

export default ExportModal
