import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { faCopy, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import Button from "components/button"
import { liveWebhookSchema } from "../validation-schema"
import Input from "components/input/input"
import { useMutation } from "react-query"
import { generatePublicKey } from "../../../axios/services/merchant-services"
import { useAppSelector } from "redux/hooks"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import FullScreenLoader from "components/loader/fullscreen-loader"
import { useIntegration } from "../hooks/useIntegration"

type PropsT = {
  settingsData: IntegrationSettingsT
}
const LiveApiKeys = ({ settingsData }: PropsT) => {
  const [showPassword, setShowPassword] = useState(false)
  const profileDetails = useAppSelector((state) => state.profile.profile)
  const methods = useForm({
    resolver: yupResolver(liveWebhookSchema),
  })
  const { handleUpdateSettings, isUpdatingSettings } = useIntegration()

  const { handleSubmit, setValue, getValues } = methods

  const onSubmit = (data: FieldValues) => {
    handleUpdateSettings({
      id: settingsData?.id ?? 0,
      merchantId: profileDetails?.businessId ?? 0,
      callBackUrl: data.liveCallbackUrl,
      webhookUrl: data.liveWebhookUrl,
      privateKey: data.livePrivateKey ?? "",
      publicKey: data.livePublicKey ?? "",
      forTest: false,
    })
  }

  const { mutate: getPublicKey, isLoading: isGeneratingPublicKey } =
    useMutation(
      () => generatePublicKey(profileDetails?.businessId ?? 0, false),
      {
        onSuccess: (response) => {
          toast.success("Public API key generated successfully")
          setValue("livePublicKey", response.data.data)
        },
        onError: (error: AxiosError) => {
          //@ts-ignore
          toast.error(error.response?.data.message)
        },
      }
    )

  const { mutate: getPrivateKey, isLoading: isGeneratingPrivateKey } =
    useMutation(
      () => generatePublicKey(profileDetails?.businessId ?? 0, false),
      {
        onSuccess: (response) => {
          toast.success("Private API key generated successfully")
          setValue("livePrivateKey", response.data.data)
        },
        onError: (error: AxiosError) => {
          //@ts-ignore
          toast.error(error.response?.data.message)
        },
      }
    )

  useEffect(() => {
    setValue("liveWebhookUrl", settingsData?.liveWebhookUrl)
    setValue("liveCallbackUrl", settingsData?.liveCallBackUrl)
    setValue("livePrivateKey", settingsData?.livePrivateKey)
    setValue("livePublicKey", settingsData?.livePublicKey)
  }, [])

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value)
    toast.success("Copied")
  }

  useEffect(() => {
    setValue("liveWebhookUrl", settingsData?.liveWebhookUrl)
    setValue("liveCallbackUrl", settingsData?.liveCallBackUrl)
  }, [])
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white-default px-4 pt-8 pb-16">
            <div className=" font-semibold text-lg">Webhooks</div>
            <div className="mt-5 w-[100%] md:w-[50%]">
              <Input
                label="Live Webhook Url"
                name="liveWebhookUrl"
                placeholder="https://live-webhook-url.com"
              />
            </div>

            <div className="mt-5 w-[100%] md:w-[50%]">
              <Input
                label="Live Callback Url"
                name="liveCallbackUrl"
                placeholder="https://live-callback-url.com"
              />
            </div>

            <div className="mt-16 font-semibold text-lg">API Keys</div>
            <div className="mt-5">
              <label className="font-medium">Live Public Key</label>
              <div className="w-[100%] block md:flex items-center">
                <div className="inline-flex items-center w-[100%] md:w-[50%]">
                  <input
                    disabled
                    className="border border-gray-100 rounded w-[100%] bg-white-default p-3 pr-12 text-gray-900"
                    type="text"
                    {...methods.register("livePublicKey")}
                  />
                  <FontAwesomeIcon
                    onClick={() => copyToClipboard(getValues("livePublicKey"))}
                    icon={faCopy}
                    className="-ml-10 rounded-tr rounded-br cursor-pointer px-3 py-4 bg-primary-default text-white-default"
                  />
                </div>

                <div
                  onClick={() => getPublicKey()}
                  className="text-primary-default cursor-pointer text-sm font-semibold ml-0 md:ml-3"
                >
                  Generate Public Key
                </div>
              </div>
            </div>

            <div className="mt-5">
              <label className="font-medium">Live Private Key</label>
              <div className="w-[100%] block md:flex items-center">
                <div className="inline-flex items-center w-[100%] md:w-[50%]">
                  <input
                    disabled
                    className="border border-gray-100 rounded w-[100%] bg-white-default p-3 pr-12 text-gray-900"
                    type={showPassword ? "text" : "password"}
                    {...methods.register("livePrivateKey")}
                  />
                  <FontAwesomeIcon
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? faEye : faEyeSlash}
                    className="-ml-10 cursor-pointer"
                  />
                  <FontAwesomeIcon
                    onClick={() => copyToClipboard(getValues("livePrivateKey"))}
                    icon={faCopy}
                    className="ml-2 rounded-tr rounded-br cursor-pointer px-3 py-4 bg-primary-default text-white-default"
                  />
                </div>

                <div
                  onClick={() => getPrivateKey()}
                  className="text-primary-default cursor-pointer text-sm font-semibold ml-0 md:ml-3"
                >
                  Generate Private Key
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end my-10">
            <Button
              text="Save Changes"
              loading={isUpdatingSettings}
              disabled={isUpdatingSettings}
              className="rounded w-auto px-5 py-2"
            />
          </div>

          {isGeneratingPublicKey ||
            (isGeneratingPrivateKey && (
              <FullScreenLoader
                loading={isGeneratingPublicKey || isGeneratingPrivateKey}
                message={`Generating ${
                  isGeneratingPublicKey ? "Public" : "Private"
                } key`}
              />
            ))}
        </form>
      </FormProvider>
    </div>
  )
}

export default LiveApiKeys
