import { DataRow } from "components/data-row/data-row"
import Status from "components/status/Status"
import React from "react"

type PropsT = {
  transactionDetails: {
    loadingDetails: boolean
    data: Transaction
  }
}

const DisputeTransactionsDetails = ({ transactionDetails }: PropsT) => {
  const transactionData = {
    "Transaction Reference": transactionDetails.data?.tgipayReference,
    "Customer Name":
      transactionDetails.data?.customerFirstName +
      " " +
      transactionDetails.data?.customerLastName,
    "Payment Channel": transactionDetails.data?.paymentChannel,
    Amount: transactionDetails.data?.amount,
    "Transaction Date": transactionDetails.data?.transactionDate,
    Status: (
      <Status variant={transactionDetails.data?.status}>
        {transactionDetails.data?.status}
      </Status>
    ),
    "TGI Pay Fee": transactionDetails.data?.fee,
    "Your Income ": transactionDetails.data?.income,
    "Transaction Narration": transactionDetails.data?.narration,
  }
  return (
    <div className="  bg-white-default pb-5">
      <div className="flex justify-between border-b-2 p-6">
        <div className="bg-white-default">
          <div className="text-xl font-medium">Transaction Details</div>
          <div className="text-sm text-gray-10/60">
            Details for transaction 121324SFJNPYT
          </div>
        </div>
      </div>
      <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
        {Object.entries(transactionData).map(([label, value]) => (
          <DataRow
            key={label}
            isLoading={transactionDetails.loadingDetails}
            label={label}
            value={value}
          />
        ))}
      </div>
    </div>
  )
}

export default DisputeTransactionsDetails
