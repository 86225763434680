import React from "react"
import { ReactComponent as AccessDenied } from "assets/images/access-denied.svg"

const ListAccessDenied: React.FC = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center h-[60vh]">
        <AccessDenied className="w-[10rem] h-fit" />
        <p className="text-black text-2xl font-semibold mt-4">Access Denied</p>
        <p className="text-gray-900">
          You do not have permission to access this page
        </p>
      </div>
    </div>
  )
}

export default ListAccessDenied
