import React, { useState } from "react"
import AppLayout from "../../layout/private/app-layout"
import { ReactComponent as RolesIcon } from "../../assets/icons/roles.svg"
import Button from "../../components/button"
import { RadioGroup } from "@headlessui/react"
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg"
import { useNavigate } from "react-router-dom"
import AddCustomRoles from "./components/add-custom-role"
import { useQuery } from "react-query"
import {
  fetchCustomRoles,
  fetchDefaultRoles,
} from "../../axios/services/auth-services"
import { useAppSelector } from "../../redux/hooks"
import Skeleton from "react-loading-skeleton"
import SelectedRoleDetails from "./selected-role-details"

const MangeUserRoles = () => {
  const [selectedRole, setSelectedRole] = useState<RoleT>()
  const [addRoleModal, setAddRoleModal] = useState(false)
  const navigate = useNavigate()

  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { data: defaultRoles, isFetching: fetchingDefaultRoles } = useQuery(
    "fetchDefaultRoles",
    fetchDefaultRoles
  )
  const { data: customRoles, isFetching: fetchingCustomRoles } = useQuery(
    "fetchCustomRoles",
    () => fetchCustomRoles(profileDetails?.businessId)
  )

  return (
    <AppLayout
      title="Manage User Roles"
      subTitle="Add and assign custom roles to your users"
    >
      <>
        <div className="bg-white-default flex">
          <div className="w-[40%] border-r-2 border-r-gray-400 p-10">
            <div className="custom-border rounded pb-1">
              <div className=" bg-primary-400 py-5 px-5 font-semibold text-lg">
                Default Roles
              </div>
              <div className="h-[20rem] overflow-y-scroll hide-scrollbar">
                {fetchingDefaultRoles ? (
                  <div className="p-2">
                    <Skeleton count={5} className="h-[2rem] mb-2" />
                  </div>
                ) : (
                  <>
                    {defaultRoles?.data?.data.length === 0 ? (
                      <div className="flex flex-col items-center justify-center h-full">
                        <RolesIcon />
                        <div className="mt-5 text-2xl font-semibold">
                          No Default Roles
                        </div>
                      </div>
                    ) : (
                      <div className="px-1">
                        <RadioGroup
                          value={selectedRole}
                          onChange={setSelectedRole}
                        >
                          <RadioGroup.Label className="sr-only">
                            Default Role
                          </RadioGroup.Label>
                          <div className="space-y-2">
                            {defaultRoles?.data?.data
                              .sort((a: RoleT, b: RoleT) =>
                                a.name.localeCompare(b.name)
                              )
                              .map((role: RoleT, index: number) => (
                                <RadioGroup.Option
                                  key={`defaultRoles${index}`}
                                  value={role}
                                  className={({ checked }) =>
                                    `
                          ${
                            checked
                              ? "bg-primary-default text-white-default"
                              : "bg-white-default"
                          }
                            relative flex cursor-pointer rounded px-5 py-4`
                                  }
                                >
                                  {({ checked }) => (
                                    <>
                                      <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center">
                                          <div className="text-sm">
                                            <RadioGroup.Label
                                              as="p"
                                              className={` ${
                                                checked
                                                  ? "text-white-default"
                                                  : "text-black-default"
                                              }`}
                                            >
                                              {role.name}
                                            </RadioGroup.Label>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                          </div>
                        </RadioGroup>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className=" bg-primary-400 py-5 px-5 font-semibold text-lg">
                Custom Roles
              </div>
              <div className="h-[25rem] overflow-y-scroll hide-scrollbar">
                {fetchingCustomRoles ? (
                  <div className="p-2">
                    <Skeleton count={5} className="h-[2rem] mb-2" />
                  </div>
                ) : (
                  <>
                    {customRoles?.data.data.length === 0 ? (
                      <div className="flex flex-col items-center justify-center h-full">
                        <RolesIcon />
                        <div className="mt-5 text-2xl font-semibold">
                          No Custom Roles
                        </div>
                        <div className="mt-1 font-extralight">
                          Add custom roles to assign to your users.
                        </div>
                        <div className="mt-5">
                          <Button
                            onClick={() => setAddRoleModal(true)}
                            text="+ Create Custom Role"
                            className="w-max px-5 py-2 rounded-md"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="px-1">
                        <RadioGroup
                          value={selectedRole}
                          onChange={setSelectedRole}
                        >
                          <RadioGroup.Label className="sr-only">
                            Custom Role
                          </RadioGroup.Label>
                          <div className="space-y-2">
                            {customRoles?.data.data
                              .sort((a: RoleT, b: RoleT) =>
                                a.name.localeCompare(b.name)
                              )
                              .map((role: RoleT, index: number) => (
                                <RadioGroup.Option
                                  key={`customRoles${index}`}
                                  value={role}
                                  className={({ checked }) =>
                                    `
                          ${
                            checked
                              ? "bg-primary-default text-white-default"
                              : "bg-white-default"
                          }
                            relative flex cursor-pointer rounded px-5 py-4`
                                  }
                                >
                                  {({ checked }) => (
                                    <>
                                      <div className="flex w-full items-center justify-between">
                                        <div className="flex items-center">
                                          <div className="text-sm">
                                            <RadioGroup.Label
                                              as="p"
                                              className={` ${
                                                checked
                                                  ? "text-white-default"
                                                  : "text-black-default"
                                              }`}
                                            >
                                              {role.name}
                                            </RadioGroup.Label>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                          </div>
                        </RadioGroup>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="py-4 px-4 text-md font-medium text-primary-default">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => setAddRoleModal(true)}
                >
                  <span className="mr-2 text-3xl font-light">+</span>
                  <span>Create Custom Role</span>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-[60%] p-10">
            {selectedRole && (
              <SelectedRoleDetails selectedRole={selectedRole} />
            )}
          </div>
        </div>
        <Button
          onClick={() => navigate("/teams")}
          icon={<BackIcon className="mr-2" />}
          text="Return To Teams"
          className="mt-10 mb-36 w-max bg-gray-300 hover:bg-gray-300 text-primary-default px-5 flex items-center"
        />

        {addRoleModal && (
          <AddCustomRoles
            closeModal={() => setAddRoleModal(false)}
            isOpen={addRoleModal}
          />
        )}
      </>
    </AppLayout>
  )
}

export default MangeUserRoles
