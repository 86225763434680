import React from "react"
import Button from "../../../components/button"

type BusinessDetailsT = {
  setIsSaved: (isSaved: boolean) => void
  setActiveTab: (item: number) => void
  businessDetails: {
    id: number
    name: string
    type: string
    typeId: number
    city: string
    description: string
    category: string
    address: string
    state: string
    country: string
    countryCode: string
    approvalDate: null
    createdOn: number
    lastModifiedOn: number
    live: boolean
    approved: boolean
    kycSubmitted: boolean
    tin: string
  }
}

const BusinessDetailsReview = ({
  setIsSaved,
  setActiveTab,
  businessDetails,
}: BusinessDetailsT) => {
  return (
    <div>
      <div className="bg-white-default">
        <div className=" w-[100%] md:w-[60%] p-10">
          <div className="input">
            <label className="input_label">Business Name</label>
            <div className={`input_wrapper disabled`}>
              <input
                disabled
                value={businessDetails.name}
                className={`input_field`}
                placeholder={"Business Name"}
              />
            </div>
          </div>

          <table className="table-auto mt-2">
            <tbody>
              <tr className="">
                <td className="font-medium py-3 pr-10">Business Type</td>
                <td className="py-3">{businessDetails.type}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">Business Category</td>
                <td className="py-3">{businessDetails.category}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">Business Address</td>
                <td className="py-3">{businessDetails.address}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">City</td>
                <td className="py-3">{businessDetails.city}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">State</td>
                <td className="py-3">{businessDetails.state}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">Country</td>
                <td className="py-3">{businessDetails.country}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">Business Description</td>
                <td className="py-3">{businessDetails.description}</td>
              </tr>
              <tr>
                <td className="font-medium py-3 pr-10">
                  Tax Identification Nnumber(TIN)
                </td>
                <td className="py-3">{businessDetails.tin}</td>
              </tr>
            </tbody>
          </table>

          {businessDetails.kycSubmitted ? null : (
            <div className="mt-14">
              <button
                onClick={() => setIsSaved(false)}
                className="text-primary-default font-semibold"
              >
                Edit
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-end mt-10 mb-36">
        <Button
          onClick={() => setActiveTab(1)}
          text="Proceed"
          className=" w-auto px-5 py-2"
        />
      </div>
    </div>
  )
}

export default BusinessDetailsReview
