import FullScreenLoader from "components/loader/fullscreen-loader"
import Status from "components/status/Status"
import PayoutTransactions from "pages/Settlement/Details/PayoutTransactions"
import React from "react"
import { formatAmount } from "utils/format-amount"

type PropsT = {
  settlementId: number
  detailsData: Settlement
  loadingDetails: boolean
}

const DisputeSettlementDetails = ({
  settlementId,
  detailsData,
  loadingDetails,
}: PropsT) => {
  const personalData = {
    "Settlement Reference": detailsData.reference,
    "Settlement Amount": formatAmount(detailsData.totalPayoutAmount),
    "Settlement Date": detailsData.settlementDate,
    "Payout Status": (
      <Status variant={detailsData.status}>{detailsData.status}</Status>
    ),
    "Transactions in Settlement Batch": detailsData.numberOfTransactions,
  }
  return (
    <div>
      <div className="bg-white-default">
        <div className="flex justify-between p-6">
          <div className="">
            <div className="text-base font-medium">Settlement Details</div>
            <div className="text-sm text-gray-default">
              Details for settlement reference XHJSD834HD
            </div>
          </div>
        </div>

        <hr />

        <div></div>
        <div className="flex flex-wrap p-6 text-sm text-gray-default">
          {Object.entries(personalData).map(([label, value]) => (
            <div key={label} className="w-[50%] md:w-[20%] px-2 mt-4 md:mt-0">
              <div>{label}</div>
              <div className="text-black-default">{value}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="border border-gray-700 bg-white-default mt-10 rounded">
        <PayoutTransactions settlementId={Number(settlementId)} />
      </div>
    </div>
  )
}

export default DisputeSettlementDetails
