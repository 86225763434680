import { CustomDateRange, customDateRanges } from "constants/customDateRanges"

export const getDateRangeByRangeText = (rangeText: CustomDateRange) => {
  // Define variables for start and end date
  let newStartDate: Date | null = null
  let newEndDate: Date | null = null

  // Logic to set start and end date based on selected date range
  switch (rangeText) {
    case customDateRanges.allTime:
      newStartDate = null
      newEndDate = null
      break
    case customDateRanges.today:
      newStartDate = new Date()
      newEndDate = new Date()
      break
    case customDateRanges.yesterday:
      newStartDate = new Date()
      newStartDate.setDate(newStartDate.getDate() - 1)
      newEndDate = new Date()
      newEndDate.setDate(newEndDate.getDate() - 1)
      break
    case customDateRanges.thisWeek:
      newStartDate = new Date()
      newStartDate.setDate(newStartDate.getDate() - newStartDate.getDay())
      newEndDate = new Date()
      newEndDate.setDate(newEndDate.getDate() + (6 - newEndDate.getDay()))
      break
    case customDateRanges.last30Days:
      newStartDate = new Date()
      newStartDate.setDate(newStartDate.getDate() - 29)
      newEndDate = new Date()
      break
    case customDateRanges.last90Days:
      newStartDate = new Date()
      newStartDate.setDate(newStartDate.getDate() - 89)
      newEndDate = new Date()
      break
    case customDateRanges.thisYear:
      newStartDate = new Date()
      newStartDate.setMonth(0, 1)
      newEndDate = new Date()
      newEndDate.setMonth(11, 31)
      break

    default:
      break
  }

  return { newStartDate, newEndDate }
}
