import { Dispatch } from "redux"
import { yupResolver } from "@hookform/resolvers/yup"
import { AxiosError } from "axios"
import { generateNewAccessToken, login } from "../axios/services/auth-services"
import { loginValidationSchema } from "pages/login/validation-schema"
import { FieldValues, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppDispatch } from "redux/hooks"
import {
  saveBusinessProfile,
  saveProfileDetails,
} from "redux/profile/profileSlice"
import { DecodeJwt } from "utils/decode-jwt"
import { fetchBusinessDetailsByBusinessId } from "../axios/services/merchant-services"

export function useLogin(redirectUrl?: string | null) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const methods = useForm({
    resolver: yupResolver(loginValidationSchema),
  })
  const { handleSubmit } = methods
  const values = methods.watch()

  const { mutate: loginUser, isLoading } = useMutation(login, {
    onSuccess: async (response) => {
      if (response.data.useTwoFactorAuth === false) {
        sessionStorage.setItem("access-token", response.data.data.accessToken)
        sessionStorage.setItem("id", response.data.data.id)
        sessionStorage.setItem("refresh-token", response.data.data.refreshToken)
        const decoded = DecodeJwt(response.data.data.accessToken)
        dispatch(saveProfileDetails(decoded))
        // @ts-ignore
        await getBusinessData(decoded.userDetails.businessId, dispatch)
        if (redirectUrl) {
          navigate(redirectUrl)
        } else {
          navigate("/dashboard")
        }
      } else navigate(`/2fa?accessKey=${response.data.data.accessKey}`)
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
      return
    },
  })

  const onSubmit = (data: FieldValues) => {
    loginUser({
      username: data.email,
      password: data.password,
    })
  }

  return { onSubmit, handleSubmit, isLoading, methods, values }
}

export const getBusinessData = async (id: number, dispatch: Dispatch<any>) => {
  try {
    const businessDataRes = await fetchBusinessDetailsByBusinessId(id)
    const businessData = businessDataRes.data.data // Assuming BusinessData is the type for business data

    dispatch(saveBusinessProfile(businessData))
  } catch (error) {
    //@ts-ignore
    toast.error(error.response?.data.message)
  }
}
