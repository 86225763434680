import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import { Dropdown } from "components/dropdown"
import Input from "components/input/input"
import CustomSelect from "components/select"
import { format } from "date-fns"
import React, { useState } from "react"
import ReactDatePicker from "react-datepicker"
import {
  FieldValues,
  FormProvider,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormReturn,
} from "react-hook-form"

type Props = {
  filter: {
    filterMethods: UseFormReturn<FieldValues, any>
    handleFilter: UseFormHandleSubmit<FieldValues>
    onFilter: (data: FieldValues) => void
    resetFilter: UseFormReset<FieldValues>
  }
  search: {
    searchMethods: UseFormReturn<FieldValues, any>
    handleSearch: UseFormHandleSubmit<FieldValues>
    onSearch: (data: FieldValues) => void
    updateSearchParams: (payload: any) => void
  }
}

const TransactionsFilter: React.FC<Props> = ({ filter, search }) => {
  const [showFilter, setShowFilter] = React.useState(false)

  const [startDate, setStartDate] = useState<Date | null>()
  const [endDate, setEndDate] = useState<Date | null>()

  const { handleSubmit: handleSearch } = search.searchMethods

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  return (
    <>
      <Dropdown
        isOpen={showFilter}
        toggleOpen={toggleFilter}
        showBackdrop={false}
        contentContainerClass="bg-white-default w-[22rem]"
        trigger={
          <Button
            variant="link"
            className="relative flex h-10 min-w-max items-center justify-evenly gap-[15px] rounded-md px-4 text-base font-medium text-primary-default"
            text={
              <>
                <FontAwesomeIcon icon={faFilter} />
                <span className="inline">Filter By</span>
              </>
            }
          />
        }
        content={
          <FormProvider {...filter.filterMethods}>
            <form
              onSubmit={filter.handleFilter((data) => {
                return filter.onFilter({
                  ...data,
                  startDate: startDate && format(startDate, "dd-MM-yyyy"),
                  endDate: endDate && format(endDate, "dd-MM-yyyy"),
                })
              })}
              autoComplete="off"
            >
              <div className="flex flex-col gap-3">
                <CustomSelect
                  label="Status"
                  options={[
                    { value: "", label: "All" },
                    { value: "pending", label: "Pending" },
                    { value: "success", label: "Success" },
                    { value: "failed", label: "Failed" },
                  ]}
                  name="status"
                  control={filter.filterMethods.control}
                />
                <div className="mt-2">
                  <label htmlFor="startDate">Start Date</label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    startDate={startDate}
                    className="w-full border border-gray-300 rounded-md mt-3 h-10 px-2"
                    maxDate={new Date()}
                  />
                </div>
                <div>
                  <label htmlFor="startDate">End Date</label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    startDate={endDate}
                    className="w-full border border-gray-300 rounded-md mt-3 h-10 px-2"
                    maxDate={new Date()}
                  />
                </div>
                <div className="mt-4 flex justify-between items-center gap-6">
                  <Button
                    variant="outline"
                    text="Reset"
                    className="w-max py-2 px-4"
                    onClick={() => {
                      filter.resetFilter({
                        status: { value: "", label: "All" },
                      })
                      setStartDate(null)
                      setEndDate(null)
                    }}
                  />

                  <Button text="Apply" className="w-max py-2 px-4" />
                </div>
              </div>
            </form>
          </FormProvider>
        }
      />
      <FormProvider {...search.searchMethods}>
        <form
          onSubmit={handleSearch(search.onSearch)}
          className="w-[18rem]"
          autoComplete="off"
        >
          <Input
            name="search"
            type="search"
            label=""
            placeholder="Search by transaction ref"
          />
        </form>
      </FormProvider>
    </>
  )
}

export default TransactionsFilter
