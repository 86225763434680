import React from "react"
import { Link, useNavigate } from "react-router-dom"
import Button from "../../components/button"
import Input from "../../components/input/input"
import { ReactComponent as Back } from "../../assets/icons/back.svg"
import AuthLayout from "../../layout/public/authLayout"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { forgetPassValidationSchema } from "./validation-schema"
import { useMutation } from "react-query"
import { forgotPassword } from "../../axios/services/auth-services"
import { AxiosError } from "axios"
import { toast } from "react-toastify"

const ForgotPassword = () => {
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(forgetPassValidationSchema),
  })

  const { handleSubmit } = methods

  const { mutate: forgotUserPassword, isLoading } = useMutation(
    forgotPassword,
    {
      onSuccess: () => {
        toast.success("A password reset link has been sent to your email")
        navigate("/login")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    forgotUserPassword({
      email: data.email,
    })
  }

  return (
    <AuthLayout
      title="Forgot Password?"
      content={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <Input label="Email Address" name="email" type="email" />
            </div>

            <div className="mt-14">
              <Button
                loading={isLoading}
                disabled={isLoading}
                text="Reset Password"
              />
            </div>

            <div className="text-center flex items-center justify-center mt-20">
              <span className="mr-2">
                <Back />
              </span>
              <Link className="text-primary-default" to="/login">
                Return to Log in
              </Link>
            </div>
          </form>
        </FormProvider>
      }
    />
  )
}

export default ForgotPassword
