import React, { ReactNode } from "react"
import Select from "react-select"
import { useFormState, Controller } from "react-hook-form"
import { colourStyles } from "./select-style"

type OptionsT = {
  label: string
  value: string | number
}

export interface GroupedOption {
  readonly label: string
  readonly options: readonly OptionsT[]
}

interface SelectPropsT {
  options: Array<OptionsT> | Array<GroupedOption>
  isDisabled?: boolean
  isLoading?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  defaultValue?: OptionsT | GroupedOption
  name: string
  label: string
  labelIcon?: ReactNode
  control: any
  placeholder?: string
}

const StyledSelect = ({
  options,
  defaultValue,
  isDisabled = false,
  isLoading = false,
  isClearable = false,
  isSearchable = false,
  name,
  label,
  control,
  labelIcon,
}: SelectPropsT) => {
  const { errors } = useFormState()
  return (
    <>
      <div>
        <label className="input_label flex items-center gap-2 mb-[0.6rem]">
          <span>{label} </span>
          <span>{labelIcon}</span>
        </label>
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            styles={colourStyles}
            options={options}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            menuPortalTarget={document.body}
          />
        )}
      />
      {errors[name] ? (
        <div className="mt-1 text-[11px] text-danger-default">
          {/* @ts-ignore */}
          {errors[name]?.value?.message}
        </div>
      ) : null}
    </>
  )
}

export default StyledSelect
