import React, { useState } from "react"
import notification from "../../assets/icons/notification.svg"
import { getInitials } from "../../utils/getInititials"
import { ReactComponent as Hamburger } from "../../assets/icons/hamburger.svg"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { toggleMobileSidebar } from "../../redux/ui/uiSlice"

const profile = {
  firstName: "Reuben",
  lastName: "Obozokhai",
  profilePicture:
    "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
}

const Header = () => {
  const [notificationCount, setNotificationCount] = useState(4)
  const dispatch = useAppDispatch()
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const toggleSidebar = () => {
    dispatch(toggleMobileSidebar())
  }

  return (
    <div className=" bg-white-default h-16 flex items-center justify-between">
      <div>
        <Hamburger
          onClick={toggleSidebar}
          className="w-7 md:hidden ml-10 cursor-pointer"
        />
      </div>
      <div className="flex items-center">
        {/* <div className="mr-6 cursor-pointer relative flex">
          <img className="h-5 w-5" src={notification} alt="notification icon" />
          {notificationCount > 0 && (
            <>
              <span className="absolute flex items-center justify-center h-5 w-5 -top-3 -right-2 rounded-full bg-primary-default text-white-default text-sm font-bold">
                {notificationCount}
              </span>
              <span className="animate-ping absolute inline-flex h-5 w-5 -top-3 -right-2 rounded-full bg-primary-default opacity-40"></span>
            </>
          )}
        </div> */}
        <div className="mr-10">
          {/* @ts-ignore */}
          {profileDetails.profilePicture ? (
            <img
              className="inline-block cursor-pointer h-10 w-10 rounded-full ring-2 ring-white-default"
              src={profile?.profilePicture}
              alt=""
            />
          ) : (
            <div className="flex cursor-pointer items-center justify-center h-10 w-10 rounded-full ring-2 ring-white-default text-xl text-primary-default bg-black-default/10 hover:bg-black-default/20">
              {profileDetails?.firstName && (
                <span className="font-semibold">
                  {/* @ts-ignore */}
                  {getInitials(
                    profileDetails?.firstName,
                    profileDetails?.lastName
                  )}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
