import React from "react"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"
import { ReactComponent as ErrorIcon } from "../../assets/icons/error.svg"

const PaymentFailure = () => {
  function BackToHome() {
    window.location.href = "https://tgipay.com"
    return
  }
  return (
    <div className=" pt-16 flex flex-col items-center">
      <div>
        <Logo className="h-10" />
      </div>

      <div className=" w-[95%] md:w-[50%] lg:w-[30%] mt-20 p-5 card">
        <div className="text-center">
          <div className="flex justify-center mb-3 text-danger-default">
            <ErrorIcon className="h-16 w-16" />
          </div>
          <h2 className="font-semibold text-2xl mb-4">Payment Failed</h2>
          <p className="font-light text-sm mb-3 px-5">
            Contact the merchant for dispute resolution
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <button
            onClick={() => BackToHome()}
            className="bg-primary-default text-white-default px-5 py-2 rounded w-auto"
          >
            Return to Home
          </button>
        </div>
      </div>
    </div>
  )
}

export default PaymentFailure
