import React, { useState } from "react"
import Modal from "../../../components/modal"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import Button from "../../../components/button"
import { userSchema } from "../validation-schema"
import { yupResolver } from "@hookform/resolvers/yup"
import Input from "../../../components/input/input"
import StyledSelect from "../../../components/select"
import { toast } from "react-toastify"
import { useMutation, useQuery } from "react-query"
import {
  fetchCustomRoles,
  fetchDefaultRoles,
  addUserAccount,
} from "../../../axios/services/auth-services"
import { useAppSelector } from "../../../redux/hooks"
import { queryClient } from "../../.."
import { AxiosError } from "axios"

interface ModalPropsT {
  isOpen: boolean
  closeModal: () => void
}

const CreateNewUser = ({ closeModal, isOpen }: ModalPropsT) => {
  const [defaultRoles, setDefaultRoles] = useState<Array<SelectRoleT>>([])
  const [customRoles, setCustomRoles] = useState<Array<SelectRoleT>>([])
  const methods = useForm({
    resolver: yupResolver(userSchema),
  })

  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { handleSubmit } = methods

  const { isFetching: loadingDefaultRoles } = useQuery(
    "fetchDefaultRoles",
    fetchDefaultRoles,
    {
      onSuccess: (response) => {
        const rolesRes = response.data.data.sort((a: RoleT, b: RoleT) =>
          a.name.localeCompare(b.name)
        )
        const roles: Array<SelectRoleT> = []
        for (let i = 0; i < rolesRes.length; i++) {
          const item = {
            label: rolesRes[i].name,
            value: rolesRes[i].id,
            isDisabled: false,
            default: rolesRes[i].default,
          }
          roles.push(item)
        }
        setDefaultRoles(roles)
      },
      onError: () => {
        toast.error("Unable to fetch default roles")
      },
    }
  )
  const { isFetching: loadingCustomRoles } = useQuery(
    "fetchCustomRoles",
    () => fetchCustomRoles(profileDetails?.businessId),
    {
      onSuccess: (response) => {
        const rolesRes = response.data.data.sort((a: RoleT, b: RoleT) =>
          a.name.localeCompare(b.name)
        )
        const roles: Array<SelectRoleT> = []
        for (let i = 0; i < rolesRes.length; i++) {
          const item = {
            label: rolesRes[i].name,
            value: rolesRes[i].id,
            isDisabled: false,
            default: rolesRes[i].default,
          }
          roles.push(item)
        }
        setCustomRoles(roles)
      },
      onError: () => {
        toast.error("Unable to fetch custom roles")
      },
    }
  )

  const { mutate: saveUserProfile, isLoading } = useMutation(addUserAccount, {
    onSuccess: () => {
      toast.success("User created successfully")
      closeModal()
      queryClient.invalidateQueries("fetchMerchantUsers")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const onSubmit = (data: FieldValues) => {
    saveUserProfile({
      businessId: profileDetails?.businessId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobileNumber: "",
      email: data?.email,
      roleId: data?.role?.value,
      role: data?.role?.label,
      defaultRole: data?.role?.default,
    })
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <FormProvider {...methods}>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8">
            <div className="font-semibold text-xl">Create New User</div>
            <div>Create a new user for your business</div>
          </div>

          <div className="mb-3">
            <StyledSelect
              control={methods.control}
              isDisabled={false}
              isLoading={loadingCustomRoles || loadingDefaultRoles}
              isClearable={false}
              isSearchable={true}
              name="role"
              label="Role"
              options={[
                {
                  label: "Default Roles",
                  options: defaultRoles,
                },
                {
                  label: "Custom Roles",
                  options: customRoles,
                },
              ]}
            />
          </div>

          <div className="mb-3">
            <Input label="First Name" name="firstName" />
          </div>
          <div className="mb-3">
            <Input label="Last Name" name="lastName" />
          </div>
          <div className="mb-3">
            <Input label="Email Address" name="email" />
          </div>

          <div className="mt-14 flex justify-end">
            <Button
              loading={isLoading}
              disabled={isLoading}
              className="w-max px-5 py-2 rounded-md"
              text="Create New User"
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default CreateNewUser
