import { AxiosError } from "axios"
import {
  authorizePaymentRequest,
  initiatePaymentRequest,
  resendOtpPaymentRequest,
  validatePaymentRequest,
  verifyPaymentRequest,
} from "../../../axios/services/payment-services"
import { useState } from "react"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { encryptData } from "utils/encrypt-data"
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
} from "components/payment/payment-methods/card/util"

type InternalTransportDataT = {
  firstName: string
  lastName: string
  email: string
  currency: string
  amount: number
  transactionId: number
  paymentLinkId: number
  customerPaymentLinkId: number
  tgipayReference: string
  processorReference: string | null
  paymentLinkRequest: boolean
  invoiceRequest: boolean
}

interface PaymentT {
  cardProcessor: string | null | undefined
  dataToSend: any
  successCallback?: (successData?: any) => void
  failureCallback?: () => void
}

export const saveInternalTransportDataToStorage = (
  internalTransportData: InternalTransportDataT
) => {
  sessionStorage.setItem(
    "internalTransportData",
    JSON.stringify(internalTransportData)
  )
}

export const retrieveInternalTransportDataToStorage = () => {
  const internalTransportData = sessionStorage.getItem("internalTransportData")
  if (internalTransportData) {
    return JSON.parse(internalTransportData)
  }
  return null
}

export function usePayment({
  cardProcessor,
  dataToSend,
  failureCallback,
  successCallback,
}: PaymentT) {
  const [showPin, setShowPin] = useState<boolean>(
    cardProcessor === "flutterwave" ? false : true
  )
  const [cardDetails, setCardDetails] = useState({
    number: "",
    name: "",
    expiry: "",
    cvc: "",
    issuer: "",
    addressLine1: "",
    addressLine2: "",
    country: "US",
    state: "",
    city: "",
    postCode: "",
    focused: "",
    formData: null,
  })
  const [pin, setPin] = useState<string>("")
  const [openOtpModal, setOpenOtpModal] = useState<boolean>(false)
  const [nextStep, setNextStep] = useState<string>("initiate")
  const [otp, setOtp] = useState<string>("")
  const [authorizationMode, setAuthorizationMode] = useState<string>("")
  const [errors, setErrors] = useState({
    number: "",
    expiry: "",
    cvc: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    postCode: "",
    pin: "",
  })
  const [cardScheme, setCardScheme] = useState<string>("")

  const navigate = useNavigate()

  const handleValidation = async (callback: any) => {
    const newErrors = {
      number: "",
      expiry: "",
      cvc: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      state: "",
      city: "",
      postCode: "",
      pin: "",
    }

    // Check card number
    if (!cardDetails.number || !/[\d| ]{16,22}/.test(cardDetails.number)) {
      newErrors.number = "Please enter a valid 16-digit card number"
    }

    // Check CVV
    if (!cardDetails.cvc || !/\d{3,4}/.test(cardDetails.cvc)) {
      newErrors.cvc = "Please enter a valid 3-digit CVV"
    }

    // Check expiry date
    if (!cardDetails.expiry || !/\d\d\/\d\d/.test(cardDetails.expiry)) {
      newErrors.expiry = "Please enter a valid expiry date in the format MM/YY"
    }

    // Check address line 1
    if (!cardDetails.addressLine1 && authorizationMode === "avs_noauth") {
      newErrors.addressLine1 = "Please enter address line 1"
    }

    // Check address line 2
    if (!cardDetails.addressLine2 && authorizationMode === "avs_noauth") {
      newErrors.addressLine2 = "Please enter address line 2"
    }

    // Check country
    if (!cardDetails.country && authorizationMode === "avs_noauth") {
      newErrors.country = "Please select your country"
    }

    // Check state
    if (!cardDetails.state && authorizationMode === "avs_noauth") {
      newErrors.state = "Please select your state"
    }

    // Check city
    if (!cardDetails.city && authorizationMode === "avs_noauth") {
      newErrors.city = "Please enter your city"
    }

    // Check post code
    if (!cardDetails.postCode && authorizationMode === "avs_noauth") {
      newErrors.postCode = "Please enter your post code"
    }

    // Check Pin
    if (!pin && pin.length !== 4 && nextStep !== "initiate") {
      newErrors.pin = "Please enter 4 digits pin code"
    }

    setErrors(newErrors)
    // Check if there are any errors
    if (Object.values(newErrors).some((error) => error !== "")) {
      return
    } else {
      //Call initiate or authorize payment based on nextStep
      callback()
    }
  }

  const handleInputFocus = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCardDetails({ ...cardDetails, focused: target.name })
  }

  const checkCardType = (value: string) => {
    const firstChar = value.substring(0, 1)
    const firstTwoChars = value.substring(0, 2)
    const firstThreeChars = value.substring(0, 3)

    switch (true) {
      case firstThreeChars === "506" ||
        firstTwoChars === "56" ||
        firstChar === "6":
        setCardScheme("verve")
        return "verve"
      case ["51", "52", "53", "54", "55"].includes(firstTwoChars):
        setCardScheme("mastercard")
        return "mastercard"
      case firstChar === "4":
        setCardScheme("visa")
        return "visa"
      case firstChar === "" || firstChar === "5" || firstTwoChars === "50":
        setCardScheme("")
        return ""
      default:
        return
    }
  }

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value)
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value)
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value)
    }
    setErrors({ ...errors, [target.name]: "" })
    checkCardType(target.value)
    setCardDetails({ ...cardDetails, [target.name]: target.value })
  }

  const handleSelectInput = (name: string, value: string) => {
    setErrors({ ...errors, [name]: "" })
    setCardDetails({ ...cardDetails, [name]: value })
  }

  const handlePinChange = (input: string) => {
    let updatedPin = ""
    switch (input) {
      case "CLR":
        setPin("")
        break
      case "DEL":
        updatedPin = pin.slice(0, -1)
        setPin(updatedPin)
        break
      default:
        if (pin.length === 4) {
          return
        }
        setPin(pin + input)
        break
    }
  }

  // NOTE: Verification is saved in sessionStorage for AVS and Redirect verification

  const { mutate: handleInitiatePayment, isLoading: isLoadingInitiatePayment } =
    useMutation(initiatePaymentRequest, {
      onSuccess: (response) => {
        saveInternalTransportDataToStorage(
          response.data.data?.internalTransportData
        )
        if (
          response.data.data.nextStep === "authorize" &&
          response.data.data.authorizationMode === "pin"
        ) {
          setShowPin(true)
          setNextStep(response.data.data.nextStep)
          return
        }
        if (
          response.data.data.nextStep === "authorize" &&
          response.data.data.authorizationMode === "avs_noauth"
        ) {
          setShowPin(true)
          setNextStep(response.data.data.nextStep)
          setAuthorizationMode(response.data.data.authorizationMode)
          return
        }
        if (
          response.data.data.nextStep === "validate" &&
          response.data.data.authorizationMode === "otp"
        ) {
          setOpenOtpModal(true)
          return
        }
        if (
          response.data.data.nextStep === "authorize" &&
          response.data.data.authorizationMode === "redirect"
        ) {
          window.location.href = response.data.data.redirectData.redirectUrl
          return
        }
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
      // retry: 1,
    })

  const initializePayment = async () => {
    const cardDetailsToEncrypt = {
      cardNumber: cardDetails.number.split(" ").join(""),
      cvv: cardDetails.cvc,
      expiryDate: cardDetails.expiry,
      pin: pin,
    }
    const encryptedData = encryptData(cardDetailsToEncrypt)

    const payload = {
      ...dataToSend,
      data: encryptedData.ciphertext,
      secretKey: encryptedData.encryptedSecret,
      iv: encryptedData.encryptedIv,
    }

    handleInitiatePayment(payload)
  }

  const {
    mutate: handleAuthorizePayment,
    isLoading: isLoadingAuthorizePayment,
  } = useMutation(authorizePaymentRequest, {
    onSuccess: (response) => {
      saveInternalTransportDataToStorage(
        response.data.data?.internalTransportData
      )
      if (
        response.data.data.nextStep === "validate" &&
        response.data.data.authorizationMode === "otp"
      ) {
        setOpenOtpModal(true)
        return
      }
      if (
        response.data.data.nextStep === "validate" &&
        response.data.data.authorizationMode === "redirect"
      ) {
        window.location.href = response.data.data.redirectData.redirectUrl
      }
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
      return
    },
    // retry: 1,
  })

  const authorizePayment = () => {
    const cardDetailsToEncrypt = {
      cardNumber: cardDetails.number.split(" ").join(""),
      cvv: cardDetails.cvc,
      expiryDate: cardDetails.expiry,
      pin: pin,
      addressLine1: cardDetails.addressLine1,
      addressLine2: cardDetails.addressLine2,
      country: cardDetails.country,
      state: cardDetails.state,
      city: cardDetails.city,
      postCode: cardDetails.postCode,
      internalTransportData: retrieveInternalTransportDataToStorage(),
    }
    const encryptedData = encryptData(cardDetailsToEncrypt)

    const payload = {
      data: encryptedData.ciphertext,
      secretKey: encryptedData.encryptedSecret,
      iv: encryptedData.encryptedIv,
    }
    handleAuthorizePayment(payload)
  }

  const { mutate: handleVerifyPayment, isLoading: isLoadingVerifyPayment } =
    useMutation(verifyPaymentRequest, {
      onSuccess: (response) => {
        if (successCallback) {
          successCallback(response?.data?.data)
        } else {
          navigate("/make-payment/payment-success")
        }
      },
      onError: () => {
        navigate("/make-payment/payment-failed")
        return
      },
      retry: 5,
    })

  const verifyPayment = (
    validatedInternalTransportData: InternalTransportDataT
  ) => {
    const cardDetailsToEncrypt = {
      transactionReference: validatedInternalTransportData.tgipayReference,
      internalTransportData: validatedInternalTransportData,
    }
    const encryptedData = encryptData(cardDetailsToEncrypt)
    const payload = {
      data: encryptedData.ciphertext,
      secretKey: encryptedData.encryptedSecret,
      iv: encryptedData.encryptedIv,
    }
    setOpenOtpModal(false)
    handleVerifyPayment(payload)
  }

  const { mutate: handleValidatePayment, isLoading: isLoadingValidatePayment } =
    useMutation(validatePaymentRequest, {
      onSuccess: (response) => {
        saveInternalTransportDataToStorage(
          response.data.data.internalTransportData
        )
        verifyPayment(response.data.data.internalTransportData)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
      // retry: 1,
    })

  const validatePayment = () => {
    const cardDetailsToEncrypt = {
      otp: otp,
      internalTransportData: retrieveInternalTransportDataToStorage(),
    }
    const encryptedData = encryptData(cardDetailsToEncrypt)

    const payload = {
      data: encryptedData.ciphertext,
      secretKey: encryptedData.encryptedSecret,
      iv: encryptedData.encryptedIv,
    }
    handleValidatePayment(payload)
  }

  const { mutate: handleResendOtpRequest, isLoading: isResendingOtp } =
    useMutation(resendOtpPaymentRequest, {
      onSuccess: (response) => {
        saveInternalTransportDataToStorage(
          response.data.data.internalTransportData
        )
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
      // retry: 1,
    })

  const resendOTP = () => {
    const cardDetailsToEncrypt = {
      internalTransportData: retrieveInternalTransportDataToStorage(),
    }
    const encryptedData = encryptData(cardDetailsToEncrypt)

    const payload = {
      data: encryptedData.ciphertext,
      secretKey: encryptedData.encryptedSecret,
      iv: encryptedData.encryptedIv,
    }
    handleResendOtpRequest(payload)
  }

  return {
    showPin,
    openOtpModal,
    pin,
    cardDetails,
    isLoadingInitiatePayment,
    isLoadingAuthorizePayment,
    isLoadingValidatePayment,
    isLoadingVerifyPayment,
    nextStep,
    otp,
    authorizationMode,
    errors,
    cardScheme,
    isResendingOtp,
    resendOTP,
    setOtp,
    validatePayment,
    setOpenOtpModal,
    handleInputChange,
    handleSelectInput,
    handleValidation,
    setShowPin,
    handleInputFocus,
    handlePinChange,
    initializePayment,
    authorizePayment,
  }
}
