import React from "react"
import Button from "../../../components/button"

type PersonalDetailsT = {
  setIsEditing: (isEditing: boolean) => void
  personalDetails: {
    firstName: string
    lastName: string
    email: string
    mobileNumber: string
  }
}

const PersonalProfilePreview = ({
  personalDetails,
  setIsEditing,
}: PersonalDetailsT) => {
  return (
    <div>
      <div className="bg-white-default p-10 mb-10">
        <table className="table-auto">
          <tr>
            <td className="font-bold pb-3">Personal Details</td>
          </tr>
          <tr className="">
            <td className="font-medium py-3 pr-10">First Name</td>
            <td className="py-3">{personalDetails?.firstName}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Last Name</td>
            <td className="py-3">{personalDetails?.lastName}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Email Address</td>
            <td className="py-3">{personalDetails?.email}</td>
          </tr>
          <tr>
            <td className="font-medium py-3 pr-10">Phone Number</td>
            <td className="py-3">{personalDetails?.mobileNumber}</td>
          </tr>
        </table>
      </div>

      <div className=" flex justify-end mt-10 mb-36">
        <Button
          onClick={() => setIsEditing(true)}
          text="Edit"
          className="rounded w-auto px-14 py-2"
        />
      </div>
    </div>
  )
}

export default PersonalProfilePreview
