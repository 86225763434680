import React, { useState } from "react"
import Tabs from "../../components/tab"
import AppLayout from "../../layout/private/app-layout"
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg"
import { ReactComponent as BusinessIcon } from "../../assets/icons/business-details.svg"
import { ReactComponent as SettlementIcon } from "../../assets/icons/bank-info.svg"
import { ReactComponent as PreferencesIcon } from "../../assets/icons/thumb.svg"
import { ReactComponent as SecurityIcon } from "../../assets/icons/security.svg"
import PersonalProfile from "./personal-profile"
import BusinessProfile from "./business-profile"
import SettlementAccount from "./settlement-account"
import Preferences from "./preferences"
import Security from "./Security"

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0)

  const tabs = [
    {
      label: (
        <div className="flex items-baseline">
          <UserIcon className="mr-2" /> <span>Personal Profile</span>
        </div>
      ),
      content: <PersonalProfile />,
    },
    {
      label: (
        <div className="flex items-baseline">
          <BusinessIcon className="mr-2" /> <span>Business Profile</span>
        </div>
      ),
      content: <BusinessProfile />,
    },
    {
      label: (
        <div className="flex items-baseline">
          <SettlementIcon className="mr-2" /> <span>Settlement Account</span>
        </div>
      ),
      content: <SettlementAccount />,
    },
    {
      label: (
        <div className="flex items-baseline">
          <PreferencesIcon className="mr-2" /> <span>Preferences</span>
        </div>
      ),
      content: <Preferences />,
    },
    {
      label: (
        <div className="flex items-baseline">
          <SecurityIcon className="mr-2" /> <span>Security</span>
        </div>
      ),
      content: <Security />,
    },
  ]

  return (
    <AppLayout title="Settings" subTitle="Update account preferences.">
      <div className="mt-5">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </AppLayout>
  )
}

export default Settings
