import React, { useState } from "react"
import Modal from "../../../components/modal"
import { ReactComponent as QRCodeIcon } from "../../../assets/icons/qrcode.svg"
import OtpInput from "../../../components/input/otpInput"
import { useMutation } from "react-query"
import { AxiosError } from "axios"
import { change2faStatus } from "../../../axios/services/auth-services"
import Button from "../../../components/button"
import { toast } from "react-toastify"

interface ModalPropsT {
  closeModal: () => void
  isOpen: boolean
  setSuccessModal: (successModal: boolean) => void
  accessKey: string
  twoFactor: boolean
}

const TwoFactorModal = ({
  closeModal,
  isOpen,
  setSuccessModal,
  accessKey,
  twoFactor,
}: ModalPropsT) => {
  const [modalOTP, setModalOtp] = useState<string>("")
  const [error, setError] = useState<string>("")

  const OTPFieldValue = (otp: string[]) => {
    setModalOtp(otp.join(""))
  }

  const { mutate: handle2faStatusChange, isLoading } = useMutation(
    change2faStatus,
    {
      onSuccess: () => {
        closeModal()
        setSuccessModal(true)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    }
  )

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} maxWidth="max-w-[60rem]">
      <div className="">
        <h2 className="font-medium text-2xl mb-10">
          {!twoFactor ? "Enable" : "Disable"} Two-Factor Authentication
        </h2>
        <p className="mb-10">
          1. To {!twoFactor ? "enable" : "disable"} two-factor authentication,
          you need to download an authenticator app like Google Authenticator or
          whichever you find preferable.
        </p>
        <p className="mb-10">
          2. Scan the QR Code displayed below with your authenticator app. If
          you cannot scan the QR code, enter the text code VDYQWUBDNAOC in the
          authenticator app instead.{" "}
        </p>
        <p className="flex justify-center mb-5">
          <QRCodeIcon />
        </p>
        <p className="mb-5">
          3. Enter the authentication code generated by the authenticator app,
          then click on {!twoFactor ? "enable" : "disable"}.
        </p>

        <div>
          <OtpInput
            fieldCount={6}
            onChange={(otp: string[]) => {
              OTPFieldValue(otp)
              setError("")
            }}
          />
          {error && (
            <div className="mt-2 text-center text-danger-default">{error}</div>
          )}
        </div>

        <div className="flex justify-around items-center mt-10">
          <button
            onClick={closeModal}
            className="bg-white-default border border-black-default text-black-default px-5 py-2 rounded w-[7rem]"
          >
            Cancel
          </button>
          <Button
            text={!twoFactor ? "Enable" : "Disable"}
            loading={isLoading}
            onClick={() =>
              handle2faStatusChange({
                accessKey: accessKey,
                otp: modalOTP,
              })
            }
            className="bg-primary-default border border-primary-default text-white-default px-5 py-2 rounded w-[7rem]"
          />
        </div>
      </div>
    </Modal>
  )
}

export default TwoFactorModal
