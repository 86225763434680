import React, { useState } from "react"
import Tabs from "../../components/tab"
import AppLayout from "../../layout/private/app-layout"
import { ReactComponent as BusinessDetailsIcon } from "../../assets/icons/business-details.svg"
import { ReactComponent as DocumentsIcon } from "../../assets/icons/documents.svg"
import { ReactComponent as BankInfoIcon } from "../../assets/icons/bank-info.svg"
import { ReactComponent as CommentsIcon } from "../../assets/icons/comments.svg"
import BusinessDetails from "./business-details"
import UploadDocuments from "./upload-documents"
import BankInformation from "./bank-information"
import CommentsHistory from "./comments-history"
import { useAppSelector } from "../../redux/hooks"
import { useParams } from "react-router-dom"

const KYC = () => {
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const params = useParams()
  const paramsId = params.id ? parseInt(params.id) : 0
  const [activeTab, setActiveTab] = useState(paramsId)

  const tabs = [
    {
      label: (
        <div className="flex items-baseline">
          <BusinessDetailsIcon className="mr-2" /> <span>Business Details</span>
        </div>
      ),
      content: <BusinessDetails setActiveTab={setActiveTab} />,
    },
    {
      label: (
        <div className="flex items-baseline">
          <DocumentsIcon className="mr-2" /> <span>Documents</span>
        </div>
      ),
      content: <UploadDocuments setActiveTab={setActiveTab} />,
    },
    {
      label: (
        <div className="flex items-baseline">
          <BankInfoIcon className="mr-2" /> <span>Bank Information</span>
        </div>
      ),
      content: <BankInformation setActiveTab={setActiveTab} />,
    },
    // {
    //   label: (
    //     <div className="flex items-baseline">
    //       <CommentsIcon className="mr-2" /> <span>Comments History</span>
    //     </div>
    //   ),
    //   content: <CommentsHistory setActiveTab={setActiveTab} />,
    // },
  ]

  return (
    <AppLayout
      // @ts-ignore
      title="KYC"
      subTitle="Register and update your business details."
    >
      <div className="mt-5">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </AppLayout>
  )
}

export default KYC
