import React from "react"
import Modal from "../../../components/modal"
import { ReactComponent as SuccessIcon } from "../../../assets/icons/green-check.svg"

interface ModalPropsT {
  closeModal: () => void
  isOpen: boolean
}

const SuccessModal = ({ closeModal, isOpen }: ModalPropsT) => {
  const handleSubmit = () => {
    closeModal()
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <div className="text-center">
        <div className="flex justify-center mb-3">
          <SuccessIcon />
        </div>
        <h2 className="font-semibold text-2xl mb-4">
          KYC Submitted Successfully!
        </h2>
        <p className="font-light text-lg mb-3">
          You have submitted your business details for verification. Account
          will be verified in 24 hours.
        </p>
        <div className="flex flex-col justify-center items-center">
          <button
            onClick={handleSubmit}
            className="bg-primary-default text-white-default px-5 py-2 rounded w-[7rem]"
          >
            Okay
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SuccessModal
