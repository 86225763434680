import React from "react"
import { CustomTable } from "./custom-table"
import TableSkeleton from "./table-skeleton"
interface TableProps {
  data: unknown[]
  columns: unknown[]
  handleRowClick?: (rowId: string | number) => void
  isLoading?: boolean
  loadingSkeletonCount?: number
  className?: string
}
const Table: React.FC<TableProps> = ({
  isLoading,
  data,
  handleRowClick,
  columns,
  loadingSkeletonCount,
  className,
}) => {
  if (isLoading) {
    return <TableSkeleton columns={columns} count={loadingSkeletonCount || 5} />
  }

  if (!data) return <div className="p-3">Unable to retrieve data</div>

  return (
    <div className={`overflow-x-auto pb-20 ${className}`}>
      <CustomTable
        data={data}
        columns={columns}
        handleRowClick={handleRowClick}
      />
    </div>
  )
}

export default Table
