import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { queryKeys } from "constants/queryKeys"
import { useForm } from "react-hook-form"
import {
  fetchDisputeRecords,
  fetchDisputeDetails,
  fetchDisputeTransactions,
  ReopenDispute,
} from "../../../axios/services/payment-services"
import { encodeSearchParams } from "utils/encodeSearchParams"
import { useAppSelector } from "redux/hooks"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { queryClient } from "index"

const useDisputes = (queryKey: string, disputeId?: string | null) => {
  const [pageSize] = React.useState(10)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [searchParams, setSearchParams] = useState<{
    status?: { label: string; value: string | undefined }
    startDate?: string
    endDate?: string
    search?: string | undefined
    paymentChannelId?: number
  }>({
    status: { label: "All", value: undefined },
    startDate: "",
    endDate: "",
    paymentChannelId: 0,
  })

  const profileDetails = useAppSelector((state) => state.profile.profile)
  const merchantId: number = profileDetails?.businessId ?? 0

  const { data: disputesResponse, isLoading } = useQuery(
    [
      queryKeys.disputes.fetchDisputeRecords,
      currentPage,
      pageSize,
      searchParams,
    ],
    () =>
      fetchDisputeRecords({
        merchantId,
        pageSize,
        currentPage,
      }),
    {
      enabled: queryKey === queryKeys.disputes.fetchDisputeRecords,
    }
  )

  const { data: detailsResponse, isLoading: loadingDetails } = useQuery(
    [queryKeys.disputes.fetchDisputeDetails, currentPage],
    () =>
      fetchDisputeDetails({
        disputeId,
        merchantId,
      }),
    {
      enabled:
        !!disputeId && queryKey === queryKeys.disputes.fetchDisputeDetails,
    }
  )

  const { data: transactionsResponse, isLoading: loadingTransactions } =
    useQuery(
      [queryKeys.disputes.fetchDisputeTransactions],
      () =>
        fetchDisputeTransactions({
          disputeId,
        }),
      {
        enabled:
          !!disputeId &&
          queryKey === queryKeys.disputes.fetchDisputeTransactions,
      }
    )

  const updateSearchParams = (
    payload: React.SetStateAction<{
      startDate?: string
      endDate?: string
      search?: string | undefined
    }>
  ) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  const onFilter = (data: any) => {
    updateSearchParams({
      ...data,
    })
  }

  const onSearch = (data: any) => {
    setSearchParams({
      ...searchParams,
      ...data,
    })
  }

  const { mutate: handleReopenDispute, isLoading: isReopeningDispute } =
    useMutation(ReopenDispute, {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.disputes.fetchDisputeDetails)
        toast.success("Dispute reopened!")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    })

  const onReopenDispute = () => {
    handleReopenDispute({
      merchantId: merchantId,
      disputeId: disputeId ?? 0,
    })
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const disputeList: DisputesT[] = disputesResponse?.data?.data?.content

  const totalElements = disputesResponse?.data?.data?.totalElements
  const totalPages = disputesResponse?.data?.data?.totalPages

  const filterMethods = useForm()
  const searchMethods = useForm()

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  const search = {
    searchMethods,
    handleSearch,
    onSearch,
    updateSearchParams,
    searchParams,
  }

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: filterMethods.reset,
  }

  const exportManager = { showExportModal, setShowExportModal }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const disputes = { disputeList, isLoading }
  const disputeDetails = {
    loadingDetails,
    data: detailsResponse?.data?.data as DisputesDetailsT,
  }
  const disputeTransactions = {
    loadingTransactions,
    data: transactionsResponse?.data?.data,
  }

  const reopenDisputeHandler = {
    onReopenDispute,
    isReopeningDispute,
  }

  return {
    disputes,
    search,
    filter,
    pagination,
    exportManager,
    disputeDetails,
    disputeTransactions,
    reopenDisputeHandler,
  }
}

export default useDisputes
