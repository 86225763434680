import React, { useState } from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { peronalDetailsSchema } from "./validation-schema"
import Input from "../../components/input/input"
import Button from "../../components/button"
import { useMutation, useQuery } from "react-query"
import {
  editUserDetails,
  fetchUserDetetails,
} from "../../axios/services/auth-services"
import { useAppSelector } from "../../redux/hooks"
import FullScreenLoader from "../../components/loader/fullscreen-loader"
import PersonalProfilePreview from "./preview-components/personal-profile-preview"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { queryClient } from "../../index"
import PhoneInputField from "../../components/input/phone-input"
import { handlePhoneNumber } from "../../utils/handlePhoneNumber"

const PersonalProfile = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const methods = useForm({
    resolver: yupResolver(peronalDetailsSchema),
  })

  const { handleSubmit, setValue } = methods

  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { data: personalDetails, isFetching: isFetchingDetails } = useQuery(
    "fetchPersonalDetails",
    // @ts-ignore
    () => fetchUserDetetails(profileDetails?.id),
    {
      onSuccess: (response) => {
        setValue("firstName", response.data?.data.firstName)
        setValue("lastName", response.data?.data.lastName)
        setValue("email", response.data?.data.email)
        setValue("phone", response.data?.data.mobileNumber)
      },
    }
  )

  const { mutate: handleProfileUpdate, isLoading: isSubmittingProfileDetails } =
    useMutation(editUserDetails, {
      onSuccess: () => {
        toast.success("Personal Details saved successfully!")
        queryClient.invalidateQueries("fetchPersonalDetails")
        setIsEditing(false)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    })

  const handlePersonalDetailsSubmit = (data: FieldValues) => {
    handleProfileUpdate({
      id: profileDetails?.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobileNumber: handlePhoneNumber(data.phone),
    })
  }

  return (
    <>
      {isFetchingDetails ? (
        <FullScreenLoader
          loading={isFetchingDetails}
          message="Fetching Personal Profile"
        />
      ) : (
        <div>
          {isEditing ? (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handlePersonalDetailsSubmit)}>
                <div className="bg-white-default p-10">
                  <div className="font-semibold mb-5 text-lg">
                    Personal Details
                  </div>
                  <div className="mb-6 block md:flex md:justify-between ">
                    <div className=" w-[100%] md:w-[45%]">
                      <Input label="First Name" name="firstName" />
                    </div>
                    <div className="w-[100%] md:w-[45%] mt-6 md:mt-0">
                      <Input label="Last Name" name="lastName" />
                    </div>
                  </div>
                  <div className="w-[100%] md:w-[45%] mb-6">
                    <Input label="Email Address" name="email" disabled />
                  </div>
                  <div className="w-[100%] md:w-[45%] mb-6">
                    <PhoneInputField
                      methods={methods}
                      label="Phone Number"
                      name="phone"
                    />
                  </div>
                </div>

                <div className="flex justify-end mt-10 mb-20">
                  <Button
                    disabled={isSubmittingProfileDetails}
                    loading={isSubmittingProfileDetails}
                    text="Save Changes"
                    className="w-auto px-5 py-2"
                  />
                </div>
              </form>
            </FormProvider>
          ) : (
            <PersonalProfilePreview
              setIsEditing={setIsEditing}
              personalDetails={personalDetails?.data?.data}
            />
          )}
        </div>
      )}
    </>
  )
}

export default PersonalProfile
