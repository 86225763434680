import React, { useState, useEffect } from "react"
import Modal from "../../../components/modal"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import Button from "../../../components/button"
import { userSchema } from "../validation-schema"
import { yupResolver } from "@hookform/resolvers/yup"
import Input from "../../../components/input/input"
import StyledSelect from "../../../components/select"
import {
  fetchCustomRoles,
  fetchDefaultRoles,
  EditUserAccount,
} from "../../../axios/services/auth-services"
import { toast } from "react-toastify"
import { useAppSelector } from "../../../redux/hooks"
import { useMutation, useQuery } from "react-query"
import { queryClient } from "../../.."
import { AxiosError } from "axios"

type UserPropsT = {
  firstName: string
  lastName: string
  email: string
  role: string
  roleId: number
  defaultRole: boolean
  id: number
  mobileNumber: string
}

interface ModalPropsT {
  isOpen: boolean
  closeModal: () => void
  userDetails: UserPropsT
}

const EditUserDetails = ({ closeModal, isOpen, userDetails }: ModalPropsT) => {
  const [defaultRoles, setDefaultRoles] = useState<Array<SelectRoleT>>([])
  const [customRoles, setCustomRoles] = useState<Array<SelectRoleT>>([])
  const methods = useForm({
    resolver: yupResolver(userSchema),
  })

  const { handleSubmit, setValue } = methods

  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { isFetching: loadingDefaultRoles } = useQuery(
    "fetchDefaultRoles",
    fetchDefaultRoles,
    {
      onSuccess: (response) => {
        const rolesRes = response.data.data.sort((a: RoleT, b: RoleT) =>
          a.name.localeCompare(b.name)
        )
        const roles: Array<SelectRoleT> = []
        for (let i = 0; i < rolesRes.length; i++) {
          const item = {
            label: rolesRes[i].name,
            value: rolesRes[i].id,
            isDisabled: false,
            default: rolesRes[i].default,
          }
          roles.push(item)
        }
        setDefaultRoles(roles)
      },
      onError: () => {
        toast.error("Unable to fetch default roles")
      },
    }
  )
  const { isFetching: loadingCustomRoles } = useQuery(
    "fetchCustomRoles",
    () => fetchCustomRoles(profileDetails?.businessId),
    {
      onSuccess: (response) => {
        const rolesRes = response.data.data.sort((a: RoleT, b: RoleT) =>
          a.name.localeCompare(b.name)
        )
        const roles: Array<SelectRoleT> = []
        for (let i = 0; i < rolesRes.length; i++) {
          const item = {
            label: rolesRes[i].name,
            value: rolesRes[i].id,
            isDisabled: false,
            default: rolesRes[i].default,
          }
          roles.push(item)
        }
        setCustomRoles(roles)
      },
      onError: () => {
        toast.error("Unable to fetch custom roles")
      },
    }
  )

  const { mutate: updateUserProfile, isLoading } = useMutation(
    EditUserAccount,
    {
      onSuccess: () => {
        toast.success("User profile updated successfully")
        closeModal()
        queryClient.invalidateQueries("fetchMerchantUsers")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    updateUserProfile({
      id: userDetails.id,
      businessId: profileDetails?.businessId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobileNumber: userDetails.mobileNumber,
      email: data?.email,
      roleId: data?.role?.value,
      role: data?.role?.label,
      defaultRole: data?.role?.default,
    })
  }

  useEffect(() => {
    setValue("firstName", userDetails.firstName)
    setValue("lastName", userDetails.lastName)
    setValue("email", userDetails.email)
    setValue("role", {
      label: userDetails.role,
      value: userDetails.roleId,
      isDisabled: false,
      default: userDetails.defaultRole,
    })
  }, [])

  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <FormProvider {...methods}>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8">
            <div className="font-semibold text-xl">Edit User</div>
            <div>Edit an existing user</div>
          </div>

          <div className="mb-3">
            <Input label="First Name" name="firstName" />
          </div>
          <div className="mb-3">
            <Input label="Last Name" name="lastName" />
          </div>
          <div className="mb-3">
            <Input label="Email Address" name="email" disabled />
          </div>
          <div className="mb-3">
            <StyledSelect
              control={methods.control}
              defaultValue={customRoles[0]}
              isDisabled={false}
              isLoading={loadingCustomRoles || loadingDefaultRoles}
              isClearable={false}
              isSearchable={false}
              name="role"
              label="Role"
              options={[
                {
                  label: "Default Roles",
                  options: defaultRoles,
                },
                {
                  label: "Custom Roles",
                  options: customRoles,
                },
              ]}
            />
          </div>

          <div className="mt-14 flex justify-end">
            <Button
              loading={isLoading}
              disabled={isLoading}
              className="w-max px-5 py-2 rounded-md"
              text="Save Changes"
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default EditUserDetails
