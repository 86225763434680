import React, { FC } from "react"
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/green-check.svg"
import { PaymentSuccessData } from "types/payment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

type Props = { successData?: PaymentSuccessData }

const SuccessMessage: FC<Props> = ({ successData }) => (
  <div className="flex flex-col items-center">
    <div className="flex justify-center mb-3">
      {successData?.status === false ? (
        <FontAwesomeIcon
          size="3x"
          icon={faExclamationCircle}
          className="text-yellow-600"
          fontSize={20}
        />
      ) : (
        <SuccessIcon width={50} />
      )}
    </div>
    <h2 className="font-semibold text-2xl mb-4">
      Payment {successData?.status === false ? "Pending" : "Successful"}
    </h2>
    <p className="font-light text-sm mb-3 px-5">
      {successData?.successMessage
        ? successData.successMessage
        : "An email of your receipt has been sent to you."}
    </p>
  </div>
)

export default SuccessMessage
