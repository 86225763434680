import React, { useState, useEffect } from "react"

type PropsT = {
  onPinChange: (input: string) => void
}

function Pin({ onPinChange }: PropsT): JSX.Element {
  const [numbers, setNumbers] = useState<string[]>([])

  useEffect(() => {
    const randomNumbers = shuffleArray([
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "DEL",
      "CLR",
    ])
    setNumbers(randomNumbers)
  }, [])

  const shuffleArray = (array: string[]) => {
    // Fisher-Yates (aka Knuth) Shuffle
    const removedButtons = array.splice(10, 2)
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return [...array, ...removedButtons]
  }

  return (
    <div className="w-full flex gap-4 flex-wrap">
      {numbers.map((input) => (
        <button
          className="w-[25%] border border-gray-700 py-3 font-semibold hover:bg-black-default hover:text-white-default rounded-md"
          key={input}
          type="button"
          onClick={() => onPinChange(input)}
        >
          {input}
        </button>
      ))}
    </div>
  )
}

export default Pin
