import React, { useState } from "react"
import { paymentSchedule } from "./data"
import { Switch } from "@headlessui/react"
import { Radio } from "../../components/input/radio"
import Button from "../../components/button"
import { useMutation, useQuery } from "react-query"
import {
  fetchPaymentPreferences,
  SavePaymentPreferences,
} from "../../axios/services/payment-services"
import { useAppSelector } from "../../redux/hooks"
import { AxiosError } from "axios"
import FullScreenLoader from "../../components/loader/fullscreen-loader"
import { toast } from "react-toastify"
type PaymentChannelT = {
  id: number
  name: string
  feeType: string
  flatFee: number
  feePercentage: number
  feeCap: number
  currency: string
  active: boolean
  lastUpdated: string
  selected: boolean
}
type PreferencesT = {
  businessId: number
  nextDay: boolean
  weekly: boolean
  monthly: boolean
  pushNotification: boolean
  paymentChannels: PaymentChannelT[]
}

const Preferences = () => {
  const [paymentPreferences, setPaymentPreferences] = useState<PreferencesT>({
    businessId: 0,
    nextDay: false,
    weekly: false,
    monthly: false,
    pushNotification: false,
    paymentChannels: [],
  })

  const profileDetails = useAppSelector((state) => state.profile.profile)

  const handlePaymentChannels = (
    event: React.ChangeEvent<HTMLInputElement>,
    channelId: number
  ) => {
    const updatedPaymentChannels = [...paymentPreferences.paymentChannels]

    const paymentChannelIndex = updatedPaymentChannels.findIndex(
      (channel) => channel.id === channelId
    )

    updatedPaymentChannels[paymentChannelIndex].selected = event.target.checked

    setPaymentPreferences({
      ...paymentPreferences,
      paymentChannels: updatedPaymentChannels,
    })
  }

  const handlePaymentSchedule = (value: string) => {
    switch (value) {
      case "nextDay":
        setPaymentPreferences({
          ...paymentPreferences,
          nextDay: true,
          weekly: false,
          monthly: false,
        })
        break
      case "weekly":
        setPaymentPreferences({
          ...paymentPreferences,
          nextDay: false,
          weekly: true,
          monthly: false,
        })
        break
      case "monthly":
        setPaymentPreferences({
          ...paymentPreferences,
          nextDay: false,
          weekly: false,
          monthly: true,
        })
        break
      default:
        break
    }
  }

  const handlePushNotificationsChange = (status: boolean) => {
    setPaymentPreferences({
      ...paymentPreferences,
      pushNotification: status,
    })
  }

  const { isFetching } = useQuery(
    "fetchPaymentPreferences",
    () =>
      // @ts-ignore
      fetchPaymentPreferences(profileDetails?.businessId),
    {
      onSuccess: (response) => {
        // console.log("rsp", response)
        setPaymentPreferences(response.data?.data)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const { mutate: saveUpdatedPreferences, isLoading } = useMutation(
    SavePaymentPreferences,
    {
      onSuccess: () => {
        toast.success("Payment preferences saved successfully!")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const handleSubmit = () => {
    // Check if at least one payment channel is selected
    const isAtLeastOneSelected = paymentPreferences.paymentChannels.some(
      (channel) => channel.selected
    )
    if (isAtLeastOneSelected) {
      saveUpdatedPreferences({
        ...paymentPreferences,
        businessId: profileDetails?.businessId,
      })
    } else toast.error("You must select at least one payment channel")
  }

  return (
    <>
      {isFetching ? (
        <FullScreenLoader
          loading={isFetching}
          message="Fetching payment preferences"
        />
      ) : (
        <div>
          <div className="bg-white-default p-10">
            <div>
              <h3 className="font-medium mb-5">Payment Channels</h3>
              {paymentPreferences.paymentChannels
                .filter((channel) => channel.active != false)
                .map((paymentChannel: PaymentChannelT, index) => (
                  <div
                    key={"payment-schedule-" + index}
                    className="flex items-center my-3"
                  >
                    <label
                      htmlFor="payment-channels"
                      className=" font-light w-40"
                    >
                      {paymentChannel.name}
                    </label>{" "}
                    <div>
                      <div className="flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          id="payment-channels"
                          checked={paymentChannel.selected}
                          name="payment-channels"
                          className="absolute h-8 w-8 opacity-0"
                          onChange={(e) =>
                            handlePaymentChannels(e, paymentChannel.id)
                          }
                        />
                        <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-md bg-gray-200 border border-gray-700 cursor-pointer">
                          <svg
                            className={`fill-current text-danger-default ${
                              // @ts-ignore
                              paymentChannel.selected ? null : "hidden"
                            } h-3 w-3`}
                            version="1.1"
                            viewBox="0 0 17 12"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd">
                              <g
                                transform="translate(-9 -11)"
                                fill="#204D88"
                                fillRule="nonzero"
                              >
                                <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="mt-16">
              <h3 className="font-medium mb-5">Payment Schedule</h3>
              {paymentSchedule.map(({ label, value }, index) => (
                <div
                  key={"payment-schedule-" + index}
                  className="flex items-center my-3"
                >
                  <label htmlFor="c1" className=" font-light w-40">
                    {label}
                  </label>{" "}
                  <Radio
                    name="payment-schedule"
                    // @ts-ignore
                    checked={paymentPreferences[value]}
                    onChange={(e) => handlePaymentSchedule(e.target.value)}
                    value={value}
                  />
                </div>
              ))}
            </div>

            <div className="mt-16">
              <h3 className="font-medium mb-3">Mobile Push Notifications</h3>
              <div className="flex items-center">
                <Switch
                  checked={paymentPreferences.pushNotification}
                  onChange={(status: boolean) =>
                    handlePushNotificationsChange(status)
                  }
                  className={`${
                    paymentPreferences.pushNotification
                      ? "bg-primary-default"
                      : "bg-gray-700"
                  }
        relative inline-flex h-[18px] w-[38px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                  <span className="sr-only">Enable Two Factor</span>
                  <span
                    aria-hidden="true"
                    className={`${
                      paymentPreferences.pushNotification
                        ? "translate-x-6 "
                        : "translate-x-0"
                    }
          pointer-events-none inline-block h-[14px] w-[14px] transform rounded-full bg-white-default shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>{" "}
                <span className="ml-5 font-light">
                  {paymentPreferences.pushNotification ? "Disable" : "Enable"}{" "}
                  push notifications on mobile
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-10 mb-20">
            <Button
              loading={isLoading}
              onClick={handleSubmit}
              text="Save Changes"
              className="w-auto px-5 py-2"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Preferences
