import React, { MutableRefObject, useRef } from "react"
import { Link, useLocation } from "react-router-dom"
import { Navlist } from "./navList"
import logo from "../../assets/images/TGIPrimaryLogo.svg"
import { ReactComponent as Logout } from "../../assets/icons/logout.svg"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { toggleMobileSidebar } from "../../redux/ui/uiSlice"
import { useOnClickOutside } from "../../hooks/useOutsideClick"
import PermissionGate from "components/permission-gate/PermissionGate"
import { toast } from "react-toastify"
import { useLogout } from "hooks/useLogout"
import FullScreenLoader from "components/loader/fullscreen-loader"

const MobileSidebar = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { logoutUser, isLoggingUserOut } = useLogout()

  const businessDetails = useAppSelector(
    (state) => state.profile.businessProfile
  )

  const toggleSidebar = () => {
    dispatch(toggleMobileSidebar())
  }
  const ref: MutableRefObject<undefined> = useRef()
  useOnClickOutside(ref, () => toggleSidebar())

  const handleNavClick = (kycApproved: boolean, link: string) => {
    if (
      kycApproved === false &&
      link !== "/" &&
      link !== "/kyc" &&
      link !== "/settings"
    ) {
      toast.error("You have to complete your KYC to have access to this module")
    }
  }
  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ref={ref}
      className="flex flex-col text-sm h-screen"
    >
      <div className="mt-14 mb-12 px-4">
        <img src={logo} className="w-[80px]" alt="tgipay-logo" />
      </div>
      <div className=" overflow-y-scroll hide-scrollbar">
        {Navlist.map(
          ({ link, name, Icon, allowedPermissions, kycRequired }, index) => {
            if (!allowedPermissions)
              return (
                <Link
                  key={"link" + index}
                  onClick={toggleSidebar}
                  className={`bg-white-default hover:bg-primary-default/5 px-4 py-4 flex items-center ${
                    location.pathname.split("/")[1] === link.split("/")[1] &&
                    "border-l-4 border-l-primary-default font-bold text-primary-default"
                  }`}
                  // to={link}
                  to={
                    kycRequired ? (businessDetails.approved ? link : "#") : link
                  }
                >
                  {/* <img className="mr-3 w-4" src={link.icon} /> */}
                  <Icon className="mr-3 w-4" />
                  <span>{name}</span>
                </Link>
              )

            return (
              <PermissionGate
                allowedPermissions={allowedPermissions}
                key={index}
              >
                <div
                  onClick={() => handleNavClick(businessDetails.approved, link)}
                >
                  <Link
                    onClick={toggleSidebar}
                    className={`bg-white-default hover:bg-primary-default/5 px-4 py-4 flex items-center ${
                      location.pathname.split("/")[1] === link.split("/")[1] &&
                      "border-l-4 border-l-primary-default font-bold text-primary-default"
                    }`}
                    to={
                      kycRequired
                        ? businessDetails.approved
                          ? link
                          : "#"
                        : link
                    }
                  >
                    {/* <img className="mr-3 w-4" src={link.icon} /> */}
                    <Icon className="mr-3 w-4" />
                    <span>{name}</span>
                  </Link>
                </div>
              </PermissionGate>
            )
          }
        )}

        <Link
          onClick={() => logoutUser()}
          className={`bg-white-default hover:bg-primary-default/5 text-black-default px-4 py-4 flex items-center mt-10 mb-10`}
          to={"/login"}
        >
          <Logout className="mr-3" />
          <span>Log Out</span>
        </Link>
      </div>

      <>
        {isLoggingUserOut && (
          <FullScreenLoader loading={isLoggingUserOut} message="Logging out" />
        )}
      </>
    </div>
  )
}

export default MobileSidebar
