import React, { FC } from "react"
import { FormProvider } from "react-hook-form"
import Button from "components/button"
import Input from "components/input/input"

type TopupFormProps = {
  handleSubmit: () => void
  methods: any
}

const TopupForm: FC<TopupFormProps> = ({ handleSubmit, methods }) => {
  return (
    <>
      <h2 className="text-center text-2xl font-medium mb-5">Topup wallet</h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-3">
            <div className="mb-4">
              <Input
                label="Amount"
                name="amount"
                type="text"
                inputMode="numeric"
              />
            </div>
            <div className="mt-4 flex justify-between gap-10">
              <Button
                type="button"
                variant="outline"
                text="Cancel"
                className="w-28 py-3 px-4 rounded"
              />
              <Button
                className="w-28 py-3 rounded"
                text="Proceed"
                type="submit"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  )
}

export default TopupForm
