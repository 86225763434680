import { useQuery } from "react-query"
import { queryKeys } from "constants/queryKeys"
import { useAppSelector } from "redux/hooks"
import { fetchUserWallets } from "../../../axios/services/payment-services"
import { WalletType } from "types/wallet"

export const useWallets = () => {
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const {
    data: walletResponse,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useQuery([queryKeys.Wallet.getUserWallets], () =>
    fetchUserWallets(profileDetails?.businessId ?? 0)
  )

  const walletData: WalletType[] = error ? [] : [walletResponse?.data?.data]

  const loading = isLoading || isFetching

  const wallet = { walletData, loading, error, refetch }

  return {
    wallet,
  }
}
