import { createSlice } from "@reduxjs/toolkit"

interface BreadcrumbState {
  isSidebarOpen: boolean
}

const initialState: BreadcrumbState = {
  isSidebarOpen: false,
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleMobileSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen
    },
  },
})

export const { toggleMobileSidebar } = uiSlice.actions

export default uiSlice.reducer
