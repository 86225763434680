import React, { useState } from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { format } from "date-fns"
import { ReactComponent as Search } from "../../../assets/icons/search.svg"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../../../components/button"
import { Dropdown } from "components/dropdown"
import ReactDatePicker from "react-datepicker"
import CustomSelect from "components/select"
import Input from "components/input/input"

type FilterT = {
  paymentStatus?: { label: string; value: string | undefined }
  requestStatus?: { label: string; value: string | undefined }
  amount: number | undefined
  startDate?: string
  endDate?: string
  search?: string | undefined
  invoiceNumber?: string
}

interface ModalPropsT {
  createInvoice: () => void
  searchParams: FilterT
  setSearchParams: (searchParams: FilterT) => void
}

const InvoicingActions = ({
  createInvoice,
  searchParams,
  setSearchParams,
}: ModalPropsT) => {
  const [showFilter, setShowFilter] = React.useState(false)
  const [startDate, setStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()
  const [searchText, setSearchText] = useState<string>("")

  const searchMethods = useForm()
  const { handleSubmit: handleSearch } = searchMethods

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleSearchTerm = (e: any) => {
    e.preventDefault()
    setSearchParams({
      ...searchParams,
      invoiceNumber: searchText,
    })
  }

  const onSubmit = (data: FieldValues) => {
    setSearchParams({
      ...searchParams,
      ...data,
      startDate: startDate && format(startDate, "dd-MM-yyyy"),
      endDate: endDate && format(endDate, "dd-MM-yyyy"),
    })
  }

  const handleReset = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    searchMethods.setValue("paymentStatus", { label: "All", value: "" })
    searchMethods.setValue("requestStatus", { label: "All", value: "" })
    searchMethods.setValue("amount", undefined)
    setSearchParams({
      paymentStatus: { label: "All", value: undefined },
      requestStatus: { label: "All", value: undefined },
      startDate: "",
      amount: undefined,
      endDate: "",
    })
  }
  return (
    <div className="flex justify-end">
      <div className="mr-4">
        <Dropdown
          isOpen={showFilter}
          toggleOpen={toggleFilter}
          showBackdrop={false}
          trigger={
            <Button
              variant="link"
              className="relative flex h-10 min-w-max items-center justify-evenly gap-[15px] rounded-md px-4 text-base font-medium text-primary-default"
              text={
                <>
                  <FontAwesomeIcon icon={faFilter} />
                  <span className="hidden md:inline">Filter By</span>
                </>
              }
            />
          }
          content={
            <FormProvider {...searchMethods}>
              <form onSubmit={handleSearch(onSubmit)}>
                <div className="flex flex-col gap-3">
                  <CustomSelect
                    label="Payment Status"
                    defaultValue={{ value: "", label: "All" }}
                    options={[
                      { value: "", label: "All" },
                      { value: "paid", label: "Paid" },
                      { value: "pending", label: "Pending" },
                      { value: "overdue", label: "Overdue" },
                    ]}
                    name="paymentStatus"
                    control={searchMethods.control}
                  />
                  <CustomSelect
                    label="Request Status"
                    defaultValue={{ value: "", label: "All" }}
                    options={[
                      { value: "", label: "All" },
                      { value: "sent", label: "Sent" },
                      { value: "saved", label: "Saved" },
                      { value: "voided", label: "Voided" },
                    ]}
                    name="requestStatus"
                    control={searchMethods.control}
                  />
                  <div className="mb-4">
                    <Input
                      label="Amount"
                      name="amount"
                      type="text"
                      inputMode="numeric"
                    />
                  </div>
                  <div>
                    <label htmlFor="startDate">Start Date</label>
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date ?? undefined)}
                      startDate={startDate}
                      className="w-full border border-gray-400 rounded px-3 py-2"
                    />
                  </div>
                  <div>
                    <label htmlFor="startDate">End Date</label>
                    <ReactDatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date ?? undefined)}
                      startDate={endDate}
                      className="w-full border border-gray-400 rounded px-3 py-2"
                    />
                  </div>
                  <div className="mt-4 flex justify-between gap-10">
                    <Button
                      type="button"
                      onClick={handleReset}
                      variant="outline"
                      text="Reset"
                      className="w-28 py-1 px-4 rounded "
                    />
                    <Button
                      className="w-28 py-1 rounded"
                      text="Apply"
                      type="submit"
                    />
                  </div>
                </div>
              </form>
            </FormProvider>
          }
        />
      </div>
      <div className="relative flex items-center mr-4 ">
        <form
          onSubmit={(e: any) => handleSearchTerm(e)}
          className="relative flex items-center"
        >
          <Search className="absolute left-3 h-6 w-6 focus:border-primary-default focus:ring-primary-default" />
          <input
            className="h-11 w-full rounded border border-gray-700 p-3 pl-12 text-xs
            focus:border-primary-default focus:ring-primary-default bg-[#f9fafb]"
            type="search"
            value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)}
            readOnly
            placeholder="Search by Invoice number"
            onFocus={(e) => e.target.removeAttribute("readonly")}
          />
        </form>
      </div>
      <Button
        onClick={createInvoice}
        text="+ Create New Invoice"
        className="w-auto px-4 py-2 rounded"
      />
    </div>
  )
}

export default InvoicingActions
