import { ReactComponent as Kyc } from "../../assets/icons/kyc.svg"
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg"
import { ReactComponent as Transactions } from "../../assets/icons/transactions.svg"
import { ReactComponent as PaymentLink } from "../../assets/icons/payment-link.svg"
import { ReactComponent as Invoicing } from "../../assets/icons/invoicing.svg"
import { ReactComponent as Settlement } from "../../assets/icons/settlement.svg"
import { ReactComponent as Settings } from "../../assets/icons/settings.svg"
import { ReactComponent as Integration } from "../../assets/icons/integration.svg"
import { ReactComponent as Teams } from "../../assets/icons/teams.svg"
import { ReactComponent as Disputes } from "../../assets/icons/disputes.svg"
import { ReactComponent as WalletIcon } from "../../assets/icons/wallet.svg"
import { PERMISSIONS } from "constants/permissions"

type NavlistT = {
  link: string
  name: string
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  allowedPermissions?: string[]
  kycRequired: boolean
}[]

export const Navlist: NavlistT = [
  {
    link: "/kyc",
    name: "KYC",
    Icon: Kyc,
    allowedPermissions: [PERMISSIONS.VIEW_MERCHANT_KYC_DETAILS],
    kycRequired: false,
  },
  {
    link: "/",
    name: "Dashboard",
    Icon: Dashboard,
    kycRequired: false,
  },
  // {
  //   link: "/wallet",
  //   name: "Wallet",
  //   Icon: WalletIcon,
  //   kycRequired: true,
  // },
  {
    link: "/transactions",
    name: "Transactions",
    Icon: Transactions,
    allowedPermissions: [PERMISSIONS.VIEW_TRANSACTIONS],
    kycRequired: true,
  },
  {
    link: "/payment-link",
    name: "Payment Link",
    Icon: PaymentLink,
    allowedPermissions: [
      PERMISSIONS.CREATE_PAYMENT_LINK,
      PERMISSIONS.MANAGE_PAYMENT_LINK,
    ],
    kycRequired: true,
  },
  {
    link: "/invoicing",
    name: "Invoicing",
    Icon: Invoicing,
    allowedPermissions: [
      PERMISSIONS.CREATE_INVOICE,
      PERMISSIONS.MANAGE_INVOICE,
    ],
    kycRequired: true,
  },
  {
    link: "/settlement",
    name: "Settlement",
    Icon: Settlement,
    allowedPermissions: [PERMISSIONS.VIEW_PAYOUT_SETTLEMENTS],
    kycRequired: true,
  },
  {
    link: "/settings",
    name: "Settings",
    Icon: Settings,
    kycRequired: false,
  },
  {
    link: "/integration",
    name: "Integration",
    Icon: Integration,
    allowedPermissions: [
      PERMISSIONS.VIEW_INTEGRATIONS,
      PERMISSIONS.MANAGE_INTEGRATIONS,
    ],
    kycRequired: true,
  },
  {
    link: "/teams",
    name: "Teams",
    Icon: Teams,
    allowedPermissions: [
      PERMISSIONS.VIEW_USERS,
      PERMISSIONS.CREATE_MERCHANT_USERS,
      PERMISSIONS.MANAGE_MERCHANT_USERS,
    ],
    kycRequired: true,
  },
  {
    link: "/disputes",
    name: "Disputes",
    Icon: Disputes,
    allowedPermissions: [
      PERMISSIONS.VIEW_DISPUTES,
      PERMISSIONS.MANAGE_DISPUTES,
    ],
    kycRequired: true,
  },
  // {
  //   link: "/audit-trail",
  //   name: "Audit Trail",
  //   Icon: AuditTrail,
  //   allowedPermissions: [PERMISSIONS.VIEW_AUDIT_TRAIL],
  //   kycRequired: true,
  // },
]
