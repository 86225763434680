import React, { FC } from "react"
import PaymentMethods from "components/payment/payment-methods"
import PaymentMethodHandler from "components/payment/payment-methods/PaymentMethodHandler"
import { formatAmount } from "utils/format-amount"

type PaymentHandlerProps = {
  customerData?: any
  paymentData?: any
  successCallback?: (successData?: any) => void
  failureCallback?: () => void
  paymentStatus?: boolean
  fullWidth?: boolean
}

const PaymentHandler: FC<PaymentHandlerProps> = ({
  paymentData,
  customerData,
  fullWidth,
  successCallback,
  failureCallback,
  paymentStatus,
}) => {
  return (
    <>
      <div
        className={`mt-10 card ${
          fullWidth ? "!w-full" : "w-[95%] md:w-[60%] lg:w-[62%]"
        }`}
      >
        <div className="flex">
          <PaymentMethods />

          <div className="w-full p-5">
            <div className="flex justify-between items-baseline">
              <div>
                <div className="flex items-center gap-3">
                  <img
                    src={paymentData?.businessLogoUrl}
                    alt=""
                    className="h-20 w-h-20 shadow-sm"
                  />

                  <div>
                    <div className="text-4xl text-primary-default font-semibold mb-2">
                      {formatAmount(paymentData?.amount)}
                    </div>
                    <div className="text-gray-800/50">
                      To:{" "}
                      <span className="ml-2 font-medium text-gray-800">
                        {paymentData?.businessName}
                      </span>
                    </div>
                    <div className="text-gray-800/50">
                      For:{" "}
                      <span className="ml-2 font-medium text-gray-800">
                        {paymentData?.description}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr className="h2 my-8 bg-gray-700" />

            <PaymentMethodHandler
              paymentData={paymentData}
              customerData={customerData}
              successCallback={successCallback}
              failureCallback={failureCallback}
              paymentStatus={paymentStatus}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentHandler
