import React from "react"
import AppLayout from "../../layout/private/app-layout"
import useTransactions from "./hooks/useTransactions"
import { queryKeys } from "../../constants/queryKeys"
import { Pagination } from "../../components/Pagination/PaginationComponent"
import Button from "../../components/button"
import Table from "../../components/table"
import PaginationInfo from "../../components/Pagination/paginationInfo"
import TransactionsFilter from "./filter"
import ExportModal from "components/export-modal/ExportModal"
import { transactionColumns } from "./table/columns"
import PermissionGate from "components/permission-gate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/permission-gate/ListAccessDenied"

const Transactions: React.FC = () => {
  const { pagination, transaction, filter, search, exportManager } =
    useTransactions(queryKeys.transactions.fetchTransactions)

  return (
    <AppLayout
      title="Transactions"
      subTitle="Monitor your business transactions"
    >
      <PermissionGate
        allowedPermissions={[PERMISSIONS.VIEW_TRANSACTIONS]}
        fallback={<ListAccessDenied />}
      >
        {
          <>
            <div className="flex items-center justify-end gap-6 pt-2">
              <TransactionsFilter filter={filter} search={search} />
            </div>
            <div className="mt-4 border border-[#D3D3D3] pb-4">
              <Table
                columns={transactionColumns}
                data={transaction.transactionList}
                isLoading={transaction.isLoading}
                loadingSkeletonCount={10}
                className="pb-0"
              />
              {transaction.transactionList?.length > 0 ? (
                <div className="flex items-center h-[4.3125rem] justify-between bg-white px-5">
                  <PaginationInfo pagination={pagination} />

                  <div>
                    <Pagination pagination={pagination} />
                  </div>
                </div>
              ) : null}
            </div>
          </>
        }

        {transaction.transactionList?.length > 0 ? (
          <Button
            className="mt-9 w-[6rem]"
            onClick={() => exportManager.setShowExportModal(true)}
            text="Export"
          />
        ) : null}

        {exportManager.showExportModal ? (
          <ExportModal
            isOpen={exportManager.showExportModal}
            closeModal={() => exportManager.setShowExportModal(false)}
            exportDataName="Transactions"
            isExporting={exportManager.isExporting}
            handleExport={exportManager.handleExport}
          />
        ) : null}
      </PermissionGate>
    </AppLayout>
  )
}

export default Transactions
