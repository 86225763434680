import React from "react"
import { faBank, faExchange, faWallet } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PaymentChannel } from "pages/make-payment/types/payment"

export const PAYMETHOD_IDS = {
  CREDIT_CARD: "creditCard",
  PAY_VIA_TRANSFER: "payViaTransfer",
  PAY_VIA_BANK: "payViaBank",
  USSD: "ussd",
}

export const PAYMETHOD_NAMES = {
  CREDIT_CARD: "Card",
  PAY_VIA_TRANSFER: "Transfer",
  PAY_VIA_BANK: "Bank",
  USSD: "USSD",
}

export const paymethodData = [
  {
    name: PAYMETHOD_NAMES.CREDIT_CARD,
    id: PAYMETHOD_IDS.CREDIT_CARD,
    icon: <FontAwesomeIcon size={"xl"} icon={faWallet} />,
  },
  {
    name: PAYMETHOD_NAMES.PAY_VIA_TRANSFER,
    id: PAYMETHOD_IDS.PAY_VIA_TRANSFER,
    icon: <FontAwesomeIcon size={"xl"} icon={faExchange} />,
  },
  {
    name: PAYMETHOD_NAMES.PAY_VIA_BANK,
    id: PAYMETHOD_IDS.PAY_VIA_BANK,
    icon: <FontAwesomeIcon size={"xl"} icon={faBank} />,
  },
]

export const internalPayChannels = [PaymentChannel.WalletTopUp]

export const banksForDirectDebit = [{ label: "Wema Bank", value: "wema" }]
