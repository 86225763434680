import React from "react"
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg"
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg"

type PropsT = {
  label: string | undefined
  fileName: string
  downloadLink: string
}

const SavedDocument = ({ label, fileName, downloadLink }: PropsT) => {
  const downloadFile = () => {
    const link = document.createElement("a")
    link.href = downloadLink
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <div>
      <div className="font-medium mb-2">{label}</div>
      <div className="bg-gray-200 h-[7rem] custom-border flex gap-2 justify-between items-center px-3 py-5">
        <div className="flex items-center w-[85%] overflow-hidden">
          <FileIcon className="w-[15%]" />
          <div className="ml-3 w-[85%] break-words text-sm">{fileName}</div>
        </div>
        <DownloadIcon
          onClick={downloadFile}
          className="text-primary-default cursor-pointer"
        />
      </div>
    </div>
  )
}

export default SavedDocument
