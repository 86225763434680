import * as yup from "yup"

export const userSchema = yup.object().shape({
  role: yup.object().shape({
    value: yup.string().required("Role is required"),
  }),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("last Name is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Address is required"),
})
