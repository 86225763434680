import React, { FC } from "react"
import CardPayment from "components/payment/payment-methods/card"
import { generatePaymentData } from "utils/generatePaymentData"
import { PaymentComponentProps } from "types/payment"

const CardDetails: FC<PaymentComponentProps> = ({
  paymentData,
  customerData,
  successCallback,
  failureCallback,
}) => {
  const dataToSend = generatePaymentData(
    paymentData.paymentType,
    paymentData,
    customerData
  )

  const cardProcessor = paymentData?.cardProcessor

  return (
    <CardPayment
      dataToSend={dataToSend}
      cardProcessor={cardProcessor}
      successCallback={successCallback}
      failureCallback={failureCallback}
    />
  )
}

export default CardDetails
