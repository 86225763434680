import React, { FC, useState } from "react"
import { useForm, FieldValues } from "react-hook-form"
import { useAppSelector } from "redux/hooks"
import Modal from "components/modal"
import PaymentHandler from "pages/make-payment/payment-handler"
import { PaymentChannel } from "pages/make-payment/types/payment"
import TopupForm from "./TopupForm"
import SuccessMessage from "./SuccessMessage"
import { PaymentSuccessData } from "types/payment"

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const WalletTopup: FC<Props> = ({ handleClose, isOpen }) => {
  const [topupSuccess, setTopupSuccess] = useState(false)
  const profileDetails = useAppSelector((state) => state.profile.profile)
  const [amountToPay, setAmountToPay] = useState(0)
  const [successData, setSuccessData] = useState<
    PaymentSuccessData | undefined
  >()

  const methods = useForm()
  const { handleSubmit } = methods

  const onSubmit = (data: FieldValues) => {
    setAmountToPay(data.amount)
  }

  const onTopupSuccess = (successData?: any) => {
    if (successData) {
      setSuccessData(successData)
    }
    setAmountToPay(0)
    setTopupSuccess(true)
  }

  const customerData = {}

  const paymentData = {
    amount: amountToPay,
    description: "Top up",
    businessName: "Wallet",
    businessId: profileDetails?.businessId,
  }

  return (
    <Modal
      closeModal={() => {
        setTopupSuccess(false)
        setSuccessData(undefined)
        handleClose()
      }}
      isOpen={isOpen}
      maxWidth={amountToPay > 0 ? "max-w-[64rem]" : "max-w-[30rem]"}
    >
      {topupSuccess ? (
        <SuccessMessage successData={successData} />
      ) : (
        <>
          {amountToPay ? (
            <PaymentHandler
              customerData={customerData}
              paymentData={{
                ...paymentData,
                paymentType: PaymentChannel.WalletTopUp,
              }}
              fullWidth
              successCallback={onTopupSuccess}
            />
          ) : (
            <TopupForm
              handleSubmit={handleSubmit(onSubmit)}
              methods={methods}
            />
          )}
        </>
      )}
    </Modal>
  )
}

export default WalletTopup
