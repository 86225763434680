import React from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { copyToClipboard } from "../../../../../utils/copy-to-clipboard"
import { formatWithCustomCurrency } from "../../../../../utils/format-amount"

type WalletTransactionType = {
  date: string
  amount: number
  createdDate: string
  url: string
}

const columnHelper = createColumnHelper<WalletTransactionType>()

export const walletTableColumns = [
  columnHelper.accessor("date", {
    cell: (info) => info.getValue(),
    header: () => <span>Date</span>,
  }),
  columnHelper.accessor("amount", {
    header: () => <span>Amount</span>,
    cell: (info) => (
      <span>{formatWithCustomCurrency(info.renderValue() ?? 0, "")}</span>
    ),
  }),
  columnHelper.accessor("createdDate", {
    header: () => <span>Type</span>,
  }),
  columnHelper.accessor("url", {
    header: "Status",
    cell: ({ cell, renderValue }) => {
      const {
        row: { original: linkData },
      } = cell
      return (
        <span
          onClick={() => copyToClipboard(renderValue())}
          className="text-primary-default font-bold cursor-pointer"
        >
          test
        </span>
      )
    },
  }),
]
