export const transactionsApi = {
  fetchTransactions: "/api/v1/transaction/all",
  fetchTransactionDetails: "/api/v1/transaction",
  totalValueByDate: "/api/v1/transaction/all/total-value-by-date",
  successfulCountPointsByDate:
    "/api/v1/transaction/all/successful-count-points-by-date",
  failedCountpointsByDate:
    "/api/v1/transaction/all/failed-count-points-by-date",
  countSuccessfulByDate: "/api/v1/transaction/all/count-successful-by-date",
  countFailedByDate: "/api/v1/transaction/all/count-failed-by-date",
  countByDate: "/api/v1/transaction/all/count-by-date",
  exportTransactions: "/api/v1/transaction/all/export",
}
