import React from "react"
import { formatAmount, formatWithCustomCurrency } from "utils/format-amount"
import { getCurrentDate } from "utils/getDateAndTime"
import Button from "components/button"
import { ReactComponent as Logo } from "../../../../../assets/images/TGIPrimaryLogo.svg"
import { FieldValues } from "react-hook-form"
import { format } from "date-fns"
import { BusinessProfileT } from "redux/profile/profileSlice"

interface PropsT {
  values: FieldValues
  isSavingInvoice: boolean
  isSendingInvoice: boolean
  saveInvoice: () => void
  sendInvoice: () => void
  businessDetails: BusinessProfileT
}

const ProfessionalInvoicePreview = ({
  values,
  saveInvoice,
  sendInvoice,
  isSavingInvoice,
  isSendingInvoice,
  businessDetails,
}: PropsT) => {
  const calculateTotal = () => {
    let total = 0
    const itemsList = values.items ? values.items : []
    for (let i = 0; i < itemsList.length; i++) {
      const subTotal =
        parseFloat(values?.items[i]?.unitPrice) *
        parseFloat(values?.items[i]?.quantity)
      total += subTotal
    }
    return total
  }

  return (
    <div className="w-[40%] md:w-[40%]">
      <div className="text-center min-h-[2rem] font-medium">Preview</div>
      <div className="rounded-lg border-2 border-gray-600 min-h-[53rem] px-5 py-5">
        <div className="flex justify-between items-center">
          <Logo className=" w-16" />
        </div>
        <div className="flex justify-between">
          <div className="">
            <div>
              <span className="text-gray-default mr-2">Date Sent</span>{" "}
              <span>{format(new Date(), "dd-MM-yyyy")}</span>
            </div>
          </div>
          <div>
            <span className="text-gray-default mr-2">Payment Due</span>{" "}
            <span>
              {values?.paymentDue
                ? format(new Date(values?.paymentDue), "dd-MM-yyyy")
                : ""}
            </span>
          </div>
        </div>

        <div className="flex mt-4">
          <div className="text-gray-default mr-3">From</div>
          <div>{businessDetails?.name}</div>
        </div>
        <div className="flex mt-1">
          <div className="text-gray-default mr-3">To</div>
          <div>{values?.email}</div>
        </div>

        <div className="mt-14 text-xs">
          <table className="w-full py-5 border">
            <thead>
              <tr className="flex gap-2 py-3 px-2 bg-[#F2F2F2]">
                <th className=" w-3/12 text-left ">Item</th>
                <th className=" w-3/12 text-center ">Quantity</th>
                <th className=" w-3/12 text-right ">Unit Price (NGN)</th>
                <th className=" w-3/12 text-right ">Sub Total (NGN)</th>
              </tr>
            </thead>
            <tbody className="font-medium">
              {values?.items?.map((item: InvoiceItemsT, index: number) => (
                <tr
                  key={index}
                  className={`flex gap-2 py-1 px-2 border  ${
                    index % 2 == 1 ? "bg-[#F2F2F250]" : ""
                  } `}
                >
                  <td className=" w-3/12 break-words ">{item.item}</td>
                  <td className="  w-3/12 text-center">{item.quantity}</td>
                  <td className="  w-3/12 text-right ">
                    {formatWithCustomCurrency(item.unitPrice, "")}
                  </td>
                  <td className="  w-3/12 text-right ">
                    {formatWithCustomCurrency(
                      item.unitPrice * item.quantity,
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <hr className="border-black-default mt-20" />

        <div className="flex justify-between mt-10">
          <div className="font-semibold">Total</div>
          <div className="">{formatAmount(calculateTotal())}</div>
        </div>

        <div className="mt-8">
          <div className="font-medium">Notes</div>
          <div className="text-gray-default">{values?.notes}</div>
        </div>
      </div>
      <div className="mt-10 font-medium flex gap-6 justify-center items-center">
        <Button
          onClick={saveInvoice}
          loading={isSavingInvoice}
          disabled={isSavingInvoice}
          text="Save As Draft"
          variant="outline"
          className="w-auto px-3 py-1 rounded ring-gray-default outline-none text-gray-default"
        />
        <Button
          onClick={sendInvoice}
          loading={isSendingInvoice}
          disabled={isSendingInvoice}
          text="Send Invoice"
          className="w-auto px-3 py-1 rounded border"
        />
      </div>
    </div>
  )
}

export default ProfessionalInvoicePreview
