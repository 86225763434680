export const baseUrl = {
  authUrl: process.env.REACT_APP_AUTH_SERVICE,
  merchantUrl: process.env.REACT_APP_MERCHANT_SERVICE,
  userUrl: "http://localhost:3000",
  transactionsUrl: "http://localhost:3000",
  paymentUrl: process.env.REACT_APP_PAYMENT_SERVICE,
  auditUrl: process.env.REACT_APP_AUDIT_SERVICE,
  integrationUrl: process.env.REACT_APP_INTEGRATION_SERVICE,
  // wallet: process.env.REACT_APP_WALLET_SERVICE,
  wallet: "http://localhost:3000",
}
