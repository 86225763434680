import React from "react"
import Button from "../../../components/button"
import { ReactComponent as UserIcon } from "../../../assets/icons/users-light.svg"

type PropsT = {
  setCreateUserModal: (isOpen: boolean) => void
}

const NoUsersData = ({ setCreateUserModal }: PropsT) => {
  return (
    <div>
      <div className="flex justify-end mb-10">
        <Button
          text="+ Manage User Roles"
          variant="outline"
          className="w-max px-5 py-3 mr-5 rounded-md"
        />
      </div>

      <div className="flex flex-col justify-center items-center mt-40 mb-52">
        <div>
          <UserIcon />
        </div>
        <div className="font-semibold text-xl mt-5">No Added Users</div>
        <div className="mt-1 text-gray-default">
          Add other users to your account
        </div>
        <div className="mt-5">
          <Button
            text="+ Create A User"
            className="w-max px-5 py-3 mr-5 rounded-md"
            onClick={() => setCreateUserModal(true)}
          />
        </div>
      </div>
    </div>
  )
}

export default NoUsersData
