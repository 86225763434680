import {
  faArrowTrendUp,
  faPlus,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import Table from "components/table"
import AppLayout from "layout/private/app-layout"
import React, { useEffect } from "react"
import { walletTableColumns } from "./components/transactions/columns/wallets-table"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import WalletTabs from "./components/tabs"
import WalletWithdrawal from "./components/withdrawal"
import WalletTopup from "./components/topup"
import {
  closeTopupModal,
  closeWithdrawalModal,
  openTopupModal,
  openWithdrawalModal,
  setSelectedWallet,
} from "redux/wallet/walletSlice"
import { formatAmount } from "utils/format-amount"
import { useWallets } from "./hooks/useWallets"
import SectionLoader from "components/loader/section-loader"

const Wallets = () => {
  const dispatch = useAppDispatch()
  const { wallet } = useWallets()

  const { showTopupModal, showWithdrawalModal, selectedWallet } =
    useAppSelector((state) => state.wallet)

  useEffect(() => {
    setDefaultWalletOnLoad()
  }, [wallet.walletData])

  const setDefaultWalletOnLoad = () => {
    dispatch(setSelectedWallet(wallet.walletData[0]))
  }

  const initiateTopup = () => {
    dispatch(openTopupModal())
  }

  const onTopupClose = () => {
    wallet.refetch()
    dispatch(closeTopupModal())
  }

  const initiateWithdrawal = () => {
    dispatch(openWithdrawalModal())
  }

  const onWithdrawalClose = () => {
    dispatch(closeWithdrawalModal())
  }

  const handleWalletRefresh = () => {
    wallet.refetch()
    setDefaultWalletOnLoad()
  }

  return (
    <AppLayout title="">
      <div>
        <h1 className="font-bold mb-4 flex items-center gap-2">
          <span>Wallet Balances</span>{" "}
          <FontAwesomeIcon
            onClick={handleWalletRefresh}
            className="cursor-pointer"
            icon={faRefresh}
          />
        </h1>
        <div className="flex justify-center items-center bg-white-default p-4">
          <div className="border border-gray-100 p-4 w-full">
            <div className="flex justify-center mb-8">
              <WalletTabs />
            </div>
            <div className="text-center text-3xl text-gray-800 mb-4">
              Balance
            </div>
            <h2 className="text-center text-5xl font-medium">
              {formatAmount(
                selectedWallet?.balance,
                selectedWallet?.currencyCode
              )}
            </h2>
            <div className="flex justify-center gap-4 mt-8 py-4">
              <Button
                onClick={initiateTopup}
                text=" Add Money"
                className="w-[10rem]"
                variant="outline"
                icon={<FontAwesomeIcon icon={faPlus} />}
              />
              <Button
                onClick={initiateWithdrawal}
                text=" Withdraw"
                className="w-[10rem]"
                variant="outline"
                icon={<FontAwesomeIcon icon={faArrowTrendUp} />}
              />
            </div>
          </div>
        </div>
        <SectionLoader loading={wallet.loading} message="Fetching balances" />
      </div>
      <div className="mt-8">
        <h1 className="font-bold mb-4">Recent Transactions</h1>

        <Table
          columns={walletTableColumns}
          data={[]}
          isLoading={false}
          loadingSkeletonCount={10}
        />

        {showWithdrawalModal ? (
          <WalletWithdrawal
            handleClose={onWithdrawalClose}
            isOpen={showWithdrawalModal}
          />
        ) : null}

        {showTopupModal ? (
          <WalletTopup isOpen={showTopupModal} handleClose={onTopupClose} />
        ) : null}
      </div>
    </AppLayout>
  )
}

export default Wallets
