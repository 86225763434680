import { IntegrationApi } from "../api/integration"
import { IntegrationAxiosInstance } from "../instances/integration-instance"

export const validateIntegrationPaymentByAccessCode = async (
  accessCode: string
) => {
  const response = await IntegrationAxiosInstance.get(
    `${IntegrationApi.validatePaymentAccesscode}/${accessCode}`
  )
  return response
}
