import { AxiosError } from "axios"
import React, { useState } from "react"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { ReactComponent as BusinessLogo } from "../../../../assets/icons/upload-logo.svg"
import { uploadBusinessLogo } from "../../../../axios/services/merchant-services"

type PropsT = {
  businessId: number | undefined | null
}

const UploadBusinessLogo = ({ businessId }: PropsT) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const { mutate, isLoading } = useMutation(uploadBusinessLogo, {
    onSuccess: () => {
      toast.success("Upload Successful")
      // queryClient.invalidateQueries("fetchKycDocs");
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const handleDrop = (file: File[]) => {
    if (file[0].type !== "image/png") {
      toast.error("Please select only PNG image files.")
      return
    }
    setSelectedFile(file[0])
  }

  const handleClearFile = () => {
    setSelectedFile(null)
  }

  const handleUpload = () => {
    if (!selectedFile) {
      toast.error("Please select an image file.")
      return
    }

    const formData = new FormData()
    // @ts-ignore
    formData.append("businessId", businessId?.toString())
    formData.append("logoFile", selectedFile)
    mutate(formData)
  }

  const dropzoneOptions: DropzoneOptions = {
    accept: {
      "image/png": [],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error("Please select only PNG image files.")
      } else {
        handleDrop(acceptedFiles)
      }
    },
    multiple: false,
  }

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions)

  return (
    <div className="flex flex-col items-center">
      {!selectedFile ? (
        <div
          {...getRootProps()}
          className="w-full h-full flex flex-col items-center"
        >
          <input {...getInputProps()} />
          <h3 className="font-semibold mb-3">Business Logo</h3>
          <div className="h-52 w-52 bg-primary-400 cursor-pointer custom-border flex justify-center items-center">
            <BusinessLogo />
          </div>
        </div>
      ) : (
        <div className="relative">
          <img
            src={URL.createObjectURL(selectedFile)}
            alt="Selected File"
            className="h-52 w-52 object-cover"
          />
          <button
            onClick={handleClearFile}
            className="absolute top-0 right-0 bg-danger-default text-white-default w-6 h-6 rounded-full"
          >
            &times;
          </button>
        </div>
      )}
      {selectedFile && (
        <button
          onClick={handleUpload}
          className="mt-4 bg-white-900 border border-primary-default text-white py-2 px-4 rounded"
          disabled={isLoading}
        >
          {isLoading ? "Uploading..." : "Upload"}
        </button>
      )}
    </div>
  )
}

export default UploadBusinessLogo
