import AppLayout from "layout/private/app-layout"
import React from "react"
import NoDisputesData from "./components/no-disputes-data"
import { queryKeys } from "constants/queryKeys"
import useDisputes from "./hooks/useDisputes"
import PaginationInfo from "components/Pagination/paginationInfo"
import { Pagination } from "components/Pagination/PaginationComponent"
import Table from "components/table"
import DisputesFilter from "./filter/disputesFilter"
import { DisputesColumns } from "./table/columns"

const Disputes = () => {
  const { filter, disputes, pagination, exportManager, search } = useDisputes(
    queryKeys.disputes.fetchDisputeRecords
  )
  return (
    <AppLayout title="Disputes" subTitle="Manage raised transactions disputes">
      <div className="mb-10">
        {disputes.disputeList?.length === 0 ? (
          <div>
            <NoDisputesData />
          </div>
        ) : (
          <>
            <div className="flex items-center justify-end gap-6 pt-2">
              <DisputesFilter filter={filter} search={search} />
            </div>
            <div className="mt-4 border border-[#D3D3D3]">
              <Table
                columns={DisputesColumns}
                data={disputes.disputeList}
                isLoading={disputes.isLoading}
                loadingSkeletonCount={10}
              />
              {disputes.disputeList?.length > 0 ? (
                <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
                  <PaginationInfo pagination={pagination} />

                  <div>
                    <Pagination pagination={pagination} />
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </AppLayout>
  )
}

export default Disputes
