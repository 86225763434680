import * as yup from "yup"

export const BasicInvoiceSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter customer email"),
  name: yup.string().required("Please enter customer name"),
  notes: yup.string(),
  amount: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(0, "Amount must be greater than or equal to 0")
    .required("Amount is required"),
})
