import ReactDOM from "react-dom/client"
import "./index.scss"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import React from "react"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "react-query"
import { store, persistor } from "./redux/store"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-loading-skeleton/dist/skeleton.css"
import "react-datepicker/dist/react-datepicker.css"
import ErrorBoundary from "./utils/error-boundary"
import { PersistGate } from "redux-persist/integration/react"
// require("./axios/mocks")

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    },
  },
})

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return
  }

  const { worker } = await import("./axios/mocks/browser")

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({ onUnhandledRequest: "bypass" })
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
              <App />
              <ToastContainer />
            </ErrorBoundary>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
