import React, { useEffect, useState } from "react"
import Button from "../../components/button"
import AuthLayout from "../../layout/public/authLayout"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import OtpInput from "../../components/input/otpInput"
import { ReactComponent as Back } from "../../assets/icons/back.svg"
import { ReactComponent as ResendIcon } from "../../assets/icons/resend.svg"
import "./2fa.scss"
import { useMutation } from "react-query"
import { regerateOtp, validateOtp } from "../../axios/services/auth-services"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { DecodeJwt } from "../../utils/decode-jwt"
import { saveProfileDetails } from "../../redux/profile/profileSlice"
import { useAppDispatch } from "../../redux/hooks"
import CountdownTimer from "../../components/countdown-timer"
import { getBusinessData } from "hooks/useLogin"

const TwoFactorAuth = () => {
  const navigate = useNavigate()
  const [otp, setotp] = useState<string>()
  const [error, setError] = useState<string>("")
  const [timeLeft, setTimeLeft] = useState(30)

  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()

  const accessKey = searchParams.get("accessKey")

  const OTPFieldValue = (otp: string[]) => {
    setotp(otp.join(""))
  }

  const { mutate: regenerateUserOtp, isLoading: isRegeneratingOtp } =
    useMutation(regerateOtp, {
      onSuccess: (response) => {
        toast.success("OTP resent successfully!")
        setTimeLeft(30)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    })

  const handleRegenerateOtp = () => {
    regenerateUserOtp({
      accessKey: accessKey,
    })
  }

  const { mutate: validateUserOtp, isLoading } = useMutation(validateOtp, {
    onSuccess: async (response) => {
      const expiresIn = 90000
      const expirationTime = new Date().getTime() + expiresIn * 1000
      sessionStorage.setItem("expiration-time", expirationTime.toString())
      sessionStorage.setItem("access-token", response.data.data.accessToken)
      sessionStorage.setItem("id", response.data.data.id)
      sessionStorage.setItem("refresh-token", response.data.data.refreshToken)
      const decoded = DecodeJwt(response.data.data.accessToken)
      dispatch(saveProfileDetails(decoded))

      // @ts-ignore
      await getBusinessData(decoded.userDetails.businessId, dispatch)

      navigate("/")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const handleValidation = () => {
    validateUserOtp({
      accessKey: accessKey,
      otp: otp,
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (otp?.length !== 6) {
      return setError("Please enter complete otp to continue")
    } else {
      handleValidation()
    }
  }

  useEffect(() => {
    if (timeLeft === 0) {
      return // Don't start a new countdown if the time has run out
    }
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1) // Decrease the time by one second
    }, 1000) // Run the countdown every 1000ms (1 second)
    return () => clearInterval(countdown) // Clear the countdown interval on component unmount
  }, [timeLeft])

  return (
    <AuthLayout
      title="Two-Factor Authentication"
      content={
        <form className="px-10" onSubmit={handleSubmit}>
          <div className="mb-8 text-center">
            Enter the authentication code generated by the authenticator app to
            log in.
          </div>
          <OtpInput
            fieldCount={6}
            onChange={(otp: string[]) => {
              OTPFieldValue(otp)
              setError("")
            }}
          />
          {error && (
            <div className="mt-2 text-center text-danger-default">{error}</div>
          )}
          <div className="mt-14">
            <Button
              loading={isLoading}
              disabled={isLoading}
              text="Proceed to Log in"
            />
          </div>

          {/* Resend OTP */}
          <div className="mt-14">
            {timeLeft !== 0 ? (
              <div className="flex justify-center text-center text-primary-default mt-5">
                <span className="text-black-default mr-1">Resend code in </span>{" "}
                <span>
                  {/* Enter the same time in "timeLeft" */}
                  <CountdownTimer minutes={0} seconds={30} />
                </span>
              </div>
            ) : (
              <div className="text-center text-primary-default font-semibold mt-5">
                <button
                  onClick={handleRegenerateOtp}
                  type="button"
                  className="hover:underline hover:font-medium"
                >
                  <div className="flex items-center">
                    <ResendIcon className="h-5 w-5 mr-2" />{" "}
                    <span>Resend Code</span>
                  </div>
                </button>
              </div>
            )}
          </div>

          <div className="text-center flex items-center justify-center mt-6">
            <span className="mr-2">
              <Back />
            </span>
            <Link className="text-primary-default" to="/login">
              Go Back
            </Link>
          </div>
        </form>
      }
    />
  )
}

export default TwoFactorAuth
