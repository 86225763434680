import Button from "components/button"
import BackArrow from "components/button/back-arrow"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import PayoutTransactions from "./PayoutTransactions"
import Status from "components/status/Status"
import AppLayout from "layout/private/app-layout"
import { formatAmount } from "utils/format-amount"
import useSettlement from "../hooks/useSettlement"
import { queryKeys } from "constants/queryKeys"

interface DetailsPDFProps {
  forwardedRef: React.RefObject<HTMLDivElement>
}

const SettlementDetailsPDF: React.FC<DetailsPDFProps> = ({ forwardedRef }) => {
  const [searchParams] = useSearchParams()
  const settlementId = searchParams.get("settlementId")

  const { detailsData } = useSettlement(
    queryKeys.settlement.fetchSettlementDetails,
    Number(settlementId)
  )

  const merchantId: string = searchParams.get("merchantId") ?? ""

  const personalData = {
    "Settlement Reference": detailsData?.reference,
    "Transaction Amount": formatAmount(detailsData?.totalTransactionAmount),
    "Settlement Amount": formatAmount(detailsData?.totalPayoutAmount),
    "Settlement Date": detailsData?.settlementDate,
    "Payout Status": (
      <Status variant={detailsData?.status}>{detailsData?.status}</Status>
    ),
    "Transactions in Settlement Batch": detailsData?.numberOfTransactions,
  }
  return (
    <div ref={forwardedRef} className="hidden">
      <div className="bg-white-default">
        <div className="flex justify-between p-6">
          <div className="">
            <div className="text-base font-medium">Settlement Details</div>
            <div className="text-sm text-gray-default">
              Details for settlement reference{" "}
              {personalData["Settlement Reference"]}
            </div>
          </div>
        </div>

        <hr />

        <div></div>
        <div className="flex flex-wrap p-6 text-sm text-gray-default">
          {Object.entries(personalData).map(([label, value]) => (
            <div key={label} className="w-[50%] md:w-[20%] px-2 mt-4">
              <div>{label}</div>
              <div className="text-black-default">{value}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="border border-gray-700 bg-white-default mt-10 rounded">
        <PayoutTransactions forPrint settlementId={Number(settlementId)} />
      </div>
      <div className="text-center p-3">Payout transactions</div>
    </div>
  )
}

export default SettlementDetailsPDF
