import React from "react"
import PayViaTransfer from "components/payment/payment-methods/transfer"
import CardDetails from "pages/make-payment/card-details"
import { PaymentComponentProps } from "types/payment"
import { PAYMETHOD_IDS } from "constants/payment"
import PayViaBank from "components/payment/payment-methods/bank"

const paymentComponents: { [key: string]: React.FC<PaymentComponentProps> } = {
  [PAYMETHOD_IDS.CREDIT_CARD]: CardDetails,
  [PAYMETHOD_IDS.PAY_VIA_TRANSFER]: PayViaTransfer,
  [PAYMETHOD_IDS.PAY_VIA_BANK]: PayViaBank,
}

export function getPaymentComponent(
  paymentMethod: string
): React.FC<PaymentComponentProps> | null {
  return paymentComponents[paymentMethod] || null
}
