import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm, FieldValues } from "react-hook-form"
import BasicInvoiceForm from "./components/invoice-form"
import BasicInvoicePreview from "./components/preview"
import { BasicInvoiceSchema } from "./validation-schema"
import { useMutation } from "react-query"
import {
  createInvoiceAndSave,
  createInvoiceAndSend,
} from "../../../../axios/services/payment-services"
import { queryClient } from "index"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import { useNavigate } from "react-router-dom"

const CreateBasicInvoice = () => {
  const methods = useForm({
    resolver: yupResolver(BasicInvoiceSchema),
  })
  const navigate = useNavigate()

  const profileDetails = useAppSelector((state) => state.profile.profile)
  const businessDetails = useAppSelector(
    (state) => state.profile.businessProfile
  )

  const { handleSubmit, watch } = methods
  const values = watch()

  const { mutate: saveNewInvoice, isLoading: isSavingInvoice } = useMutation(
    createInvoiceAndSave,
    {
      onSuccess: () => {
        toast.success("Invoice saved!")
        navigate("/invoicing")
        queryClient.invalidateQueries("fetchInvoices")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    }
  )

  const { mutate: sendNewInvoice, isLoading: isSendingInvoice } = useMutation(
    createInvoiceAndSend,
    {
      onSuccess: () => {
        toast.success("Invoice sent!")
        navigate("/invoicing")
        queryClient.invalidateQueries("fetchInvoices")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        return
      },
    }
  )

  const sendInvoice = (values: FieldValues) => {
    const payload = {
      businessId: profileDetails?.businessId,
      invoiceType: "basic",
      customerEmail: values.email,
      customerName: values.name,
      notes: values.notes,
      amount: values.amount,
      currency: "NGN",
    }
    sendNewInvoice(payload)
  }

  const saveInvoice = () => {
    const payload = {
      businessId: profileDetails?.businessId,
      invoiceType: "basic",
      customerEmail: values.email,
      customerName: values.name,
      notes: values.notes,
      amount: values.amount,
      currency: "NGN",
    }
    saveNewInvoice(payload)
  }

  return (
    <div className="flex gap-10 mb-20">
      {/* Enter Details */}
      <BasicInvoiceForm methods={methods} />
      {/* Preview */}
      <BasicInvoicePreview
        values={values}
        isSavingInvoice={isSavingInvoice}
        isSendingInvoice={isSendingInvoice}
        saveInvoice={handleSubmit(saveInvoice)}
        sendInvoice={handleSubmit(sendInvoice)}
        businessDetails={businessDetails}
      />
    </div>
  )
}

export default CreateBasicInvoice
