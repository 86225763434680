import React from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Button from "../../components/button"
import Input from "../../components/input/input"
import { resetValidationSchema } from "./validation-schema"
import { useNavigate, useSearchParams } from "react-router-dom"
import AuthLayout from "../../layout/public/authLayout"
import { AxiosError } from "axios"
import { useMutation, useQuery } from "react-query"
import {
  resetPassword,
  validateAccessCode,
} from "../../axios/services/auth-services"
import { toast } from "react-toastify"
import FullScreenLoader from "components/loader/fullscreen-loader"

const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const methods = useForm({
    resolver: yupResolver(resetValidationSchema),
  })

  const { handleSubmit } = methods

  const accessKey = searchParams.get("code")

  const { isFetching } = useQuery(
    "validate-access-code",
    () => validateAccessCode(accessKey),
    {
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
        navigate("/forgot-password")
      },
    }
  )

  const { mutate: resetUserPassword, isLoading } = useMutation(resetPassword, {
    onSuccess: () => {
      navigate("/reset-success")
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
      if (
        //@ts-ignore
        error.response?.data.message ===
        "Password reset link expired, kindly reattempt password reset"
      ) {
        navigate("/forgot-password")
      }
    },
  })

  const handleReset = (data: FieldValues) => {
    resetUserPassword({
      accessKey: accessKey,
      password: data.password,
      repeatPassword: data.confirmPassword,
    })
  }

  return (
    <AuthLayout
      title="Reset Password"
      content={
        <>
          {isFetching ? (
            <FullScreenLoader
              loading={isFetching}
              message="Verifying access code"
            />
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(handleReset)}>
                <div className="mb-4">
                  <Input label="Password" name="password" type="password" />
                </div>
                <div className="mb-4">
                  <Input
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                  />
                </div>

                <div className="w-100 mt-20">
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    text="Continue"
                  />
                </div>
              </form>
            </FormProvider>
          )}
        </>
      }
    />
  )
}

export default ResetPassword
