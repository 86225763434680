import * as yup from "yup"
const reg =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const testWebhookSchema = yup.object().shape({
  testWebhookUrl: yup
    .string()
    .nullable()
    .matches(reg, "Please enter a valid URL")
    .required("Webhook Url is required"),
  testCallbackUrl: yup
    .string()
    .nullable()
    .matches(reg, "Please enter a valid URL")
    .required("Callback Url is required"),
  testPublicKey: yup.string().nullable(),
  testPrivateKey: yup.string().nullable(),
})

export const liveWebhookSchema = yup.object().shape({
  liveWebhookUrl: yup
    .string()
    .nullable()
    .matches(reg, "Please enter a valid URL")
    .required("Webhook Url is required"),
  liveCallbackUrl: yup
    .string()
    .nullable()
    .matches(reg, "Please enter a valid URL")
    .required("Callback Url is required"),
  livePublicKey: yup.string().nullable(),
  livePrivateKey: yup.string().nullable(),
})
