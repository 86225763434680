import React, { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { queryKeys } from "constants/queryKeys"
import { useAppSelector } from "redux/hooks"
import { useForm } from "react-hook-form"
import {
  exportTransactionsrecords,
  fetchTransactiondetails,
  fetchTransactions,
} from "../../../axios/services/transactions"
import { encodeSearchParams } from "utils/encodeSearchParams"

const useTransactions = (queryKey: string, transactionId?: string | null) => {
  const [pageSize] = React.useState(10)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [searchParams, setSearchParams] = useState<{
    status?: { label: string; value: string | undefined }
    startDate?: string
    endDate?: string
    search?: string | undefined
    paymentChannelId?: number
  }>({
    status: { label: "All", value: undefined },
    startDate: "",
    endDate: "",
    paymentChannelId: 0,
  })

  const authUser = useAppSelector((state) => state)

  const { data: transactionResponse, isLoading } = useQuery(
    [
      queryKeys.transactions.fetchTransactions,
      currentPage,
      pageSize,
      searchParams,
    ],
    () =>
      fetchTransactions({
        pageSize,
        currentPage,
        merchantId: authUser?.profile?.profile?.businessId ?? 0,
        search: encodeSearchParams({
          status: searchParams.status?.value,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          transactionReference: searchParams?.search?.trim(),
        }),
      }),
    {
      enabled: queryKey === queryKeys.transactions.fetchTransactions,
    }
  )

  const { data: detailsResponse, isLoading: loadingDetails } = useQuery(
    [queryKeys.transactions.fetchTransactionDetails, currentPage],
    () =>
      fetchTransactiondetails({
        transactionId,
        merchantId: authUser?.profile?.profile?.businessId ?? 0,
      }),
    {
      enabled:
        !!transactionId &&
        queryKey === queryKeys.transactions.fetchTransactionDetails,
    }
  )

  const { mutate: exportTransactions, isLoading: isExporting } = useMutation(
    exportTransactionsrecords,
    {
      onSuccess: (response, { type }) => {
        // Create a blob object from the response data
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        })

        // Create a download link for the blob and click it
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = `Transactions ${new Date()}.${
          type === "excel" ? "xlsx" : type
        }`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      },
      onError: () => {
        //@ts-ignore
        toast.error("Unable to export file, try again")
      },
    }
  )

  const handleExport = (downloadType: string) => {
    exportTransactions({
      merchantId: authUser?.profile?.profile?.businessId ?? 0,
      type: downloadType,
      filter: encodeSearchParams({
        ...searchParams,
        status: searchParams.status?.value,
      }),
    })
  }

  const updateSearchParams = (
    payload: React.SetStateAction<{
      startDate?: string
      endDate?: string
      search?: string | undefined
    }>
  ) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  const onFilter = (data: any) => {
    updateSearchParams({
      ...data,
    })
  }

  const onSearch = (data: any) => {
    setSearchParams({
      ...searchParams,
      ...data,
    })
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const transactionList: Transaction[] =
    transactionResponse?.data?.data?.content

  const totalElements = transactionResponse?.data?.data?.totalElements
  const totalPages = transactionResponse?.data?.data?.totalPages

  const filterMethods = useForm()
  const searchMethods = useForm()

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  const search = {
    searchMethods,
    handleSearch,
    onSearch,
    updateSearchParams,
    searchParams,
  }

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: filterMethods.reset,
  }

  const exportManager = {
    showExportModal,
    setShowExportModal,
    handleExport,
    isExporting,
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const transaction = { transactionList, isLoading }
  const transactionDetails = {
    loadingDetails,
    data: detailsResponse?.data?.data as Transaction,
  }

  return {
    transaction,
    search,
    filter,
    pagination,
    exportManager,
    transactionDetails,
  }
}

export default useTransactions
