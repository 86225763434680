import React from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { formatAmount, formatWithCustomCurrency } from "utils/format-amount"

const columnHelper = createColumnHelper<PayoutTransaction>()

export const settlementTxColumns = [
  columnHelper.accessor("tgipayReference", {
    cell: (info) => <div>{info.renderValue()?.toUpperCase()}</div>,
    header: () => <div className="text-center">Reference</div>,
  }),
  columnHelper.accessor((row) => row.amount, {
    id: "customerName",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell

      return <span>{originalData.customerEmail}</span>
    },
    header: () => <span>Customer Name</span>,
  }),
  columnHelper.accessor("amount", {
    header: () => <div className="text-center">Transaction Amount (NGN)</div>,
    cell: (info) => (
      <div className="text-center">
        {formatWithCustomCurrency(Number(info.renderValue()), "")}
      </div>
    ),
  }),
  columnHelper.accessor("fee", {
    header: () => <div className="text-center">Transaction Fee (NGN)</div>,
    cell: (info) => (
      <div className="text-center">
        {formatWithCustomCurrency(Number(info.renderValue()), "")}
      </div>
    ),
  }),
  columnHelper.accessor("income", {
    header: () => <div className="text-center">Settlement (NGN)</div>,
    cell: (info) => (
      <div className="text-center">
        {formatWithCustomCurrency(Number(info.renderValue()), "")}
      </div>
    ),
  }),
  columnHelper.accessor("transactionDate", {
    header: () => <div className="text-center">Date</div>,
    cell: (info) => <div className=" text-center">{info.renderValue()}</div>,
  }),
]
