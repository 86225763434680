import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Navlist } from "./navList"
import logo from "../../assets/images/TGIPrimaryLogo.svg"
import { ReactComponent as Logout } from "../../assets/icons/logout.svg"
import PermissionGate from "components/permission-gate/PermissionGate"
import { useAppSelector } from "redux/hooks"
import { toast } from "react-toastify"
import { useLogout } from "hooks/useLogout"
import FullScreenLoader from "components/loader/fullscreen-loader"

const Sidebar = () => {
  const location = useLocation()
  const { logoutUser, isLoggingUserOut } = useLogout()
  const businessDetails = useAppSelector(
    (state) => state.profile.businessProfile
  )

  const handleNavClick = (kycApproved: boolean, link: string) => {
    if (
      kycApproved === false &&
      link !== "/" &&
      link !== "/kyc" &&
      link !== "/settings"
    ) {
      toast.error("You have to complete your KYC to have access to this module")
    }
  }
  return (
    <div className="flex flex-col text-sm h-screen">
      <div className="mt-14 mb-12 px-4">
        <img src={logo} className="w-[80px]" alt="tgipay-logo" />
      </div>
      <div className=" overflow-y-scroll hide-scrollbar">
        {Navlist.map(
          ({ link, name, Icon, allowedPermissions, kycRequired }, index) => {
            if (!allowedPermissions)
              return (
                <div
                  key={index}
                  onClick={() => handleNavClick(businessDetails.approved, link)}
                >
                  <Link
                    key={index}
                    className={`bg-white-default hover:bg-primary-default/5 px-4 py-4 flex items-center ${
                      location.pathname.split("/")[1] === link.split("/")[1] &&
                      "border-l-4 border-l-primary-default font-bold text-primary-default"
                    }`}
                    to={
                      kycRequired
                        ? businessDetails.approved
                          ? link
                          : "/#"
                        : link
                    }
                  >
                    <Icon className="mr-3 w-4" />
                    <span>{name}</span>
                  </Link>
                </div>
              )

            return (
              <PermissionGate
                allowedPermissions={allowedPermissions}
                key={"permission_gate" + index}
              >
                <div
                  onClick={() => handleNavClick(businessDetails.approved, link)}
                >
                  <Link
                    className={`bg-white-default hover:bg-primary-default/5 px-4 py-4 flex items-center ${
                      location.pathname.split("/")[1] === link.split("/")[1] &&
                      "border-l-4 border-l-primary-default font-bold text-primary-default"
                    }`}
                    to={
                      kycRequired
                        ? businessDetails.approved
                          ? link
                          : "/#"
                        : link
                    }
                  >
                    <Icon className="mr-3 w-4" />
                    <span>{name}</span>
                  </Link>
                </div>
              </PermissionGate>
            )
          }
        )}

        <Link
          onClick={() => logoutUser()}
          className={`bg-white-default hover:bg-primary-default/5 text-black-default px-4 py-4 flex items-center mt-10 mb-10`}
          to={"/login"}
        >
          <Logout className="mr-3" />
          <span>Log Out</span>
        </Link>
      </div>

      <>
        {isLoggingUserOut && (
          <FullScreenLoader loading={isLoggingUserOut} message="Logging out" />
        )}
      </>
    </div>
  )
}

export default Sidebar
