import React from "react"
import DashboardCard from "./components/dashboard-card"
import useDashboard from "./hooks/useDashboard"
import { queryKeys } from "constants/queryKeys"
import { formatAmount, formatNumber } from "utils/format-amount"

type MetricsT = {
  title: string
  value: string | number
}

interface PropsT {
  startDate: string
  endDate: string
  dateGrouping: string
  merchantId: number | null | undefined
}

const DashboardCards = ({
  startDate,
  endDate,
  dateGrouping,
  merchantId,
}: PropsT) => {
  const { data, loading } = useDashboard({
    startDate,
    endDate,
    targetQuerykeys: [
      queryKeys.dashboard.totalValueByDate,
      queryKeys.dashboard.countSuccessfulByDate,
      queryKeys.dashboard.countByDate,
      queryKeys.dashboard.allSuccessfulByValuePoints,
      queryKeys.dashboard.successfulCountPointsByDate,
      queryKeys.dashboard.countFailedByDate,
      queryKeys.dashboard.totalPendingSettlement,
    ],
    dateGrouping,
    merchantId,
  })

  return (
    <div className="flex flex-wrap gap-11">
      <DashboardCard
        title="Total Transaction Value"
        value={formatAmount(data.totalValueByDate).replace("NGN", "N")}
        loading={loading.loadingTotalValueByDate}
        className="bg-gradient-to-r from-primary-default  to-black-default text-white-900"
      />
      <DashboardCard
        title="Pending Settlement"
        value={formatNumber(data.pendingSettlement)}
        loading={loading.loadingPendingSettlement}
        className="bg-gradient-to-r from-yellow-500  to-yellow-600 text-white-900"
      />
      <DashboardCard
        title="Total Transactions"
        value={formatNumber(data.countByDate)}
        loading={loading.loadingCountByDate}
        className="bg-gradient-to-r from-secondary-500  to-secondary-700 text-white-900"
      />
      <DashboardCard
        title="Successful Transactions"
        value={formatNumber(data.countSuccessfulByDate)}
        loading={loading.loadingcountSuccessfulByDate}
        className="bg-gradient-to-r from-success-500 to-success-300 text-white-900"
      />
      <DashboardCard
        title="Failed Transactions"
        value={formatNumber(data.countFailedByDate)}
        loading={loading.loadingCountFailedByDate}
        className="bg-gradient-to-r from-pink-600 to-pink-400 text-white-900"
      />
    </div>
  )
}

export default DashboardCards
