import React, { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import FullScreenLoader from "components/loader/fullscreen-loader"

import { useQuery } from "react-query"
import { validateIntegrationPaymentByAccessCode } from "../../axios/services/integration-services"
import { setIntegrationData } from "redux/paymethods/paymethodsSlice"
import { useAppDispatch } from "redux/hooks"

const PayNow = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()

  const { isFetching } = useQuery(
    "fetchIntegrationServiceCode",
    () => {
      //@ts-expect-error
      return validateIntegrationPaymentByAccessCode(params.id)
    },
    {
      onSuccess: (response) => {
        const linkResponse = response.data?.data
        dispatch(setIntegrationData(linkResponse))
        setTimeout(() => {
          window.location.href = "/pay-now/integration"
        }, 0)
      },
      onError: () => {
        navigate("/pay-now/invalid-link")
      },
    }
  )

  return (
    <div className="flex flex-col items-center card !shadow-none !border-none bg-gray-700 h-full self-center">
      <FullScreenLoader
        loading={isFetching}
        message="Loading Payment Information"
      />
    </div>
  )
}

export default PayNow
