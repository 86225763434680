export const walletApi = {
  getWalletsByMerchantId: "api/v1/wallet/get-wallet-by-merchantId",
  payByTransfer: "api/v1/wallet/pay-by-transfer",
  payByTransferInternal: "api/v1/wallet/pay-by-transfer-internal",
  verifyTransfer: "api/v1/wallet/pay-by-transfer-status",
  verifyTransferInternal: "api/v1/wallet/pay-by-transfer-status-internal",
  initiateDirectDebit: "api/v1/wallet/initiate-pwba",
  getDirectDebitStatus: "/api/v1/wallet/get-pwba-status",
  directDebitNotification: "api/v1/third-party/direct-debit-notification",
}
