export type PaymentRequestBase = {
  forPaymentLinkRequest?: boolean
  forInvoiceRequest?: boolean
  forPaymentIntegrationRequest?: boolean
  forWalletTopUp?: boolean
  paymentChannel?: PaymentChannel
}

export interface CustomerPaymentLinkRequest {
  id?: number
  businessId: number
  paymentLinkId: number
  firstName: string
  lastName: string
  email: string
  amount: number
}

export interface InvoiceRequest {
  id: number
  businessId: number
  invoiceType: string
  customerName: string
  customerEmail: string
  notes: string
  amount: number
  currency: string
  dueDate: string
  invoiceItems?: InvoiceItemsEntity[] | null
}

// export interface invoiceRequest {}

export interface InvoiceItemsEntity {
  id: number
  item: string
  quantity: number
  unitPrice: number
}

export interface PaymentIntegrationRequest {
  id: number
  businessId: number
  customerFirstName: string
  customerLastName: string
  customerEmail: string
  amount: number
  transactionReference: string
  currency: string
}

export interface WalletTopUpRequest {
  businessId: number
  amount: number
}

export type PaymentLinkRequestData = PaymentRequestBase & {
  customerPaymentLinkRequest: CustomerPaymentLinkRequest
}

export type WalletTopUpRequestData = PaymentRequestBase & {
  cardTopUpRequest: WalletTopUpRequest
}

export type InvoiceRequestData = PaymentRequestBase & {
  invoiceRequest: any
}

export type IntegrationRequestData = PaymentRequestBase & {
  paymentIntegrationRequest: PaymentIntegrationRequest
}

export type PaymentRequestData =
  | PaymentLinkRequestData
  | WalletTopUpRequestData
  | InvoiceRequestData
  | IntegrationRequestData

export enum PaymentChannel {
  PaymentLink = "paymentLink",
  WalletTopUp = "walletTopUp",
  Invoice = "invoice",
  Integration = "integration",
}
