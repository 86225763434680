import React, { useState } from "react"
import { useQuery } from "react-query"
import { Pagination } from "../../components/Pagination/PaginationComponent"
import Table from "../../components/table"
import AppLayout from "../../layout/private/app-layout"
import { usersColumns } from "./columns/teamsTable"
import NoUsersData from "./components/no-users-data"
import CreateNewUser from "./components/create-new-user"
import { useAppSelector } from "../../redux/hooks"
import { fetchAllUserAccounts } from "../../axios/services/auth-services"
import TeamsActions from "./users-actions"
import PaginationInfo from "../../components/Pagination/paginationInfo"

const Teams = () => {
  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [createUserModal, setCreateUserModal] = useState<boolean>(false)

  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { data: merchantUsers, isFetching: isFetchingUsers } = useQuery(
    ["fetchMerchantUsers", currentPage],
    () =>
      fetchAllUserAccounts(profileDetails?.businessId, currentPage, pageSize)
  )

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const usersList: Users[] = merchantUsers?.data?.data?.content
  const totalElements = merchantUsers?.data?.data?.totalElements
  const totalPages = merchantUsers?.data?.data?.totalPages

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  return (
    <AppLayout title="Teams" subTitle="Manage roles and users on your account.">
      {usersList?.length === 0 ? (
        <NoUsersData setCreateUserModal={setCreateUserModal} />
      ) : (
        <div>
          <TeamsActions
            totalElements={totalElements}
            setCreateUserModal={setCreateUserModal}
          />
          <div className="mt-4 border border-[#D3D3D3] mb-20">
            <Table
              columns={usersColumns}
              data={usersList}
              isLoading={isFetchingUsers}
              loadingSkeletonCount={10}
            />
            <div className="flex h-[69px] items-center justify-between bg-white-default px-5">
              <PaginationInfo pagination={pagination} />

              <div>
                <Pagination pagination={pagination} />
              </div>
            </div>
          </div>
        </div>
      )}
      {createUserModal && (
        <CreateNewUser
          closeModal={() => setCreateUserModal(false)}
          isOpen={createUserModal}
        />
      )}
    </AppLayout>
  )
}

export default Teams
