import React from "react"
import "./input.scss"
import { ErrorMessage } from "@hookform/error-message"
import { useFormState, useFormContext } from "react-hook-form"

type TextAreaPropsT = {
  label: string
  name: string
  id?: string
  placeholder?: string
  disabled?: boolean
}

const TextArea = ({
  label,
  name,
  id,
  placeholder,
  disabled = false,
}: TextAreaPropsT) => {
  const { errors } = useFormState()
  const { register } = useFormContext()

  return (
    <div className="input">
      <label className="input_label">{label}</label>
      <div
        className={`input_wrapper ${
          errors[name]?.message !== undefined && "error"
        } ${disabled ? "disabled" : null}`}
      >
        <textarea
          className="input_field"
          placeholder={placeholder}
          id={id}
          rows={3}
          {...register(name)}
        />
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className="mt-1 text-[11px] text-danger-default">{message}</div>
        )}
      />
    </div>
  )
}

export default TextArea
