import { queryClient } from "index"
import {
  fetchInvoiceDetails,
  sendInvoice,
  sendInvoiceReminder,
} from "../../../axios/services/payment-services"
import { useMutation, useQuery } from "react-query"
import { toast } from "react-toastify"

export const useInvoicing = (businessId: number, invoiceId: number) => {
  const { mutate: sendReminderMutation, isLoading: isSendingReminder } =
    useMutation(() => sendInvoiceReminder(businessId, invoiceId), {
      onSuccess: () => {
        toast.success("Reminder sent successfully!")
      },
      onError: () => {
        toast.error("Unable to send reminder")
      },
    })

  const handleSendInvoiceReminder = () => {
    sendReminderMutation()
  }

  const { mutate: sendInvoiceToCustomer, isLoading: isSendingInvoice } =
    useMutation(() => sendInvoice(businessId, invoiceId), {
      onSuccess: () => {
        toast.success("Invoice sent!")
        queryClient.invalidateQueries("fetchInvoices")
      },
      onError: () => {
        toast.error("Unable to send invoice")
      },
    })

  const handleSendInvoice = () => {
    sendInvoiceToCustomer()
  }

  const { data: invoiceDetails, isFetching: isFetchingDetails } = useQuery(
    "viewInvoiceDetails",
    () => fetchInvoiceDetails(businessId, invoiceId),
    {
      onError: () => {
        toast.error("Unable to fetch Invoice Details")
      },
    }
  )

  return {
    invoiceDetails,
    isFetchingDetails,
    isSendingReminder,
    isSendingInvoice,
    handleSendInvoiceReminder,
    handleSendInvoice,
  }
}
