import React from "react"
import Modal from "../../../components/modal"
import { ReactComponent as SuccessIcon } from "../../../assets/icons/green-check.svg"

interface ModalPropsT {
  closeModal: () => void
  isOpen: boolean
  twoFactor: boolean
}

const SuccessModal = ({ closeModal, isOpen, twoFactor }: ModalPropsT) => {
  const handleSubmit = () => {
    closeModal()
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen} maxWidth="max-w-[45rem]">
      <div className="text-center">
        <div className="flex justify-center mb-10">
          <SuccessIcon />
        </div>
        <h2 className="font-medium text-2xl mb-4">
          Two Factor Authentication {!twoFactor ? "Enabled" : "Disabled"}
        </h2>
        <p className="font-light text-lg mb-3">
          Two-factor authentication has been {!twoFactor ? "enable" : "disable"}{" "}
          on your account.
        </p>
        <div className="flex flex-col justify-center items-center mt-10">
          <button
            onClick={handleSubmit}
            className="bg-primary-default text-white-default px-5 py-2 rounded w-[7rem]"
          >
            Okay
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SuccessModal
