import React from "react"
import Button from "components/button"
import { ReactComponent as Logo } from "../../../../../assets/images/TGIPrimaryLogo.svg"
import { getCurrentDate } from "utils/getDateAndTime"
import { formatAmount } from "utils/format-amount"
import { FieldValues } from "react-hook-form"

interface PropsT {
  values: FieldValues
  invoiceData: InvoiceDetailsT
  isEditingInvoice: boolean
  handleUpdate: () => void
}

const EditBasicInvoicePreview = ({
  values,
  invoiceData,
  isEditingInvoice,
  handleUpdate,
}: PropsT) => {
  return (
    <div className="w-[50%] md:w-[35%]">
      <div className="text-center min-h-[2rem] font-medium">Preview</div>
      <div className="rounded-lg border-2 border-gray-600 h-[43rem] px-5 py-5">
        <div className="flex justify-between items-center">
          <Logo className=" w-16" />
          <div className="text-gray-default">{getCurrentDate()}</div>
        </div>
        <div className="flex mt-2">
          <div className="text-gray-default w-3/12">Invoice ID</div>{" "}
          <div className=" w-9/12">{invoiceData.invoiceNumber}</div>
        </div>
        <div className="flex mt-3">
          <div className="text-gray-default w-3/12">From</div>
          <div className=" w-9/12">{invoiceData.businessName}</div>
        </div>
        <div className="flex mt-3">
          <div className="text-gray-default w-3/12">To</div>
          <div className="text-gray-default w-9/12">{values?.email}</div>
        </div>

        <div className="flex justify-between mt-10">
          <div className="font-semibold">Amount</div>
          <div className="">{formatAmount(values?.amount)}</div>
        </div>

        <hr className="border-black-default mt-8" />

        <div className="mt-8">
          <div className="font-medium">Notes</div>
          <div className="text-gray-default">{values?.notes}</div>
        </div>
      </div>
      <div className="mt-10 font-medium flex gap-6 justify-end items-center">
        <Button
          loading={isEditingInvoice}
          onClick={handleUpdate}
          text="Save Changes"
          className="w-auto px-3 py-1 rounded border"
        />
      </div>
    </div>
  )
}

export default EditBasicInvoicePreview
