import Button from "components/button"
import Modal from "components/modal"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import React, { FC } from "react"
import CustomSelect from "components/select"
import Input from "components/input/input"

type Props = {
  isOpen: boolean
  handleClose: () => void
}

const WalletWithdrawal: FC<Props> = ({ isOpen, handleClose }) => {
  const searchMethods = useForm()
  const { handleSubmit: handleSearch } = searchMethods

  const onSubmit = (data: FieldValues) => {
    // console.log(data)
  }

  return (
    <Modal closeModal={handleClose} isOpen={isOpen}>
      <h2 className="text-center text-2xl font-medium mb-5">
        Withdraw from wallet
      </h2>

      <FormProvider {...searchMethods}>
        <form onSubmit={handleSearch(onSubmit)}>
          <div className="flex flex-col gap-3">
            <div className="mb-4">
              <Input
                label="Amount"
                name="amount"
                type="text"
                inputMode="numeric"
              />
            </div>

            <CustomSelect
              label="Destination Account"
              options={[{ value: "kelvUBA", label: "Orhungul Kelvin" }]}
              name="recipientAccount"
              control={searchMethods.control}
            />

            <div className="mt-4 flex justify-between gap-10">
              <Button
                type="button"
                variant="outline"
                text="Cancel"
                className="w-28 py-3 px-4 rounded "
              />
              <Button
                className="w-28 py-3 rounded"
                text="Proceed"
                type="submit"
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default WalletWithdrawal
