import React, { ReactNode, useState } from "react"
import "./input.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { ErrorMessage } from "@hookform/error-message"
import { useFormState, useFormContext } from "react-hook-form"

type InputPropsT = {
  label: string
  type?: string
  name: string
  labelIcon?: ReactNode
  id?: string
  placeholder?: string
  disabled?: boolean
  pattern?: string
  onFocus?: () => void
  min?: string
  max?: string
} & React.InputHTMLAttributes<HTMLInputElement>

const Input = ({
  label,
  type = "text",
  name,
  id,
  placeholder,
  disabled = false,
  onFocus,
  pattern,
  max,
  min,
  labelIcon,
  ...rest
}: InputPropsT) => {
  const [showPassword, setShowPassword] = useState(false)
  const { errors } = useFormState()
  const { register } = useFormContext()

  const toggleInputType = () => {
    setShowPassword(!showPassword)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "text" && rest.inputMode === "numeric") {
      if (
        ["Backspace", "Delete", "Tab", "Escape", "Enter"].includes(e.key) ||
        (e.key >= "0" && e.key <= "9") ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight"
      ) {
        return
      } else {
        e.preventDefault()
      }
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "text" && rest.inputMode === "numeric") {
      e.target.value = e.target.value.replace(/\D/g, "")
    }
  }

  return (
    <div className="input">
      <label className="input_label flex items-center gap-2">
        <span>{label}</span>
        <span>{labelIcon}</span>
      </label>
      <div
        className={`input_wrapper ${
          errors[name]?.message !== undefined && "error"
        } ${disabled ? "disabled" : ""}`}
      >
        <input
          disabled={disabled}
          className="input_field"
          placeholder={placeholder}
          type={showPassword ? "text" : type}
          id={id}
          {...register(name)}
          pattern={pattern}
          onFocus={onFocus}
          min={min}
          max={max}
          onKeyDown={handleKeyDown}
          onInput={handleInput}
          {...rest}
        />
        {type === "password" && (
          <FontAwesomeIcon
            onClick={toggleInputType}
            icon={showPassword ? faEye : faEyeSlash}
            className="-ml-8 cursor-pointer"
          />
        )}
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className="mt-1 text-[11px] text-danger-default">{message}</div>
        )}
      />
    </div>
  )
}

export default Input
