export const merchantApi = {
  validateOtp: "api/v1/merchant/validate-otp",
  editBusiness: "api/v1/merchant/edit-business",
  viewBusinessDetails: "api/v1/merchant/business-details",
  viewBusinessDetailsById: "api/v1/merchant/business-details-by-merchant-id",
  viewAllBusinesses: "api/v1/merchant/all-businesses",
  // Upload Documents
  uploadKycDocuments: "api/v1/documents/upload-kyc-document",
  getAllDocuments: "api/v1/documents/all-document-data",
  getIdentificationTypes: "api/v1/documents/get-identification-types",
  // Bank
  initiateBvnVerification: "api/v1/banks/initiate-bvn-verification",
  verifyBvn: "api/v1/banks/verify-bvn",
  verifyBankAccount: "api/v1/banks/verify-settlement-account",
  editSettlementAccount: "api/v1/banks/edit-settlement-account",
  editSettingsSettlementAccount: "api/v1/banks/settings/new-settlement-account",
  nibbsBvnVerification: "/api/v1/banks/verify-bvn-igree",
  authorizeSettlementAccount:
    "api/v1/banks/settings/authorize-settlement-account",
  getAllBanks: "api/v1/banks",
  getBusinessBanks: "api/v1/banks/get-business-banks",
  viewBusinessBankDetails: "api/v1/banks/business-bank-by-user",
  viewUserBusinesses: "api/internal/v1/merchant/user-businesses",
  // Submit KYC
  submitKyc: "api/v1/merchant/submit-kyc",
  // No auth
  getCountries: "api/non-auth/v1/merchant/countries",
  getBusinessTypes: "api/non-auth/v1/merchant/business-types",
  getBusinessCategories: "api/non-auth/v1/merchant/business-categories",
  // Logo
  saveBusinessLogo: "api/v1/merchant/upload-business-logo",
  // Integration
  generatePublicKey: "api/v1/integration-settings/public-key",
  generatePivateKey: "api/v1/integration-settings/private-key",
  getMerchantSettings: "api/v1/integration-settings",
  updateIntegrationSettings: "api/v1/integration-settings",
}
