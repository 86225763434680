import React from "react"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"
import { Link } from "react-router-dom"

const AccessCodeExpired = () => {
  return (
    <div className=" pt-16 flex flex-col items-center mb-28">
      <div>
        <Logo className="h-10" />
      </div>

      <section className="mt-16 text-center">
        <h1 className="text-3xl font-semibold">Link Expired</h1>

        <p className="mt-3 font-light">Account activation link expired</p>
        <p className="font-light">
          <span>Please proceed to</span>{" "}
          <Link className="text-primary-default font-semibold" to={"/signup"}>
            Sign Up.
          </Link>
        </p>
      </section>
    </div>
  )
}

export default AccessCodeExpired
