import { generateNewAccessToken } from "../../axios/services/auth-services"
import { baseUrl } from "./baseUrl"
import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios"

export const NoAuthAxiosInstance = axios.create({
  baseURL: `${baseUrl.authUrl}`,
  /* other custom settings */
})

NoAuthAxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  async (error: AxiosError) => {
    // Check if the error is a 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export const AuthAxiosInstance = axios.create({
  baseURL: `${baseUrl.authUrl}`,
  /* other custom settings */
})

AuthAxiosInstance.interceptors.request.use(
  async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    // Get the access token from the AuthToken object
    const authToken = sessionStorage.getItem("access-token")
    if (authToken) {
      // Add the Authorization header to the request config
      config.headers.Authorization = `Bearer ${authToken}`
    }
    return config
  },
  (error: AxiosError) => {
    console.error("Error making request:", error)
    return Promise.reject(error)
  }
)

AuthAxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  async (error: any) => {
    const originalRequest = error.config
    // Check if the error is a 401 Unauthorized error
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      try {
        const response = await generateNewAccessToken()
        if (originalRequest) {
          originalRequest.headers.Authorization = `Bearer ${response.data.data.accessToken}`
          // Set the _retry flag to true to prevent an infinite loop
          ;(originalRequest as any)._retry = true
          // Retry the original request with the new token
          return AuthAxiosInstance(originalRequest)
        } else {
          // Handle case where original request is undefined
          return Promise.reject(error)
        }
      } catch (error) {
        // Log the user out if refresh token generation fails
        sessionStorage.clear()
        window.location.replace("/")
      }

      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)
