import Button from "components/button"
import { DataRow } from "components/data-row/data-row"
import Status from "components/status/Status"
import { queryKeys } from "constants/queryKeys"
import React from "react"
import { useSearchParams } from "react-router-dom"
import useTransactions from "./hooks/useTransactions"
import { formatWithCustomCurrency } from "utils/format-amount"

interface DetailsPDFProps {
  forwardedRef: React.RefObject<HTMLDivElement>
}

const TransactionDetailsPDF: React.FC<DetailsPDFProps> = ({ forwardedRef }) => {
  const [searchParams] = useSearchParams()
  const transactionId = searchParams.get("transactionId")

  const { transactionDetails } = useTransactions(
    queryKeys.transactions.fetchTransactionDetails,
    transactionId
  )

  const personalData = {
    "Transaction Reference": transactionDetails.data?.tgipayReference,
    "Customer Name":
      transactionDetails.data?.customerFirstName +
      " " +
      transactionDetails.data?.customerLastName,
    "Payment Channel": transactionDetails.data?.paymentChannel,
    "Payment Processor": transactionDetails.data?.processor,
    Amount: formatWithCustomCurrency(transactionDetails.data?.amount, "NGN"),
    "Transaction Date": transactionDetails.data?.transactionDate,
    Status: (
      <Status variant={transactionDetails.data?.status}>
        {transactionDetails.data?.status}
      </Status>
    ),
    "TGI Pay Fee": formatWithCustomCurrency(
      transactionDetails.data?.fee,
      "NGN"
    ),
    "Your Income ": formatWithCustomCurrency(
      transactionDetails.data?.income,
      "NGN"
    ),
    "Transaction Narration": transactionDetails.data?.narration,
  }
  return (
    <div
      style={{
        position: "absolute",
        left: "100%",
        top: 0,
      }}
    >
      <div className="  bg-white-default pb-5" ref={forwardedRef}>
        <div className="flex justify-between border-b-2 p-6">
          <div className="bg-white-default">
            <div className="text-xl font-medium">Transaction Details</div>
            <div className="text-sm text-gray-10/60">
              Details for transaction {transactionDetails.data?.tgipayReference}
            </div>
          </div>
        </div>
        <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
          {Object.entries(personalData).map(([label, value]) => (
            <DataRow
              key={label}
              isLoading={transactionDetails.loadingDetails}
              label={label}
              value={value}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
export default TransactionDetailsPDF
