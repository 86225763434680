import React, { useState } from "react"
import AppLayout from "../../layout/private/app-layout"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import DashboardCards from "./dashboard-cards"
import TransactionsChart from "./transactions-chart"
import useDashboard from "./hooks/useDashboard"
import { queryKeys } from "constants/queryKeys"
import { DATE_GROUPINGS, DateGrouping } from "constants/dashboard"
import { format } from "date-fns"
import DashboardFilter from "./components/Filter"
import { useNavigate } from "react-router-dom"
import { setSelectedWallet } from "redux/wallet/walletSlice"
import { WalletType } from "types/wallet"
import { useWallets } from "pages/wallets/hooks/useWallets"
import WalletCard from "pages/wallets/components/wallet-card"
import WalletCardSkeleton from "pages/wallets/components/wallet-card/WalletCardSkeleton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRefresh } from "@fortawesome/free-solid-svg-icons"

const Dashboard = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const profileDetails = useAppSelector((state) => state.profile.profile)
  const [startDate, setStartDate] = useState(format(new Date(), "dd-MM-yyyy"))
  const [endDate, setEndDate] = useState(format(new Date(), "dd-MM-yyyy"))
  const [dateGrouping, setDateGrouping] = useState(DATE_GROUPINGS.hour)

  const { transactionTimeData, filter } = useDashboard({
    startDate,
    endDate,
    targetQuerykeys: [
      queryKeys.dashboard.failedCountpointsByDate,
      queryKeys.dashboard.allSuccessfulByValuePoints,
    ],
    dateGrouping,
    merchantId: profileDetails?.businessId,
  })

  const { wallet } = useWallets()

  const updateDate = (
    startDate: Date | null,
    endDate: Date | null,
    dateGrouping: DateGrouping
  ) => {
    if (startDate) {
      setStartDate(format(startDate, "dd-MM-yyyy"))
    } else setStartDate("")
    if (endDate) {
      setEndDate(format(endDate, "dd-MM-yyyy"))
    } else setEndDate("")
    setDateGrouping(dateGrouping)
  }

  const handleWalletRefresh = () => {
    wallet.refetch()
  }

  return (
    <AppLayout
      title="Dashboard"
      subTitle={
        <div className="flex justify-between items-center">
          {`Good morning, ${profileDetails?.firstName}`}
          <DashboardFilter filter={filter} updateDate={updateDate} />
        </div>
      }
    >
      <div>
        <div>
          <DashboardCards
            dateGrouping={dateGrouping}
            startDate={startDate}
            endDate={endDate}
            merchantId={profileDetails?.businessId}
          />
        </div>

        {/* <div className="mt-16">
          <div className="ext-lg font-medium flex items-center gap-2">
            <span>Wallet</span>{" "}
            <FontAwesomeIcon
              onClick={handleWalletRefresh}
              className="cursor-pointer"
              icon={faRefresh}
            />
          </div>

          <div className="mt-5 flex flex-wrap gap-8">
            {wallet.loading ? (
              <WalletCardSkeleton count={3} />
            ) : (
              <>
                {wallet.walletData.map((wallet: WalletType, index: number) => (
                  <WalletCard
                    type={wallet.currencyName}
                    currency={wallet.currencySymbol}
                    flag={wallet.flag}
                    balance={wallet.balance}
                    key={index}
                    onClick={() => {
                      dispatch(setSelectedWallet(wallet))
                      navigate("/wallet")
                    }}
                  />
                ))}
              </>
            )}
          </div>
          {wallet.error ? (
            <div className="text-danger-default">
              We are unable to retrieve your wallets at the moment. Please
              reload or try again later.{" "}
            </div>
          ) : null}
        </div> */}

        <div className="flex justify-between items-center mt-16">
          <div className=" text-lg font-medium">Processed Transactions</div>
        </div>

        <div className="mt-5">
          <TransactionsChart transactionTimeData={transactionTimeData} />
        </div>
      </div>
    </AppLayout>
  )
}

export default Dashboard
