import React from "react"
import { ReactComponent as DisputesIcon } from "../../../assets/icons/disputes.svg"
import { ReactComponent as ReloadIcon } from "assets/icons/reload.svg"

const NoDisputesData = () => {
  return (
    <div>
      <div className="flex flex-col justify-center items-center mt-40 mb-52">
        <div className=" text-secondary-default">
          <DisputesIcon />
        </div>
        <div className="font-semibold text-xl mt-5">No Disputes</div>
        <div className="text-gray-default">
          You have no dispute history on your account
        </div>
        <div className="mt-5">
          <ReloadIcon
            onClick={() => window.location.reload()}
            className="mt-4 cursor-pointer "
          />
        </div>
      </div>
    </div>
  )
}

export default NoDisputesData
