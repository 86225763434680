import React, { useState, ChangeEvent } from "react"
import Modal from "../../../components/modal"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import Button from "../../../components/button"
import { roleSchema } from "../validation-schema"
import { yupResolver } from "@hookform/resolvers/yup"
import Input from "../../../components/input/input"
import TextArea from "../../../components/input/textarea"
import { toast } from "react-toastify"
import { useMutation, useQuery } from "react-query"
import {
  addCustomRole,
  fetchAllPermissions,
} from "../../../axios/services/auth-services"
import Spinner from "../../../components/spinner/Spinner"
import PermissionsList from "./permission-list"
import { AxiosError } from "axios"
import { queryClient } from "../../.."
import { useAppSelector } from "../../../redux/hooks"

interface ModalPropsT {
  isOpen: boolean
  closeModal: () => void
}

const AddCustomRoles = ({ closeModal, isOpen }: ModalPropsT) => {
  const methods = useForm({
    resolver: yupResolver(roleSchema),
  })
  const [selectedPermissions, setSelectedPermissions] = useState<
    Array<PermissionT>
  >([])

  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { handleSubmit } = methods

  const { data: permissionsData, isFetching: fetchingPermissions } = useQuery(
    "fetchAllPermissions",
    fetchAllPermissions
  )

  const handlePermissionsChange = (
    event: ChangeEvent<HTMLInputElement>,
    permission: PermissionT
  ) => {
    if (event.target.checked) {
      setSelectedPermissions([...selectedPermissions, permission])
    } else
      setSelectedPermissions(
        selectedPermissions.filter(
          (selectedPermission) => selectedPermission.id != permission.id
        )
      )
  }

  const { mutate: saveCustomRole, isLoading } = useMutation(addCustomRole, {
    onSuccess: () => {
      toast.success("Role saved successfully!")
      queryClient.invalidateQueries("fetchCustomRoles")
      closeModal()
    },
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const onSubmit = (data: FieldValues) => {
    if (selectedPermissions.length === 0) {
      toast.error("You must select minimum of 1 permission.")
      return
    }
    saveCustomRole({
      businessId: profileDetails?.businessId,
      name: data.roleName,
      description: data.description,
      permissions: selectedPermissions,
    })
  }

  const permissions: PermissionT[] = permissionsData?.data?.data.sort(
    (a: PermissionT, b: PermissionT) => a.name.localeCompare(b.name)
  )

  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <FormProvider {...methods}>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8">
            <div className="font-semibold text-xl">Create Custom Role</div>
            <div>Provide details of the new role.</div>
          </div>

          <div className="mb-3">
            <Input label="Role Name" name="roleName" />
          </div>
          <div className="mb-3">
            <TextArea label="Role Description" name="description" />
          </div>
          <div className="mb-3">
            <div className=" py-5 text-lg">
              Permissions{" "}
              {fetchingPermissions ? null : (
                <span>
                  ({selectedPermissions.length} of {permissions?.length})
                </span>
              )}
            </div>
            {fetchingPermissions ? (
              <div className="text-primary-blue">
                <Spinner />
              </div>
            ) : (
              <>
                {permissions?.map((permission: PermissionT, index) => (
                  <div key={index}>
                    <PermissionsList
                      permission={permission}
                      handlePermissionsChange={handlePermissionsChange}
                      selectedPermissions={selectedPermissions}
                    />
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="mt-14 flex justify-between">
            <Button
              variant="outline"
              className="w-max px-5 py-2 rounded-md"
              text="Cancel"
              onClick={closeModal}
            />
            <Button
              loading={isLoading}
              disabled={isLoading}
              className="w-max px-5 py-2 rounded-md"
              text="Create New Role"
            />
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default AddCustomRoles
