import AppLayout from "layout/private/app-layout"
import React from "react"
import { useParams } from "react-router-dom"
import CreateBasicInvoice from "./basic-invoice/create-basic-invoice"
import CreateProfessionalInvoice from "./professional-invoice/create-professional-invoice"

const CreateInvoice = () => {
  const params = useParams()
  return (
    <AppLayout
      title="Create New Invoice"
      subTitle="Create and send an invoice to request for payment"
    >
      {params.invoiceType === "basic" && <CreateBasicInvoice />}
      {params.invoiceType === "professional" && <CreateProfessionalInvoice />}
    </AppLayout>
  )
}

export default CreateInvoice
