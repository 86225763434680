import React, { Fragment, useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { Popover, Transition } from "@headlessui/react"
import { ReactComponent as Elipsis } from "../../../assets/icons/vertical-elipsis.svg"
import { formatAmount } from "../../../utils/format-amount"
import VoidInvoice from "../modals/void-invoice"
import { useNavigate } from "react-router-dom"
import { capitalizeFirstLetter } from "utils/capitalize-first-letter"
import { useInvoicing } from "../hooks/useInvoicing"
import { useAppSelector } from "redux/hooks"
import FullScreenLoader from "components/loader/fullscreen-loader"
import DeleteInvoice from "../modals/delete-invoice"

const columnHelper = createColumnHelper<InvoiceDetailsT>()

export const invoiceColumns = [
  columnHelper.accessor("invoiceNumber", {
    cell: (info) => (
      <div className="w-[6rem] truncate text-center">{info.getValue()}</div>
    ),
    header: () => <div className="w-[6rem] text-center">Invoice ID</div>,
  }),
  columnHelper.accessor("customerEmail", {
    header: () => <div className="text-center">Customer Email</div>,
    cell: (info) => <div className="text-center">{info.renderValue()}</div>,
  }),
  columnHelper.accessor("customerName", {
    header: () => <div className="text-center">Customer Name</div>,
    cell: (info) => <div className="text-center">{info.getValue()}</div>,
  }),
  columnHelper.accessor("amount", {
    header: () => <div className="text-center">Amount</div>,
    cell: (info) => (
      <div className="text-center">{formatAmount(info.renderValue())}</div>
    ),
  }),
  columnHelper.accessor("requestStatus", {
    header: () => <div className="text-center">Request Status</div>,
    cell: (info) => (
      <div className="text-center">
        {capitalizeFirstLetter(info.renderValue())}
      </div>
    ),
  }),
  columnHelper.accessor("sentDate", {
    header: () => <div className="text-center">Date Sent</div>,
    cell: (info) => <div className="text-center">{info.renderValue()}</div>,
  }),
  columnHelper.accessor("paymentStatus", {
    header: () => <div className="text-center">Payment Status</div>,
    cell: (info) => (
      <div
        className={`${paymentStatusColor(
          info.renderValue()
        )} truncate text-center font-semibold`}
      >
        {capitalizeFirstLetter(info.renderValue())}
      </div>
    ),
  }),

  columnHelper.accessor("id", {
    header: () => <div className="text-center">Action</div>,
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const [voidInvoiceModal, setVoidInvoiceModal] = useState<boolean>(false)
      const [deleteInvoiceModal, setDeleteInvoiceModal] =
        useState<boolean>(false)
      const navigate = useNavigate()
      const profileDetails = useAppSelector((state) => state.profile.profile)
      const {
        handleSendInvoiceReminder,
        handleSendInvoice,
        isSendingReminder,
        isSendingInvoice,
      } = useInvoicing(
        // @ts-ignore
        profileDetails?.businessId,
        originalData.id
      )

      function displayAction() {
        if (
          originalData.requestStatus !== "voided" &&
          originalData.paymentStatus !== "paid"
        ) {
          return true
        } else return false
      }
      return (
        <>
          <Popover className="relative">
            {() => (
              <>
                <Popover.Button className="px-3">
                  <div className="text-center">
                    <Elipsis />
                  </div>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -top-1/4 left-3/4 z-50 mt-3 w-max -translate-x-full transform px-4 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black-default ring-opacity-5">
                      <div className="relative grid gap-8 bg-white-default p-5">
                        <div className="-m-3 flex items-center rounded p-1 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                          <div
                            className="ml-4"
                            onClick={() =>
                              navigate(`/invoicing/details/${originalData.id}`)
                            }
                          >
                            <p className="text-sm">View Details</p>
                          </div>
                        </div>

                        {/* Delete Saved Invoice */}
                        {originalData.requestStatus !== "saved" && (
                          <>
                            {displayAction() && (
                              <div
                                onClick={() => setVoidInvoiceModal(true)}
                                className="-m-3 flex items-center rounded p-1 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                              >
                                <div className="ml-4">
                                  <p className="text-sm">Void Invoice</p>
                                </div>
                              </div>
                            )}
                          </>
                        )}

                        <div className="-m-3 flex items-center rounded p-1 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                          <div className="ml-4">
                            <p className="text-sm">Download</p>
                          </div>
                        </div>

                        {/* Send reminder to customer to make invoice payment */}
                        {originalData.requestStatus === "sent" && (
                          <>
                            {displayAction() && (
                              <div className="-m-3 flex items-center rounded p-1 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                <div
                                  className="ml-4"
                                  onClick={handleSendInvoiceReminder}
                                >
                                  <p className="text-sm">Send Reminder</p>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {/* Send invoice to customer after saving it */}
                        {originalData.requestStatus === "saved" && (
                          <>
                            {displayAction() && (
                              <div className="-m-3 flex items-center rounded p-1 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                <div
                                  className="ml-4"
                                  onClick={handleSendInvoice}
                                >
                                  <p className="text-sm">Send Invoice</p>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {/* Delete Saved Invoice */}
                        {originalData.requestStatus === "saved" && (
                          <>
                            {displayAction() && (
                              <div className="-m-3 flex items-center rounded p-1 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50">
                                <div
                                  className="ml-4"
                                  onClick={() => setDeleteInvoiceModal(true)}
                                >
                                  <p className="text-sm">Delete Invoice</p>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>

                {voidInvoiceModal && (
                  <VoidInvoice
                    id={originalData.id}
                    invoiceNumber={originalData.invoiceNumber}
                    isOpen={voidInvoiceModal}
                    closeModal={() => setVoidInvoiceModal(false)}
                  />
                )}
                {deleteInvoiceModal && (
                  <DeleteInvoice
                    id={originalData.id}
                    invoiceNumber={originalData.invoiceNumber}
                    isOpen={deleteInvoiceModal}
                    closeModal={() => setDeleteInvoiceModal(false)}
                  />
                )}
                {isSendingReminder && (
                  <FullScreenLoader
                    loading={isSendingReminder}
                    message="Sending Reminder"
                  />
                )}
                {isSendingInvoice && (
                  <FullScreenLoader
                    loading={isSendingInvoice}
                    message="Sending Invoice"
                  />
                )}
              </>
            )}
          </Popover>
        </>
      )
    },
  }),
]

const paymentStatusColor = (color: string | null) => {
  switch (color) {
    case "paid":
      return "text-success-default"
    case "overdue":
      return "text-danger-default"
    case "pending":
      return "text-caution-default"
    default:
      return ""
  }
}
