import React, { FC, useEffect, useState } from "react"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"
import { ReactComponent as SuccessIcon } from "../../assets/icons/green-check.svg"
import { ReactComponent as WarningIcon } from "../../assets/icons/caution.svg"
import { useLocation } from "react-router-dom"
import { useAppDispatch } from "redux/hooks"
import { closeTopupModal } from "redux/wallet/walletSlice"
import { PaymentSuccessData } from "types/payment"

type Props = {
  successData?: PaymentSuccessData
}

const PaymentSuccess: FC<Props> = ({ successData }) => {
  const dispatch = useAppDispatch()
  const [requiresCallBack, setRequiresCallBack] = useState(false)
  const [callBackUrl, setCallBackUrl] = useState("")
  const [redirectTimer, setRedirectTimer] = useState(30)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const responseData = JSON.parse(queryParams.get("response") ?? "{}")

  const redirectUser = (url: string) => {
    window.location.href = url
  }

  useEffect(() => {
    dispatch(closeTopupModal())
  }, [])

  useEffect(() => {
    if (responseData?.callBack) {
      setRequiresCallBack(true)
      setCallBackUrl(responseData?.callBackUrl)
    }
  }, [responseData])

  useEffect(() => {
    if (requiresCallBack) {
      if (redirectTimer === 0) {
        redirectUser(
          `${callBackUrl}?response=${encodeURIComponent(
            JSON.stringify(responseData.internalTransportData)
          )}`
        ) // Redirect to the callBack url and pass the internalTransportData as data
        return // Don't start a new countdown if the time has run out
      }
      const countdown = setInterval(() => {
        setRedirectTimer((prevTime) => prevTime - 1) // Decrease the time by one second
      }, 1000) // Run the countdown every 1000ms (1 second)
      return () => clearInterval(countdown) // Clear the countdown interval on component unmount
    }
  }, [redirectTimer])

  return (
    <div className="pt-16 flex flex-col items-center">
      <div>
        <Logo className="h-10" />
      </div>

      <div className="w-[95%] md:w-[50%] lg:w-[30%] mt-20 p-5 card">
        <div className="text-center">
          <div className="flex justify-center mb-3">
            {successData?.status === false ? <WarningIcon /> : <SuccessIcon />}
          </div>
          <h2 className="font-semibold text-2xl mb-4">Payment Successful</h2>
          <p className="font-light text-sm mb-3 px-5">
            {successData?.successMessage
              ? successData.successMessage
              : "An email of your receipt has been sent to you."}
          </p>

          {requiresCallBack && (
            <div className="mt-5 mb-2 text-primary-default">
              You will be automatically redirected in {redirectTimer} seconds...
            </div>
          )}
          <div className="flex flex-col justify-center items-center">
            {requiresCallBack ? (
              <button
                onClick={() =>
                  redirectUser(
                    `${callBackUrl}?response=${encodeURIComponent(
                      JSON.stringify(responseData.internalTransportData)
                    )}`
                  )
                }
                className="bg-primary-default text-white-default px-5 py-2 rounded w-auto"
              >
                Return to Merchant Site
              </button>
            ) : (
              <button
                onClick={() => redirectUser("https://tgipay.com")}
                className="bg-primary-default text-white-default px-5 py-2 rounded w-auto"
              >
                Return to Home
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess
