import React from "react"
import { useNavigate } from "react-router-dom"
import { FormProvider } from "react-hook-form"
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg"
import { ReactComponent as SwitchIcon } from "../../../../../assets/icons/rotate-arrows.svg"
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete.svg"
import Input from "components/input/input"
import TextArea from "components/input/textarea"
import { format } from "date-fns"

interface PropsT {
  methods: any
  fields: any
  append: any
  remove: any
}

const ProfessionalInvoiceForm = ({
  methods,
  fields,
  append,
  remove,
}: PropsT) => {
  const navigate = useNavigate()
  return (
    <div className="w-[60%] md:w-[60%]">
      <div className="rounded-lg border-2 border-gray-600 min-h-[55rem] flex flex-col justify-between">
        <div>
          <div className="text-center bg-primary-400 font-medium py-3">
            Fill Invoice Details
          </div>
          <div className="px-3 pt-3">
            <FormProvider {...methods}>
              <div className="block md:flex gap-4 mt-3 mb-3">
                <div className="w-[100%] md:w-[50%] mb-0 md:mb-3">
                  <Input label="Customer name" name="name" />
                </div>
                <div className="w-[100%] md:w-[50%] mt-3 md:mt-0">
                  <Input label="Customer Email" name="email" />
                </div>
              </div>
              <div className="mt-3 mb-5">
                <div className="font-medium">Item Description</div>
                {fields.map((item: any, index: number) => (
                  <div key={item.id} className="mt-3 flex gap-4 items-center">
                    <div className="w-[30%]">
                      <Input
                        label="Item"
                        name={`items.${index}.item` as const}
                        placeholder="Name of Item"
                      />
                    </div>
                    <div className="w-[30%]">
                      <Input
                        label="Quantity"
                        type="text"
                        inputMode="numeric"
                        name={`items.${index}.quantity` as const}
                        placeholder="Quantity"
                      />
                    </div>
                    <div className="w-[30%]">
                      <Input
                        label="Unit Price"
                        type="text"
                        inputMode="numeric"
                        name={`items.${index}.unitPrice` as const}
                        placeholder="Unit Price"
                      />
                    </div>
                    <div
                      onClick={() => remove(index)}
                      className="cursor-pointer w-[10%] text-danger-default self-center mt-9"
                    >
                      <DeleteIcon className="h-5 w-5" />
                    </div>
                  </div>
                ))}
                <div
                  onClick={append}
                  className="text-primary-default font-medium text-center mt-5 cursor-pointer"
                >
                  + Add New Item
                </div>
              </div>
              <div className="mb-3 ">
                <TextArea
                  label="Notes"
                  name="notes"
                  placeholder="Add notes to your invoice"
                />
              </div>
              <div className=" text-right mt-10 ">
                <div className="w-[100%] md:w-[40%] ml-auto">
                  <Input
                    label="Payment Due"
                    min={format(new Date(), "yyyy-MM-dd")}
                    name="paymentDue"
                    type="date"
                  />
                </div>
              </div>
            </FormProvider>
          </div>
        </div>
        <div
          onClick={() => navigate("/invoicing/create-invoice/basic")}
          className=" text-primary-default opacity-60 font-medium flex items-center justify-center px-3 pb-3 mt-10 cursor-pointer"
        >
          <SwitchIcon className="mr-3" />
          <span>Switch To Basic Invoice</span>
        </div>
      </div>
      <div
        onClick={() => navigate("/invoicing")}
        className=" text-primary-default mt-10 font-medium flex items-center cursor-pointer"
      >
        <CloseIcon className="mr-3" />
        <span> Discard And Return To Invoicing</span>
      </div>
    </div>
  )
}

export default ProfessionalInvoiceForm
