import React from "react"
import Button from "../../../components/button"
import Modal from "../../../components/modal"
import { useAppSelector } from "../../../redux/hooks"
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg"
import { useMutation } from "react-query"
import { deleteInvoice } from "../../../axios/services/payment-services"
import { toast } from "react-toastify"
import { queryClient } from "index"

interface ModalPropsT {
  closeModal: () => void
  isOpen: boolean
  id: number
  invoiceNumber: string
}

const DeleteInvoice = ({
  closeModal,
  isOpen,
  id,
  invoiceNumber,
}: ModalPropsT) => {
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { mutate: deleteInvoiceMutation, isLoading: isDeletingInvoice } =
    // @ts-ignore
    useMutation(() => deleteInvoice(profileDetails?.businessId, id), {
      onSuccess: () => {
        queryClient.invalidateQueries("fetchInvoices")
        toast.success("Invoice Deleted!")
        closeModal()
      },
      onError: () => {
        toast.error("Unable to delete invoice")
      },
    })

  const handleDeleteInvoice = () => {
    deleteInvoiceMutation()
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <div>
        <div className="text-center">
          <div className="flex justify-center mb-3 text-danger-default">
            <DeleteIcon className="h-14" />
          </div>
          <h2 className="font-semibold text-lg mb-4">Delete Invoice</h2>
        </div>
        <p className="mb-3 text-sm text-center">
          You are about to Delete invoice{" "}
          <span className="font-semibold">{invoiceNumber}.</span> Performing
          this action will delete this invoice.
        </p>
        <p className="flex justify-center mt-5">
          <Button
            onClick={handleDeleteInvoice}
            loading={isDeletingInvoice}
            text="Continue"
            className="py-1 w-auto px-5 rounded bg-danger-default hover:bg-danger-default/90"
          />
        </p>
      </div>
    </Modal>
  )
}

export default DeleteInvoice
