import React, { useState } from "react"
import { useQuery } from "react-query"
import { fetchPaymentLinks } from "../../axios/services/payment-services"
import { Pagination } from "../../components/Pagination/PaginationComponent"
import PaginationInfo from "../../components/Pagination/paginationInfo"
import Table from "../../components/table"
import AppLayout from "../../layout/private/app-layout"
import { useAppSelector } from "../../redux/hooks"
import { linksColumns } from "./columns/linksTable"
import NoPaymentData from "./components/no-payments-found"
import CreatePaymentLink from "./modals/create-payment-link"
import CreatePaymentLinkSuccessModal from "./modals/link-creation-success-modal"
import PaymentActions from "./payment-actions"
import PermissionGate from "components/permission-gate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/permission-gate/ListAccessDenied"
import { encodeSearchParams } from "utils/encodeSearchParams"

type FilterT = {
  amount: number | undefined
  startDate?: string
  endDate?: string
  search?: string | undefined
  name?: string
}

const PaymentLink = () => {
  const [createPaymentLinkModal, setCreatePaymentLinkModal] =
    useState<boolean>(false)
  const [createPaymentLinkSuccessModal, setCreatePaymentLinkSuccessModal] =
    useState<boolean>(false)
  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [searchParams, setSearchParams] = useState<FilterT>({
    startDate: "",
    endDate: "",
    amount: undefined,
    name: "",
  })
  const [selectedLink, setSelectedLink] = useState<any>()
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { data: paymentLinks, isFetching } = useQuery(
    ["fetchPaymentLinks", currentPage, pageSize, searchParams],
    () =>
      // @ts-ignore
      fetchPaymentLinks({
        businessId: profileDetails?.businessId ?? 0,
        currentPage: currentPage,
        pageSize: pageSize,
        filter: encodeSearchParams({
          amount: searchParams.amount,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          name: searchParams.name,
        }),
      })
  )

  const paymentLinksData: LinkDetailsT[] = paymentLinks?.data?.data?.content
  const totalElements = paymentLinks?.data?.data?.totalElements
  const totalPages = paymentLinks?.data?.data?.totalPages

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  return (
    <AppLayout
      title="Payment Link"
      subTitle="Create and manage your payment links"
    >
      <PermissionGate
        allowedPermissions={[
          PERMISSIONS.CREATE_PAYMENT_LINK,
          PERMISSIONS.MANAGE_PAYMENT_LINK,
        ]}
        fallback={<ListAccessDenied />}
      >
        {paymentLinksData?.length === 0 ? (
          <div>
            <NoPaymentData setPaymentLinkModal={setCreatePaymentLinkModal} />
          </div>
        ) : (
          <div>
            <PaymentActions
              createPaymentLink={() => setCreatePaymentLinkModal(true)}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
            />
            <div className="mt-4 border border-[#D3D3D3] mb-20">
              <Table
                columns={linksColumns}
                data={paymentLinksData}
                isLoading={isFetching}
                loadingSkeletonCount={10}
              />
              <div className=" block md:flex h-[69px] items-center justify-between bg-white-default px-5">
                <PaginationInfo pagination={pagination} />

                <div>
                  <Pagination pagination={pagination} />
                </div>
              </div>
            </div>
          </div>
        )}

        {createPaymentLinkModal && (
          <div>
            <CreatePaymentLink
              closeModal={() => setCreatePaymentLinkModal(false)}
              openSuccess={() => setCreatePaymentLinkSuccessModal(true)}
              setSelectedLink={setSelectedLink}
              isOpen={createPaymentLinkModal}
            />
          </div>
        )}

        {createPaymentLinkSuccessModal && (
          <div>
            <CreatePaymentLinkSuccessModal
              closeModal={() => setCreatePaymentLinkSuccessModal(false)}
              selectedLink={selectedLink}
              isOpen={createPaymentLinkSuccessModal}
            />
          </div>
        )}
      </PermissionGate>
    </AppLayout>
  )
}

export default PaymentLink
