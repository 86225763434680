import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import React, { useState } from "react"
import CustomSelect from "components/select"
import {
  FieldValues,
  FormProvider,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormReturn,
} from "react-hook-form"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import { format } from "date-fns"
import ReactDatePicker from "react-datepicker"
import { SETTLEMENT_STATUS_OPTIONS } from "constants/settlement"
import { Dropdown } from "components/dropdown"
import Input from "components/input/input"

type Props = {
  filter: {
    filterMethods: UseFormReturn<FieldValues, any>
    handleFilter: UseFormHandleSubmit<FieldValues>
    onFilter: (data: FieldValues) => void
    resetFilter: UseFormReset<FieldValues>
  }
  search: {
    searchMethods: UseFormReturn<FieldValues, any>
    handleSearch: UseFormHandleSubmit<FieldValues>
    onSearch: (data: FieldValues) => void
  }
}

const SettlementFilter: React.FC<Props> = ({ search, filter }) => {
  const [showFilter, setShowFilter] = React.useState(false)

  const [startDate, setStartDate] = useState<Date | null>()
  const [endDate, setEndDate] = useState<Date | null>()

  const { handleSubmit: handleSearch } = search.searchMethods

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  return (
    <>
      <Dropdown
        isOpen={showFilter}
        toggleOpen={toggleFilter}
        showBackdrop={false}
        trigger={
          <Button
            variant="link"
            className="relative flex h-10 min-w-max items-center justify-evenly gap-[15px] rounded-md px-4 text-base font-medium text-primary-default"
            text={
              <>
                <FontAwesomeIcon icon={faFilter} />
                <span className="hidden md:inline">Filter By</span>
              </>
            }
          />
        }
        content={
          <FormProvider {...filter.filterMethods}>
            <form
              onSubmit={filter.handleFilter((data) => {
                return filter.onFilter({
                  ...data,
                  startDate: startDate && format(startDate, "dd-MM-yyyy"),
                  endDate: endDate && format(endDate, "dd-MM-yyyy"),
                })
              })}
            >
              <div className="flex flex-col gap-3">
                <CustomSelect
                  label="Status"
                  options={SETTLEMENT_STATUS_OPTIONS}
                  name="status"
                  control={filter.filterMethods.control}
                />
                <div className="mb-4">
                  <Input
                    label="Transaction Amount"
                    name="transactionAmount"
                    type="text"
                    inputMode="numeric"
                  />
                </div>
                <div className="mb-4">
                  <Input
                    label="Payout Amount"
                    name="payoutAmount"
                    type="text"
                    inputMode="numeric"
                  />
                </div>
                <div>
                  <label htmlFor="startDate">Start Date</label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    startDate={startDate}
                    className="w-full border border-gray-400 rounded px-3 py-2"
                  />
                </div>
                <div>
                  <label htmlFor="startDate">End Date</label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    startDate={endDate}
                    className="w-full border border-gray-400 rounded px-3 py-2"
                  />
                </div>
                <div className="mt-4 flex justify-between gap-10">
                  <Button
                    variant="outline"
                    text="Reset"
                    className="w-28 py-1 px-4 rounded "
                  />
                  <Button className="w-28 py-1 rounded" text="Apply" />
                </div>
              </div>
            </form>
          </FormProvider>
        }
      />
      <FormProvider {...search.searchMethods}>
        <form onSubmit={handleSearch(search.onSearch)} autoComplete="off">
          <Input
            label=""
            name="settlementReference"
            type="search"
            placeholder="Search by settlement references"
          />
        </form>
      </FormProvider>
    </>
  )
}

export default SettlementFilter
