import React from "react"
import TwoFactorAuth from "../pages/2FA"
import ActivateAccount from "../pages/activate-account"
import ExpirationScreen from "../pages/expiration-screen"
import ForgotPassword from "../pages/forgot-password"
import Login from "../pages/login"
import MakePayment from "../pages/make-payment"
import PaymentLinkInactive from "../pages/make-payment/link-inactive"
import PaymentSuccess from "../pages/make-payment/payment-success"
import ResetPassword from "../pages/reset-password"
import SetPassword from "../pages/set-password"
import SignUp from "../pages/sign-up"
import SignUpSuccess from "../pages/signUp-success"
import PasswordSetSuccess from "../pages/successful-password-set"
import ResetSuccess from "../pages/successful-reset"
import MakeInvoicePayment from "pages/make-invoice-payment"
import MakeInvoiceCardPayment from "pages/make-invoice-payment/make-invoice-card-payment"
import AccessCodeExpired from "pages/reset-password/access-code-expired"
import VerifyPayment from "pages/make-payment/verify-payment"
import PaymentFailure from "pages/make-payment/payment-failure"
import InvoiceLinkInactive from "pages/make-invoice-payment/link-inactive"
import InvalidInvoice from "pages/make-invoice-payment/invalid-invoice"
import PayNow from "pages/make-integration-payment"
import IntegrationLinkInactive from "pages/make-integration-payment/link-inactive"
import MakeIntegrationCardPayment from "pages/make-integration-payment/make-integration-card-payment"
import ValidateBVN from "pages/validate-bvn"
import MakeIntegrationPayment from "pages/make-integration-payment/make-integration-payment"

export const publicRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/access-code-expired",
    element: <AccessCodeExpired />,
  },
  {
    path: "/reset-success",
    element: <ResetSuccess />,
  },
  {
    path: "/set-password",
    element: <SetPassword />,
  },

  {
    path: "/set-password-success",
    element: <PasswordSetSuccess />,
  },
  {
    path: "/activate-account",
    element: <ActivateAccount />,
  },
  {
    path: "/signup-success",
    element: <SignUpSuccess />,
  },
  {
    path: "/2fa",
    element: <TwoFactorAuth />,
  },
  {
    path: "/session-expired",
    element: <ExpirationScreen />,
  },
  {
    path: "/make-payment/:id",
    element: <MakePayment />,
  },
  {
    path: "/make-payment/payment-success",
    element: <PaymentSuccess />,
  },
  {
    path: "/make-payment/payment-failed",
    element: <PaymentFailure />,
  },
  {
    path: "/verify-payment",
    element: <VerifyPayment />,
  },
  {
    path: "/make-payment/invalid-link",
    element: <PaymentLinkInactive />,
  },
  {
    path: "/make-invoice-payment/:id",
    element: <MakeInvoicePayment />,
  },
  {
    path: "/make-invoice-payment/invalid-link",
    element: <InvoiceLinkInactive />,
  },
  {
    path: "/make-invoice-payment/invalid-invoice/:errorType",
    element: <InvalidInvoice />,
  },
  {
    path: "/make-invoice-card-payment/pay-now",
    element: <MakeInvoiceCardPayment />,
  },
  {
    path: "/pay-now/:id",
    element: <PayNow />,
  },
  {
    path: "/pay-now/integration",
    element: <MakeIntegrationPayment />,
  },
  {
    path: "/pay-now/invalid-link",
    element: <IntegrationLinkInactive />,
  },
  {
    path: "pay-now/pay",
    element: <MakeIntegrationCardPayment />,
  },
  {
    path: "/validate-bvn",
    element: <ValidateBVN />,
  },
]
