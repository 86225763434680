import React, { ReactNode } from "react"
import Skeleton from "react-loading-skeleton"

export const DataRow: React.FC<{
  label: string
  value?: ReactNode
  isLoading?: boolean
}> = ({ label, value, isLoading }) => (
  <div className="flex gap-4 text-sm">
    <div className="w-2/5 text-gray-10/60">{label}</div>
    <div className="w-3/5 font-normal text-gray-10">
      {isLoading ? <Skeleton className="w-44" /> : value}
    </div>
  </div>
)
