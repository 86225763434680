import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg"
import Button from "components/button"
import { ReactComponent as CaretDown } from "../../../assets/icons/caret-down.svg"
import DatePicker from "react-datepicker"
import React, { useEffect, useState } from "react"
import {
  FieldValues,
  FormProvider,
  useForm,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormReturn,
} from "react-hook-form"
import { getDateRangeByRangeText } from "../util/getDateRangeByRangeText"
import {
  DATE_GROUPINGS,
  DateGrouping,
  dayGroupingToDateRangeType,
} from "constants/dashboard"
import { customDateRanges } from "constants/customDateRanges"
import { Dropdown } from "components/dropdown"

type Props = {
  filter: {
    filterMethods: UseFormReturn<FieldValues, any>
    handleFilter: UseFormHandleSubmit<FieldValues>
    onFilter: (data: FieldValues) => void
    resetFilter: UseFormReset<FieldValues>
  }
  updateDate: (
    startDate: Date | null,
    endDate: Date | null,
    dateGrouping: DateGrouping
  ) => void
}

const DashboardFilter: React.FC<Props> = ({ updateDate }) => {
  const methods = useForm()
  const [showFilter, setShowFilter] = React.useState(false)
  const [rangeType, setRangeType] = useState("allTime")

  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [dateGrouping, setDateGrouping] = useState<DateGrouping>(
    DATE_GROUPINGS.hour
  )
  const onChange = (dates: [Date, Date | null]) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleCustomDate = (selectedDateRange: DateGrouping) => {
    setRangeType(selectedDateRange)
    if (rangeType === customDateRanges.custom) {
      setStartDate(null)
      setEndDate(null)
    }
    const { newEndDate, newStartDate } = getDateRangeByRangeText(
      customDateRanges[selectedDateRange]
    )

    //also update the date grouping
    setStartDate(newStartDate)
    setEndDate(newEndDate)

    setDateGrouping(
      dayGroupingToDateRangeType(customDateRanges[selectedDateRange])
    )
  }

  const handleReset = () => {
    setRangeType("")
    setStartDate(new Date())
    setEndDate(new Date())
    setShowFilter(false)
  }

  const handleApply = () => {
    updateDate(startDate, endDate, dateGrouping)
    setShowFilter(false)
  }

  useEffect(() => {
    updateDate(null, null, dateGrouping)
  }, [])

  return (
    <>
      <Dropdown
        isOpen={showFilter}
        toggleOpen={toggleFilter}
        showCloseButton={false}
        showBackdrop={false}
        contentContainerClass="bg-white-default"
        trigger={
          <Button
            variant="link"
            className="relative flex h-10 min-w-fit items-center justify-center gap-4 rounded-md bg-white px-3 text-base font-medium text-primary-default md:shadow-lg border border-gray-200 border-solid"
            text={
              <div className="flex items-center gap-2">
                <span className=" w-max">{customDateRanges[rangeType]}</span>
                <CaretDown />
              </div>
            }
          >
            <EyeIcon className="h-6" />
            <span className=" w-max">{customDateRanges[rangeType]}</span>
            <span className=" w-5 text-gray-10/50">
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </Button>
        }
        content={
          <>
            <div className="mb-2 px-2 text-center">
              {startDate?.toDateString()} - {endDate?.toDateString()}
            </div>
            <div className="flex gap-4">
              <div className="flex w-[7rem] flex-col justify-start">
                {Object.keys(customDateRanges).map((range, index) => (
                  <div
                    key={`custom-date-range-${index}`}
                    onClick={() => handleCustomDate(range)}
                    className={`cursor-pointer px-2 py-2 text-xs hover:bg-gray-20/90 ${
                      range === rangeType
                        ? "rounded bg-primary-default text-white-default"
                        : ""
                    }`}
                  >
                    {customDateRanges[range]}
                  </div>
                ))}
              </div>
              <div>
                <FormProvider {...methods}>
                  <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                  />
                </FormProvider>
              </div>
            </div>
            <div className="flex justify-between px-2 mt-2">
              <Button
                onClick={handleReset}
                // variant="outlineDefault"
                className="w-max py-2 px-4 text-xs"
                text="Cancel"
                variant="outline"
              />

              <Button
                onClick={handleApply}
                className="w-max py-2 px-4 text-xs"
                text="Apply"
                variant="primary"
              />
            </div>
          </>
        }
      />
    </>
  )
}

export default DashboardFilter
