import React, { useState } from "react"
import Button from "components/button"
import Dropzone from "components/dropzone"
import TextArea from "components/input/textarea"
import StyledSelect from "components/select"
import AppLayout from "layout/private/app-layout"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import BackArrow from "components/button/back-arrow"
import { useMutation, useQuery } from "react-query"
import {
  fetchDisputeReasons,
  raiseSettlementDispute,
} from "../../../axios/services/payment-services"
import { disputeSchema } from "./validation-schema"
import { yupResolver } from "@hookform/resolvers/yup"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import EditDocument from "pages/kyc/components/EditDocument"

type SelectT = {
  label: string
  value: string
  isDisabled?: boolean
}

const SettlementDisputeForm = () => {
  const [disputeReasons, setDisputeReasons] = useState<Array<SelectT>>([])
  const [disputeFile, setDisputeFile] = useState<File | null>(null)
  const [fileError, setFileError] = useState<string | null>(null)
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(disputeSchema),
  })

  const { handleSubmit } = methods

  const [searchParams] = useSearchParams()
  const settlementId: string = searchParams.get("settlementId") ?? ""
  const merchantId: string = searchParams.get("merchantId") ?? ""
  const reference: string = searchParams.get("reference") ?? ""

  const { isLoading } = useQuery("fetchDisputeReasons", fetchDisputeReasons, {
    onSuccess: (response) => {
      const reasonsRes = response.data.data
      const reasons: Array<SelectT> = []
      for (let i = 0; i < reasonsRes.length; i++) {
        const item = {
          label: reasonsRes[i].name,
          value: reasonsRes[i].id,
          isDisabled: false,
        }
        reasons.push(item)
      }
      setDisputeReasons([...reasons])
    },
  })

  const handleDrop = (files: File[]) => {
    setFileError(null)
    setDisputeFile(files[0])
  }

  const { mutate: handleDispute, isLoading: isSubmittingDispute } = useMutation(
    raiseSettlementDispute,
    {
      onSuccess: () => {
        toast.success("Dispute submitted successfully")
        methods.reset()
        setDisputeFile(null)
        navigate("/settlement")
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const submitDispute = (data: FieldValues) => {
    if (disputeFile === null) {
      setFileError("Upload dispute document")
      return
    }
    const formdata = new FormData()
    formdata.append("document", disputeFile)
    formdata.append("merchantId", merchantId)
    formdata.append("settlementId", settlementId.toString())
    formdata.append("notes", data.notes)
    formdata.append("reasonId", data.disputeReason.value.toString())
    handleDispute(formdata)
  }

  return (
    <AppLayout title="" subTitle="">
      <div>
        <div className="flex justify-between p-6">
          <div>
            <div className="text-xl font-medium">Raise A Dispute</div>
            <div className="text-sm text-gray-default">
              Raise a dspute for transaction {reference}
            </div>
          </div>
        </div>

        <div className="bg-white-default max-w-[100%] md:max-w-[60%] pb-5 mb-10 rounded">
          <div className="flex min-h-352 max-w-[100%] md:max-w-[70%] flex-col gap-8 p-6">
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submitDispute)}>
                <StyledSelect
                  control={methods.control}
                  isDisabled={false}
                  isClearable={false}
                  isSearchable={true}
                  isLoading={isLoading}
                  name="disputeReason"
                  label="Reason For Dispute"
                  options={disputeReasons}
                />
                <div className=" w-[100%] mt-6 md:mt-5">
                  <div className="mb-3">
                    <label className="font-medium text-black-default">
                      Upload Document
                    </label>
                  </div>
                  {disputeFile ? (
                    <EditDocument
                      label=""
                      fileName={disputeFile.name}
                      removeFile={() => setDisputeFile(null)}
                    />
                  ) : (
                    <Dropzone
                      acceptedFileTypes={{
                        "image/jpeg": [],
                        "image/png": [],
                        "application/pdf": [],
                      }}
                      onDrop={(files) => handleDrop(files)}
                    />
                  )}
                  <span className="text-sm text-danger-default">
                    {fileError}
                  </span>
                </div>
                <div className="mt-5">
                  <TextArea name="notes" label="Notes" />
                </div>
                <div className="flex justify-end mt-6">
                  <Button
                    loading={isSubmittingDispute}
                    disabled={isSubmittingDispute}
                    className="w-[10rem] h-10 flex items-center justify-center rounded mr-5"
                    text="Submit"
                  />
                </div>
              </form>
            </FormProvider>
          </div>
        </div>

        <div className="mt-10 mb-14">
          <BackArrow onClick={() => navigate(-1)} label="Back" />
        </div>
      </div>
    </AppLayout>
  )
}

export default SettlementDisputeForm
