import React, { useState } from "react"
import { useQuery } from "react-query"
import {
  fetchCustomRoleById,
  fetchDefaultRoleById,
} from "../../../axios/services/auth-services"
import Button from "../../../components/button"
import FullScreenLoader from "../../../components/loader/fullscreen-loader"
import EditCustomRole from "../components/edit-custom-role"

interface PropsT {
  selectedRole: RoleT | undefined
}

const SelectedRoleDetails = ({ selectedRole }: PropsT) => {
  const [editModal, setEditModal] = useState<boolean>(false)
  const [RoleData, setRoleData] = useState<RoleT>()

  const { isFetching: isFetchingCustomRole } = useQuery(
    ["fetchCustomRoleById", selectedRole?.id],
    // @ts-ignore
    () => fetchCustomRoleById(selectedRole?.id),
    {
      onSuccess: (response) => {
        setRoleData(response.data?.data)
      },
      enabled: !selectedRole?.default,
    }
  )

  const { isFetching: isFetchingDefaultRole } = useQuery(
    ["fetchDefaultRoleById", selectedRole?.id],
    // @ts-ignore
    () => fetchDefaultRoleById(selectedRole?.id),
    {
      onSuccess: (response) => {
        setRoleData(response.data?.data)
      },
      enabled: selectedRole?.default,
    }
  )

  return (
    <>
      {(isFetchingCustomRole || isFetchingDefaultRole) && (
        <FullScreenLoader
          loading={isFetchingCustomRole || isFetchingDefaultRole}
          message="Fetching Role Details"
        />
      )}
      <div className="custom-border rounded">
        <div className=" bg-primary-400 py-5 px-5 font-semibold text-lg">
          Description
        </div>
        <div className="h-[10rem] px-5">
          <div className="mt-4">{RoleData?.description}</div>
          <div className="flex justify-between mt-10">
            <div className="font-extralight max-w-[75%]">
              Team Members with this role: {RoleData?.numberOfUsers} members
            </div>
            {/* <div className=" max-w-[25%]">
              <AssignRoles />
            </div> */}
          </div>
        </div>
        <div className="relative">
          <div className=" bg-primary-400 py-5 px-5 font-semibold text-lg">
            Permissions ({RoleData?.numberOfPermission})
          </div>
          <div className="h-[39rem] max-h-[39rem] py-3 overflow-y-scroll hide-scrollbar">
            {RoleData?.permissions
              .sort((a: PermissionT, b: PermissionT) =>
                a.name.localeCompare(b.name)
              )
              .map((permission: PermissionT, index: number) => (
                <div
                  className="text-sm flex items-center mb-2 px-5"
                  key={index}
                >
                  <div className="flex items-center cursor-pointer mr-4 py-3">
                    <input
                      type="checkbox"
                      disabled
                      className="absolute h-8 w-8 opacity-0"
                    />
                    <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-md bg-gray-200 border border-gray-700 cursor-pointer">
                      <svg
                        version="1.1"
                        viewBox="0 0 17 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <g
                            transform="translate(-9 -11)"
                            fill="#204D88"
                            fillRule="nonzero"
                          >
                            <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <span>{permission.name}</span>
                </div>
              ))}

            {!RoleData?.default && (
              <div className="absolute bottom-10 right-6">
                <Button
                  onClick={() => setEditModal(true)}
                  className="w-auto px-4 py-2 rounded"
                  text="Edit Role"
                  variant="outline"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {editModal && RoleData && (
        <EditCustomRole
          closeModal={() => setEditModal(false)}
          isOpen={editModal}
          roleData={RoleData}
        />
      )}
    </>
  )
}

export default SelectedRoleDetails
