import React, { useEffect, useState } from "react"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { settlementSchema } from "./validation-schema"
import Input from "../../components/input/input"
import Button from "../../components/button"
import StyledSelect from "../../components/select"
import { useMutation, useQuery } from "react-query"
import {
  editSettingsSettlementAccount,
  fetchBanks,
  fetchBusinessBankDetails,
  verifyBankAccount,
} from "../../axios/services/merchant-services"
import { useAppSelector } from "../../redux/hooks"
import FullScreenLoader from "../../components/loader/fullscreen-loader"
import SettlementAccountPreview from "./preview-components/settlement-account-preview"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import SettlementOtpModal from "./modals/settlement-otp-modal"

type SelectT = {
  label: string
  value: string
  isDisabled: boolean
  id: number
}

const SettlementAccount = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [banks, setBanks] = useState<Array<SelectT>>([])
  const [modal, setModal] = useState<boolean>(false)
  const [accessKey, setAccessKey] = useState<string>("")

  const profileDetails = useAppSelector((state) => state.profile.profile)
  const methods = useForm({
    resolver: yupResolver(settlementSchema),
  })

  const { handleSubmit, setValue } = methods
  const bankValues = methods.watch()

  const { data: BankDetails, isFetching: isLoadingBankDetails } = useQuery(
    "fetchBankDetails",
    // @ts-ignore
    () => fetchBusinessBankDetails(profileDetails?.id),
    {
      onSuccess: (response) => {
        setValue("accountName", response.data?.data.accountName)
        setValue("accountNumber", response.data?.data.accountNumber)
        setValue("bankName", {
          label: response.data?.data.bankName,
          value: response.data?.data.bankCode,
          id: response.data?.data.bankId,
        })
      },
    }
  )

  const { isLoading: isLoadingBanks } = useQuery("fetchBanksList", fetchBanks, {
    onSuccess: (response) => {
      const banksRes = response.data.data
      const banksArray: Array<SelectT> = []
      for (let i = 0; i < banksRes.length; i++) {
        const item = {
          label: banksRes[i].name,
          value: banksRes[i].code,
          id: banksRes[i].id,
          isDisabled: false,
        }
        banksArray.push(item)
      }
      setBanks([...banksArray])
    },
  })

  const {
    mutate: handleVerifyBankDetails,
    isLoading: isLoadingBankVerification,
  } = useMutation(verifyBankAccount, {
    onSuccess: (response) => {
      setValue("accountName", response.data?.data.account_name)
    },
    onError: (error: AxiosError) => {
      setValue("accountName", "")
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const { mutate: editSettlementAccount, isLoading: isLoadingBankInfoUpdate } =
    useMutation(editSettingsSettlementAccount, {
      onSuccess: (response) => {
        setAccessKey(response.data?.data.accessKey)
        setModal(true)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    })

  const onSubmit = (data: FieldValues) => {
    editSettlementAccount({
      bankId: data.bankName.id,
      businessId: profileDetails?.businessId,
      accountNumber: data.accountNumber,
      accountName: data.accountName,
      bvn: BankDetails?.data.data.bvn,
    })
  }

  useEffect(() => {
    if (
      bankValues.accountNumber?.length === 10 &&
      bankValues.bankName &&
      isEditing
    ) {
      handleVerifyBankDetails({
        account_number: bankValues.accountNumber,
        account_bank: bankValues.bankName.value,
      })
    }
  }, [bankValues.accountNumber, bankValues.bankName])

  return (
    <>
      {isLoadingBankDetails ? (
        <FullScreenLoader
          loading={isLoadingBankDetails}
          message="Fetching Settlement Account"
        />
      ) : (
        <div>
          {isEditing ? (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="bg-white-default p-10">
                  <div className="font-semibold mb-5 text-lg">
                    Bank Information
                  </div>
                  <div className="w-[100%] md:w-[30%] mb-6">
                    <StyledSelect
                      control={methods.control}
                      defaultValue={banks[0]}
                      isDisabled={false}
                      isLoading={isLoadingBanks}
                      isClearable={false}
                      isSearchable={true}
                      name="bankName"
                      label="Bank Name"
                      options={banks}
                    />
                  </div>
                  <div className="w-[100%] md:w-[30%] mb-6">
                    <Input label="Account Number" name="accountNumber" />
                    {isLoadingBankVerification && (
                      <span className="text-xs text-success-default">
                        Verifying account...
                      </span>
                    )}
                  </div>
                  <div className="w-[100%] md:w-[30%] mb-6">
                    <Input disabled label="Account Name" name="accountName" />
                  </div>
                </div>

                <div className="flex justify-end mt-10 mb-20">
                  <Button
                    disabled={isLoadingBankInfoUpdate}
                    loading={isLoadingBankInfoUpdate}
                    text="Save Changes"
                    className="w-[10rem] px-5 py-2"
                  />
                </div>
              </form>
            </FormProvider>
          ) : (
            <SettlementAccountPreview
              setIsEditing={setIsEditing}
              bankDetails={BankDetails?.data?.data}
            />
          )}
        </div>
      )}

      {modal ? (
        <SettlementOtpModal
          accessKey={accessKey}
          isOpen={modal}
          closeModal={() => setModal(false)}
          setIsEditing={setIsEditing}
        />
      ) : null}
    </>
  )
}

export default SettlementAccount
