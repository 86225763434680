import React, { useState } from "react"
import AppLayout from "layout/private/app-layout"
import { useParams } from "react-router-dom"
import EditBasicInvoice from "./basic-invoice"
import EditProfessionalInvoice from "./professional-invoice"
import { useInvoicing } from "../hooks/useInvoicing"
import { useAppSelector } from "redux/hooks"
import FullScreenLoader from "components/loader/fullscreen-loader"

const EditInvoice = () => {
  const [invoiceType, setInvoiceType] = useState("professional")
  const params = useParams()
  const id = params.id
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { isFetchingDetails, invoiceDetails } = useInvoicing(
    // @ts-ignore
    profileDetails?.businessId,
    id
  )

  const invoiceData: InvoiceDetailsT = invoiceDetails?.data.data
  return (
    <AppLayout
      title="Edit Invoice"
      subTitle="Make changes to an existing invoice before payment is made"
    >
      {isFetchingDetails ? (
        <FullScreenLoader
          loading={isFetchingDetails}
          message="Fetching Invoice Details"
        />
      ) : (
        <>
          {invoiceData.invoiceType === "basic" && (
            <EditBasicInvoice invoiceData={invoiceData} />
          )}
          {invoiceData.invoiceType === "professional" && (
            <EditProfessionalInvoice invoiceData={invoiceData} />
          )}
        </>
      )}
    </AppLayout>
  )
}

export default EditInvoice
