import React from "react"
import Modal from "../../../components/modal"
import { ReactComponent as CautionIcon } from "../../../assets/icons/caution.svg"
import { useMutation } from "react-query"
import { ActivateDeactivateUserAccount } from "../../../axios/services/auth-services"
import Button from "../../../components/button"
import { toast } from "react-toastify"
import { queryClient } from "../../.."
import { AxiosError } from "axios"
import { useAppSelector } from "../../../redux/hooks"

interface ModalPropsT {
  closeModal: () => void
  isOpen: boolean
  userDetails: Users
}

const ActivateDeactivateModal = ({
  closeModal,
  isOpen,
  userDetails,
}: ModalPropsT) => {
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { mutate: handleActivateDeactivate, isLoading } = useMutation(
    () =>
      ActivateDeactivateUserAccount({
        ...userDetails,
        businessId: profileDetails?.businessId ? profileDetails?.businessId : 0,
      }),
    {
      onSuccess: () => {
        toast.success(
          `User ${
            userDetails.activated ? "deactivated" : "activated"
          } successfully!`
        )
        queryClient.invalidateQueries("fetchMerchantUsers")
        closeModal()
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )
  const handleSubmit = () => {
    handleActivateDeactivate()
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <div className="text-center">
        <div className="flex justify-center mb-3 text-caution-default">
          <CautionIcon />
        </div>
        <h2 className="font-semibold text-2xl mb-4">
          {userDetails.activated ? "Deactivate" : "Activate"} User
        </h2>
        <p className="font-light mb-7">
          You are about to {userDetails.activated ? "Deactivate" : "Activate"}{" "}
          <span className="font-semibold">
            {userDetails.firstName} {userDetails.lastName}
          </span>{" "}
          from your list of users.
        </p>
        <div className="flex gap-20 justify-center items-center">
          <Button
            onClick={closeModal}
            text="Cancel"
            className=" bg-gray-900 w-[7rem] py-2 rounded hover:bg-gray-900"
          />
          <Button
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
            text="Proceed"
            className={`${
              userDetails.activated ? "bg-danger-default" : "bg-primary-default"
            } w-[7rem] py-2 rounded hover:bg-gray-900`}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ActivateDeactivateModal
