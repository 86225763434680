import React, { Fragment, useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { Popover, Transition } from "@headlessui/react"
import { ReactComponent as Elipsis } from "../../../assets/icons/vertical-elipsis.svg"
import EditUserDetails from "../components/edit-user-details"
import ActivateDeactivateModal from "../components/activate-deactivate-modal"

const columnHelper = createColumnHelper<Users>()

export const usersColumns = [
  columnHelper.accessor("firstName", {
    cell: (info) => info.getValue(),
    header: () => <span>First Name</span>,
  }),
  columnHelper.accessor("lastName", {
    header: () => "Last Name",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("email", {
    header: () => <span>Email</span>,
  }),
  columnHelper.accessor("role", {
    header: "Role",
  }),
  columnHelper.accessor("createdDate", {
    header: "Date Created",
  }),
  columnHelper.accessor("activated", {
    header: "Status",
    cell: (info) => (
      <span
        className={`${
          info.getValue() === true
            ? "text-success-default"
            : "text-danger-default"
        } font-semibold`}
      >
        {info.getValue() ? "Active" : "Inactive"}
      </span>
    ),
  }),
  columnHelper.accessor("activated", {
    header: "Action",
    cell: ({ cell, getValue }) => {
      const {
        row: { original: originalData },
      } = cell

      const [editUserModal, setEditUserModal] = useState<boolean>(false)
      const [activateDeactivateModal, setActivateDeactivateModal] =
        useState<boolean>(false)
      return (
        <>
          <Popover className="relative">
            {() => (
              <>
                <Popover.Button className="px-3">
                  <span>
                    <Elipsis />
                  </span>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -top-1/4 left-3/4 z-50 mt-3 w-max -translate-x-full transform px-4 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black-default ring-opacity-5">
                      <div className="relative grid gap-8 bg-white-default p-5">
                        <div
                          onClick={() => setEditUserModal(true)}
                          className="-m-3 flex items-center rounded p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="ml-4">
                            <p className="text-sm font-medium">Edit User</p>
                          </div>
                        </div>
                        <div
                          onClick={() => setActivateDeactivateModal(true)}
                          className="-m-3 flex items-center rounded p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="ml-4">
                            <p className="text-sm font-medium">
                              {getValue() ? "Deactivate" : "Activate"} User
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>

                {editUserModal && (
                  <EditUserDetails
                    closeModal={() => setEditUserModal(false)}
                    isOpen={editUserModal}
                    userDetails={originalData}
                  />
                )}

                {activateDeactivateModal && (
                  <ActivateDeactivateModal
                    closeModal={() => setActivateDeactivateModal(false)}
                    isOpen={activateDeactivateModal}
                    userDetails={originalData}
                  />
                )}
              </>
            )}
          </Popover>
        </>
      )
    },
  }),
]
