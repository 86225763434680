import * as yup from "yup"

export const peronalDetailsSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .nullable()
    .email("Invalid Email format")
    .required("Email Address is required"),
  phone: yup
    .string()
    .nullable()
    .min(10, "Enter a valid Phone Number")
    .max(16, "Enter a valid Phone Number")
    .required("Phone number is required"),
})

//Regex to validate website
const reg =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const BusinessDetailsSchema = yup.object().shape({
  businessName: yup.string().nullable().required("Business Name is required"),
  businessCategory: yup.object().shape({
    value: yup.string().required("Business Category is required"),
  }),
  supportEmail: yup
    .string()
    .nullable()
    .email("Invalid Email format")
    .required("Email Address is required"),
  businessDescription: yup
    .string()
    .nullable()
    .required("Business Description is required"),
  businessAddress: yup
    .string()
    .nullable()
    .required("Business Address is required"),

  supportPhone: yup
    .string()
    .nullable()
    .min(10, "Enter a valid Phone Number")
    .max(16, "Enter a valid Phone Number")
    .required("Phone number is required"),
})

export const settlementSchema = yup.object().shape({
  bankName: yup.object().shape({
    value: yup.string().required("Bank Name is required"),
  }),
  accountNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Account number be only digits")
    .matches(/^\d{10}$/, "Account Number must be exactly 10 digits")
    .required("Account Number is required"),
  accountName: yup.string().required("Account Name is required"),
})

export const securitySchema = yup.object().shape({
  oldPassword: yup.string().required("Old Password is required"),
  newPassword: yup
    .string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Password is required"),
  repeatPassword: yup
    .string()
    .oneOf(
      [yup.ref("newPassword"), null],
      'Must match "New Password" field value'
    ),
})
