import { AxiosError } from "axios"
import React, { useCallback, useEffect, useRef } from "react"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import SignupLayout from "../../layout/public/signupLayout"
import { nibbsBvnVerification } from "../../axios/services/merchant-services"

const ValidateBVN = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dataFetchedRef = useRef(false)

  const code = searchParams.get("code")

  const { mutate: verifyUserBvn } = useMutation(nibbsBvnVerification, {
    onSuccess: () => {
      toast.success("Bvn verified successfully!")
      navigate("/kyc/2")
    },
    onError: (error: AxiosError) => {
      toast.error("Unable to validate bvn")
      navigate("/kyc/2")
    },
  })

  const handleVerification = useCallback(() => {
    verifyUserBvn({
      code: code,
    })
  }, [code])

  useEffect(() => {
    if (dataFetchedRef.current) return
    dataFetchedRef.current = true
    handleVerification()
  }, [handleVerification])

  return (
    <SignupLayout
      content={
        <div className="w-[40%] text-center">
          <h2 className="text-xl font-semibold mb-3">Please wait...</h2>
          <div>Verifying BVN</div>
        </div>
      }
    />
  )
}

export default ValidateBVN
