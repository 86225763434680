import React from "react"
import Dashboard from "../pages/dashboard"
import Invoicing from "../pages/invoicing"
import InvoiceDetails from "../pages/invoicing/invoice-details"
import KYC from "../pages/kyc"
import MangeUserRoles from "../pages/manage-user-roles"
import PaymentLink from "../pages/payment-link"
import Settings from "../pages/settings"
import Teams from "../pages/teams"
import Transactions from "../pages/transactions"
import ProtectedRoute from "../utils/protected-route"
import TransactionDetails from "pages/transactions/details"
import TransactionDispute from "pages/transactions/dispute"
import CreateInvoice from "pages/invoicing/create-invoice"
import EditInvoice from "pages/invoicing/edit-invoice"
import Settlement from "pages/Settlement/Settlement"
import SettlementDetails from "pages/Settlement/Details/Details"
import SettlementDisputeForm from "pages/Settlement/settlement-dispute"
import Disputes from "pages/disputes"
import AuditTrail from "pages/audit-trail"
import AuditDetails from "pages/audit-trail/details"
import DisputeTransaction from "pages/disputes/transaction"
import DisputeDetails from "pages/disputes/details"
import Integration from "pages/integration"
import ValidateBVN from "pages/validate-bvn"
import Wallet from "pages/wallets"

export const privateRoutes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/kyc",
    element: (
      <ProtectedRoute>
        <KYC />
      </ProtectedRoute>
    ),
  },
  {
    path: "/kyc/:id",
    element: (
      <ProtectedRoute>
        <KYC />
      </ProtectedRoute>
    ),
  },
  {
    path: "/transactions",
    element: (
      <ProtectedRoute>
        <Transactions />
      </ProtectedRoute>
    ),
  },
  {
    path: "/transactions/details",
    element: (
      <ProtectedRoute>
        <TransactionDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/transactions/dispute",
    element: (
      <ProtectedRoute>
        <TransactionDispute />
      </ProtectedRoute>
    ),
  },
  {
    path: "/payment-link",
    element: (
      <ProtectedRoute>
        <PaymentLink />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/teams",
    element: (
      <ProtectedRoute>
        <Teams />
      </ProtectedRoute>
    ),
  },
  {
    path: "/teams/manage-user-roles",
    element: (
      <ProtectedRoute>
        <MangeUserRoles />
      </ProtectedRoute>
    ),
  },
  {
    path: "/invoicing",
    element: (
      <ProtectedRoute>
        <Invoicing />
      </ProtectedRoute>
    ),
  },
  {
    path: "/invoicing/create-invoice/:invoiceType",
    element: (
      <ProtectedRoute>
        <CreateInvoice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/invoicing/details/:id",
    element: (
      <ProtectedRoute>
        <InvoiceDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/invoicing/edit/:id",
    element: (
      <ProtectedRoute>
        <EditInvoice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settlement",
    element: (
      <ProtectedRoute>
        <Settlement />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settlement/details",
    element: (
      <ProtectedRoute>
        <SettlementDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/settlement/dispute",
    element: (
      <ProtectedRoute>
        <SettlementDisputeForm />
      </ProtectedRoute>
    ),
  },
  {
    path: "/disputes",
    element: (
      <ProtectedRoute>
        <Disputes />
      </ProtectedRoute>
    ),
  },
  {
    path: "/disputes/details",
    element: (
      <ProtectedRoute>
        <DisputeDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "/disputes/details/transactions",
    element: (
      <ProtectedRoute>
        <DisputeTransaction />
      </ProtectedRoute>
    ),
  },
  // {
  //   path: "/audit-trail",
  //   element: (
  //     <ProtectedRoute>
  //       <AuditTrail />
  //     </ProtectedRoute>
  //   ),
  // },
  // {
  //   path: "/audit-trail/details",
  //   element: (
  //     <ProtectedRoute>
  //       <AuditDetails />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    path: "/integration",
    element: (
      <ProtectedRoute>
        <Integration />
      </ProtectedRoute>
    ),
  },
  {
    path: "/validate-bvn",
    element: <ValidateBVN />,
  },
  {
    path: "/wallet",
    element: (
      <ProtectedRoute>
        <Wallet />
      </ProtectedRoute>
    ),
  },
]
