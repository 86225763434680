import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import Modal from "components/modal"
import Status from "components/status/Status"
import { formatAmount } from "utils/format-amount"

const columnHelper = createColumnHelper<Transaction>()

export const transactionColumns = [
  columnHelper.accessor("tgipayReference", {
    cell: (info) => info.getValue(),
    header: () => <span>Reference</span>,
  }),
  columnHelper.accessor((row) => row.amount, {
    id: "customerName",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell

      return (
        <span>
          {originalData.customerFirstName + " " + originalData.customerLastName}
        </span>
      )
    },
    header: () => <span>Customer Name</span>,
  }),
  columnHelper.accessor("paymentChannel", {
    header: () => "Payment Channel",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("amount", {
    header: () => "Amount(NGN)",
    cell: (info) => <span>{formatAmount(info.getValue())}</span>,
  }),
  columnHelper.accessor("status", {
    header: () => <span>Status</span>,
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor("transactionDate", {
    header: "Created",
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const navigate = useNavigate()
      const [showModal, setShowModal] = useState(false)
      function closeModal() {
        setShowModal(false)
      }
      return (
        <>
          <span
            onClick={() => {
              navigate(`details?transactionId=${originalData.id}`)
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <Modal isOpen={showModal} closeModal={closeModal}>
            <div className="p-6">What do you want to put here?</div>
          </Modal>
        </>
      )
    },
  }),
]
