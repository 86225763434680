import React from "react"
import SettlementFilter from "./Filter/SettlementFilter"
import { settlementColumns } from "./Table/columns"
import useSettlement from "./hooks/useSettlement"
import { Pagination } from "components/Pagination/PaginationComponent"
import Button from "components/button"
import { queryKeys } from "constants/queryKeys"
import { PERMISSIONS } from "constants/permissions"
import PermissionGate from "components/permission-gate/PermissionGate"
import ListAccessDenied from "components/permission-gate/ListAccessDenied"
import Table from "components/table"
import PaginationInfo from "components/Pagination/paginationInfo"
import ExportModal from "components/export-modal/ExportModal"
import AppLayout from "layout/private/app-layout"
import NoSettlementData from "./components/no-settlement-data"

const Settlement: React.FC = () => {
  const { filter, settlement, pagination, exportManager, search } =
    useSettlement(queryKeys.settlement.fetchSettlements)

  return (
    <AppLayout
      title="Settlements"
      subTitle="Monitor your transaction settlements"
    >
      <PermissionGate
        allowedPermissions={[PERMISSIONS.VIEW_PAYOUTS]}
        fallback={<ListAccessDenied />}
      >
        <div className="mb-10">
          {settlement.settlementList?.length == 0 ? (
            <NoSettlementData />
          ) : (
            <>
              <div className="flex items-center justify-end gap-6 pt-2">
                <SettlementFilter filter={filter} search={search} />
              </div>
              <div className="mt-4 border border-[#D3D3D3]">
                <Table
                  columns={settlementColumns}
                  data={settlement.settlementList}
                  isLoading={
                    settlement.isLoading || settlement.refetchingSettlement
                  }
                  loadingSkeletonCount={10}
                />
                {settlement.settlementList?.length > 0 ? (
                  <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
                    <PaginationInfo pagination={pagination} />

                    <div>
                      <Pagination pagination={pagination} />
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )}

          {settlement.settlementList?.length > 0 ? (
            <Button
              className="mt-9 w-fit py-2 px-10 rounded"
              onClick={() => exportManager.setShowExportModal(true)}
              text="Export"
            />
          ) : null}

          {exportManager.showExportModal ? (
            <ExportModal
              isOpen={exportManager.showExportModal}
              closeModal={() => exportManager.setShowExportModal(false)}
              exportDataName="Settlement"
              isExporting={exportManager.isExporting}
              handleExport={exportManager.handleExport}
            />
          ) : null}
        </div>
      </PermissionGate>
    </AppLayout>
  )
}

export default Settlement
