import * as yup from "yup"

export const paymentLinkSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  amount: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(0, "Amount must be greater than or equal to 0")
    .required("Amount is required"),
  fixedAmount: yup.boolean(),
  description: yup.string().required("Description is required"),
})
