export const paymentApi = {
  getPaymentPreferences: "api/v1/payment-preference/payment-preference",
  updatepaymentPreference: "api/v1/payment-preference/payment-preference",
  getAllPaymentLinks: "api/v1/payment-link/all",
  deletePaymentLink: "api/v1/payment-link",
  activateDeactivatePaymentLink: "api/v1/payment-link/activate-deactivate",
  editPaymentLink: "api/v1/payment-link",
  createPaymentLink: "api/v1/payment-link",
  getPaymentLinkByAccessCode: "api/external/v1/payment-link",

  //Invoicing
  viewAllInvoices: "api/v1/invoice/all",
  sendInvoiceReminder: "api/v1/invoice/reminder",
  voidInvoice: "api/v1/invoice/void",
  deleteInvoice: "api/v1/invoice",
  validateInvoiceAccessCode: "api/external/v1/invoice",
  sendInvoiceToCustomer: "api/v1/invoice/send",
  getInvoiceDetails: "api/v1/invoice",
  createInvoiceAndSend: "api/v1/invoice/send",
  createInvoiceAndSave: "api/v1/invoice",
  editInvoice: "api/v1/invoice",

  // Card Payment
  initiatePayment: "api/v1/card-payment/initiate",
  authorizePayment: "api/v1/card-payment/authorize",
  verifyPayment: "api/v1/card-payment/verify",
  validatePayment: "api/v1/card-payment/validate",
  resendOtp: "api/v1/card-payment/resend-otp",

  //Settlement
  fetchSettlements: "api/v1/settlement/all",
  fetchSettlementdetails: "api/v1/settlement",
  fetchPayoutTransactions: "api/v1/settlement/transactions",
  exportSettlementData: "api/v1/settlement/all/export",
  totalPendingSettlement: "api/v1/settlement/all/count-pending-by-date",

  //Audit trail
  viewAuditRecords: "api/v1/merchant/audit-trail",
  viewAuditDetails: "api/v1/merchant/audit-trail",

  //Dispute
  viewDisputeRecords: "api/v1/dispute/all",
  viewDisputeDetails: "api/v1/dispute",
  viewDisputeComments: "api/v1/merchant/dispute/comments",
  viewDisputeReasons: "api/v1/dispute/dispute-reasons",
  viewDisputeTransactions: "api/v1/merchant/dispute/transactions",
  raiseTransactionDispute: "api/v1/dispute/transaction/raise",
  raiseSettlementDispute: "api/v1/dispute/settlement/raise",
  reopenDispute: "api/v1/dispute/reopen",
}
