import React from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {
  useFormState,
  useFormContext,
  FieldValues,
  UseFormReturn,
} from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import "./phone-input.scss"

type PhoneInputT = {
  name: string
  label: string
  disabled?: boolean
  methods: UseFormReturn<FieldValues, any>
}

const PhoneInputField = ({ name, label, disabled, methods }: PhoneInputT) => {
  const { register } = useFormContext()
  const { errors } = useFormState()
  const { setValue, watch } = methods
  const value = watch()

  return (
    <div className="phone_input">
      <label className="phone_input_label">{label}</label>
      <PhoneInput
        disabled={disabled}
        inputProps={{
          name: name,
          ref: { ...register(name) },
        }}
        value={value[name]}
        onChange={(phone) => setValue(name, phone)}
        prefix="+"
        copyNumbersOnly={false}
        country="ng"
        countryCodeEditable={false}
        containerClass={`phone_input_wrapper ${
          errors[name]?.message !== undefined && "phone_error"
        } ${disabled ? "phone_disabled" : null}`}
        inputClass="phone_input_field"
      />

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <div className="mt-1 text-[11px] text-danger-default">{message}</div>
        )}
      />
    </div>
  )
}

export default PhoneInputField
