import React from "react"
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg"
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg"

type PropsT = {
  label: string
  fileName: string
  removeFile: () => void
}

const EditDocument = ({ label, fileName, removeFile }: PropsT) => {
  return (
    <div>
      <div className="font-medium mb-2">{label}</div>
      <div className="bg-gray-200 h-[7rem] custom-border flex gap-2 justify-between items-center px-3 py-2">
        <div className="flex items-center w-[85%] overflow-hidden">
          <FileIcon className="w-[15%]" />
          <div className="ml-3 w-[85%] break-words text-sm">{fileName}</div>
        </div>
        <CloseIcon
          onClick={removeFile}
          className="text-primary-default cursor-pointer"
        />
      </div>
    </div>
  )
}

export default EditDocument
