import React from "react"
import AppLayout from "../../layout/private/app-layout"
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg"
import { ReactComponent as Back } from "../../assets/icons/back.svg"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { savePDF } from "@progress/kendo-react-pdf"
import { useAppSelector } from "redux/hooks"
import FullScreenLoader from "components/loader/fullscreen-loader"
import { formatAmount, formatWithCustomCurrency } from "utils/format-amount"
import { capitalizeFirstLetter } from "utils/capitalize-first-letter"
import { useInvoicing } from "./hooks/useInvoicing"
import InvoiceDetailsPDF from "./invoice-details-pdf"

const InvoiceDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const paymentStatusColor = (color: string | null) => {
    switch (color) {
      case "paid":
        return "text-success-default"
      case "overdue":
        return "text-danger-default"
      case "pending":
        return "text-caution-default"
      default:
        return ""
    }
  }

  const { invoiceDetails, isFetchingDetails } = useInvoicing(
    // @ts-ignore
    profileDetails?.businessId,
    params.id
  )

  const invoiceDetailsData: InvoiceDetailsT = invoiceDetails?.data.data

  function displayAction() {
    if (
      invoiceDetailsData.requestStatus !== "voided" &&
      invoiceDetailsData.paymentStatus !== "paid"
    ) {
      return true
    } else return false
  }

  const container = React.useRef(null)
  const exportPDFWithMethod = () => {
    const element = container.current || document.body
    savePDF(element, {
      paperSize: "A3",
      margin: 40,
      fileName: `Invoice Details`,
    })
  }

  return (
    <AppLayout title="" subTitle="">
      {isFetchingDetails ? (
        <FullScreenLoader
          loading={isFetchingDetails}
          message="Fetching Invoice Details"
        />
      ) : (
        <>
          <div className="bg-white-default py-8 w-[100%] md:w-[50%] ">
            <div className=" px-8 pb-3 border-b-2 border-gray-200 flex justify-between items-center">
              <div className="w-[50%]">
                <div className="font-medium">Invoice Details</div>
                <div className="font-extralight">
                  View invoice details for {invoiceDetailsData?.invoiceNumber}
                </div>
              </div>
              <div className="text-primary-default flex justify-end items-center cursor-pointer w-[50%]">
                <div onClick={exportPDFWithMethod} className="mr-2 font-medium">
                  Download Invoice
                </div>
                <DownloadIcon />
              </div>
            </div>

            <div className="flex mt-6 px-8">
              <div className="w-[50%]">
                <div className="text-gray-default">
                  Invoice ID (Auto Generated)
                </div>
                <div>{invoiceDetailsData?.invoiceNumber}</div>
              </div>
              <div className="w-[50%]">
                <div className="text-gray-default text-right">
                  Customer Email
                </div>
                <div className="text-right">
                  {invoiceDetailsData?.customerEmail}
                </div>
              </div>
            </div>

            <div className="flex mt-6 px-8">
              <div className="w-[50%]">
                <div className="text-gray-default">Amount</div>
                <div>{formatAmount(invoiceDetailsData?.amount)}</div>
              </div>
              <div className="w-[50%]">
                <div className="text-gray-default text-right">
                  Payment Request Status
                </div>
                <div className="text-right">
                  {capitalizeFirstLetter(invoiceDetailsData?.requestStatus)}
                </div>
              </div>
            </div>

            <div className="flex mt-6 px-8">
              <div className="w-[50%]">
                <div className="text-gray-default">Payment Status</div>
                <div
                  className={` font-semibold ${paymentStatusColor(
                    invoiceDetailsData?.paymentStatus
                  )}`}
                >
                  {capitalizeFirstLetter(invoiceDetailsData?.paymentStatus)}
                </div>
              </div>
              <div className="w-[50%]">
                <div className="text-gray-default text-right">Date Sent</div>
                <div className="text-right">{invoiceDetailsData?.sentDate}</div>
              </div>
            </div>

            {invoiceDetailsData.invoiceType === "professional" && (
              <div className="mt-6 px-8">
                <table className="w-full py-5 border text-sm">
                  <thead>
                    <tr className="flex gap-2 py-3 px-3 bg-[#F2F2F2]">
                      <th className=" w-[33%] text-left max-w-[33%] ">Item</th>
                      <th className=" w-[33%] text-center max-w-[33%] ">
                        Quantity
                      </th>
                      <th className=" w-[33%] text-right max-w-[33%] ">
                        Unit Price (NGN)
                      </th>
                      <th className=" w-[33%] text-right max-w-[33%] ">
                        Sub Total (NGN)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDetailsData?.invoiceItems?.map(
                      (item: InvoiceItemsT, index: number) => (
                        <tr
                          key={index}
                          className={`flex gap-2 py-1 px-3 border  ${
                            index % 2 == 1 ? "bg-[#F2F2F250]" : ""
                          } `}
                        >
                          <td className=" w-[33%] max-w-[33%] break-words ">
                            {item.item}
                          </td>
                          <td className="  w-[33%] max-w-[33%] text-center">
                            {item.quantity}
                          </td>
                          <td className="  w-[33%] max-w-[33%] text-right ">
                            {formatWithCustomCurrency(item.unitPrice, "")}
                          </td>
                          <td className="  w-[33%] max-w-[33%] text-right ">
                            {formatWithCustomCurrency(
                              item.unitPrice * item.quantity,
                              ""
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}

            <div className="mt-6 px-8">
              <div className="text-gray-default">Notes</div>
              <div className=" min-h-[5rem] h-auto w-[100%] bg-[#F2F2F2] rounded p-3">
                {invoiceDetailsData?.notes}
              </div>
            </div>

            {displayAction() && (
              <div
                onClick={() =>
                  navigate(`/invoicing/edit/${invoiceDetailsData.id}`)
                }
                className="text-primary-default font-semibold text-sm px-8 mt-12 cursor-pointer"
              >
                Edit
              </div>
            )}
          </div>

          <Link className="text-primary-default" to="/invoicing">
            <div className="text-center flex items-center mt-10">
              <span className="mr-2">
                <Back />
              </span>
              <span>Return to Invoicing</span>
            </div>
          </Link>
        </>
      )}
      <InvoiceDetailsPDF forwardedRef={container} />
    </AppLayout>
  )
}

export default InvoiceDetails
