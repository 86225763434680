import { DecodeJwt } from "utils/decode-jwt"
import { authApi } from "../api/auth"
import {
  AuthAxiosInstance,
  NoAuthAxiosInstance,
} from "../instances/auth-instance"
import { useAppDispatch } from "redux/hooks"
import { saveProfileDetails } from "redux/profile/profileSlice"
import { store } from "../../redux/store"

export const signUp = async (signUpDetails: unknown) => {
  const response = await NoAuthAxiosInstance.post(authApi.signup, signUpDetails)
  return response
}

export const activateAccount = async (activateData: unknown) => {
  const response = await NoAuthAxiosInstance.post(
    authApi.activateAccount,
    activateData
  )
  return response
}

export const login = async (loginDetails: unknown) => {
  const response = await NoAuthAxiosInstance.post(authApi.login, loginDetails)
  return response
}

export const logout = async () => {
  const response = await AuthAxiosInstance.post(authApi.logout)
  return response
}

export const regerateOtp = async (data: unknown) => {
  const response = await NoAuthAxiosInstance.post(authApi.regenerateOtp, data)
  return response
}

export const validateOtp = async (otpDetails: unknown) => {
  const response = await NoAuthAxiosInstance.post(
    authApi.validateOtp,
    otpDetails
  )
  return response
}

export const forgotPassword = async (data: unknown) => {
  const response = await NoAuthAxiosInstance.post(
    authApi.initiatePasswordReset,
    data
  )
  return response
}

export const validateAccessCode = async (accessCode: unknown) => {
  const response = await NoAuthAxiosInstance.get(
    `${authApi.validateAccessCode}/${accessCode}`
  )
  return response
}

export const resetPassword = async (resetDetails: unknown) => {
  const response = await NoAuthAxiosInstance.put(
    authApi.resetPassword,
    resetDetails
  )
  return response
}

export const generateNewAccessToken = async () => {
  const response = await NoAuthAxiosInstance.post(
    `${authApi.getNewAccessToken}`,
    null,
    {
      headers: {
        refresh: sessionStorage.getItem("refresh-token"),
      },
    }
  )
  sessionStorage.setItem("access-token", response.data.data.accessToken)
  sessionStorage.setItem("id", response.data.data.id)
  sessionStorage.setItem("refresh-token", response.data.data.refreshToken)
  const decoded = DecodeJwt(response.data.data.accessToken)
  store.dispatch(saveProfileDetails(decoded))
  return response
}

export const logoutUser = async () => {
  const response = await AuthAxiosInstance.post(`${authApi.logoutUser}`)
  return response
}

export const changePassword = async (changeDetails: unknown) => {
  const response = await AuthAxiosInstance.put(
    authApi.changePassword,
    changeDetails
  )
  return response
}

export const fetchUserDetetails = async (id: unknown) => {
  const response = await AuthAxiosInstance.get(
    `${authApi.viewUserDetails}/${id}`
  )
  return response
}

export const editUserDetails = async (userDetails: unknown) => {
  const response = await AuthAxiosInstance.put(
    authApi.editUserDetails,
    userDetails
  )
  return response
}

export const fetch2faStatus = async (id: unknown) => {
  const response = await AuthAxiosInstance.get(`${authApi.get2faStatus}/${id}`)
  return response
}

export const initiate2faStatusChange = async (data: unknown) => {
  const response = await AuthAxiosInstance.post(authApi.initiate2faChange, data)
  return response
}

export const change2faStatus = async (data: unknown) => {
  const response = await AuthAxiosInstance.put(authApi.change2fa, data)
  return response
}

export const fetchDefaultRoles = async () => {
  const response = await AuthAxiosInstance.get(`${authApi.getDefaultRoles}`)
  return response
}

export const fetchDefaultRoleById = async (id: number) => {
  const response = await AuthAxiosInstance.get(
    `${authApi.getDefaultRoleById}/${id}`
  )
  return response
}

export const fetchCustomRoles = async (businessId: unknown) => {
  const response = await AuthAxiosInstance.get(
    `${authApi.getCustomRoles}/${businessId}`
  )
  return response
}

export const fetchCustomRoleById = async (id: number) => {
  const response = await AuthAxiosInstance.get(
    `${authApi.getCustomRoleById}/${id}`
  )
  return response
}

export const addCustomRole = async (roleData: unknown) => {
  const response = await AuthAxiosInstance.post(authApi.addCustomRole, roleData)
  return response
}

export const editCustomRole = async (roleData: unknown) => {
  const response = await AuthAxiosInstance.put(authApi.editCustomRole, roleData)
  return response
}

export const fetchAllPermissions = async () => {
  const response = await AuthAxiosInstance.get(`${authApi.getAllPermissions}`)
  return response
}

export const fetchPermissionById = async (id: number) => {
  const response = await AuthAxiosInstance.get(
    `${authApi.getPermissionById}/${id}`
  )
  return response
}

export const fetchAllUserAccounts = async (
  businessId: unknown,
  page: number,
  size: number
) => {
  const response = await AuthAxiosInstance.get(
    `${authApi.getAllUserAccounts}/${businessId}?page=${page}&size=${size}`
  )
  return response
}

export const fetchTotalUserAccounts = async (businessId: number) => {
  const response = await AuthAxiosInstance.get(
    `${authApi.getTotalUserCount}/${businessId}`
  )
  return response
}

export const fetchUserAccountById = async (id: number) => {
  const response = await AuthAxiosInstance.get(
    `${authApi.getUserAccount}/${id}`
  )
  return response
}

export const addUserAccount = async (userDetails: unknown) => {
  const response = await AuthAxiosInstance.post(
    authApi.addUserAccount,
    userDetails
  )
  return response
}

export const EditUserAccount = async (userDetails: unknown) => {
  const response = await AuthAxiosInstance.put(
    authApi.editUserAccount,
    userDetails
  )
  return response
}

export const ActivateDeactivateUserAccount = async (userDetails: Users) => {
  const { id, businessId, activated } = userDetails
  const response = await AuthAxiosInstance.put(
    `${
      authApi.activateDeactivateAccount
    }/${businessId}?userId=${id}&activate=${!activated}`
  )
  return response
}
