import { type } from "os"
import React, { useState, useEffect } from "react"

type TimePropsT = {
  minutes: number
  seconds: number
}

function CountdownTimer({ minutes = 0, seconds = 0 }: TimePropsT) {
  const [timeLeft, setTimeLeft] = useState({
    minutes,
    seconds,
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timeLeft.seconds === 0) {
        if (timeLeft.minutes === 0) {
          clearInterval(intervalId)
        } else {
          setTimeLeft({
            minutes: timeLeft.minutes - 1,
            seconds: 59,
          })
        }
      } else {
        setTimeLeft({
          minutes: timeLeft.minutes,
          seconds: timeLeft.seconds - 1,
        })
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  return (
    <div>
      <span>
        {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}
      </span>
      :
      <span>
        {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
      </span>
    </div>
  )
}

export default CountdownTimer
