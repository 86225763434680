import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import Button from "components/button"
import BackArrow from "components/button/back-arrow"
import React, { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import AppLayout from "layout/private/app-layout"
import Dropzone from "components/dropzone"
import TextArea from "components/input/textarea"
import { useForm, FormProvider, FieldValues } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import StyledSelect from "components/select"
import { useMutation, useQuery } from "react-query"
import {
  fetchDisputeReasons,
  raiseTransactionDispute,
} from "../../axios/services/payment-services"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { disputeSchema } from "./validation-schema"
import EditDocument from "pages/kyc/components/EditDocument"

type SelectT = {
  label: string
  value: string
  isDisabled?: boolean
}

const TransactionDispute: React.FC = () => {
  const [disputeReasons, setDisputeReasons] = useState<Array<SelectT>>([])
  const [disputeFile, setDisputeFile] = useState<File | null>(null)
  const [fileError, setFileError] = useState<string | null>(null)
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(disputeSchema),
  })

  const { handleSubmit } = methods

  const [searchParams] = useSearchParams()
  const transactionId: string = searchParams.get("transactionId") ?? ""
  const merchantId: string = searchParams.get("merchantId") ?? ""

  const { isLoading } = useQuery("fetchDisputeReasons", fetchDisputeReasons, {
    onSuccess: (response) => {
      const reasonsRes = response.data.data
      const reasons: Array<SelectT> = []
      for (let i = 0; i < reasonsRes.length; i++) {
        const item = {
          label: reasonsRes[i].name,
          value: reasonsRes[i].id,
          isDisabled: false,
        }
        reasons.push(item)
      }
      setDisputeReasons([...reasons])
    },
  })

  const handleDrop = (files: File[]) => {
    setFileError(null)
    setDisputeFile(files[0])
  }

  const { mutate: handleDispute, isLoading: isSubmittingDispute } = useMutation(
    raiseTransactionDispute,
    {
      onSuccess: () => {
        toast.success("Dispute submitted successfully")
        methods.reset()
        setDisputeFile(null)
        navigate(`/transactions/details?transactionId=${transactionId}`)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    }
  )

  const submitDispute = (data: FieldValues) => {
    if (disputeFile === null) {
      setFileError("Upload dispute document")
      return
    }
    const formData = new FormData()
    formData.append("document", disputeFile)
    formData.append("merchantId", merchantId)
    formData.append("transactionId", transactionId)
    formData.append("notes", data.notes)
    formData.append("reasonId", data.disputeReason.value)
    handleDispute(formData)
  }
  return (
    <AppLayout title="" subTitle="">
      <div className="  bg-white-default mb-5">
        <div className="flex justify-between border-b-2 p-6">
          <div className="bg-white-default">
            <div className="text-xl font-medium">Raise A Dispute</div>
            <div className="text-sm text-gray-10/60">
              Raise a dspute for transaction AS2BFJNEKK
            </div>
          </div>
        </div>
        <div className="flex min-h-352 max-w-[35rem] flex-col gap-8 p-6">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitDispute)}>
              <StyledSelect
                control={methods.control}
                isDisabled={false}
                isClearable={false}
                isSearchable={true}
                isLoading={isLoading}
                name="disputeReason"
                label="Reason For Dispute"
                options={disputeReasons}
              />
              <div className=" w-[100%] mt-6 md:mt-5">
                <div className="mb-3">
                  <label className="font-medium text-black-default">
                    Upload Document
                  </label>
                </div>
                {disputeFile ? (
                  <EditDocument
                    label=""
                    fileName={disputeFile.name}
                    removeFile={() => setDisputeFile(null)}
                  />
                ) : (
                  <Dropzone
                    acceptedFileTypes={{
                      "image/jpeg": [],
                      "image/png": [],
                      "application/pdf": [],
                    }}
                    onDrop={(files) => handleDrop(files)}
                  />
                )}
                <span className="text-sm text-danger-default">{fileError}</span>
              </div>
              <div className="mt-5">
                <TextArea name="notes" label="Notes" />
              </div>
              <div className="flex justify-end mt-6">
                <Button
                  loading={isSubmittingDispute}
                  disabled={isSubmittingDispute}
                  className="w-[10rem] h-10 flex items-center justify-center rounded mr-5"
                  text="Submit"
                />
              </div>
            </form>
          </FormProvider>
        </div>
      </div>

      <div className="mt-10">
        <BackArrow
          onClick={() =>
            navigate(`/transactions/details?transactionId=${transactionId}`)
          }
          label="Back"
        />
      </div>
    </AppLayout>
  )
}
export default TransactionDispute
