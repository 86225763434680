import React from "react"
import { formatAmount } from "utils/format-amount"
import Button from "components/button"
import { ReactComponent as Logo } from "../../../assets/images/TGIPrimaryLogo.svg"
import { useNavigate } from "react-router-dom"
import { convertToUrlParams } from "utils/convertToUrlParams"

interface PropsT {
  invoiceData: InvoiceDetailsT
}

const ProfessionalInvoice = ({ invoiceData }: PropsT) => {
  const navigate = useNavigate()
  const params = {
    invoiceRequest: encodeURIComponent(JSON.stringify(invoiceData)),
  }
  const searchParams = new URLSearchParams(params)

  const calculateTotal = () => {
    let total = 0
    const itemsList = invoiceData.invoiceItems ? invoiceData.invoiceItems : []
    for (let i = 0; i < itemsList.length; i++) {
      const subTotal = itemsList[i]?.unitPrice * itemsList[i]?.quantity
      total += subTotal
    }
    return total
  }

  return (
    <div className="">
      <div className="rounded-lg px-5 py-5">
        <div className="flex justify-between items-center">
          <Logo className=" w-16" />
        </div>
        <div className="flex justify-between">
          <div className=""></div>
          <div>
            <div className="text-end">
              <span className="text-gray-default mr-2">Payment Due</span>{" "}
              <span>{invoiceData.dueDate}</span>
            </div>
            <div className="text-end">
              <span className="text-gray-default mr-2">From</span>{" "}
              <span>{invoiceData.businessName}</span>
            </div>
          </div>
        </div>

        <div className="flex mt-4">
          <div>{invoiceData.customerEmail}</div>
        </div>
        <div className="flex mt-1">
          <div>{invoiceData.customerName}</div>
        </div>

        <div className="mt-14">
          <div className="flex font-semibold">
            <div className="w-6/12">Item</div>
            <div className="w-3/12">Quantity</div>
            <div className="w-3/12">Unit Price</div>
          </div>
          <div className="mt-2">
            {invoiceData?.invoiceItems?.map(
              (item: InvoiceItemsT, index: number) => (
                <div key={index} className="flex mb-1 text-gray-default">
                  <div className="w-6/12">{item?.item}</div>
                  <div className="w-3/12">{item?.quantity}</div>
                  <div className="w-3/12">{formatAmount(item?.unitPrice)}</div>
                </div>
              )
            )}
          </div>
        </div>

        <hr className="border-black-default mt-20" />

        <div className="flex justify-between mt-10">
          <div className="font-semibold">Total</div>
          <div className="">{formatAmount(calculateTotal())}</div>
        </div>

        <div className="mt-8">
          <div className="font-medium">Notes</div>
          <div className="text-gray-default">{invoiceData.notes}</div>
        </div>
      </div>
      <div className="mt-10 font-medium flex gap-6 justify-center items-center">
        <Button
          onClick={() =>
            navigate(
              `/make-invoice-card-payment/pay-now/?${searchParams.toString()}`
            )
          }
          text="Proceed To Payment"
          className="w-auto px-10 py-2 rounded-lg border"
        />
      </div>
    </div>
  )
}

export default ProfessionalInvoice
