import React, { useEffect, useState } from "react"
import { ReactComponent as Logo } from "../../assets/images/TGIPrimaryLogo.svg"
import FullScreenLoader from "components/loader/fullscreen-loader"
import ProfessionalInvoice from "./components/professional-invoice"
import BasicInvoice from "./components/basic-invoice"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { validateInvoiceByAccessCode } from "../../axios/services/payment-services"

const MakeInvoicePayment = () => {
  const params = useParams()
  const navigate = useNavigate()

  const { data: invoiceResponse, isFetching } = useQuery(
    "validateInvoice",
    () =>
      // @ts-ignore
      validateInvoiceByAccessCode(params.id),
    {
      onSuccess: (response) => {
        const resData: InvoiceDetailsT = response.data.data
        if (
          resData.paymentStatus === "overdue" ||
          resData.paymentStatus === "paid"
        ) {
          navigate(
            `/make-invoice-payment/invalid-invoice/${resData.paymentStatus}`
          )
        }
      },
      onError: () => {
        navigate("/make-invoice-payment/invalid-link")
      },
    }
  )

  const invoiceData: InvoiceDetailsT = invoiceResponse?.data.data

  return (
    <div className=" pt-16 flex flex-col items-center mb-28">
      {isFetching ? (
        <FullScreenLoader
          loading={isFetching}
          message="Loading Invoice Information"
        />
      ) : (
        <>
          <div>
            <Logo className="h-10" />
          </div>
          <div className=" w-[95%] md:max-w-[60rem] lg:w-[35%] mt-10 p-5 border border-gray-600 rounded-xl">
            {invoiceData.invoiceType === "professional" && (
              <ProfessionalInvoice invoiceData={invoiceData} />
            )}
            {invoiceData.invoiceType === "basic" && (
              <BasicInvoice invoiceData={invoiceData} />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default MakeInvoicePayment
