import Button from "components/button"
import BackArrow from "components/button/back-arrow"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import PayoutTransactions from "./PayoutTransactions"
import Status from "components/status/Status"
import AppLayout from "layout/private/app-layout"
import { formatAmount } from "utils/format-amount"
import useSettlement from "../hooks/useSettlement"
import { queryKeys } from "constants/queryKeys"
import { savePDF } from "@progress/kendo-react-pdf"
import SettlementDetailsPDF from "./DetailsPdf"

const SettlementDetails: React.FC = () => {
  const [searchParams] = useSearchParams()
  const settlementId = searchParams.get("settlementId")

  const { detailsData } = useSettlement(
    queryKeys.settlement.fetchSettlementDetails,
    Number(settlementId)
  )

  const container = React.useRef(null)
  const exportPDFWithMethod = () => {
    const element = container.current || document.body
    element.classList.toggle("hidden")
    savePDF(element, {
      paperSize: "A3",
      margin: 40,
      fileName: `Settlement Details`,
    })
    element.classList.toggle("hidden")
  }

  const merchantId: string = searchParams.get("merchantId") ?? ""

  const navigate = useNavigate()
  const personalData = {
    "Settlement Reference": detailsData?.reference,
    "Transaction Amount": formatAmount(detailsData?.totalTransactionAmount),
    "Settlement Amount": formatAmount(detailsData?.totalPayoutAmount),
    "Settlement Date": detailsData?.settlementDate,
    "Payout Status": (
      <Status variant={detailsData?.status}>{detailsData?.status}</Status>
    ),
    "Transactions in Settlement Batch": detailsData?.numberOfTransactions,
  }

  return (
    <>
      <AppLayout title="">
        <div className="bg-white-default">
          <div className="flex justify-between p-6">
            <div className="">
              <div className="text-base font-medium">Settlement Details</div>
              <div className="text-sm text-gray-default">
                Details for settlement reference{" "}
                {personalData["Settlement Reference"]}
              </div>
            </div>
            {}
            <div className="flex gap-4">
              <div>
                <Button
                  onClick={() =>
                    navigate(
                      `/settlement/dispute/?settlementId=${settlementId}&merchantId=${merchantId}&reference=${searchParams.get(
                        "reference"
                      )}`
                    )
                  }
                  variant="outline"
                  className="px-5 py-1 rounded"
                  text="Raise a Dispute"
                />
              </div>
              <div>
                <Button
                  onClick={exportPDFWithMethod}
                  variant="outline"
                  className="px-5 py-1 rounded"
                  text="Download PDF"
                />
              </div>
            </div>
          </div>

          <hr />

          <div></div>
          <div className="flex flex-wrap p-6 text-sm text-gray-default">
            {Object.entries(personalData).map(([label, value]) => (
              <div key={label} className="w-[50%] md:w-[20%] px-2 mt-4">
                <div>{label}</div>
                <div className="text-black-default">{value}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="border border-gray-700 bg-white-default mt-10 rounded">
          <PayoutTransactions settlementId={Number(settlementId)} />
        </div>
        <div className="mt-4 p-2">
          <BackArrow
            onClick={() => navigate(-1)}
            label="Return To Settlement"
          />
        </div>
      </AppLayout>
      <SettlementDetailsPDF forwardedRef={container} />
    </>
  )
}

export default SettlementDetails
