import React from 'react'
import logo from '../../assets/images/TGIPrimaryLogo.svg'

type AuthLayoutPropsT ={
    title: string
    subTitle?: string
    content?: React.ReactNode
}

const AuthLayout = ({title, subTitle, content}: AuthLayoutPropsT) => {
  return (
    <div className=" w-[90%] md:w-[45%] mx-auto">
      <div className="flex justify-center mt-12">
        <img src={logo} className=" w-36" alt="tgipay-logo" />
      </div>
      <div className="text-center text-4xl font-semibold mt-10 mb-5">
        <h1>{title}</h1>
      </div>
      <div className="flex justify-center text-md mb-14">
        <h3 className='w-[60%] text-center'>{subTitle}</h3>
      </div>

      <div className=" bg-white-default p-7 rounded-lg">
        {content}
      </div>
    </div>
  )
}

export default AuthLayout

