import React from "react"
import Modal from "../../../components/modal"
import Button from "../../../components/button"

interface ModalPropsT {
  closeModal: () => void
  isOpen: boolean
  setSuccessModal: (success: boolean) => void
  setActiveTab: (activeTab: number) => void
  handleSubmitKYC: () => void
  isSubmittingKyc: boolean
}

const ReviewSubmitModal = ({
  closeModal,
  isOpen,
  setSuccessModal,
  setActiveTab,
  handleSubmitKYC,
  isSubmittingKyc,
}: ModalPropsT) => {
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <div className="text-center">
        <h2 className="font-semibold text-2xl mb-3">Submit Details</h2>
        <p className="font-light mb-3">
          You are about to submit your business details for verification.
        </p>
        <div className="flex flex-col justify-center items-center">
          <button
            onClick={() => setActiveTab(0)}
            className="text-primary-default px-5 py-2 border-0 mb-3 w-[7rem]"
          >
            Review
          </button>
          <Button
            onClick={handleSubmitKYC}
            loading={isSubmittingKyc}
            text="Submit KYC"
            className=" w-auto px-5 py-2"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ReviewSubmitModal
