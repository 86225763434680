import Wallet from "pages/wallets/components/wallet-card"

export const queryKeys = {
  transactions: {
    fetchTransactions: "fetchTransactions",
    fetchTransactionDetails: "fetchTransactionDetails",
  },
  dashboard: {
    allSuccessfulByValuePoints: "allSuccessfulByValuePoints",
    successfulCountPointsByDate: "successfulCountPointsByDate",
    countFailedByDate: "countFailedByDate",
    totalValueByDate: "totalValueByDate",
    failedCountpointsByDate: "failedCountpointsByDate",
    countSuccessfulByDate: "countSuccessfulByDate",
    countByDate: "countByDate",
    totalPendingSettlement: "totalPendingSettlement",
  },
  settlement: {
    fetchSettlements: "fetchSettlements",
    fetchSettlementDetails: "fetchSettlementDetails",
    fetchSettlementTransactions: "fetchSettlementTransactions",
  },
  audit: {
    fetchAuditRecords: "fetchAuditRecords",
    fetchAuditDetails: "fetchAuditDetails",
  },
  disputes: {
    fetchDisputeRecords: "fetchDisputeRecords",
    fetchDisputeDetails: "fetchDisputeDetails",
    fetchDisputeTransactions: "fetchDisputeTransactions",
    reopenDispute: "reopenDispute",
  },
  integration: {
    getMerchantIntegrationSettings: "getMerchantIntegrationSettings",
  },
  Wallet: {
    getUserWallets: "getUserWallets",
  },
}
