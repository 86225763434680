import React from "react"
import { formatAmount, formatWithCustomCurrency } from "utils/format-amount"
import Button from "components/button"
import { ReactComponent as Logo } from "../../../../../assets/images/TGIPrimaryLogo.svg"
import { FieldValues } from "react-hook-form"
import { format } from "date-fns"

interface PropsT {
  values: FieldValues
  invoiceData: InvoiceDetailsT
  isEditingInvoice: boolean
  handleUpdate: () => void
}

const EditProfessionalInvoicePreview = ({
  values,
  invoiceData,
  isEditingInvoice,
  handleUpdate,
}: PropsT) => {
  const calculateTotal = () => {
    let total = 0

    const itemsList = values.items ? values.items : []
    for (let i = 0; i < itemsList.length; i++) {
      const subTotal =
        parseFloat(values?.items[i]?.unitPrice) *
        parseFloat(values?.items[i]?.quantity)
      total += subTotal
    }
    return total
  }
  return (
    <div className="w-[40%] md:w-[40%]">
      <div className="text-center min-h-[2rem] font-medium">Preview</div>
      <div className="rounded-lg border-2 border-gray-600 min-h-[53rem] px-5 py-5">
        <div className="flex justify-between items-center">
          <Logo className=" w-16" />
        </div>
        <div className="flex gap-2 justify-between">
          <div className="">
            <div className="mb-2">
              <span className="text-gray-default mr-2">Invoice ID</span> <br />
              <span className="font-semibold">{invoiceData.invoiceNumber}</span>
            </div>
            <div>
              <span className="text-gray-default mr-2">Date Sent</span>{" "}
              <span className="font-semibold">{invoiceData.sentDate}</span>
            </div>
          </div>
          <div>
            <span className="text-gray-default mr-2">Payment Due</span>{" "}
            <span className="font-semibold">
              {" "}
              {values?.paymentDue
                ? format(new Date(values?.paymentDue), "dd-MM-yyyy")
                : ""}
            </span>
          </div>
        </div>

        <div className="mt-6 text-xs">
          <table className="w-full py-5 border">
            <thead>
              <tr className="flex gap-2 py-3 bg-[#F2F2F2]">
                <th className=" w-3/12 text-left ">Item</th>
                <th className=" w-3/12 text-center ">Quantity</th>
                <th className=" w-3/12 text-right ">Unit Price (NGN)</th>
                <th className=" w-3/12 text-right ">Sub Total (NGN)</th>
              </tr>
            </thead>
            <tbody className="font-medium">
              {values?.items?.map((item: InvoiceItemsT, index: number) => (
                <tr
                  key={index}
                  className={`flex gap-2 py-1 border  ${
                    index % 2 == 1 ? "bg-[#F2F2F250]" : ""
                  } `}
                >
                  <td className=" w-3/12 break-words ">{item.item}</td>
                  <td className="  w-3/12 text-center">{item.quantity}</td>
                  <td className="  w-3/12 text-right ">
                    {formatWithCustomCurrency(item.unitPrice, "")}
                  </td>
                  <td className="  w-3/12 text-right ">
                    {formatWithCustomCurrency(
                      item.unitPrice * item.quantity,
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <hr className="border-black-default mt-20" />

        <div className="flex justify-between mt-10">
          <div className="font-semibold">Total</div>
          <div className="">{formatAmount(calculateTotal())}</div>
        </div>

        <div className="mt-8">
          <div className="font-medium">Notes</div>
          <div className="text-gray-default">{values?.notes}</div>
        </div>
      </div>
      <div className="mt-10 font-medium flex gap-6 justify-end items-center">
        <Button
          onClick={handleUpdate}
          loading={isEditingInvoice}
          text="Save Changes"
          className="w-auto px-3 py-1 rounded border"
        />
      </div>
    </div>
  )
}

export default EditProfessionalInvoicePreview
