import React, { Fragment, useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { Popover, Transition } from "@headlessui/react"
import { ReactComponent as Elipsis } from "../../../assets/icons/vertical-elipsis.svg"
import ActivateDeactivateLink from "../modals/activate-deactivaet-link"
import DeletePaymentLink from "../modals/delete-payment-link"
import EditPaymentLink from "../modals/edit-payment-link"
import ViewPaymentLinkDetails from "../modals/view-payment-link-details"
import { copyToClipboard } from "../../../utils/copy-to-clipboard"
import {
  formatAmount,
  formatWithCustomCurrency,
} from "../../../utils/format-amount"

const columnHelper = createColumnHelper<LinkDetailsT>()

export const linksColumns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
  }),
  columnHelper.accessor("amount", {
    header: () => <span>Amount (NGN)</span>,
    cell: (info) => (
      <span>{formatWithCustomCurrency(info.renderValue() ?? 0, "")}</span>
    ),
  }),
  columnHelper.accessor("createdDate", {
    header: () => <span>Created</span>,
  }),
  columnHelper.accessor("url", {
    header: "Link",
    cell: (info) => (
      <div className="w-[12rem] truncate">{info.renderValue()}</div>
    ),
  }),
  columnHelper.accessor("url", {
    header: "Copy Link",
    cell: ({ cell, renderValue }) => {
      const {
        row: { original: linkData },
      } = cell
      return (
        <span
          onClick={() => copyToClipboard(renderValue())}
          className="text-primary-default font-bold cursor-pointer"
        >
          {linkData.active ? "Copy" : ""}
        </span>
      )
    },
  }),
  columnHelper.accessor("url", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell

      const [editLinkModal, setEditLinkModal] = useState<boolean>(false)
      const [viewLinkModal, setViewLinkModal] = useState<boolean>(false)
      const [deleteLinkModal, setDeleteLinkModal] = useState<boolean>(false)
      const [activateDeactivateModal, setActivateDeactivateModal] =
        useState<boolean>(false)
      return (
        <>
          <Popover className="relative">
            {() => (
              <>
                <Popover.Button className="px-3">
                  <span>
                    <Elipsis />
                  </span>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -top-1/4 left-3/4 z-50 mt-3 w-max -translate-x-full transform px-4 sm:px-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black-default ring-opacity-5">
                      <div className="relative grid gap-8 bg-white-default p-5">
                        {originalData.active && (
                          <>
                            <div
                              onClick={() => setViewLinkModal(true)}
                              className="-m-3 flex items-center rounded p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <div className="ml-4">
                                <p className="text-sm font-medium">
                                  View Details
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={() => setEditLinkModal(true)}
                              className="-m-3 flex items-center rounded p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                            >
                              <div className="ml-4">
                                <p className="text-sm font-medium">Edit Link</p>
                              </div>
                            </div>
                          </>
                        )}
                        <div
                          onClick={() => setActivateDeactivateModal(true)}
                          className="-m-3 flex items-center rounded p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="ml-4">
                            <p className="text-sm font-medium">
                              {originalData.active ? "Deactivate" : "Activate"}{" "}
                              Link
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => setDeleteLinkModal(true)}
                          className="-m-3 flex items-center rounded p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="ml-4">
                            <p className="text-sm font-medium">Delete Link</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>

                {viewLinkModal && (
                  <ViewPaymentLinkDetails
                    isOpen={viewLinkModal}
                    selectedLink={originalData}
                    closeModal={() => setViewLinkModal(false)}
                  />
                )}

                {editLinkModal && (
                  <div>
                    <EditPaymentLink
                      selectedLink={originalData}
                      closeModal={() => setEditLinkModal(false)}
                      isOpen={editLinkModal}
                    />
                  </div>
                )}

                {activateDeactivateModal && (
                  <ActivateDeactivateLink
                    selectedLink={originalData}
                    closeModal={() => setActivateDeactivateModal(false)}
                    isOpen={activateDeactivateModal}
                  />
                )}

                {deleteLinkModal && (
                  <DeletePaymentLink
                    selectedLink={originalData}
                    closeModal={() => setDeleteLinkModal(false)}
                    isOpen={deleteLinkModal}
                  />
                )}
              </>
            )}
          </Popover>
        </>
      )
    },
  }),
]
