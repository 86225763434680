import React from "react"
import { ReactComponent as SettlementIcon } from "../../../assets/icons/settlement.svg"
import { ReactComponent as ReloadIcon } from "assets/icons/reload.svg"

const NoSettlementData = () => {
  return (
    <div>
      <div className="flex flex-col justify-center items-center mt-40 mb-52">
        <div className=" text-secondary-default">
          <SettlementIcon />
        </div>
        <div className="font-semibold text-xl mt-5">No Settlement</div>
        <div className="text-gray-default">
          You have no settlement history on your account
        </div>
        <div className="mt-5">
          <ReloadIcon
            onClick={() => window.location.reload()}
            className="mt-4 cursor-pointer "
          />
        </div>
      </div>
    </div>
  )
}

export default NoSettlementData
