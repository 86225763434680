import * as yup from "yup"

export const BusinessDetailsSchema = yup.object().shape({
  businessName: yup.string().required("Business Name is required"),
  businessType: yup.object().shape({
    value: yup.string().required("Business Type is required"),
  }),
  businessCategory: yup.object().shape({
    value: yup.string().nullable().required("Business Category is required"),
  }),
  businessDescription: yup
    .string()
    .nullable()
    .required("Business Description is required"),
  businessAddress: yup
    .string()
    .nullable()
    .required("Business Address is required"),
  country: yup.object().shape({
    value: yup.string().required("Country is required"),
  }),
  state: yup.object().shape({
    value: yup.string().nullable().required("State is required"),
  }),
  city: yup.string().nullable().required("City is required"),
  taxId: yup.string().required("Tax ID is required"),
})

export const documentsSchema = yup.object().shape({
  idType: yup.string().required("ID type is required"),
  idDocument: yup
    .mixed()
    .required("A file is required")
    .test({
      message: "Please provide a supported file type",
      test: (file, context) => {
        const isValid = ["application/pdf", "application/pdf"].includes(
          file?.type
        )
        if (!isValid) context?.createError()
        return isValid
      },
    })
    .test({
      message: `File too big, can't exceed ${2000000}`,
      test: (file) => {
        const isValid = file?.size < 2000000
        return isValid
      },
    }),
  utilityBill: yup
    .mixed()
    .required("A file is required")
    .test({
      message: "Please provide a supported file type",
      test: (file, context) => {
        const isValid = ["application/pdf", "application/pdf"].includes(
          file?.type
        )
        if (!isValid) context?.createError()
        return isValid
      },
    })
    .test({
      message: `File too big, can't exceed ${2000000}`,
      test: (file) => {
        const isValid = file?.size < 2000000
        return isValid
      },
    }),
  cacDocument: yup
    .mixed()
    .required("A file is required")
    .test({
      message: "Please provide a supported file type",
      test: (file, context) => {
        const isValid = ["application/pdf", "application/pdf"].includes(
          file?.type
        )
        if (!isValid) context?.createError()
        return isValid
      },
    })
    .test({
      message: `File too big, can't exceed ${2000000}`,
      test: (file) => {
        const isValid = file?.size < 2000000
        return isValid
      },
    }),
  memorandumOfAssociation: yup
    .mixed()
    .required("A file is required")
    .test({
      message: "Please provide a supported file type",
      test: (file, context) => {
        const isValid = ["application/pdf", "application/pdf"].includes(
          file?.type
        )
        if (!isValid) context?.createError()
        return isValid
      },
    })
    .test({
      message: `File too big, can't exceed ${2000000}`,
      test: (file) => {
        const isValid = file?.size < 2000000
        return isValid
      },
    }),
})

export const bvnSchema = yup.object().shape({
  bvn: yup
    .string()
    .nullable()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(11, "BVN must be 11 digits")
    .max(11, "BVN must be 11 digits")
    .required("BVN is required"),
})

export const bankSchema = yup.object().shape({
  bankName: yup.object().shape({
    value: yup.string().nullable().required("Bank Name is required"),
  }),
  accountNumber: yup
    .string()
    .nullable()
    .matches(/^[0-9]+$/, "Account number be only digits")
    .matches(/^\d{10}$/, "Account Number must be exactly 10 digits")
    .required("Account Number is required"),
  accountName: yup.string().nullable().required("Account Name is required"),
})
