import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import Button from "components/button"
import BackArrow from "components/button/back-arrow"
import { DataRow } from "components/data-row/data-row"
import Status from "components/status/Status"
import { queryKeys } from "constants/queryKeys"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useTransactions from "./hooks/useTransactions"
import AppLayout from "layout/private/app-layout"
import { formatWithCustomCurrency } from "utils/format-amount"
import { savePDF } from "@progress/kendo-react-pdf"
import TransactionDetailsPDF from "./detailsPDF"

const TransactionDetails: React.FC = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const transactionId = searchParams.get("transactionId")

  const { transactionDetails } = useTransactions(
    queryKeys.transactions.fetchTransactionDetails,
    transactionId
  )

  const personalData = {
    "Transaction Reference": transactionDetails.data?.tgipayReference,
    "Customer Name":
      transactionDetails.data?.customerFirstName +
      " " +
      transactionDetails.data?.customerLastName,
    "Payment Channel": transactionDetails.data?.paymentChannel,
    "Payment Processor": transactionDetails.data?.processor,
    Amount: formatWithCustomCurrency(transactionDetails.data?.amount, "NGN"),
    "Transaction Date": transactionDetails.data?.transactionDate,
    Status: (
      <Status variant={transactionDetails.data?.status}>
        {transactionDetails.data?.status}
      </Status>
    ),
    "TGI Pay Fee": formatWithCustomCurrency(
      transactionDetails.data?.fee,
      "NGN"
    ),
    "Your Income ": formatWithCustomCurrency(
      transactionDetails.data?.income,
      "NGN"
    ),
    "Transaction Narration": transactionDetails.data?.narration,
  }

  const container = React.useRef(null)

  const exportPDFWithMethod = () => {
    const element = container.current || document.body
    savePDF(element, {
      paperSize: "A4",
      margin: 40,
      fileName: `Transaction Details`,
    })
  }

  return (
    <AppLayout title="" subTitle="">
      <div className="  bg-white-default pb-5">
        <div className="flex justify-between border-b-2 p-6">
          <div className="bg-white-default">
            <div className="text-xl font-medium">Transaction Details</div>
            <div className="text-sm text-gray-10/60">
              Details for transaction {transactionDetails.data?.tgipayReference}
            </div>
          </div>
          <div>
            <Button
              variant="linkPrimary"
              className="flex items-center gap-2"
              onClick={exportPDFWithMethod}
              text={
                <>
                  <span>Download PDF </span>
                  <DownloadIcon />
                </>
              }
            />
          </div>
        </div>
        <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
          {Object.entries(personalData).map(([label, value]) => (
            <DataRow
              key={label}
              isLoading={transactionDetails.loadingDetails}
              label={label}
              value={value}
            />
          ))}
        </div>
        <div className="flex justify-end ">
          <Button
            className="w-[10rem] h-10 flex items-center justify-center"
            text="Raise A Dispute"
            onClick={() =>
              navigate(
                `/transactions/dispute?transactionId=${transactionDetails.data?.id}&merchantId=${transactionDetails.data?.merchantId}`
              )
            }
          />
        </div>
      </div>

      <div className="mt-10">
        <BackArrow
          onClick={() => navigate(`/transactions`)}
          label="Return to Transactions"
        />
      </div>
      <TransactionDetailsPDF forwardedRef={container} />
    </AppLayout>
  )
}
export default TransactionDetails
