import React, { useEffect, useState } from "react"
import FullScreenLoader from "components/loader/fullscreen-loader"
import Button from "components/button"
import OtpInput from "components/input/otpInput"
import Modal from "components/modal"

interface ModalPropsT {
  isOpen: boolean
  otp: string
  isLoadingValidatePayment: boolean
  closeModal: () => void
  setOtp: (otp: string) => void
  validatePayment: () => void
  resendOtp: () => void
  isResendindOtp: boolean
}

const PaymentOtpModal = ({
  isOpen,
  otp,
  isLoadingValidatePayment,
  closeModal,
  setOtp,
  validatePayment,
  resendOtp,
  isResendindOtp,
}: ModalPropsT) => {
  const [error, setError] = useState<string>("")
  const [timeLeft, setTimeLeft] = useState(30)

  const OTPFieldValue = (otp: string[]) => {
    setOtp(otp.join(""))
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (otp?.length !== 6) {
      return setError("Please enter complete otp to continue")
    } else {
      validatePayment()
      return
    }
  }

  useEffect(() => {
    if (timeLeft === 0) {
      return // Don't start a new countdown if the time has run out
    }
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1) // Decrease the time by one second
    }, 1000) // Run the countdown every 1000ms (1 second)
    return () => clearInterval(countdown) // Clear the countdown interval on component unmount
  }, [timeLeft])

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} maxWidth="max-w-[45rem]">
      <form className="px-10" onSubmit={handleSubmit}>
        <div className="mb-8 font-semibold text-center">One Time Password</div>
        <div className="text-center mb-8">Enter OTP</div>
        <OtpInput
          fieldCount={6}
          onChange={(otp: string[]) => {
            OTPFieldValue(otp)
            setError("")
          }}
        />
        {error && (
          <div className="mt-2 text-center text-danger-default">{error}</div>
        )}

        {timeLeft !== 0 ? (
          <div className="text-center text-primary-default mt-5">
            Resend OTP in {timeLeft}s
          </div>
        ) : (
          <div className="text-center text-primary-default mt-5">
            <button onClick={resendOtp} type="button">
              Resend OTP
            </button>
          </div>
        )}
        <div className="mt-8">
          <Button
            loading={isLoadingValidatePayment}
            text="Submit"
            disabled={isLoadingValidatePayment}
          />
        </div>
      </form>

      {isResendindOtp && (
        <FullScreenLoader loading={isResendindOtp} message="Resending OTP" />
      )}
    </Modal>
  )
}

export default PaymentOtpModal
