import React from "react"

interface TabProps {
  tabs: Array<{ label: JSX.Element; content: JSX.Element }>
  activeTab: number
  setActiveTab: (index: number) => void
}

const Tabs: React.FC<TabProps> = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className="flex flex-col">
      <ul className="flex list-none mx-0 mt-0 mb-5 p-0 border-b border-gray-default">
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={`py-1 mr-16 font-semibold cursor-pointer ${
              activeTab === index
                ? "text-primary-default border-b-2 border-b-primary-default"
                : "text-gray-default"
            } ease-in duration-200`}
            onClick={() => setActiveTab(index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className=" py-5">{tabs[activeTab].content}</div>
    </div>
  )
}

export default Tabs
