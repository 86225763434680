import React, { ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { STATUS } from "types/statuses"
import { capitalizeFirstLetter } from "utils/capitalize-first-letter"
type StatusProps = {
  children: string | null | undefined
  variant: string
}
const Status: React.FC<StatusProps> = ({ children, variant }) => {
  const getVariantClass = (variant: string) => {
    switch (variant?.toLowerCase()) {
      case STATUS.SUCCESS:
      case STATUS.KYC.APPROVED:
      case STATUS.TRANSACTION.SUCCESSFUL:
      case STATUS.AUDIT.SUCCESSFUL:
      case STATUS.DISPUTES.RESOLVED:
        return "text-success-default"
      case STATUS.ERROR:
      case STATUS.KYC.DECLINED:
      case STATUS.TRANSACTION.FAILED:
      case STATUS.AUDIT.FAILED:
        return "text-danger-default"
      case STATUS.WARNING:
      case STATUS.KYC.PENDING:
      case STATUS.TRANSACTION.PENDING:
      case STATUS.DISPUTES.UNRESOLVED:
        return "text-caution-default"
      default:
        return ""
    }
  }

  return (
    <span
      className={twMerge(
        `font-medium ${variant?.length > 0 ? getVariantClass(variant) : ""}`
      )}
    >
      {capitalizeFirstLetter(children)}
    </span>
  )
}

export default Status
