import AppLayout from "layout/private/app-layout"
import React, { useState } from "react"
import TestApiKeys from "./components/test-api-keys"
import LiveApiKeys from "./components/live-api-keys"
import Tabs from "components/tab"
import { ReactComponent as BusinessDetailsIcon } from "assets/icons/business-details.svg"
import { ReactComponent as DocumentsIcon } from "assets/icons/documents.svg"
import { useQuery } from "react-query"
import { getMerchantIntegrationSettings } from "../../axios/services/merchant-services"
import { queryKeys } from "constants/queryKeys"
import { useAppSelector } from "redux/hooks"
import FullScreenLoader from "components/loader/fullscreen-loader"

const Integration = () => {
  const [activeTab, setActiveTab] = useState(0)
  const profileDetails = useAppSelector((state) => state.profile.profile)

  const { data: settingsData, isFetching: isFetchingSettings } = useQuery(
    queryKeys.integration.getMerchantIntegrationSettings,
    () => getMerchantIntegrationSettings(profileDetails?.businessId ?? 0)
  )

  const tabs = [
    {
      label: (
        <div className="flex items-baseline">
          <BusinessDetailsIcon className="mr-2" />{" "}
          <span>Test API Keys & Webhooks</span>
        </div>
      ),
      content: <TestApiKeys settingsData={settingsData?.data.data} />,
    },
    {
      label: (
        <div className="flex items-baseline">
          <DocumentsIcon className="mr-2" />{" "}
          <span>Live API Keys & Webhooks</span>
        </div>
      ),
      content: <LiveApiKeys settingsData={settingsData?.data.data} />,
    },
  ]

  return (
    <AppLayout title="Integration" subTitle="Desktop Subtitle">
      <div className="mt-5">
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      {isFetchingSettings && (
        <FullScreenLoader
          loading={isFetchingSettings}
          message="Fetching Settings"
        />
      )}
    </AppLayout>
  )
}

export default Integration
