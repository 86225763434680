import { customDateRanges } from "./customDateRanges"

export const DATE_GROUPINGS = {
  hour: "hour",
  day: "day",
  month: "month",
  year: "year",
}

export type DateGrouping = (typeof DATE_GROUPINGS)[keyof typeof DATE_GROUPINGS]

export const dayGroupingToDateRangeType = (
  dateRangeType: string
): DateGrouping => {
  switch (dateRangeType) {
    case customDateRanges.today:
    case customDateRanges.yesterday:
      return DATE_GROUPINGS.hour
    case customDateRanges.thisWeek:
    case customDateRanges.last30Days:
    case customDateRanges.last90Days:
      return DATE_GROUPINGS.day
    case customDateRanges.thisYear:
      return DATE_GROUPINGS.month
    default:
      return DATE_GROUPINGS.day
  }
}
