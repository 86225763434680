import { faCopy, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AxiosError } from "axios"
import {
  payByTransfer,
  verifyTransfer,
} from "../../../../axios/services/payment-services"
import Button from "components/button"
import React, { FC, useEffect } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import FullScreenLoader from "components/loader/fullscreen-loader"
import { useAppSelector } from "redux/hooks"
import { PAYMETHOD_IDS } from "constants/payment"
import { copyToClipboard } from "utils/copy-to-clipboard"
import { PaymentComponentProps } from "types/payment"
import { generatePaymentData } from "utils/generatePaymentData"
import { useNavigate } from "react-router-dom"

const PayViaTransfer: FC<PaymentComponentProps> = ({
  paymentData,
  successCallback,
  customerData,
}) => {
  const navigate = useNavigate()

  const { selectedPaymethod } = useAppSelector((state) => state.paymethod)

  useEffect(() => {
    if (
      paymentData.amount &&
      selectedPaymethod?.id === PAYMETHOD_IDS.PAY_VIA_TRANSFER
    ) {
      const data = generatePaymentData(
        paymentData.paymentType,
        paymentData,
        customerData
      )
      initiateTransfer(data)
    }
  }, [paymentData.amount])

  const {
    error,
    data: bankData,
    mutate: initiateTransfer,
    isLoading: isFetchingTransferAcct,
  } = useMutation(payByTransfer, {
    onError: (error: AxiosError) => {
      //@ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  const { mutate: verifyTransferTxn, isLoading: isVerifyingTransfer } =
    useMutation(verifyTransfer, {
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
      onSuccess: (response) => {
        if (successCallback) {
          successCallback({
            successMessage: response?.data?.message,
            status: response?.data?.status,
          })
        } else {
          if (response?.data.status === true) {
            navigate("/make-payment/payment-success")
          }
        }
      },
    })

  const handleTransferVerification = () => {
    verifyTransferTxn({
      paymentChannel: paymentData.paymentType,
      craccount: bankData?.data.data.accountNumber,
    })
  }

  const loadingBanDetails = (!error && !bankData) || isFetchingTransferAcct

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold mb-2">Bank Transfer</h2>
        <p className="text-gray-900">
          Make a bank transfer to the account below
        </p>
        {!error ? (
          <>
            <div className="flex flex-wrap gap-10 items-center bg-primary-default/10 border border-gray-100 p-6 my-6">
              <div className="w-[45%]">
                <div className="text-gray-900">Account Number</div>
                <div className="font-semibold text-2xl">
                  {bankData?.data.data.accountNumber}{" "}
                  <FontAwesomeIcon
                    onClick={() =>
                      copyToClipboard(bankData?.data.data.accountNumber)
                    }
                    className="cursor-pointer"
                    icon={faCopy}
                  />
                </div>
              </div>
              <div className="w-[45%]">
                <div className="text-gray-900">Bank Name</div>
                <div className="font-semibold text-2xl">
                  {bankData?.data.data.bankName}
                </div>
              </div>
              <div className="w-[45%]">
                <div className="text-gray-900">Account Name</div>
                <div className="font-semibold text-2xl">
                  {bankData?.data?.data?.accountName}
                </div>
              </div>
            </div>

            <div className="text-center mb-6 text-gray-800">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="text-yellow-600"
                fontSize={20}
              />{" "}
              Only confirm if you have made the transfer
            </div>
            <div className="flex justify-center">
              <Button
                onClick={handleTransferVerification}
                className="w-[15rem] px-4"
                text="I have done the transfer"
                loading={isVerifyingTransfer}
              />
            </div>
          </>
        ) : (
          <div className="text-danger-default p-10 flex justify-center items-center">
            Sorry we are unable to process bank transfers at the moment, please
            use another means of payment or try again later.
          </div>
        )}
      </div>

      {loadingBanDetails ? (
        <FullScreenLoader
          loading={loadingBanDetails}
          message="Fetching transaction details"
        />
      ) : null}
    </>
  )
}

export default PayViaTransfer
