import React, { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { verifyBvn } from "../../../axios/services/merchant-services"
import Button from "../../../components/button"
import OtpInput from "../../../components/input/otpInput"
import Modal from "../../../components/modal"

interface ModalPropsT {
  isOpen: boolean
  closeModal: () => void
  setBvnVerified: (bvnVerified: boolean) => void
  merchantId: number | undefined | null
  bvn: string
}

const BvnOtpModal = ({
  closeModal,
  isOpen,
  setBvnVerified,
  bvn,
}: ModalPropsT) => {
  const [error, setError] = useState<string>("")
  const [modalOTP, setModalOtp] = useState<string>("")

  const OTPFieldValue = (otp: string[]) => {
    setModalOtp(otp.join(""))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (modalOTP?.length !== 6) {
      return setError("Please enter complete otp to continue")
    } else {
      handleOtpValidation({
        otp: modalOTP,
        bvn: bvn,
      })
      return
    }
  }

  const { mutate: handleOtpValidation, isLoading } = useMutation(verifyBvn, {
    onSuccess: () => {
      toast.success("BVN verified successfully!")
      closeModal()
      setBvnVerified(true)
    },
    onError: (error) => {
      // @ts-ignore
      toast.error(error.response?.data.message)
    },
  })

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} maxWidth="max-w-[45rem]">
      <form className="px-10" onSubmit={handleSubmit}>
        <div className="mb-8 text-center">
          Enter the OTP sent to the phone number registered with BVN
        </div>
        <OtpInput
          fieldCount={6}
          onChange={(otp: string[]) => {
            OTPFieldValue(otp)
            setError("")
          }}
        />
        {error && (
          <div className="mt-2 text-center text-danger-default">{error}</div>
        )}
        <div className="mt-14">
          <Button loading={isLoading} text="Submit" />
        </div>
      </form>
    </Modal>
  )
}

export default BvnOtpModal
