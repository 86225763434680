import React from "react"
import styles from "./radio.module.scss"

type RadioT = {
  name: string
  value: string
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Radio = ({ name, value, checked, onChange }: RadioT) => {
  return (
    <div className={styles.field}>
      <input
        type="radio"
        onChange={onChange}
        name={name}
        checked={checked}
        value={value}
      />
    </div>
  )
}
