import React, { useState } from "react"
import Modal from "../../../components/modal"
import { ReactComponent as SuccessIcon } from "../../../assets/icons/green-check.svg"
import Button from "../../../components/button"
import { toast } from "react-toastify"
import { copyToClipboard } from "../../../utils/copy-to-clipboard"
import { formatAmount } from "../../../utils/format-amount"

interface ModalPropsT {
  closeModal: () => void
  isOpen: boolean
  selectedLink: LinkDetailsT | undefined
}

const CreatePaymentLinkSuccessModal = ({
  closeModal,
  isOpen,
  selectedLink,
}: ModalPropsT) => {
  const handleSubmit = () => {
    copyToClipboard(selectedLink?.url)
    closeModal()
  }
  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <div>
        <div className="text-center">
          <div className="flex justify-center mb-3">
            <SuccessIcon className="h-14" />
          </div>
          <h2 className="font-semibold text-lg mb-4">
            Link Generated Successfully!
          </h2>
        </div>
        <p className="mb-3">
          <div className=" text-gray-default">Name</div>
          <div>{selectedLink?.name}</div>
        </p>
        <p className="mb-3">
          <div className=" text-gray-default">Amount</div>
          <div>{formatAmount(selectedLink?.amount)}</div>
        </p>
        <p className="mb-3">
          <div className=" text-gray-default">Description</div>
          <div>{selectedLink?.description}</div>
        </p>
        <p className="mb-3">
          <div className=" text-gray-default">Link</div>
          <div className="input">
            <div className={`input_wrapper`}>
              <input
                disabled
                className="input_field truncate"
                value={selectedLink?.url}
              />
            </div>
          </div>
        </p>
        <p className="flex justify-end">
          <Button
            onClick={handleSubmit}
            text="Copy"
            className="py-2 w-[6rem]"
          />
        </p>
      </div>
    </Modal>
  )
}

export default CreatePaymentLinkSuccessModal
